import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  CompositeNavigationProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import useAppLocale from '~/data/hooks/useAppLocale';
import {
  ADD_USER_TO_GROUP,
  GROUP_SAMPLE,
  GROUP_USERS,
} from '~/data/operations/group';
import { GroupsUserGroupStatusChoices, UserType } from '~/data/types/graphql';
import { AuthStackParamList, RootStackParamList } from '~/navigation/types';
import InviteLayout from './layout';

type InviteNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList, 'Invite'>,
  StackNavigationProp<AuthStackParamList>
>;
type InviteRouteProp = RouteProp<RootStackParamList, 'Invite'>;

export default function Invite(): JSX.Element {
  const navigation = useNavigation<InviteNavProp>();
  const {
    params: { g: groupId, t: groupToken },
  } = useRoute<InviteRouteProp>();
  const {
    loading,
    data: groupData,
    error: groupError,
    refetch: reloadGroup,
  } = useQuery(GROUP_SAMPLE, {
    skip: !groupId,
    variables: {
      id: groupId as string,
    },
  });

  const { authUserId, authGroupId, onAuthGroup, onLogout } = useAuth();
  const isAuthenticated = !!authUserId;
  const isGroupAuth = !!authGroupId; //on MainTab
  const { locale, onChangeLocale } = useAppLocale({
    onReload: () => navigation.replace('Invite', { g: groupId, t: groupToken }),
  });

  const isTokenValid = groupToken && groupToken.length === 27;
  if (!groupId || groupError || !isTokenValid) {
    navigation.navigate('BrokenLink');
  }

  const [addUserToGroup, { loading: addUserToGroupLoading }] =
    useMutation(ADD_USER_TO_GROUP);

  useEffect(() => {
    const isPartOfGroup = !!groupData?.group?.sampleMembers?.find(
      (member) => member?.user.id === authUserId,
    );
    if (isAuthenticated && isPartOfGroup) {
      return navigation.navigate('InviteSuccess', {
        groupId: groupData?.group?.id as string,
      });
    }
  }, []);

  async function handleInvite() {
    try {
      if (!groupId || !authUserId) {
        throw new Error('Unable to add user to group: missing required data');
      }
      const { data: addUserToGroupData } = await addUserToGroup({
        variables: {
          input: {
            groupId,
            userId: authUserId,
            role: UserType.STUDENT,
            token: groupToken,
          },
        },
        refetchQueries: [
          {
            query: GROUP_USERS,
            variables: {
              user: authUserId,
              first: NO_PAG_LIST_OFFSET,
              status: isGroupAuth
                ? GroupsUserGroupStatusChoices.ACCEPTED
                : undefined,
            },
          },
        ],
      });
      const groupUser = addUserToGroupData?.addUserToGroup?.userGroup;
      isGroupAuth && groupUser && onAuthGroup(groupId, groupUser.id);
      navigation.navigate('InviteSuccess', { groupId });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  }

  const groupSample = groupData?.group;

  return (
    <InviteLayout
      onLogout={onLogout}
      isAuthenticated={isAuthenticated}
      groupSample={groupSample}
      loading={loading}
      joinLoading={addUserToGroupLoading}
      errorMessage={(groupError && groupError.message) || undefined}
      onBack={() => navigation.goBack()}
      onLogIn={() => {
        navigation.navigate('Login', { g: groupId, t: groupToken });
      }}
      onSignUp={() => {
        navigation.navigate('SignUpName', { g: groupId, t: groupToken });
      }}
      onJoin={handleInvite}
      onDecline={() => navigation.popToTop()}
      onRetry={() => reloadGroup()}
      selectedLocale={locale}
      onSelectedLocale={onChangeLocale}
    />
  );
}
