import { gql } from './../../types';

export const INSTITUTE_REQUEST_FRAGMENT = gql(`
  fragment InstituteRequest on InstituteRequestNode {
    id
    name
    street
    zip
    city
    type {
      id
      name
    }
    country
    user {
      id
      firstName
      lastName
      email
      avatar
    }
    status
    website
    created
  }
`);

export const INSTITUTE_REQUESTS = gql(`
  query InstituteRequests(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $name: String
    $status: Status
    $type: ID
    $street: String
    $country: InstitutesInstituteRequestCountryChoices
    $zip: String
    $website: String
  ) {
    instituteRequests(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      name: $name
      status: $status
      type: $type
      street: $street
      country: $country
      zip: $zip
      website: $website
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...InstituteRequest
        }
      }
    }
  }
`);

export const INSTITUTE_REQUEST = gql(`
  query InstituteRequest($id: ID!) {
    instituteRequest(id: $id) {
      ...InstituteRequest
    }
  }
`);

export const FIND_DUPLICATE_INSTITUTES = gql(`
  query FindDuplicateInstitutes(
    $name: String
    $street: String
    $website: String
    $customSearch: String
  ) {
    findDuplicateInstitutes(
      name: $name
      street: $street
      website: $website
      customSearch: $customSearch
    ) {
      ...Institute
    }
  }
`);

export const ACCEPT_INSTITUTE_REQUEST = gql(`
  mutation AcceptInstituteRequest($input: AdminAcceptInstituteRequestInput!) {
    adminAcceptInstituteRequest(input: $input) {
      instituteRequest {
        ...InstituteRequest
      }
      errors {
        field
        messages
      }
    }
  }
`);

export const REJECT_INSTITUTE_REQUEST = gql(`
  mutation RejectInstituteRequest($input: AdminRejectInstituteRequestInput!) {
    adminRejectInstituteRequest(input: $input) {
      instituteRequest {
        ...InstituteRequest
      }
    }
  }
`);

export const DELETE_INSTITUTE_REQUEST = gql(`
  mutation DeleteInstituteRequest($input: AdminDeleteInstituteRequestInput!) {
    adminDeleteInstituteRequest(input: $input) {
      success
    }
  }
`);
