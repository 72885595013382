import React from 'react';
import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { CITIES_ZIP } from '~/data/operations/institute';
import { GroupStackParamList } from '~/navigation/types';
import useThrottledState from '~/utils/hooks/useThrottledState';
import JoinSelectLocationLayout from './layout';

type JoinSelectLocationNavProp = StackNavigationProp<
  GroupStackParamList,
  'JoinSelectLocation'
>;

export default function JoinSelectLocation(): JSX.Element {
  const navigation = useNavigation<JoinSelectLocationNavProp>();

  const { country, onUpdateCountry } = useAuth();

  const [searchingLocation, setSearchingLocation] = useThrottledState<string>(
    '',
    500,
  );

  const { loading, data } = useQuery(CITIES_ZIP, {
    variables: {
      query:
        searchingLocation && searchingLocation.length > 2
          ? searchingLocation
          : null,
      country,
    },
  });

  const cities = data?.citiesZip?.map((city) => city?.name) as string[];

  return (
    <JoinSelectLocationLayout
      inputProgressTotal={4}
      inputProgress={1}
      country={country}
      cities={cities}
      loading={loading}
      onBack={() => navigation.goBack()}
      onUpdateCountry={onUpdateCountry}
      onSearchLocationChange={(location) => {
        setSearchingLocation(location.trim());
      }}
      onSelectedCity={(city) =>
        navigation.navigate('JoinSelectInstitute', { city })
      }
    />
  );
}

/**
 * TODO:
 * - Handle country
 */
