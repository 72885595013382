import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import { CREATE_MODULE_INSTANCE, MODULE } from '~/data/operations/yearbook';
import {
  readModuleInstancesQuery,
  writeModuleInstancesQuery,
} from '~/data/operations/yearbook/helpers';
import { YearbookStackParamList } from '~/navigation/types';
import ModuleDetailLayout from './layout';

type ModuleDetailNavProp = StackNavigationProp<
  YearbookStackParamList,
  'ModuleDetail'
>;
type ModuleDetailRouteProp = RouteProp<YearbookStackParamList, 'ModuleDetail'>;

export default function ModuleDetail(): JSX.Element {
  const navigation = useNavigation<ModuleDetailNavProp>();
  const {
    params: { moduleId, viewOnly },
  } = useRoute<ModuleDetailRouteProp>();

  const { authGroupId } = useAuth();

  const { data: moduleData } = useQuery(MODULE, {
    variables: {
      id: moduleId,
    },
  });

  const [createModuleInstance, { loading }] = useMutation(
    CREATE_MODULE_INSTANCE,
  );

  const onAddModule = async () => {
    try {
      const { data } = await createModuleInstance({
        variables: {
          input: {
            group: authGroupId,
            module: moduleId,
          },
        },
        update(cache, { data }) {
          const newModuleInstance = data?.createModuleInstance?.moduleInstance;

          const currentModuleInstancesQuery = readModuleInstancesQuery({
            cache,
            variables: {
              group: authGroupId,
              first: NO_PAG_LIST_OFFSET,
            },
          });

          if (
            currentModuleInstancesQuery &&
            currentModuleInstancesQuery.moduleInstances &&
            currentModuleInstancesQuery.moduleInstances.edges &&
            newModuleInstance
          ) {
            writeModuleInstancesQuery({
              cache,
              variables: {
                group: authGroupId,
                first: NO_PAG_LIST_OFFSET,
              },
              data: {
                ...currentModuleInstancesQuery,
                moduleInstances: {
                  ...currentModuleInstancesQuery.moduleInstances,
                  edges: [
                    ...currentModuleInstancesQuery.moduleInstances.edges,
                    {
                      __typename: 'ModuleInstanceNodeEdge',
                      node: newModuleInstance,
                    },
                  ],
                },
              },
            });
          }
        },
      });
      const messages = data?.createModuleInstance?.errors?.map(
        (error) => error?.messages[0],
      );
      const errorMessage = messages?.[0];
      if (errorMessage) {
        Snackbar.show(errorMessage);
        return;
      }
      navigation.navigate('Manage');
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <ModuleDetailLayout
      module={moduleData?.module}
      viewOnly={viewOnly}
      loading={loading}
      onBack={() => navigation.goBack()}
      onAddModule={onAddModule}
    />
  );
}
