import { useMutation } from '@apollo/client';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { LIKE_POST, REMOVE_LIKE_FROM_POST } from '../operations/post';

type PollLikeHookProps = {
  onLikePost: (postId: string, liked: boolean) => void;
};

const useLikePost = (): PollLikeHookProps => {
  const [likePost] = useMutation(LIKE_POST);
  const [removeLikeFromPost] = useMutation(REMOVE_LIKE_FROM_POST);

  const { authUserGroupId } = useAuth();

  const onLikePost = async (postId: string, liked: boolean) => {
    try {
      if (liked) {
        await removeLikeFromPost({
          variables: {
            input: {
              id: postId,
            },
            authUserGroupId,
          },
        });
      } else {
        await likePost({
          variables: {
            input: {
              id: postId,
            },
            authUserGroupId,
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };
  return {
    onLikePost,
  };
};

export default useLikePost;
