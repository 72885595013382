import React, { useRef, useState } from 'react';
import CheckBox from '~/components/CheckBox';
import ModalCountryPicker, {
  ModalCountryPickerHandler,
} from '~/components/ModalCountryPicker';
import ResidenceCountryCell from '~/components/ResidenceCountryCell';
import { Country } from '~/data/models/marketProfile';
import { SIGN_UP_TOTAL_STEPS } from '~/screens/Auth/constants';
import AuthLayout from '~/screens/Auth/layout/AuthLayout';
import {
  LanguageProps,
  SubmitButtonProps,
} from '~/screens/Auth/layout/AuthLayout/types';
import { t } from '~/utils/i18n';
import {
  CenterContent,
  CheckText,
  CheckTextLink,
  CheckTextView,
  ResidenceCountryView,
  Row,
  SectionHeader,
} from './style';

type SignUpTermLayoutProps = {
  inputProgress: number;
  loading: boolean;
  onBack: () => void;
  onShowPolicy: () => void;
  onShowTerm: () => void;
  onNext: (
    dataPolicyAcceptance: boolean,
    termsAcceptance: boolean,
    subscribeNewsletter: boolean,
  ) => void;
  onUpdateCountry(country: Country): void;
  country: Country;
} & LanguageProps;

export default function SignUpTerm({
  inputProgress,
  loading,
  selectedLocale,
  onBack,
  onShowPolicy,
  onShowTerm,
  onNext,
  onSelectedLocale,
  country,
  onUpdateCountry,
}: SignUpTermLayoutProps): JSX.Element {
  const [dataPolicyAcceptance, setDataPolicyAcceptance] =
    useState<boolean>(false);
  const [termsAcceptance, setTermsAcceptance] = useState<boolean>(false);
  const [subscribeNewsletter, setSubscribeNewsletter] =
    useState<boolean>(false);
  const countryModalizeRef = useRef<ModalCountryPickerHandler>(null);

  const isValidInput = (): boolean => {
    return dataPolicyAcceptance && termsAcceptance;
  };

  const handleNext = () => {
    isValidInput() &&
      onNext &&
      onNext(dataPolicyAcceptance, termsAcceptance, subscribeNewsletter);
  };

  const headerProps = {
    title: t('signUpTerm.title'),
    descp: t('signUpTerm.descp'),
    progress: inputProgress,
    total: SIGN_UP_TOTAL_STEPS,
  };

  const submitButtonProps: SubmitButtonProps = {
    isValidInput,
    text: t('signUpTerm.button'),
    loading,
    onPress: handleNext,
    minWidth: 260,
    size: '2xl',
  };

  const languageProps = {
    selectedLocale,
    onSelectedLocale,
  };

  return (
    <AuthLayout
      onBack={onBack}
      headerProps={headerProps}
      submitButtonProps={submitButtonProps}
      languageProps={languageProps}
    >
      <CenterContent>
        <ResidenceCountryView>
          <SectionHeader sub title={t('signUpTerm.selectCountryOfResidence')} />
          <ResidenceCountryCell
            country={country}
            onSelect={() => countryModalizeRef.current?.open()}
          />
        </ResidenceCountryView>
        <Row>
          <CheckBox
            testID={'checkBoxPolicy'}
            size={'medium'}
            checked={dataPolicyAcceptance}
            onChange={(checked) => setDataPolicyAcceptance(checked)}
          />
          <CheckTextView>
            <CheckText>
              {t('signUpTerm.checkText1')}
              <CheckTextLink
                testID={'buttonLinkPolicy'}
                onPress={() => onShowPolicy && onShowPolicy()}
              >
                {t('dataPolicy')}
              </CheckTextLink>
              {t('signUpTerm.checkText2')}
            </CheckText>
          </CheckTextView>
        </Row>
        <Row>
          <CheckBox
            testID={'checkBoxTerm'}
            size={'medium'}
            checked={termsAcceptance}
            onChange={(checked) => setTermsAcceptance(checked)}
          />
          <CheckTextView>
            <CheckText>
              {t('signUpTerm.checkText1')}
              <CheckTextLink
                testID={'buttonLinkTerm'}
                onPress={() => onShowTerm && onShowTerm()}
              >
                {t('term')}
              </CheckTextLink>
              {t('signUpTerm.checkText2')}
            </CheckText>
          </CheckTextView>
        </Row>
        <Row>
          <CheckBox
            testID={'checkBoxUpdate'}
            size={'medium'}
            checked={subscribeNewsletter}
            onChange={(checked) => setSubscribeNewsletter(checked)}
          />
          <CheckTextView>
            <CheckText>{t('signUpTerm.checkUpdate')}</CheckText>
          </CheckTextView>
        </Row>
        <ModalCountryPicker
          testID={'countryPicker'}
          ref={countryModalizeRef}
          initialSelectedCountry={country}
          onUpdateCountry={(pickedCountry) => {
            countryModalizeRef.current?.close();
            onUpdateCountry(pickedCountry);
          }}
        />
      </CenterContent>
    </AuthLayout>
  );
}
