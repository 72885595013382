import { Platform, Animated } from 'react-native';
import styled, { css } from 'styled-components/native';
import Image from '~/components/Image';
import { InputFieldSizeType, InputFieldState } from './types';

export const Container = styled.View``;

interface IFocusContainer {
  state: InputFieldState;
}
export const FocusContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 1.0,
})<IFocusContainer>`
  border-width: 4px;
  border-color: ${({ state, theme }) =>
    state === 'focus' ? theme.color.brand_02 : 'transparent'};
  border-radius: 55px;
  ${Platform.OS === 'web' &&
  css`
    padding-left: 0;
    padding-right: 0;
  `}
`;

interface ISizeAwareView {
  size: InputFieldSizeType;
}

interface IBoxContainer extends ISizeAwareView {
  validationColor: string;
  isTwoLines?: boolean;
}
const INPUT_HEIGHT = 24;
const INPUT_HEIGHT_FILLED = 22;

export const LABEL_HEIGHT = 24;
export const LABEL_HEIGHT_FILLED = 12;

//default: VERTICAL_PADDING * 2 + INPUT_HEIGHT = 20*2 + 24 = 64
//default_filled: VERTICAL_PADDING_FILLED * 2 + LABEL_HEIGHT_FILLED + INPUT_HEIGHT_FILLED = 15*2 + 12 + 22 = 64
const BOX_HEIGHT = 64;
//default_filled_multiline: VERTICAL_PADDING_FILLED * 2 + LABEL_HEIGHT_FILLED + INPUT_HEIGHT_FILLED * 2 = 15*2 + 12 + 22*2 = 86
const BOX_HEIGHT_MULTILINE = 86;
//small: 16*2 + 18 = 50
const BOX_HEIGHT_SMALL = 50;

export const BoxContainer = styled.View<IBoxContainer>`
  flex-direction: row;
  border-width: 1px;
  border-color: ${({ validationColor }) => validationColor};
  border-radius: 55px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  overflow: hidden;
  height: ${({ isTwoLines = false, size }) =>
    size === 'default'
      ? isTwoLines
        ? BOX_HEIGHT_MULTILINE
        : BOX_HEIGHT
      : BOX_HEIGHT_SMALL}px;
`;

export const Content = styled.View<ISizeAwareView>`
  flex: 1;
  margin-horizontal: 16px;
  display: flex;
  justify-content: center;
`;
//isTwoLines is undefined because on android we need to start with auto height, to then define onContentSizeChange
export const TextInput = styled.TextInput<{
  isFilled: boolean;
  isTwoLines?: boolean;
}>`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 18px;
  min-height: 22px;
  ${({ isFilled, isTwoLines }) =>
    isTwoLines === undefined
      ? ''
      : isFilled
      ? isTwoLines
        ? `height:${INPUT_HEIGHT_FILLED * 2}px;`
        : `height:${INPUT_HEIGHT_FILLED}px;`
      : `height:${INPUT_HEIGHT}px;`}
  color: ${({ theme }) => theme.color.base.c10};
  ${Platform.OS == 'web' &&
  `
    outline-color: transparent;
    outline-style: none;
  `}
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const Label = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  position: absolute;
  left: 0px;
`;

//label is better with absolute path for animating
// but makes more sense to render the same height to align in the center
export const LabelFlex = styled.View`
  height: ${LABEL_HEIGHT_FILLED}px;
`;

export const AnimatedLabel = Animated.createAnimatedComponent(Label);

export const InfoContent = styled.View`
  flex-direction: row;
  height: 22px;
  padding-horizontal: 26px;
  alig-items: center;
`;

export const InfoLabel = styled.Text<{ color?: string }>`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
  font-weight: 400;
  color: ${({ color, theme }) => color || theme.color.base.c10};
`;

export const InfoLinkView = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})``;

export const InfoLink = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.brand_02};
  text-decoration-line: underline;
  margin-left: 3px;
`;

export const AddOn = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: 400;
  color: ${({ theme }) => theme.color.base.c6};
`;

export const TypeContent = styled.View<ISizeAwareView>`
  min-width: 30px;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
`;

export const PhoneContent = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  min-width: 30px;
  align-items: center;
  flex-direction: row;
  padding-left: 16px;
`;

export const PhoneSelection = styled.View`
  padding-left: ${({ theme }) => theme.spacing.tiny};
`;

export const PhoneCode = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.base.c6};
  padding-left: ${({ theme }) => theme.spacing.medium};
`;

export const FlagImage = styled(Image)`
  height: 15px;
  width: 24px;
`;

export const IconView = styled.View`
  padding-left: 6px;
  padding-right: 16px;
  justify-content: center;
`;

export const PreTagContent = styled.View`
  background-color: ${({ theme }) => theme.color.base.c2};
  padding-left: 16px;
  padding-right: 8px;
  justify-content: center;
`;

export const PreTagText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.base.c6};
`;
