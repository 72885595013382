import React from 'react';
import { useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import { Group } from '~/data/models/group';
import { GROUPS } from '~/data/operations/group';
import { getGroupSectionsByYear } from '~/data/utils';
import { GroupStackParamList } from '~/navigation/types';
import JoinSelectGroupLayout from './layout';

type JoinSelectGroupNavProp = StackNavigationProp<
  GroupStackParamList,
  'JoinSelectGroup'
>;
type JoinSelectGroupRouteProp = RouteProp<
  GroupStackParamList,
  'JoinSelectGroup'
>;

export default function JoinSelectGroup(): JSX.Element {
  const navigation = useNavigation<JoinSelectGroupNavProp>();
  const {
    params: { instituteId },
  } = useRoute<JoinSelectGroupRouteProp>();

  const groupsVar = {
    institute: instituteId,
    after: null,
    first: DEFAULT_LIST_OFFSET,
  };
  const {
    loading,
    data: groupsData,
    fetchMore,
  } = useQuery(GROUPS, {
    variables: groupsVar,
  });
  const groups: Group[] =
    (groupsData?.groups?.edges.map((edge) => edge?.node) as Group[]) || [];

  const hasNextPage = groupsData?.groups?.pageInfo.hasNextPage;
  const endCursor = groupsData?.groups?.pageInfo.endCursor;

  const onLoadMore = () => {
    hasNextPage &&
      fetchMore({
        variables: {
          ...groupsVar,
          after: endCursor,
        },
      });
  };

  const groupsByYear = getGroupSectionsByYear(groups);

  return (
    <JoinSelectGroupLayout
      inputProgressTotal={4}
      inputProgress={3}
      groupsByYear={groupsByYear}
      loading={loading}
      onBack={() => navigation.goBack()}
      onSelectedGroup={(group) =>
        navigation.navigate('JoinGroupDetail', { groupId: group.id })
      }
      onCreateGroup={() => navigation.navigate('CreateGroup', { instituteId })}
      onLoadMore={onLoadMore}
    />
  );
}
