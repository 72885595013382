import { useCallback, useState } from 'react';
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import Snackbar from '~/components/Snackbar';
import { BestMatchedAsset } from '~/data/models/campaign';
import {
  BEST_MATCH_ASSET,
  CREATE_ASSET_EVENT,
} from '~/data/operations/campaign';
import {
  BestMatchAssetQuery,
  BestMatchAssetQueryVariables,
  ChannelType,
  EventTypeEnum,
  VariantTypeEnum,
} from '~/data/types/graphql';

type UseAdsTrackingOptions = {
  authUserId: string;
  variantType: VariantTypeEnum;
};

type UseAdsTrackingOutput = {
  handleViewTracking: (postId: string) => Promise<void>;
  handleAdsClick: (
    postId: string,
    trackingUrl: string | undefined,
    openBannersInBrowser: (url: string) => void,
  ) => Promise<void>;
  bestMatchedAsset: BestMatchedAsset[];
  refetch: (
    variables?: Partial<BestMatchAssetQueryVariables> | undefined,
  ) => Promise<ApolloQueryResult<BestMatchAssetQuery>>;
  randomIndex: number;
};
const useAdsTracking = ({
  authUserId,
  variantType,
}: UseAdsTrackingOptions): UseAdsTrackingOutput => {
  const [createAssetEvent] = useMutation(CREATE_ASSET_EVENT);

  const handleViewTracking = async (postId: string) => {
    try {
      // You need to implement the createAssetEvent function and authUserId as needed.
      await createAssetEvent({
        variables: {
          input: {
            assetId: postId,
            userId: authUserId,
            variant: variantType,
            eventType: EventTypeEnum.VIEW,
          },
        },
      });
    } catch (error) {
      if (error instanceof Error) {
        Snackbar.show(error.message);
      }
    }
  };

  const handleAdsClick = async (
    postId: string,
    trackingUrl: string | undefined,
    openBannersInBrowser: (url: string) => void,
  ) => {
    if (trackingUrl) {
      try {
        openBannersInBrowser(trackingUrl);
        // If the URL is successfully opened, send the request to the backend.
        await createAssetEvent({
          variables: {
            input: {
              assetId: postId,
              userId: authUserId,
              variant: variantType,
              eventType: EventTypeEnum.CLICK,
            },
          },
        });
      } catch (error) {
        if (error instanceof Error) {
          Snackbar.show(error.message);
        }
      }
    }
  };

  const { data: bestMatchAssets, refetch } = useQuery(BEST_MATCH_ASSET, {
    variables: {
      userId: authUserId,
      channelType: ChannelType.BANNER_ADS,
    },
  });

  const bestMatchedAsset: BestMatchedAsset[] =
    bestMatchAssets?.bestMatchAssets as BestMatchedAsset[];

  const [randomIndex, setRandomIndex] = useState(
    Math.floor(Math.random() * bestMatchedAsset?.length),
  );

  useFocusEffect(
    useCallback(() => {
      setRandomIndex(Math.floor(Math.random() * bestMatchedAsset?.length));
    }, []),
  );

  if (!authUserId) {
    return {
      handleViewTracking: async () => {},
      handleAdsClick: async () => {},
      refetch,
      randomIndex: 0,
      bestMatchedAsset: [],
    };
  }

  return {
    handleViewTracking,
    handleAdsClick,
    refetch,
    randomIndex,
    bestMatchedAsset,
  };
};

export default useAdsTracking;
