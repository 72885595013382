import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  useNavigation,
  useRoute,
  RouteProp,
  CompositeNavigationProp,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import useAuthTeams from '~/data/hooks/useAuthTeams';
import useCollagesPhotos from '~/data/hooks/useCollagesPhotos';
import { COLLAGES_ALBUM, MODULE_INSTANCE } from '~/data/operations/yearbook';
import { RootStackParamList, YearbookStackParamList } from '~/navigation/types';
import CollagesAlbumDetailLayout, { LocalCollagesPhoto } from './layout';

type CollagesAlbumDetailNavProp = CompositeNavigationProp<
  StackNavigationProp<YearbookStackParamList, 'CollagesAlbumDetail'>,
  StackNavigationProp<RootStackParamList>
>;

type CollagesAlbumDetailRouteProp = RouteProp<
  YearbookStackParamList,
  'CollagesAlbumDetail'
>;

export type PhotoUriMap = { [key: string]: string };

export default function CollagesAlbumDetail(): JSX.Element {
  const navigation = useNavigation<CollagesAlbumDetailNavProp>();
  const {
    params: { moduleInstanceId, collageAlbumId, viewOnly },
  } = useRoute<CollagesAlbumDetailRouteProp>();

  const [localPhotoUriMap, setLocalPhotoUriMap] = useState<PhotoUriMap>({});
  const [localPhotoCount, setLocalPhotoCount] = useState(1);

  const { authUserGroupId, yearbookAdmin } = useAuth();
  const { authTeamIds } = useAuthTeams();

  const {
    collagesPhotos,
    loading: collagesPhotosLoading,
    onUploadPhoto,
    onRefresh: onRefreshCollagesPhotos,
    onLoadMore: onLoadMoreCollagesPhotos,
  } = useCollagesPhotos({
    moduleInstanceId,
    collageAlbumId,
  });

  const { data: moduleInstanceData } = useQuery(MODULE_INSTANCE, {
    variables: {
      id: moduleInstanceId,
    },
  });

  const collagesSetup = moduleInstanceData?.moduleInstance?.collagesSetup;
  const addPhotosTeam = collagesSetup?.addPhotosTeam;
  const viewPhotosTeam = collagesSetup?.viewPhotosTeam;
  const viewAll =
    viewPhotosTeam === null ||
    authTeamIds.includes(viewPhotosTeam?.id as string);

  const addPhotoEnabled =
    addPhotosTeam === null || authTeamIds.includes(addPhotosTeam?.id as string);

  const isEditableByAuthUser = useMemo(() => {
    const createTeamId = collagesSetup?.createAlbumsTeam?.id;
    if (yearbookAdmin || (createTeamId && authTeamIds.includes(createTeamId))) {
      return true;
    } else {
      return false;
    }
  }, [collagesSetup?.createAlbumsTeam, authTeamIds, yearbookAdmin]);

  const {
    data: collagesAlbumData,
    loading: collagesLoading,
    refetch: refetchCollageAlbum,
  } = useQuery(COLLAGES_ALBUM, {
    skip: !authUserGroupId,
    variables: {
      id: collageAlbumId,
    },
  });

  const photosCount = collagesAlbumData?.collagesAlbum?.photosCount || 0;

  const collagesAlbum = collagesAlbumData?.collagesAlbum;

  const onUploadImages = (photoUris: string[]) => {
    const photoUriMap: PhotoUriMap = {};
    for (let i = 0; i < photoUris.length; i++) {
      const photoUri = photoUris[i];
      const localPhotoId = `lp${localPhotoCount + i}`;
      photoUriMap[localPhotoId] = photoUri;
    }
    setLocalPhotoCount(localPhotoCount + photoUris.length);
    setLocalPhotoUriMap((lPhotoUriMap) => ({
      ...photoUriMap,
      ...lPhotoUriMap,
    }));
  };

  const localCollagesPhotos: LocalCollagesPhoto[] = Object.keys(
    localPhotoUriMap,
  ).map<LocalCollagesPhoto>((localPhotoId) => {
    return {
      __typename: 'LocalCollagesPhoto',
      id: localPhotoId,
      photoUri: localPhotoUriMap[localPhotoId],
    };
  });

  useEffect(() => {
    const localPhotoIds = Object.keys(localPhotoUriMap);
    const lastLocalPhotoId = localPhotoIds.pop();
    if (lastLocalPhotoId) {
      onUploadPhoto(localPhotoUriMap[lastLocalPhotoId], () => {
        const localPhotoUriMapKeys = Object.keys(localPhotoUriMap);
        const filtedMapKeys = localPhotoUriMapKeys.filter(
          (localPhotoUriMapKey) => lastLocalPhotoId != localPhotoUriMapKey,
        );
        const filtedredLocalPhotoUriMap = filtedMapKeys.reduce<PhotoUriMap>(
          (obj, filteredMapKey) => ({
            ...obj,
            [filteredMapKey]: localPhotoUriMap[filteredMapKey],
          }),
          {},
        );
        setLocalPhotoUriMap(filtedredLocalPhotoUriMap);
      });
    }
  }, [localPhotoUriMap]);

  return (
    <CollagesAlbumDetailLayout
      collagesAlbumName={collagesAlbum?.name || ''}
      photosCount={photosCount}
      collagesPhotos={collagesPhotos}
      localCollagesPhotos={localCollagesPhotos}
      viewOnly={viewOnly}
      viewAll={viewAll}
      loading={collagesLoading || collagesPhotosLoading}
      addPhotoEnabled={addPhotoEnabled}
      onBack={() => navigation.goBack()}
      onEditAlbum={() =>
        navigation.navigate('CreateCollage', {
          moduleInstanceId,
          collageAlbumId: collageAlbumId,
          currentText: collagesAlbum?.name,
        })
      }
      onUploadImages={onUploadImages}
      onSelectedPhoto={(collagePhotoId) =>
        navigation.navigate('CollagesPhotoDetail', {
          moduleInstanceId,
          collageAlbumId,
          collagePhotoId,
          viewOnly,
        })
      }
      onRefresh={() => {
        refetchCollageAlbum();
        onRefreshCollagesPhotos();
      }}
      onLoadMore={onLoadMoreCollagesPhotos}
      isEditableByAuthUser={isEditableByAuthUser}
    />
  );
}
