import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { Container, Spinner } from '~/components/common/style.web';
import { useAuth } from '~/context/auth';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import {
  CUSTOM_PAGES_INSTANCE,
  DESTROY_CUSTOM_PAGES_INSTANCE,
  UPDATE_CUSTOM_PAGES_INSTANCE,
} from '~/data/operations/yearbook';
import {
  readCustomPagesInstanceQuery,
  writeCustomPagesInstanceQuery,
} from '~/data/operations/yearbook/helpers';
import { UpdateCustomPagesInstanceInput } from '~/data/types/graphql';
import { YearbookStackParamList } from '~/navigation/types';
import EditCustomPageLayout from './layout';

type EditCustomPageNavProp = StackNavigationProp<
  YearbookStackParamList,
  'EditCustomPage'
>;

type CustomRouteProp = RouteProp<YearbookStackParamList, 'EditCustomPage'>;

export default function EditCustomPage(): JSX.Element {
  const navigation = useNavigation<EditCustomPageNavProp>();
  const { authUserGroupId } = useAuth();
  const {
    params: { customPageId, moduleInstanceId },
  } = useRoute<CustomRouteProp>();

  const { data, loading } = useQuery(CUSTOM_PAGES_INSTANCE, {
    variables: {
      id: customPageId,
    },
  });

  const [updateCustomPageInstance] = useMutation(UPDATE_CUSTOM_PAGES_INSTANCE, {
    update(cache, { data }) {
      const customPagesInstance =
        data?.updateCustomPagesInstance?.customPagesInstance;

      const customPagesInstancesQuery = readCustomPagesInstanceQuery({
        cache,
        variables: {
          after: null,
          first: DEFAULT_LIST_OFFSET,
          moduleInstance: moduleInstanceId,
          userGroup: authUserGroupId,
        },
      });

      if (!customPagesInstance) return;

      if (
        customPagesInstancesQuery &&
        customPagesInstancesQuery.customPagesInstances?.edges
      ) {
        // Find and update the corresponding edge for the updated custom page instance
        const edges = customPagesInstancesQuery.customPagesInstances.edges.map(
          (edge) => {
            if (
              edge?.node !== null &&
              edge?.node.id === customPagesInstance.id
            ) {
              return {
                ...edge,
                node: customPagesInstance,
              };
            }
            return edge;
          },
        );

        if (
          customPagesInstancesQuery &&
          customPagesInstancesQuery?.customPagesInstances?.edges &&
          customPagesInstance
        ) {
          writeCustomPagesInstanceQuery({
            cache,
            variables: {
              after: null,
              first: DEFAULT_LIST_OFFSET,
              moduleInstance: moduleInstanceId,
              userGroup: authUserGroupId,
            },
            data: {
              ...customPagesInstancesQuery,
              customPagesInstances: {
                ...customPagesInstancesQuery.customPagesInstances,
                edges, // Updated edges array
              },
            },
          });
        }
      }
    },
  });

  const [deleteCustomPageInstance] = useMutation(
    DESTROY_CUSTOM_PAGES_INSTANCE,
    {
      update(cache, { data }) {
        const deletedId =
          data?.destroyCustomPagesInstance?.customPagesInstanceId;

        if (!deletedId) return;

        const customPagesInstancesQuery = readCustomPagesInstanceQuery({
          cache,
          variables: {
            after: null,
            first: DEFAULT_LIST_OFFSET,
            moduleInstance: moduleInstanceId,
            userGroup: authUserGroupId,
          },
        });

        if (
          customPagesInstancesQuery &&
          customPagesInstancesQuery.customPagesInstances?.edges
        ) {
          const newEdges =
            customPagesInstancesQuery.customPagesInstances.edges.filter(
              (edge) => edge?.node !== null && edge?.node.id !== deletedId,
            );

          writeCustomPagesInstanceQuery({
            cache,
            variables: {
              after: null,
              first: DEFAULT_LIST_OFFSET,
              moduleInstance: moduleInstanceId,
              userGroup: authUserGroupId,
            },
            data: {
              ...customPagesInstancesQuery,
              customPagesInstances: {
                ...customPagesInstancesQuery.customPagesInstances,
                edges: newEdges, // Update edges without the deleted node
              },
            },
          });
        }
      },
    },
  );

  const onEditCustomPage = async (data: UpdateCustomPagesInstanceInput) => {
    try {
      await updateCustomPageInstance({
        variables: {
          input: data,
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDeleteCustomPage = async () => {
    try {
      await deleteCustomPageInstance({
        variables: {
          input: {
            id: customPageId,
          },
        },
      });
      navigation.goBack();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  if (loading) {
    return (
      <Container centerContent>
        <Spinner testID="loadingSpinner" />
      </Container>
    );
  }

  return (
    <EditCustomPageLayout
      onBack={() => navigation.goBack()}
      customPageData={data}
      onEditCustomPage={onEditCustomPage}
      onDeleteCustomPage={onDeleteCustomPage}
    />
  );
}
