import { ScrollView } from 'react-native';
import styled from 'styled-components/native';
import ImageComp from '~/components/Image';

const COLOR_BOX_HEIGHT_MOBILE = 200;

export const ScrollContent = styled(ScrollView).attrs({
  contentContainerStyle: {
    flex: 1,
  },
})`
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
`;

export const Content = styled.View`
  flex: 1;
`;

export const Title = styled.Text`
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 32px;
`;

export const SmallImage = styled(ImageComp)`
  width: 92px;
  height: 74px;
  margin-left: -18px;
  margin-top: 20px;
  transform: rotate(-10deg);
`;

export const Image = styled(ImageComp)`
  width: 160px;
  height: 160px;
  border-radius: 5px;
  transform: rotate(10deg);
  margin-left: 22px;
  border-width: 5px;
  border-color: ${({ theme }) => theme.color.base.c2};
  margin-vertical: 36px;
  background-color: ${({ theme }) => theme.color.base.c1};
`;

export const SectionTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 18px;
  line-height: 23px;
`;

export const SectionValue = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 18px;
  line-height: 23px;
`;

export const MembersAvatarRow = styled.View`
  margin-top: 16px;
  flex-direction: row;
`;

export const AvatarContent = styled.View<{ orderNo?: number }>`
  margin-left: ${({ orderNo }) => (orderNo === 0 ? 0 : -12)}px;
`;

export const AvatarMore = styled.View`
  width: 59px;
  height: 59px;
  border-radius: 59px;
  background-color: ${({ theme }) => theme.color.base.c3};
  align-items: center;
  justify-content: center;
`;

export const AvatarMoreText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c0};
  font-size: 20px;
`;

export const ModalContainer = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding-horizontal: 16px;
  padding-bottom: 54px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

export const ModalContent = styled.View`
  padding-vertical: 32px;
`;

export const ModalTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 32px;
`;

export const ModalDescp = styled.Text`
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 16px;
  line-height: 22px;
`;

export const ColorBox = styled.View<{ isDesktop?: boolean }>`
  position: absolute;
  background-color: ${({ theme }) => theme.color.brand_01};
  z-index: -1;
  top: 0px;
  left: 0px;
  right: 0px;
  height: ${({ theme }) =>
    COLOR_BOX_HEIGHT_MOBILE + theme.safeAreaInsets.top}px;
`;

export const ImageWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

export const RowSpaceBetween = styled.View`
  flex-direction: row;
  gap: 40px;
  margin-top: 24px;
`;

export const Footer = styled.View`
  margin-bottom: 24px;
`;
