import { Image, Platform } from 'react-native';
import styled from 'styled-components/native';

export const GuideContent = styled.View`
  flex-direction: row;
  padding: 16px;
  margin-top: 28px;
  border: 1px solid ${({ theme }) => theme.color.base.c2};
  border-radius: 5px;
  background-color: white;
  ${Platform.select({
    android: `
      elevation: 2;
    `,
  })}
`;

export const GuideText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
`;

export const ImageMegaphone = styled(Image)`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

export const Header = styled.View`
  padding-horizontal: 16px;
  padding-bottom: 8px;
`;

export const RestrictedAllCustomPagesContainer = styled.View``;

export const LockImage = styled(Image)`
  width: 48px;
  height: 64px;
`;

export const RestrictedAllCustomPagesDescription = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 24px;
`;

export const RestrictedAllCustomPagesTeamName = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 24px;
`;
