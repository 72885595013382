import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import useUserGroups from '~/data/hooks/useUserGroups';
import { CustomListOption } from '~/data/models/yearbook';
import {
  CREATE_RANKINGS_ANSWER,
  CUSTOM_LIST_OPTIONS,
  RANKINGS_QUESTION,
  UPDATE_RANKINGS_ANSWER,
} from '~/data/operations/yearbook';
import { AllowedVoteType, AnswerType, Status } from '~/data/types/graphql';
import { YearbookStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import RankingVoteLayout, { UserVote } from './layout';

type RankingVoteNavProp = StackNavigationProp<
  YearbookStackParamList,
  'RankingVote'
>;

type RankingVoteRouteProp = RouteProp<YearbookStackParamList, 'RankingVote'>;

export default function RankingVote(): JSX.Element {
  const navigation = useNavigation<RankingVoteNavProp>();
  const {
    params: { rankingQuestionId },
  } = useRoute<RankingVoteRouteProp>();

  const { authUserGroupId } = useAuth();

  const { data: rankingsQuestionData } = useQuery(RANKINGS_QUESTION, {
    variables: {
      id: rankingQuestionId,
      authUserGroupId,
    },
  });

  const rankingQuestion = rankingsQuestionData?.rankingsQuestion;

  const rankingAnswer = rankingQuestion?.rankingsAnswers?.edges?.[0]?.node;

  const {
    userGroups,
    loading: userGroupsLoading,
    onLoadMore: onLoadMoreUsers,
  } = useUserGroups();

  const listOptionsVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    customList: rankingQuestion?.customList?.id,
  };

  const {
    data: customListOptionsData,
    loading: listOptionsLoading,
    fetchMore: fetchMoreOptions,
  } = useQuery(CUSTOM_LIST_OPTIONS, {
    skip: !rankingQuestion?.customList,
    variables: listOptionsVar,
    notifyOnNetworkStatusChange: true,
  });

  const customListOptions: CustomListOption[] =
    (customListOptionsData?.customListOptions?.edges.map(
      (edge) => edge?.node,
    ) as CustomListOption[]) || [];
  const listOptionsHasNextPage =
    customListOptionsData?.customListOptions?.pageInfo.hasNextPage;
  const listOptionsEndCursor =
    customListOptionsData?.customListOptions?.pageInfo.endCursor;

  const [createRankingsAnswer] = useMutation(CREATE_RANKINGS_ANSWER);
  const [updateRankingsAnswer] = useMutation(UPDATE_RANKINGS_ANSWER);

  const acceptedUserGroups = userGroups.filter(
    (userGroup) => userGroup.status === Status.ACCEPTED,
  );

  const filteredUserGroups =
    rankingQuestion &&
    rankingQuestion.answerType === AnswerType.PERSON &&
    rankingQuestion.allowedVotes !== AllowedVoteType.EVERYBODY
      ? acceptedUserGroups.filter(
          (userGroup) => userGroup.user.gender === rankingQuestion.allowedVotes,
        )
      : acceptedUserGroups;

  const onUserVote = async ({
    userGroupId1,
    userGroupId2,
    listOptionId,
  }: UserVote) => {
    if (
      rankingQuestion?.answerType === AnswerType.COUPLE &&
      userGroupId1 === userGroupId2
    ) {
      Snackbar.show(t('rankingsVote.errorForSamePerson'));
      return;
    }
    try {
      if (rankingAnswer) {
        const { data } = await updateRankingsAnswer({
          variables: {
            input: {
              id: rankingAnswer.id,
              votee1UserGroup: userGroupId1,
              votee2UserGroup: userGroupId2,
              voteeListOption: listOptionId,
            },
            authUserGroupId,
          },
        });
        const errors = data?.updateRankingsAnswer?.errors;
        if (errors && errors.length > 0) {
          const messages = errors.map((error) => error?.messages[0]);
          const errorMessage = messages?.[0] as string;
          Snackbar.show(errorMessage);
          return;
        }
      } else {
        const { data } = await createRankingsAnswer({
          variables: {
            input: {
              rankingsQuestion: rankingQuestionId,
              votee1UserGroup: userGroupId1,
              votee2UserGroup: userGroupId2,
              voteeListOption: listOptionId,
            },
            authUserGroupId,
          },
        });
        const errors = data?.createRankingsAnswer?.errors;
        if (errors && errors.length > 0) {
          const messages = errors.map((error) => error?.messages[0]);
          const errorMessage = messages?.[0] as string;
          Snackbar.show(errorMessage);
          return;
        }
      }

      navigation.goBack();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onLoadMoreOptions = () => {
    listOptionsHasNextPage &&
      !listOptionsLoading &&
      fetchMoreOptions({
        variables: {
          ...listOptionsVar,
          after: listOptionsEndCursor,
        },
      });
  };

  return (
    <RankingVoteLayout
      rankingQuestion={rankingQuestion}
      userGroups={filteredUserGroups}
      customListOptions={customListOptions}
      loading={userGroupsLoading || listOptionsLoading}
      onBack={() => navigation.goBack()}
      onUserVote={onUserVote}
      onLoadMoreUsers={() => {
        if (rankingQuestion?.customList) {
          onLoadMoreOptions();
        } else {
          onLoadMoreUsers();
        }
      }}
    />
  );
}
