import styled from 'styled-components/native';

export const ScrollView = styled.ScrollView``;

export const InputContainer = styled.View`
  margin-top: 32px;
  margin-bottom: 32px;
  padding-horizontal: 16px;
`;

export const Footer = styled.View`
  padding: 16px;
`;
