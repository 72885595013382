import React, { useState } from 'react';
import LanguagePicker from '~/components/LanguagePicker';
// import SectionHeader from '~/components/SectionHeader';
// import { SettingPanel, SettingToggleItem } from '~/components/SettingPanel';
import { AppLocale } from '~/data/models/app';
import { NotificationSetting } from '~/data/models/group';
import { TabScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import { ScrollView, VSpacer, Loading } from './style';

type NotificationSettingsLoadingProps = {
  onBack: () => void;
};

export function NotificationSettingsLoading({
  onBack,
}: NotificationSettingsLoadingProps): JSX.Element {
  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.notificationSettings')}
      onBackPress={onBack}
    >
      <ScrollView>
        <Loading />
      </ScrollView>
    </TabScreenLayout>
  );
}

type NotificationSettingsLayoutProps = {
  initialNotificationSetting: NotificationSetting;
  initialSelectedLocale?: AppLocale;
  onBack: () => void;
  onUpdateLocale: (locale: AppLocale) => void;
};

export default function NotificationSettings({
  // initialNotificationSetting,
  initialSelectedLocale = 'en',
  onBack,
  onUpdateLocale,
}: NotificationSettingsLayoutProps): JSX.Element {
  const [selectedLocale, setSelectedLocale] = useState<AppLocale>(
    initialSelectedLocale,
  );
  // const [notificationSetting, setNotificationSetting] = useState(
  //   () => initialNotificationSetting,
  // );
  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.notificationSettings')}
      onBackPress={onBack}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <LanguagePicker
          testID={'languagePicker'}
          label={t('g.communicationLanguage')}
          selectedLocale={selectedLocale}
          onSelectedLocale={(locale) => {
            setSelectedLocale(locale);
            onUpdateLocale(locale);
          }}
        />
        <VSpacer />
        {/* <SectionHeader sub title={t('notificationSettings.pushSection')} />
        <SettingPanel>
          <SettingToggleItem
            testID={'groupUpdatesPushToggleItem'}
            text={t('notificationSettings.groupUpdates')}
            checked={notificationSetting.groupUpdatesPush}
            onToggleChange={(checked) =>
              setNotificationSetting({
                ...notificationSetting,
                groupUpdatesPush: checked,
              })
            }
          />
          <SettingToggleItem
            testID={'chatPushToggleItem'}
            text={t('notificationSettings.chat')}
            checked={notificationSetting.chatPush}
            onToggleChange={(checked) =>
              setNotificationSetting({
                ...notificationSetting,
                chatPush: checked,
              })
            }
          />
          <SettingToggleItem
            testID={'sponsoringsPushToggleItem'}
            text={t('notificationSettings.sponsorings')}
            checked={notificationSetting.sponsoringsPush}
            onToggleChange={(checked) =>
              setNotificationSetting({
                ...notificationSetting,
                sponsoringsPush: checked,
              })
            }
          />
          <SettingToggleItem
            testID={'orderUpdatesPushToggleItem'}
            text={t('notificationSettings.groupUpdates')}
            checked={notificationSetting.orderUpdatesPush}
            onToggleChange={(checked) =>
              setNotificationSetting({
                ...notificationSetting,
                orderUpdatesPush: checked,
              })
            }
          />
        </SettingPanel>
        <VSpacer />
        <SectionHeader sub title={t('notificationSettings.emailSection')} />
        <SettingPanel>
          <SettingToggleItem
            testID={'groupUpdatesEmailToggleItem'}
            text={t('notificationSettings.groupUpdates')}
            checked={notificationSetting.groupUpdatesEmail}
            onToggleChange={(checked) =>
              setNotificationSetting({
                ...notificationSetting,
                groupUpdatesEmail: checked,
              })
            }
          />
          <SettingToggleItem
            testID={'chatEmailToggleItem'}
            text={t('notificationSettings.chat')}
            checked={notificationSetting.chatEmail}
            onToggleChange={(checked) =>
              setNotificationSetting({
                ...notificationSetting,
                chatEmail: checked,
              })
            }
          />
          <SettingToggleItem
            testID={'sponsoringsEmailToggleItem'}
            text={t('notificationSettings.sponsorings')}
            checked={notificationSetting.sponsoringsEmail}
            onToggleChange={(checked) =>
              setNotificationSetting({
                ...notificationSetting,
                sponsoringsEmail: checked,
              })
            }
          />
          <SettingToggleItem
            testID={'orderUpdatesEmailToggleItem'}
            text={t('notificationSettings.groupUpdates')}
            checked={notificationSetting.orderUpdatesEmail}
            onToggleChange={(checked) =>
              setNotificationSetting({
                ...notificationSetting,
                orderUpdatesEmail: checked,
              })
            }
          />
        </SettingPanel> */}
      </ScrollView>
    </TabScreenLayout>
  );
}
