import styled from 'styled-components/native';

export const CenterContent = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled.ActivityIndicator``;

export const ScrollView = styled.ScrollView`
  padding: 16px;
`;
