import React, { useRef, useState } from 'react';
import CityCell from '~/components/CityCell';
import FlagButton from '~/components/FlagButton';
import InputField from '~/components/InputField';
import InputHeader from '~/components/InputHeader';
import ModalCountryPicker, {
  ModalCountryPickerHandler,
} from '~/components/ModalCountryPicker';
import { Country } from '~/data/models/marketProfile';
import { StackScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import { InputContent, CityList, Footer, Loading, EmptyText } from './style';

export type JoinSelectLocationLayoutProps = {
  inputProgressTotal: number;
  inputProgress: number;
  country?: Country;
  cities: string[];
  loading: boolean;
  onBack: () => void;
  onSearchLocationChange: (location: string) => void;
  onUpdateCountry: (country: Country) => void;
  onSelectedCity: (city: string) => void;
};

export default function JoinSelectLocation({
  inputProgressTotal,
  inputProgress,
  country,
  cities,
  loading,
  onBack,
  onSearchLocationChange,
  onUpdateCountry,
  onSelectedCity,
}: JoinSelectLocationLayoutProps): JSX.Element {
  const countryModalizeRef = useRef<ModalCountryPickerHandler>(null);

  const [location, setLocation] = useState<string>('');

  const renderItem = ({ item: city }: { item: string }) => (
    <CityCell city={city} onPress={() => onSelectedCity(city)} />
  );

  return (
    <StackScreenLayout
      backButtonTestID={'buttonBack'}
      onBackPress={onBack}
      RightComponent={
        country ? (
          <FlagButton
            testID={'buttonCountry'}
            country={country}
            onPress={() => countryModalizeRef.current?.open()}
          />
        ) : null
      }
    >
      <CityList<React.ElementType>
        ListHeaderComponent={
          <>
            <InputHeader
              title={t('joinSelectLocation.title')}
              descp={t('joinSelectLocation.descp')}
              total={inputProgressTotal}
              progress={inputProgress}
            />
            <InputContent>
              <InputField
                testID={'inputLocation'}
                iconName={'marker-pin-06'}
                label={t('joinSelectLocation.locationLabel')}
                placeholder={t('joinSelectLocation.locationPlaceholder')}
                value={location}
                onChangeText={(value) => {
                  setLocation(value);
                  onSearchLocationChange(value);
                }}
              />
            </InputContent>
          </>
        }
        data={cities}
        renderItem={renderItem}
        keyExtractor={(item: string) => item}
        ListEmptyComponent={
          <>
            {!loading && location.length > 0 && (
              <EmptyText>{t('joinSelectLocation.empty')}</EmptyText>
            )}
          </>
        }
        ListFooterComponent={<Footer>{loading && <Loading />}</Footer>}
      />
      {country ? (
        <ModalCountryPicker
          testID={'countryPicker'}
          ref={countryModalizeRef}
          initialSelectedCountry={country}
          onUpdateCountry={(country) => {
            countryModalizeRef.current?.close();
            onUpdateCountry(country);
          }}
        />
      ) : null}
    </StackScreenLayout>
  );
}
