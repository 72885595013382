import { gql } from './../../types';

// ADMIN SEARCH

export const INSTITUTE_ACTIVITY_TYPE_FRAGMENT = gql(`
  fragment InstituteActivityType on InstituteActivityType {
    year
    status
  }
`);

export const ADMIN_SEARCH_USER_FRAGMENT = gql(`
  fragment AdminSearchUser on SearchUserType {
    id
    firstName
    lastName
    phone
    email
    avatar
    phoneCountryCode
    groups {
      ...AdminSearchGroup
    }
  }
`);

export const ADMIN_SEARCH_GROUP_FRAGMENT = gql(`
  fragment AdminSearchGroup on SearchGroupType {
    id
    name
    graduationYear
    type
    avatar
    institute {
      ...AdminSearchInstitute
    }
  }
`);

export const ADMIN_SEARCH_INSTITUTE_FRAGMENT = gql(`
  fragment AdminSearchInstitute on SearchInstituteType {
    id
    name
    internalId
    country
    city
    zip
    type
  }
`);

export const ADMIN_SEARCH = gql(`
  query AdminSearch($searchString: String!) {
    adminSearchResults(custom: $searchString) {
      users {
        ...AdminSearchUser
      }
      groups {
        ...AdminSearchGroup
      }
      institutes {
        ...AdminSearchInstitute
      }
    }
  }
`);

// EDIT GROUP

export const ADMIN_GROUP_MEMBER_FRAGMENT = gql(`
  fragment AdminGroupMember on GroupMemberNode {
    id
    firstName
    lastName
    avatar
    email
    phone
    phoneCountryCode
    role
    status
    user {
      id
    }
    teams {
      id
      name
      avatar
      icon {
        icon
      }
      color {
        hex
      }
      group {
        id
      }
    }
  }
`);

export const ADMIN_GROUP_INSTITUTE_FRAGMENT = gql(`
  fragment AdminGroupInstitute on InstituteNode {
    name
    id
    internalId
    type {
      name
      graduationTypes {
        id
        name
      }
    }
    country
    street
    zip
    city
  }
`);

export const ADMIN_GROUP_FRAGMENT = gql(`
  fragment AdminGroup on GroupNode {
    id
    name
    avatar
    created
    token
    isActive
    isImported
    type {
      id
      name
    }
    year
    allMembers {
      ...AdminGroupMember
    }
    institute {
      ...AdminGroupInstitute
    }
  }
`);

export const ADMIN_GET_GROUP = gql(`
  query AdminGetGroup($id: ID!) {
    group(id: $id) {
      ...AdminGroup
    }
  }
`);

export const ADMIN_TOGGLE_GROUP_ACTIVE = gql(`
  mutation AdminToggleGroupActive($input: ToggleIsActiveGroupInput!) {
    toggleIsActiveGroup(input: $input) {
      group {
        ...AdminGroup
      }
    }
  }
`);

export const ADMIN_MOVE_GROUP_TO_INSTITUTE = gql(`
  mutation AdminMoveGroupToInstitute($input: MoveGroupToInstituteInput!) {
    moveGroupToInstitute(input: $input) {
      group {
        ...AdminGroup
      }
    }
  }
`);

// EDIT USER

export const ADMIN_USER_TEAMS_FRAGMENT = gql(`
  fragment AdminUserTeams on TeamNode {
    id
    name
    avatar
    icon {
      icon
    }
    color {
      hex
    }
    group {
      id
    }
  }
`);

export const ADMIN_USER_GROUPS_FRAGMENT = gql(`
  fragment AdminUserGroups on GroupNode {
    id
    name
    avatar
    year
    icon {
      icon
    }
    institute {
      id
      name
      internalId
      country
    }
    type {
      name
    }
    users {
      id
      status
      user {
        id
      }
    }
    teams {
      id
      name
    }
  }
`);

export const ADMIN_USER_FRAGMENT = gql(`
  fragment AdminUser on UserNode {
    id
    firstName
    lastName
    email
    phoneCountryCode
    phone
    gender
    isActive
    optIn
    created
    isImported
    isAgent
    isSuperuser
    avatar
    groups {
      ...AdminUserGroups
    }
    teams {
      ...AdminUserTeams
    }
  }
`);

export const ADMIN_GET_USER = gql(`
  query AdminGetUser($id: ID!) {
    user(id: $id) {
      ...AdminUser
    }
  }
`);

export const ADMIN_GET_GRADOO_STAFF = gql(`
  query AdminGetGradooStaff(
    $offset: Int
    $after: String
    $first: Int
    $searchNameEmail: String
  ) {
    users(
      offset: $offset
      after: $after
      first: $first
      isStaff: true
      searchNameEmail: $searchNameEmail
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...AdminUser
        }
      }
      totalCount
    }
  }
`);

export const ADMIN_GET_USERS = gql(`
  query AdminGetUsers(
    $offset: Int
    $after: String
    $first: Int
    $firstName: String
    $lastName: String
    $email: String
    $isAgent: Boolean
    $isSuperuser: Boolean
    $isStaff: Boolean
    $searchNameEmail: String
  ) {
    users(
      offset: $offset
      after: $after
      first: $first
      firstName: $firstName
      lastName: $lastName
      email: $email
      isAgent: $isAgent
      isSuperuser: $isSuperuser
      isStaff: $isStaff
      searchNameEmail: $searchNameEmail
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...AdminUser
        }
      }
      totalCount
    }
  }
`);

export const ADMIN_UPDATE_USER = gql(`
  mutation AdminUpdateUser($input: AdminUpdateUserInput!) {
    adminUpdateUser(input: $input) {
      user {
        ...AdminUser
      }
      errors {
        field
        messages
      }
    }
  }
`);

export const ADMIN_UPGRADE_USER = gql(`
  mutation AdminUpgradeUser($input: AdminUpgradeUserInput!) {
    adminUpgradeUser(input: $input) {
      user {
        ...AdminUser
      }
    }
  }
`);

export const ADMIN_DOWNGRADE_USER = gql(`
  mutation AdminDowngradeUser($input: AdminDowngradeUserInput!) {
    adminDowngradeUser(input: $input) {
      user {
        ...AdminUser
      }
    }
  }
`);

export const ADMIN_CLEAR_USER_FLAGS = gql(`
  mutation AdminClearUserFlags($input: AdminClearUserFlagsInput!) {
    adminClearUserFlags(input: $input) {
      user {
        ...AdminUser
      }
    }
  }
`);

export const ADMIN_FORGET_PASSWORD_LINK = gql(`
  mutation AdminForgetPasswordLink($input: AdminForgetPasswordLinkInput!) {
    adminForgetPasswordLink(input: $input) {
      forgetPasswordLink
    }
  }
`);

export const ADMIN_ADD_USER_TO_TEAM = gql(`
  mutation AdminAddUserToTeam($input: AdminAddUserToTeamInput!) {
    adminAddUserToTeam(input: $input) {
      user {
        ...AdminUser
      }
    }
  }
`);

export const ADMIN_DELETE_USER = gql(`
  mutation AdminDeleteUser($input: AdminDeleteUserInput!) {
    adminDeleteUser(input: $input) {
      user {
        ...AdminUser
      }
    }
  }
`);

// EDIT INSTITUTE

export const ADMIN_INSTITUTE_GROUP_FRAGMENT = gql(`
  fragment AdminInstituteGroup on GroupNode {
    id
    name
    type {
      name
    }
    year
    avatar
    icon {
      icon
    }
    users {
      id
    }
    institute {
      country
      internalId
    }
  }
`);

export const ADMIN_INSTITUTE_FRAGMENT = gql(`
  fragment AdminInstitute on InstituteNode {
    id
    name
    street
    internalId
    city
    zip
    country
    website
    type {
      id
      name
    }
    marketProfile {
      id
      name
    }
    groups {
      ...AdminInstituteGroup
    }
    instituteActivity {
      ...InstituteActivityType
    }
  }
`);

export const ADMIN_GET_INSTITUTE = gql(`
  query AdminGetInstitute($id: ID!) {
    institute(id: $id) {
      ...AdminInstitute
    }
  }
`);

export const ADMIN_UPDATE_INSTITUTE = gql(`
  mutation AdminUpdateInstitute($input: AdminUpdateInstituteInput!) {
    adminUpdateInstitute(input: $input) {
      institute {
        ...AdminInstitute
      }
      errors {
        field
        messages
      }
    }
  }
`);

// ________### Targeting config for Ads feature ###________

// Institutes without groups in current graduation year
// Institutes with inactive groups (<15 members) in current graduation year
// Institutes with active groups (>=15 members) in current graduation year

export const INSTITUTE_TARGETING_FRAGMENT = gql(`
  fragment InstituteTargeting on InstituteTargetingNode {
    id
    location
    activity
  }
`);

export const INSTITUTES_TARGETING = gql(`
  query InstitutesTargeting($targetingFilters: [TargetingFilterInput]) {
    institutesTargeting(targetingFilters: $targetingFilters) {
      usersCount
      activeGroupsCount
      inactiveGroupsCount
      institutes {
        ...InstituteTargeting
      }
    }
  }
`);
