import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import {
  MODULE_INSTANCE,
  UPDATE_MODULE_INSTANCE,
  UPDATE_REPORTS_SETUP,
} from '~/data/operations/yearbook';
import { YearbookStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import {
  ModuleSetupLayoutLoading,
  UpdateModuleInstanceInput,
} from '../layout/ModuleSetupLayout';
import ReportsSetupLayout, { UpdateReportsSetupInput } from './layout';

type ReportsSetupNavProp = StackNavigationProp<
  YearbookStackParamList,
  'ReportsSetup'
>;
type ReportsSetupRouteProp = RouteProp<YearbookStackParamList, 'ReportsSetup'>;

export default function ReportsSetup(): JSX.Element {
  const navigation = useNavigation<ReportsSetupNavProp>();
  const {
    params: { moduleInstanceId },
  } = useRoute<ReportsSetupRouteProp>();

  const { data: moduleInstanceData, loading: moduleInstanceLoading } = useQuery(
    MODULE_INSTANCE,
    {
      variables: {
        id: moduleInstanceId,
      },
    },
  );

  const moduleInstance = moduleInstanceData?.moduleInstance;
  const reportsSetupConfig = moduleInstanceData?.moduleInstance?.reportsSetup;

  const [updateModuleInstance] = useMutation(UPDATE_MODULE_INSTANCE);
  const [updateReportsSetup] = useMutation(UPDATE_REPORTS_SETUP);

  const onUpdateModuleInstance = async ({
    isActive,
    dueDate,
  }: UpdateModuleInstanceInput) => {
    try {
      await updateModuleInstance({
        variables: {
          input: {
            id: moduleInstanceId,
            isActive,
            dueDate,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onUpdateReportsSetup = async ({
    allowImages,
  }: UpdateReportsSetupInput) => {
    try {
      if (moduleInstance && reportsSetupConfig) {
        await updateReportsSetup({
          variables: {
            input: {
              id: reportsSetupConfig.id,
              isActive: moduleInstance.isActive,
              dueDate: moduleInstance.dueDate,
              allowImages,
            },
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  if (!moduleInstance || !reportsSetupConfig || moduleInstanceLoading) {
    return (
      <ModuleSetupLayoutLoading
        title={t('screens.reportsSetup')}
        onBack={() => navigation.goBack()}
      />
    );
  }

  return (
    <ReportsSetupLayout
      moduleInstance={moduleInstance}
      reportsSetupConfig={reportsSetupConfig}
      onBack={() => navigation.goBack()}
      onUpdateModuleInstance={onUpdateModuleInstance}
      onUpdateReportsSetup={onUpdateReportsSetup}
    />
  );
}
