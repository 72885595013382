import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import TrackingFlatlist from '~/components/TrackingFlatList';

interface ITeamColor {
  teamColor?: string;
}

export const HeaderBG = styled.View<ITeamColor>`
  background-color: ${({ teamColor, theme }) =>
    teamColor || theme.color.base.c4};
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 350px;
`;

export const HeaderContainer = styled.View<ITeamColor>`
  background-color: ${({ teamColor, theme }) =>
    teamColor || theme.color.base.c4};
`;

export const FlatList = styled.FlatList.attrs(({ theme }) => ({
  contentContainerStyle: {
    backgroundColor: theme.color.base.c0,
  },
}))``;

export const MemberCellView = styled.View`
  padding-horizontal: 32px;
  background-color: ${({ theme }) => theme.color.base.c1};
`;

export const Content = styled.View`
  background-color: ${({ theme }) => theme.color.base.c1};
  padding-top: 32px;
  padding-bottom: 200px;
  padding-horizontal: 16px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c6,
}))``;

export const EmptyText = styled.Text`
  margin-vertical: 24px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
`;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  tintColor: theme.color.base.c0,
  colors: [theme.color.base.c0],
}))``;

export const SettingsButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  styledName: 'settings',
  color: theme.color.base.c0,
  size: 24,
}))``;

export const HiddenTitle = styled.Text`
  margin-top: 48px;
  margin-bottom: 16px;
  padding-horizontal: 16px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 28px;
  line-height: 34px;
`;

export const HiddenDescp = styled.Text`
  padding-horizontal: 16px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c7};
  font-size: 18px;
  line-height: 24px;
`;

export const TeamBannerView = styled.View`
  padding-vertical: 48px;
`;

export const PendingText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-style: italic;
  font-size: 14px;
  padding-top: 8px;
  color: ${({ theme }) => theme.color.base.c0};
`;
export const BannersContainer = styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.color.base.c0};
`;
export const TeamDetailList = styled(TrackingFlatlist)``;

export const PostWrapper = styled.View`
  background-color: ${({ theme }) => theme.color.base.c1};
`;

export const EmptyActivityText = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 24px;
`;

export const NotificationCarouselContainer = styled.View`
  background-color: ${({ theme }) => theme.color.base.c1};
`;
