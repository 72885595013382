import React, { useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import Button from '~/components/Button';
import EventCalendar, { EventCalendarProps } from '~/components/EventCalendar';
import SwitchSelector from '~/components/SwitchSelector';
import UpcomingEventsCard from '~/components/UpcomingEventsCard';
import { mapCalendarEventsByDay } from '~/data/mappers';
import { Event } from '~/data/models/calendar';
import { TabScreenLayout } from '~/screens/style';
import { Spacer } from '~/theme/wrappers';
import useEffectToggle from '~/utils/hooks/useEffectToggle';
import { t } from '~/utils/i18n';
import {
  Header,
  EventsHeaderContainer,
  EventsHeader,
  UpcomingEventsContainer,
  TabContainer,
  ScrollView,
  EventCardContainer,
} from './style';

type CalendarLayoutProps = {
  calendarEvents: Event[];
  now?: Date;
  onBack: () => void;
  onAddEvent: EventCalendarProps['onAddEvent'];
  onEventOpen: (eventId: string) => void;
  upcomingEvents: Event[];
};

enum CalendarViewEnum {
  Calendar = 'calendar',
  UpcomingEvents = 'upcomingEvents',
}

export default function Calendar({
  calendarEvents,
  now = new Date(),
  onBack,
  onAddEvent,
  onEventOpen,
  upcomingEvents,
}: CalendarLayoutProps): JSX.Element {
  const [calendarKey, setCalendarKey] = useState<CalendarViewEnum>(
    CalendarViewEnum.Calendar,
  );
  const [filteredCalendarEvents, setFilteredCalendarEvents] =
    useState<Event[]>(calendarEvents);

  const { stopEffect } = useEffectToggle({
    effect: () => {
      if (calendarEvents.length > 0) {
        setFilteredCalendarEvents(calendarEvents);
        stopEffect();
      }
    },
    deps: [calendarEvents],
  });

  useFocusEffect(
    React.useCallback(() => {
      setFilteredCalendarEvents(calendarEvents);
    }, [calendarEvents]),
  );

  const calendarEventsByDay = mapCalendarEventsByDay(calendarEvents);
  const handleDateSelected = (date: string) => {
    const datetime = new Date(date).getTime();

    const filterEvents = (events: Event[]) =>
      events.filter((event) => {
        const eventTime = new Date(event.startDateTime).getTime();
        return eventTime == datetime;
      });

    setFilteredCalendarEvents(filterEvents(calendarEvents));
  };

  const renderUpcomingEvents = (event: { item: Event }) => {
    return <UpcomingEventsCard openEvent={onEventOpen} event={event.item} />;
  };

  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.calendar')}
      RightComponent={
        <Button
          testID={'buttonSetup'}
          text={t('g.addEvent')}
          size={'sm'}
          onPress={onAddEvent}
        />
      }
      onBackPress={onBack}
    >
      <TabContainer>
        <SwitchSelector
          items={[
            {
              key: CalendarViewEnum.UpcomingEvents,
              title: t('calendar.upcomingEvents'),
            },
            { key: CalendarViewEnum.Calendar, title: t('calendar.calendar') },
          ]}
          selectedItemKey={calendarKey}
          onSelectedItem={(key) => {
            setCalendarKey(key as CalendarViewEnum);
          }}
        />
      </TabContainer>
      <ScrollView showsVerticalScrollIndicator={false}>
        {calendarKey === CalendarViewEnum.Calendar ? (
          <>
            <EventCalendar
              testID={'eventCalendar'}
              calendarEventsByDay={calendarEventsByDay}
              now={now}
              onAddEvent={onAddEvent}
              onDateSelected={handleDateSelected}
            />
            <Header>
              <EventsHeaderContainer>
                <EventsHeader title={t('calendar.eventsSection')} />
                <Button
                  testID="addEventButton"
                  text={t('g.addEvent')}
                  icon={'left'}
                  iconName={'plus'}
                  size={'sm'}
                  onPress={() => onAddEvent()}
                />
              </EventsHeaderContainer>
            </Header>
            {filteredCalendarEvents.map((item) => {
              return (
                <EventCardContainer key={item.id}>
                  {renderUpcomingEvents({ item })}
                </EventCardContainer>
              );
            })}
            <Spacer size={16} />
          </>
        ) : (
          <UpcomingEventsContainer>
            {upcomingEvents.map((item) => {
              return (
                <EventCardContainer key={item.id}>
                  {renderUpcomingEvents({ item })}
                </EventCardContainer>
              );
            })}
          </UpcomingEventsContainer>
        )}
      </ScrollView>
    </TabScreenLayout>
  );
}
