import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import Icon from '~/components/Icon';
import Image from '~/components/Image';
import { STACK_SCREEN_CONTENT_WIDTH } from '~/data/constants';

const CONTAINER_WIDTH = STACK_SCREEN_CONTENT_WIDTH;
export const COLUMN_NUM = 3;
const SPACE_NUM = COLUMN_NUM - 1;
export const ITEM_HORIZONTAL_SPACE = 12;
const ITEM_VERTICAL_SPACE = 16;
export const PADDING_FOR_UNSELECT_ICON = 4;

const PER_IMAGE_SIZE =
  (CONTAINER_WIDTH -
    SPACE_NUM * ITEM_HORIZONTAL_SPACE -
    PADDING_FOR_UNSELECT_ICON * 2) /
  COLUMN_NUM;
export const IMAGE_CONTAINER_HEIGHT =
  PER_IMAGE_SIZE * 2 + ITEM_VERTICAL_SPACE + PADDING_FOR_UNSELECT_ICON * 2;

export const Container = styled.View``;

export const UploadItemButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  width: ${PER_IMAGE_SIZE}px;
  height: ${PER_IMAGE_SIZE}px;
  border-radius: 7px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.color.base.c2};
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.base.c2};
`;

export const ImageSeperator = styled.View`
  height: ${ITEM_VERTICAL_SPACE}px;
`;

export const ImageItemButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  position: relative;
`;

export const ImageItem = styled(Image)`
  width: ${PER_IMAGE_SIZE}px;
  height: ${PER_IMAGE_SIZE}px;
  border-radius: 7px;
  border-width: 4px;
  border-color: ${({ theme }) => theme.color.base.c2};
`;

export const PickedImageCover = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  width: ${PER_IMAGE_SIZE}px;
  height: ${PER_IMAGE_SIZE}px;
  border-radius: 7px;
  background-color: ${`rgba(1, 87, 255, 0.80)`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PickedIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'pin-02',
  size: 48,
  color: theme.color.base.c0,
}))``;

export const UnPickButton = styled.Pressable`
  position: absolute;
  top: -3px;
  right: -3px;
  padding: 3px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.brand_01};
`;

export const UnPickIcon = styled(Icon).attrs(() => ({
  name: 'x-close',
  size: 12,
}))``;

export const UploadIcon = styled(Icon).attrs({
  name: 'upload-01',
  size: 48,
})``;

export const UploadIconLabel = styled.Text`
  font-size: 10px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.brand_02};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const PickedIconLabel = styled.Text`
  font-size: 10px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.base.c0};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const Title = styled.Text`
  margin-top: 32px;
  margin-bottom: 28px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 20px;
  line-height: 24px;
`;

export const Footer = styled.View`
  padding-top: 32px;
  padding-bottom: 48px;
`;

export const SelectedImageWrapper = styled.View<{
  lg?: boolean;
  center?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ center }) => center && 'justify-content: center;'}
  padding-bottom: 34px;
  padding-left: 10px;
  padding-top: ${({ lg }) => (lg ? 48 : 26)}px;
`;

export const SelectedImagePressable = styled.Pressable`
  padding: 4px;
`;

export const SelectedImage = styled.ImageBackground<{ lg?: boolean }>`
  width: ${({ lg }) => (lg ? 160 : 121)}px;
  height: ${({ lg }) => (lg ? 160 : 121)}px;
  border-radius: 5px;
  transform: rotate(10deg);
  border-width: 5px;
  border-color: ${({ theme }) => theme.color.base.c2};
  background-color: ${({ theme }) => theme.color.base.c1};
  margin-bottom: 8px;
`;

export const NotSelectedImage = styled.View`
  width: 160px;
  height: 160px;
  border-radius: 5px;
  transform: rotate(10deg);
  border-width: 5px;
  border-color: ${({ theme }) => theme.color.base.c2};
  margin-bottom: 8px;
`;

export const UploadButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'upload-01',
  size: 24,
  color: theme.color.base.c0,
}))`
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 10px;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.color.brand_02};
  align-items: center;
  justify-content: center;
`;

export const SmallImage = styled(Image)`
  width: 92px;
  height: 74px;
  margin-left: -18px;
  margin-top: 20px;
  transform: rotate(-10deg);
`;

export const CenterLayer = styled.View`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  justify-content: center;
  align-items: center;
`;

export const UploadBox = styled(CenterLayer)`
  background-color: ${({ theme }) => theme.color.brand_02};
`;
