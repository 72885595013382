import styled from 'styled-components/native';
import SectionHeaderComp from '~/components/SectionHeader';

export const ScrollView = styled.ScrollView`
  padding-horizontal: 16px;
`;

export const InputContainer = styled.View`
  margin-top: 8px;
  margin-bottom: 14px;
`;

export const SectionHeader = styled(SectionHeaderComp)`
  padding-bottom: 16px;
`;

export const OptionsContainer = styled.View`
  flex-direction: row;
  margin-bottom: 14px;
`;

export const ToggleContainer = styled.View``;

export const ButtonContainer = styled.View`
  padding-horizontal: 16px;
  padding-top: 8px;
  padding-bottom: 14px;
`;

export const Content = styled.View`
  padding-vertical: 24px;
  align-items: center;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c7,
}))``;
