import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import {
  DATABASE_ROWS_PER_PAGE_OPTIONS,
  DEFAULT_AGENTS_DATABASE_FIRST,
} from '~/data/constants';
import useAppLocale from '~/data/hooks/useAppLocale';
import { AdminUser } from '~/data/models/user';
import {
  ADMIN_CLEAR_USER_FLAGS,
  ADMIN_DOWNGRADE_USER,
  ADMIN_GET_GRADOO_STAFF,
  ADMIN_GET_USERS,
  ADMIN_UPGRADE_USER,
} from '~/data/operations/admin';
import { SettingsStackParamList } from '~/navigation/types';
import useDebounceValue from '~/utils/hooks/useDeboucedValue';
import { t } from '~/utils/i18n';
import SettingsLayout from './layout';

type SettingsNavProp = StackNavigationProp<SettingsStackParamList, 'Settings'>;

export type UpgradeUserInput = {
  id: string;
  role: string;
};

export default function Settings(): JSX.Element {
  const navigation = useNavigation<SettingsNavProp>();
  const { openDrawer } =
    useNavigation<DrawerNavigationProp<SettingsStackParamList>>();

  const { locale, onChangeLocale } = useAppLocale({
    onReload: () => navigation.replace('Settings', {}),
  });

  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [userSearchValue, setUserSearchValue] = useState<string>('');
  const debouncedFilterQuery = useDebounceValue<string>(filterQuery, 500);
  const deboucedUserSearchValue = useDebounceValue<string>(
    userSearchValue,
    500,
  );

  const [currentActiveTab, setCurrentActiveTab] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    DATABASE_ROWS_PER_PAGE_OPTIONS[0],
  );

  const [upgradeUser] = useMutation(ADMIN_UPGRADE_USER, {
    update: () => handleAgentStaffUpdate(),
  });

  const [downgradeUser] = useMutation(ADMIN_DOWNGRADE_USER, {
    update: () => handleAgentStaffUpdate(),
  });
  const [clearUserFlags] = useMutation(ADMIN_CLEAR_USER_FLAGS, {
    update: () => handleAgentStaffUpdate(),
  });

  const handleAgentStaffUpdate = async () => {
    await refetchStaff();
    await refetchUsers();
    setSubmitting(false);
  };

  const staffOffset = (currentActiveTab - 1) * rowsPerPage;

  const {
    data: dataStaff,
    loading: staffLoading,
    refetch: refetchStaff,
  } = useQuery(ADMIN_GET_GRADOO_STAFF, {
    variables: {
      first: rowsPerPage,
      offset: staffOffset,
      searchNameEmail: debouncedFilterQuery,
    },
  });

  const { data: allUsersData, refetch: refetchUsers } = useQuery(
    ADMIN_GET_USERS,
    {
      skip: !userSearchValue,
      variables: {
        first: DEFAULT_AGENTS_DATABASE_FIRST,
        offset: 0,
        searchNameEmail: deboucedUserSearchValue,
        isStaff: false,
        isAgent: false,
        isSuperuser: false,
      },
    },
  );

  const onUserUpgrade = async (id: string) => {
    try {
      setSubmitting(true);
      const { data } = await upgradeUser({
        variables: {
          input: {
            id,
          },
        },
      });

      if (data?.adminUpgradeUser?.user) {
        Snackbar.show(t('settingsPanel.agentMessageUpgrade'));
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onUserDowngrade = async (id: string) => {
    try {
      setSubmitting(true);
      const { data } = await downgradeUser({
        variables: {
          input: {
            id,
          },
        },
      });

      if (data?.adminDowngradeUser?.user) {
        Snackbar.show(t('settingsPanel.agentMessageDowngrade'));
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onAddAgent = async ({ id, role }: UpgradeUserInput) => {
    try {
      setSubmitting(true);
      const { data } = await upgradeUser({
        variables: {
          input: {
            id,
            toSuperuser: role === 'admin',
          },
        },
      });

      if (data?.adminUpgradeUser?.user) {
        Snackbar.show(t('settingsPanel.agentMessageAdd'));
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onRemoveAgent = async (id: string) => {
    try {
      setSubmitting(true);
      const { data } = await clearUserFlags({
        variables: {
          input: {
            id,
          },
        },
      });

      if (data?.adminClearUserFlags?.user) {
        Snackbar.show(t('settingsPanel.agentMessageRemove'));
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const allUsers: AdminUser[] =
    allUsersData?.users?.edges.map((edge) => edge?.node as AdminUser) || [];

  const gradooStaff: AdminUser[] =
    dataStaff?.users?.edges.map((edge) => edge?.node as AdminUser) || [];

  const staffCount = dataStaff?.users?.totalCount ?? 0;

  return (
    <SettingsLayout
      onBack={() => navigation.goBack()}
      onOpenDrawer={openDrawer}
      agents={gradooStaff}
      users={allUsers}
      agentsTotalCount={staffCount}
      loading={staffLoading || isSubmitting}
      selectedLocale={locale}
      onSelectedLocale={onChangeLocale}
      onUserDowngrade={onUserDowngrade}
      onUserUpgrade={onUserUpgrade}
      onAddAgent={onAddAgent}
      onRemoveAgent={onRemoveAgent}
      setFilterQuery={setFilterQuery}
      filterQuery={filterQuery}
      setCurrentActiveTab={setCurrentActiveTab}
      setUserSearchValue={setUserSearchValue}
      setRowsPerPage={setRowsPerPage}
      rowsPerPage={rowsPerPage}
    />
  );
}
