import React from 'react';
import SectionHeader from '~/components/SectionHeader';
import SwitchSelector, { SwitchItem } from '~/components/SwitchSelector';
import ToDoListCell from '~/components/ToDoListCell';
import { ToDoList } from '~/data/models/todo';
import { TabScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import { Header, List } from './style';

type ToDoListLayoutProps = {
  items: ToDoList[];
  tabOptions: SwitchItem[];
  currentTab: string;
  onTabPressed: (tabKey: string) => void;
  onListSelected: (listId: string) => void;
  onBack: () => void;
  onNotification: () => void;
};

export default function ToDoListsLayout({
  items,
  tabOptions,
  currentTab,
  onTabPressed,
  onListSelected,
  onBack,
  onNotification,
}: ToDoListLayoutProps): JSX.Element {
  const renderItem = ({ item: todo }: { item: ToDoList }) => {
    return (
      <ToDoListCell
        testID={todo.id}
        listTitle={todo.text}
        icon={todo.team.icon}
        tasksUnfinished={todo.items.filter((item) => item.finished).length}
        // eslint-disable-next-line no-console
        onPress={() => onListSelected(todo.id)}
      />
    );
  };

  return (
    <TabScreenLayout
      firstButtonTestID={'buttonIconNotification'}
      backButtonTestID={'buttonBack'}
      title={t('screens.toDoList')}
      onBackPress={onBack}
      onFirstButtonPress={onNotification}
    >
      <Header>
        <SwitchSelector
          items={tabOptions}
          selectedItemKey={currentTab}
          onSelectedItem={(item) => onTabPressed(item)}
        />
        <SectionHeader
          title={currentTab}
          buttonText={t('toDoList.addList')}
          buttonIcon={'plus'}
        />
      </Header>
      <List<React.ElementType>
        data={items}
        renderItem={renderItem}
        keyExtractor={(item: ToDoList) => item.id}
      />
    </TabScreenLayout>
  );
}
