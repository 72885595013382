import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import SectionHeader from '~/components/SectionHeader';
import TrackingFlatlist from '~/components/TrackingFlatList';
import UnderlineButton from '~/components/UnderlineButton';
import { AdsBiggerImage } from '~/components/common/style';
import { BestMatchedAsset } from '~/data/models/campaign';
import { VariantTypeEnum } from '~/data/types/graphql';
import { ModalScreenLayout } from '~/screens/style';
import useAdsTracking from '~/utils/hooks/useAdsTracking';
import { t } from '~/utils/i18n';
import {
  ScrollView,
  Image,
  Descp,
  HistoryView,
  Footer,
  AdsText,
} from './style';

type ExportingLayoutProps = {
  onBack: () => void;
  onViewHistory: () => void;
  openBannersInBrowser: (url: string) => void;
  authUserId: string;
};

export default function Exporting({
  onBack,
  onViewHistory,
  openBannersInBrowser,
  authUserId,
}: ExportingLayoutProps): JSX.Element {
  const { handleViewTracking, handleAdsClick, bestMatchedAsset, randomIndex } =
    useAdsTracking({ authUserId, variantType: VariantTypeEnum.LARGE });
  const renderItem = ({
    item,
    index,
  }: {
    item?: BestMatchedAsset;
    index: number;
  }) => {
    const post = item as BestMatchedAsset;

    return (
      <TouchableOpacity
        testID={`${index}:AdsCell:${post?.id}`}
        onPress={() => {
          handleAdsClick(
            post?.id as string,
            post?.channelData?.[0]?.trackingUrl as string,
            openBannersInBrowser,
          );
        }}
      >
        <AdsText>{t('asset.gratitude')}</AdsText>
        <AdsBiggerImage
          source={{ uri: post?.channelData?.[0]?.imageLarge as string }}
        />
      </TouchableOpacity>
    );
  };

  return (
    <ModalScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.exporting')}
    >
      <ScrollView>
        {bestMatchedAsset?.length === 0 ? (
          <Image source={general.exporting} resizeMode={'cover'} />
        ) : (
          <TrackingFlatlist
            testID={'flatList'}
            handleViewTracking={handleViewTracking}
            data={[bestMatchedAsset?.[randomIndex]]}
            renderItem={renderItem}
            scrollEnabled={false}
          />
        )}

        <SectionHeader title={t('exporting.title')} />
        <Descp>{t('exporting.descp')}</Descp>
        <HistoryView>
          <UnderlineButton
            testID={'buttonViewHistory'}
            text={t('exporting.viewHistory')}
            onPress={onViewHistory}
          />
        </HistoryView>
      </ScrollView>
      <Footer>
        <Button
          testID={'buttonGoBack'}
          text={t('exporting.goBack')}
          type={'secondary-base'}
          flex
          onPress={onBack}
        />
      </Footer>
    </ModalScreenLayout>
  );
}
