import styled from 'styled-components/native';
import ImageComp from '~/components/Image';

export const ScrollView = styled.ScrollView`
  padding-horizontal: 16px;
`;

export const Image = styled(ImageComp)`
  height: 60px;
  width: 45px;
`;

export const Desc = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 24px;
  line-height: 30px;
`;
export const DescColourful = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 24px;
  line-height: 30px;
`;
