import React, { useState } from 'react';
import Button from '~/components/Button';
import FlagButton from '~/components/FlagButton';
import InputField from '~/components/InputField';
import InputHeader from '~/components/InputHeader';
import InstituteCell from '~/components/InstituteCell';
import SchoolTypePicker from '~/components/SchoolTypePicker';
import { Institute, InstituteType } from '~/data/models/institute';
import { Country } from '~/data/models/marketProfile';
import { StackScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  InputContent,
  InstituteList,
  InstituteCellWrapper,
  Footer,
  Loading,
  EmptyText,
} from './style';

export type JoinSelectInstituteLayoutProps = {
  inputProgressTotal: number;
  inputProgress: number;
  country?: Country;
  institutes: Institute[];
  instituteTypes: InstituteType[];
  selectedInstituteType?: InstituteType;
  loading: boolean;
  hasNextPage?: boolean;
  onBack: () => void;
  onSearchInstituteChange: (searchingText: string) => void;
  onInstituteTypeChange: (instituteType: InstituteType) => void;
  onSelectedInstitute: (institute: Institute) => void;
  onNotListed: () => void;
  onLoadMore: () => void;
};

export default function JoinSelectInstitute({
  inputProgressTotal,
  inputProgress,
  country,
  institutes,
  instituteTypes,
  selectedInstituteType,
  loading,
  hasNextPage,
  onBack,
  onSearchInstituteChange,
  onInstituteTypeChange,
  onSelectedInstitute,
  onNotListed,
  onLoadMore,
}: JoinSelectInstituteLayoutProps): JSX.Element {
  const [institute, setInstitute] = useState<string>('');

  const renderItem = ({ item: institute }: { item: Institute }) => (
    <InstituteCellWrapper>
      <InstituteCell
        institute={institute}
        onPress={() => onSelectedInstitute(institute)}
      />
    </InstituteCellWrapper>
  );

  return (
    <StackScreenLayout
      backButtonTestID={'buttonBack'}
      onBackPress={onBack}
      RightComponent={
        country ? (
          <FlagButton testID={'buttonCountry'} country={country} disabled />
        ) : null
      }
    >
      <InstituteList<React.ElementType>
        testID={'instituteList'}
        ListHeaderComponent={
          <>
            <InputHeader
              title={t('joinSelectInstitute.title')}
              descp={t('joinSelectInstitute.descp')}
              total={inputProgressTotal}
              progress={inputProgress}
            />
            <InputContent>
              <InputField
                testID={'inputInstitute'}
                iconName={'search-md'}
                label={t('joinSelectInstitute.instituteLabel')}
                placeholder={t('joinSelectInstitute.institutePlaceholder')}
                value={institute}
                onChangeText={(value) => {
                  setInstitute(value);
                  onSearchInstituteChange(value);
                }}
              />
              <SchoolTypePicker
                testID={'pickerSchool'}
                toggleTestID={'buttonPickerToggle'}
                label={t('instituteType')}
                schoolTypes={instituteTypes}
                selectedSchoolType={selectedInstituteType}
                initialShowPicker={false}
                onSelectedSchoolType={(schoolType) => {
                  onInstituteTypeChange(schoolType as InstituteType);
                }}
              />
            </InputContent>
          </>
        }
        data={institutes}
        renderItem={renderItem}
        keyExtractor={(item: Institute) => item.id}
        ListEmptyComponent={
          <>
            {!loading && (
              <EmptyText>{t('joinSelectInstitute.empty')}</EmptyText>
            )}
          </>
        }
        ListFooterComponent={loading && <Loading />}
        onEndReachedThreshold={0.3}
        onEndReached={() => hasNextPage && onLoadMore()}
      />
      <Footer>
        <Button
          testID={'buttonNotListed'}
          onPress={onNotListed}
          flex
          flexScreenContainer="stack"
          size="2xl"
          type="secondary-base"
          text={t('joinSelectInstitute.notListed')}
        />
      </Footer>
    </StackScreenLayout>
  );
}
