import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';

export const ScroolView = styled(KeyboardAwareScrollView)`
  padding-top: 8px;
  padding-horizontal: 16px;
`;

export const Section = styled.View`
  padding-vertical: 16px;
`;

export const SectionButtonRow = styled.View`
  padding-top: 8px;
  flex-direction: row;
`;

export const OptionsChosenContainer = styled.View`
  position: absolute;
  top: 0px;
  right: 0px;
`;

export const Footer = styled.View`
  padding-bottom: 48px;
`;
