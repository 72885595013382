import { useQuery } from '@apollo/client';
import { DEFAULT_AVATAR_1 } from '../mocks';
import { DefaultAvatar } from '../models/custom';
import { ADMIN_GET_USER } from '../operations/admin';

type UseAdminAvatarProps = {
  adminId?: string;
};

type UseAdminAvatarData = {
  avatar: DefaultAvatar;
};

const TEAM_GRADOO = 'Team Gradoo';

const useAdminAvatar = ({
  adminId,
}: UseAdminAvatarProps): UseAdminAvatarData => {
  const { data: dataAdmin } = useQuery(ADMIN_GET_USER, {
    variables: {
      id: adminId as string,
    },
  });

  const adminGroups = dataAdmin?.user?.groups;
  let avatar: DefaultAvatar = DEFAULT_AVATAR_1;

  const hasAdminGroup = adminGroups && adminGroups?.length !== 0;

  if (hasAdminGroup) {
    const teamGradooGroup = adminGroups?.find(
      (group) => group?.name === TEAM_GRADOO,
    );
    const avatarUrl = teamGradooGroup
      ? teamGradooGroup?.avatar
      : adminGroups[0]?.avatar;
    avatar = {
      __typename: 'DefaultAvatarNode',
      id: '',
      avatar: avatarUrl || DEFAULT_AVATAR_1.avatar,
    };
  }

  return { avatar };
};

export default useAdminAvatar;
