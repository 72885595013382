import styled from 'styled-components/native';
import { web } from '~/assets/imgs/web';

export const Container = styled.View`
  padding-top: ${({ theme }) => theme.safeAreaInsets.top + 16}px;
  flex-direction: row;
  align-items: center;
`;

export const LogoContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  width: 190px;
  height: 80px;
  align-items: center;
  justify-content: center;
`;

export const LogoImage = styled.Image.attrs({
  source: web.web_logo,
  resizeMode: 'contain',
})`
  width: 164px;
  height: 48px;
`;

export const CenterFlex = styled.View`
  flex: 1;
`;

export const HeaderRightView = styled.View``;

export const CursorPointer = styled.View`
  cursor: pointer;
`;
