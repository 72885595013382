type EmarsysHookProps = {
  setContact: (userId: string) => Promise<void>;
  clearContact: () => void;
};

const useEmarsys = (): EmarsysHookProps => {
  async function setContact() {}

  async function clearContact() {}

  return {
    setContact,
    clearContact,
  };
};

export default useEmarsys;
