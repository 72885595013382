import styled from 'styled-components/native';
import SectionHeaderComp from '~/components/SectionHeader';

export const HeaderContainer = styled.View`
  padding-bottom: 16px;
`;

export const MemberList = styled.FlatList``;

export const MemberCellView = styled.View`
  padding-horizontal: 16px;
`;

export const Name = styled.Text`
  margin-top: 8px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 18px;
`;

export const Header = styled.View`
  align-items: center;
  padding: 16px;
`;

export const InputContent = styled.View`
  padding-horizontal: 16px;
`;

export const SectionHeader = styled(SectionHeaderComp)`
  padding-horizontal: 16px;
`;

export const Footer = styled.View`
  padding: 16px;
`;
