import { Dimensions } from 'react-native';
import styled from 'styled-components/native';
import {
  NEWS_ITEM_HEIGHT,
  NEWS_ITEM_WIDTH,
} from '~/components/BannerCarousel/style';
import Image from '~/components/Image';
import TrackingFlatlist from '~/components/TrackingFlatList';
import { DRAWER_WIDTH, IS_LANDSCAPE } from '~/data/constants';

const { width } = Dimensions.get('window');

export const CONTAINER_PADDING = 16;
const CONTENT_WIDTH = IS_LANDSCAPE ? width - DRAWER_WIDTH : width;
export const NUM_COLUMNS = IS_LANDSCAPE ? 5 : 2;
export const CONTENT_CELL_WIDTH =
  (CONTENT_WIDTH - CONTAINER_PADDING * 3) / NUM_COLUMNS;

export const Header = styled.View``;

export const Section = styled.View`
  margin-top: 24px;
  margin-bottom: 16px;
  padding-horizontal: 16px;
`;

export const List = styled.FlatList`
  flex: 1;
`;

export const BannerList = styled(TrackingFlatlist)`
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 8px;
`;

export const AssetImage = styled(Image)`
  width: ${NEWS_ITEM_WIDTH}px;
  height: ${NEWS_ITEM_HEIGHT}px;
  border-radius: 5px;
`;
