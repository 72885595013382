import React from 'react';
import ExportCell from '~/components/ExportCell';
import { Content, Loading, RefreshControl } from '~/components/common/style';
import { DataExport } from '~/data/models/yearbook';
import { ModalScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import { ExportList } from './style';

type ExportHistoryLayoutProps = {
  dataExports: DataExport[];
  loading: boolean;
  onBack: () => void;
  onSelectedExport: (exportLink: string) => void;
  onRefresh: () => void;
  onLoadMore: () => void;
};

export default function ExportHistory({
  dataExports,
  loading,
  onBack,
  onSelectedExport,
  onRefresh,
  onLoadMore,
}: ExportHistoryLayoutProps): JSX.Element {
  const renderItem = ({ item: dataExport }: { item: DataExport }) => {
    return (
      <ExportCell
        testID={`ExportCell:${dataExport.id}`}
        dataExport={dataExport}
        onPress={() =>
          !!dataExport.exportFile && onSelectedExport(dataExport.exportFile)
        }
      />
    );
  };

  return (
    <ModalScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.exportHistory')}
      onBackPress={onBack}
    >
      <ExportList<React.ElementType>
        testID={'exportList'}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
        data={dataExports}
        renderItem={renderItem}
        keyExtractor={(item: DataExport) => item.id}
        ListFooterComponent={
          loading && (
            <Content>
              <Loading />
            </Content>
          )
        }
        onEndReachedThreshold={0.3}
        onEndReached={() => onLoadMore()}
      />
    </ModalScreenLayout>
  );
}
