import styled from 'styled-components/native';

export const Title = styled.Text`
  padding: 24px 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
`;

export const InputView = styled.View`
  padding-horizontal: 16px;
  padding-bottom: 16px;
`;

export const UserList = styled.FlatList`
  padding-horizontal: 16px;
`;

export const Footer = styled.View`
  padding-vertical: 32px;
  align-items: center;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c6,
}))``;
