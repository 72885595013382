import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import { Module, ModuleInstance } from '~/data/models/yearbook';
import {
  CREATE_MODULE_INSTANCE,
  MODULE_INSTANCES,
  MODULES,
} from '~/data/operations/yearbook';
import {
  readModuleInstancesQuery,
  writeModuleInstancesQuery,
} from '~/data/operations/yearbook/helpers';
import { YearbookStackParamList } from '~/navigation/types';
import AddModuleLayout from './layout';

type AddModuleNavProp = StackNavigationProp<
  YearbookStackParamList,
  'AddModule'
>;

export default function AddModule(): JSX.Element {
  const navigation = useNavigation<AddModuleNavProp>();
  const { authGroupId } = useAuth();

  const modulesInstancesVar = { group: authGroupId, first: NO_PAG_LIST_OFFSET };

  const { data: moduleInstancesData } = useQuery(MODULE_INSTANCES, {
    skip: !authGroupId,
    variables: modulesInstancesVar,
  });

  const { data: modulesData } = useQuery(MODULES, {
    variables: {
      first: NO_PAG_LIST_OFFSET,
    },
  });

  const [createModuleInstance] = useMutation(CREATE_MODULE_INSTANCE);

  const onAddModule = async (moduleId: string) => {
    try {
      const { data } = await createModuleInstance({
        variables: {
          input: {
            group: authGroupId,
            module: moduleId,
          },
        },
        update(cache, { data }) {
          const newModuleInstance = data?.createModuleInstance?.moduleInstance;

          const currentModuleInstancesQuery = readModuleInstancesQuery({
            cache,
            variables: modulesInstancesVar,
          });

          if (
            currentModuleInstancesQuery &&
            currentModuleInstancesQuery.moduleInstances &&
            currentModuleInstancesQuery.moduleInstances.edges &&
            newModuleInstance
          ) {
            writeModuleInstancesQuery({
              cache,
              variables: modulesInstancesVar,
              data: {
                ...currentModuleInstancesQuery,
                moduleInstances: {
                  ...currentModuleInstancesQuery.moduleInstances,
                  edges: [
                    ...currentModuleInstancesQuery.moduleInstances.edges,
                    {
                      __typename: 'ModuleInstanceNodeEdge',
                      node: newModuleInstance,
                    },
                  ],
                },
              },
            });
          }
        },
      });
      const messages = data?.createModuleInstance?.errors?.map(
        (error) => error?.messages[0],
      );
      const errorMessage = messages?.[0];

      if (errorMessage) {
        Snackbar.show(errorMessage);
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const moduleInstances: ModuleInstance[] =
    (moduleInstancesData?.moduleInstances?.edges.map(
      (edge) => edge?.node,
    ) as ModuleInstance[]) || [];

  const modules: Module[] =
    (modulesData?.modules?.edges.map((edge) => edge?.node) as Module[]) || [];

  const addedModuleIds = moduleInstances.map(
    (moduleInstance) => moduleInstance.module.id,
  );

  return (
    <AddModuleLayout
      modules={modules}
      addedModuleIds={addedModuleIds}
      onBack={() => navigation.goBack()}
      onSelectModule={(moduleId) =>
        navigation.navigate('ModuleDetail', { moduleId })
      }
      onAddModule={onAddModule}
    />
  );
}
