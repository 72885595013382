import { SEARCH_TAGS } from '~/data/constants';
import { formattedDate } from '~/utils/dates';
import { AdminSearchResults, AdminSearchResultType } from './models/admin';
import { CalendarEvent, CalendarEventByDay, Event } from './models/calendar';
import { TeamDetail } from './models/team';
import { MappedUser, User } from './models/user';
import { Status } from './types/graphql';
import { createOrderedSearchResults } from './utils/index';

function getEventColorByIndex(index: number) {
  const colors = [
    '#a5b4fc',
    '#FDA4AF',
    '#C4B5FD',
    '#4f46e5',
    '#E11D48',
    '#7C3AED',
    '#312e81',
    '#881337',
    '#4C1D95',
  ];
  return colors[index % colors.length];
}

function mapUserName(firstName?: string, lastName?: string): string {
  return `${firstName || ''} ${lastName || ''}`;
}

function mapUserInitials(firstName?: string, lastName?: string): string {
  return `${firstName?.charAt(0) || ''}${
    lastName?.charAt(0) || ''
  }`.toUpperCase();
}

export function mapCalendarEvent(event: Event, index: number): CalendarEvent {
  const color = getEventColorByIndex(index);
  const name = event.title;
  const fromDate = formattedDate(event.startDateTime, 'yyyy-MM-dd');
  return {
    ...event,
    index,
    color,
    fromDate,
    name,
  };
}

export function mapCalendarEvents(events: Event[]): CalendarEvent[] {
  return events.map((event, index) => mapCalendarEvent(event, index));
}

export function mapCalendarEventsByDay(
  calendarEvents: Event[],
): CalendarEventByDay {
  const calendarEventByDay: CalendarEventByDay = {};
  calendarEvents.forEach((calendarEvent) => {
    if (!calendarEventByDay[calendarEvent.startDateTime]) {
      calendarEventByDay[calendarEvent.startDateTime] = [];
    }
    calendarEventByDay[calendarEvent.startDateTime].push(calendarEvent);
  });
  return calendarEventByDay;
}

export function getRoundedPercentage(number: number): number {
  return Math.round(number * 100);
}

//so we can safely mapUser(userGroup?.user)?.initials
export const mapUser = (user?: User | null): MappedUser | undefined => {
  if (!user) return undefined;
  return {
    ...user,
    name: mapUserName(user.firstName, user.lastName),
    initials: mapUserInitials(user.firstName, user.lastName),
  };
};

export const mapAuthTeams = (
  authUserId?: string,
  teams?: TeamDetail[],
): TeamDetail[] => {
  return teams
    ? teams.filter((team) =>
        team.users
          ?.filter((user) => user?.status === Status.ACCEPTED)
          .map((user) => user?.user?.id)
          .includes(authUserId),
      )
    : [];
};

export const flattenAdminSearchResults = (
  searchResults?: AdminSearchResults,
  parameters: string[] = [],
) => {
  if (!searchResults) {
    return [];
  }
  /* 
    all the Param are initialized to 0 because if userParam != 0 
    the order of the elements will be User - group - Institutes
    and the other two instatnces are possible if all Param are for 
    groups(Group - Institute - User) or all parms are for 
    institutes(Institute - Groups - Users)
  */
  let instituteParam = 0;
  let userParam = 0;
  let groupParam = 0;

  const { institutes, groups, users } = searchResults;
  parameters.forEach((item) => {
    SEARCH_TAGS.forEach((tag) => {
      if (
        (tag.key === 'institute' && tag.tags.some((e) => e.tag === item)) ||
        item.match(/C(\d{1})/)
      ) {
        instituteParam += 1;
      } else if (tag.key === 'group' && tag.tags.some((e) => e.tag === item)) {
        instituteParam = 0;
        groupParam += 1;
      } else if (tag.key === 'user' && tag.tags.some((e) => e.tag === item)) {
        instituteParam = 0;
        groupParam = 0;
        userParam += 1;
      }
    });
  });

  if (userParam !== 0) {
    return createOrderedSearchResults([
      ...users,
      ...groups,
      ...institutes,
    ] as AdminSearchResultType[]);
  } else if (parameters.length !== 0 && parameters.length === instituteParam) {
    return createOrderedSearchResults([
      ...institutes,
      ...groups,
      ...users,
    ] as AdminSearchResultType[]);
  } else if (parameters.length !== 0 && parameters.length === groupParam) {
    return createOrderedSearchResults([
      ...groups,
      ...institutes,
      ...users,
    ] as AdminSearchResultType[]);
  }
  return createOrderedSearchResults([
    ...users,
    ...groups,
    ...institutes,
  ] as AdminSearchResultType[]);
};
