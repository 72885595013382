import styled from 'styled-components/native';

export const Header = styled.View`
  padding-horizontal: 16px;
`;

export const UserList = styled.FlatList`
  padding-horizontal: 32px;
`;

export const Footer = styled.View`
  padding-vertical: 32px;
  align-items: center;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c6,
}))``;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  tintColor: theme.color.base.c6,
  colors: [theme.color.base.c6],
}))``;
