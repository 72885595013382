import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import { AdminGroup } from '~/data/models/admin';
import { GraduationType } from '~/data/models/group';
import { Institute } from '~/data/models/institute';
import {
  ADMIN_GET_GROUP,
  ADMIN_MOVE_GROUP_TO_INSTITUTE,
  ADMIN_TOGGLE_GROUP_ACTIVE,
} from '~/data/operations/admin';
import { UPDATE_GROUP } from '~/data/operations/group';
import { INSTITUTES } from '~/data/operations/institute';
import { CREATE_DATA_EXPORT } from '~/data/operations/yearbook';
import { AdminStackParamList, SearchStackParamList } from '~/navigation/types';
import { EditGroupInput } from '~/screens/Main/GroupSetup/layout';
import useCopyToClipboard from '~/utils/hooks/useCopyToClipboard';
import useDebouncedState from '~/utils/hooks/useDebouncedState';
import { t } from '~/utils/i18n';
import EditGroupLayout from './layout';

type EditGroupNavProp = StackNavigationProp<SearchStackParamList, 'EditGroup'>;

type EditGroupRouteProp = RouteProp<SearchStackParamList, 'EditGroup'>;

export default function EditGroup(): JSX.Element {
  const [submitting, setIsSubmitting] = useState<boolean>(false);
  const [searchInsitituteId, setSearchInstituteId] = useDebouncedState<string>(
    '',
    500,
  );

  const navigation = useNavigation<EditGroupNavProp>();
  const { openDrawer } =
    useNavigation<DrawerNavigationProp<AdminStackParamList>>();

  const {
    params: { id },
  } = useRoute<EditGroupRouteProp>();
  const groupId = decodeURIComponent(id);

  const { data, loading } = useQuery(ADMIN_GET_GROUP, {
    variables: {
      id: groupId,
    },
  });

  const { data: institutesData } = useQuery(INSTITUTES, {
    variables: {
      internalId: searchInsitituteId,
      after: null,
      first: DEFAULT_LIST_OFFSET,
    },
  });

  const [updateGroup] = useMutation(UPDATE_GROUP);
  const [toggleIsGroupActive] = useMutation(ADMIN_TOGGLE_GROUP_ACTIVE);
  const [moveGroupToInstitute] = useMutation(ADMIN_MOVE_GROUP_TO_INSTITUTE);
  const { copy } = useCopyToClipboard();
  const [createDataExport] = useMutation(CREATE_DATA_EXPORT);

  const onExportData = async (onlyAgent: boolean) => {
    setIsSubmitting(true);
    try {
      const { data } = await createDataExport({
        variables: {
          input: {
            groupId: groupId,
            exportQuotes: true,
            exportCollages: true,
            exportProfilePages: true,
            exportRankings: true,
            onlyAgent: onlyAgent,
          },
        },
      });
      if (data?.createDataExport?.success) {
        Snackbar.show('Successfully exported data!');
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onMoveGroup = async (instituteId: string) => {
    if (!data?.group) {
      return;
    }

    setIsSubmitting(true);
    try {
      await moveGroupToInstitute({
        variables: {
          input: {
            groupId,
            instituteId,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onCopyGroupId = async () => {
    const success = await copy(groupId);
    if (success) {
      Snackbar.show(t('clipboardCopy.groupId', { groupId }));
    }
  };

  const onShareInviteLink = async (token: string) => {
    const success = await copy(
      `https://gradoo.app/invite?g=${groupId}&t=${token}`,
    );
    if (success) {
      Snackbar.show(t('clipboardCopy.groupInvite'));
    }
  };

  const onToggleGroupActive = async () => {
    if (!data?.group) {
      return;
    }

    try {
      setIsSubmitting(true);
      const toggleTo = !data.group.isActive;

      await toggleIsGroupActive({
        variables: {
          input: {
            groupId,
            isActive: toggleTo,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onUpdateGroup = async ({ name, typeId, year }: EditGroupInput) => {
    try {
      setIsSubmitting(true);
      const { data } = await updateGroup({
        variables: {
          input: {
            id: groupId,
            name,
            type: typeId,
            year: parseInt(year),
          },
        },
      });
      const messages = data?.updateGroup?.errors?.map(
        (error) => error?.messages[0],
      );
      const errorMessage = messages?.[0];
      if (errorMessage) {
        Snackbar.show(errorMessage);
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOnUserPress = (id: string) =>
    navigation.navigate('EditUser', {
      id,
    });

  const institutes: Institute[] =
    (institutesData?.institutes?.edges.map(
      (edge) => edge?.node,
    ) as Institute[]) || [];

  const graduationTypes =
    (data?.group?.institute.type.graduationTypes as GraduationType[]) || [];

  return (
    <EditGroupLayout
      onBack={() => navigation.navigate('Search')}
      graduationTypes={graduationTypes}
      navigationState={navigation.getState()}
      loading={loading || submitting}
      group={data?.group as AdminGroup}
      onCopyGroupId={onCopyGroupId}
      onMoveGroup={onMoveGroup}
      onShareInviteLink={onShareInviteLink}
      onToggleGroupActive={onToggleGroupActive}
      onUpdateGroup={onUpdateGroup}
      institutes={institutes}
      setSearchInstituteId={setSearchInstituteId}
      onUserPress={handleOnUserPress}
      openDrawer={openDrawer}
      onExportData={onExportData}
      onNavigateInstitute={(id) => {
        id && navigation.navigate('EditInstitute', { id });
      }}
    />
  );
}
