import styled from 'styled-components/native';

export const UserSummaryList = styled.FlatList`
  padding: 16px;
`;

export const CellContainer = styled.View`
  padding-horizontal: 16px;
`;

export const VSpacer = styled.View`
  height: 32px;
`;
