import { gql } from './../../types';

//TEAM_COLOR
export const COLOR_FRAGMENT = gql(`
  fragment Color on ColorNode {
    id
    hex
  }
`);

//TEAM_ICON
export const ICON_FRAGMENT = gql(`
  fragment Icon on IconNode {
    id
    icon
  }
`);

//USER_AVATARS
export const DEFAULT_AVATAR_FRAGMENT = gql(`
  fragment DefaultAvatar on DefaultAvatarNode {
    id
    avatar
  }
`);

//GROUP_ICONS
export const GROUP_ICON_FRAGMENT = gql(`
  fragment GroupIcon on GroupIconNode {
    id
    icon
  }
`);

export const COLORS = gql(`
  query Colors($after: String, $first: Int!) {
    colors(after: $after, first: $first) {
      edges {
        node {
          ...Color
        }
      }
    }
  }
`);

export const ICONS = gql(`
  query Icons($after: String, $first: Int!) {
    icons(after: $after, first: $first) {
      edges {
        node {
          ...Icon
        }
      }
    }
  }
`);

export const DEFAULT_AVATARS = gql(`
  query DefaultAvatars($after: String, $first: Int!) {
    defaultAvatars(after: $after, first: $first) {
      edges {
        node {
          ...DefaultAvatar
        }
      }
    }
  }
`);

export const GROUP_ICONS = gql(`
  query GroupIcons($after: String, $first: Int!) {
    groupIcons(after: $after, first: $first) {
      edges {
        node {
          ...GroupIcon
        }
      }
    }
  }
`);
