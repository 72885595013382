import { ApolloCache } from '@apollo/client';
import {
  TEAMS,
  TEAM_DETAIL_FRAGMENT,
  TEAM_SAMPLES,
  TEAM_SAMPLE_FRAGMENT,
} from './';
import {
  TeamDetailFragment,
  TeamSampleFragment,
  TeamSamplesQuery,
  TeamSamplesQueryVariables,
  TeamsQuery,
  TeamsQueryVariables,
} from './../../types/graphql';

export function readTeamsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: TeamsQueryVariables;
}): TeamsQuery | null {
  return cache.readQuery<TeamsQuery>({
    query: TEAMS,
    variables,
  });
}

export function writeTeamsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: TeamsQueryVariables;
  data: TeamsQuery;
}): void {
  cache.writeQuery<TeamsQuery>({
    query: TEAMS,
    variables,
    data,
  });
}

export function readTeamSamplesQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: TeamSamplesQueryVariables;
}): TeamSamplesQuery | null {
  return cache.readQuery<TeamSamplesQuery>({
    query: TEAM_SAMPLES,
    variables,
  });
}

export function writeTeamSamplesQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: TeamSamplesQueryVariables;
  data: TeamSamplesQuery;
}): void {
  cache.writeQuery<TeamSamplesQuery>({
    query: TEAM_SAMPLES,
    variables,
    data,
  });
}

export function readTeamDetailFragment({
  cache,
  id,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
}): TeamDetailFragment | null {
  return cache.readFragment<TeamDetailFragment>({
    id: `TeamNode:${id}`,
    fragment: TEAM_DETAIL_FRAGMENT,
    fragmentName: 'TeamDetail',
  });
}

export function writeTeamDetailFragment({
  cache,
  id,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  data: TeamDetailFragment;
}): void {
  cache.writeFragment<TeamDetailFragment>({
    id: `TeamNode:${id}`,
    fragment: TEAM_DETAIL_FRAGMENT,
    fragmentName: 'TeamDetail',
    data,
  });
}

export function readTeamSampleFragment({
  cache,
  id,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
}): TeamSampleFragment | null {
  return cache.readFragment<TeamSampleFragment>({
    id: `TeamNode:${id}`,
    fragment: TEAM_SAMPLE_FRAGMENT,
    fragmentName: 'TeamSample',
  });
}

export function writeTeamSampleFragment({
  cache,
  id,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  data: TeamSampleFragment;
}): void {
  cache.writeFragment<TeamSampleFragment>({
    id: `TeamNode:${id}`,
    fragment: TEAM_SAMPLE_FRAGMENT,
    fragmentName: 'TeamSample',
    data,
  });
}
