import styled from 'styled-components/native';

export const NumberInfoList = styled.FlatList``;

export const PercentInfoList = styled.FlatList`
  padding-top: 32px;
  padding-bottom: 24px;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const Header = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding-top: 32px;
  padding-horizontal: 16px;
`;

export const HSpacer = styled.View`
  width: 16px;
`;

export const TimeProgressView = styled.View`
  padding-top: 24px;
  padding-bottom: 21px;
`;
