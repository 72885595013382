import React, { useEffect } from 'react';
import { useAssets } from 'expo-asset';
import { FontSource, useFonts } from 'expo-font';
import 'react-native-devsettings';
import * as SplashScreen from 'expo-splash-screen';
import { FontFamilyKey } from 'gradoo-theme/lib/types';

SplashScreen.preventAutoHideAsync().catch();

const STORYBOOK_ENABLED = false;
const fontFamilyMap: {
  [key in FontFamilyKey]: FontSource;
} = {
  //core
  'RoobertPRO-Regular': require('./assets/fonts/RoobertPRO-Regular.ttf'),
  'RoobertPRO-RegularItalic': require('./assets/fonts/RoobertPRO-RegularItalic.ttf'),
  'RoobertPRO-SemiBold': require('./assets/fonts/RoobertPRO-SemiBold.ttf'),
  'RoobertPRO-SemiBoldItalic': require('./assets/fonts/RoobertPRO-SemiBold.ttf'),
  //monospace
  RobotoMono400: require('./assets/fonts/RobotoMono-Regular.ttf'),
  RobotoMono500: require('./assets/fonts/RobotoMono-Medium.ttf'),
  RobotoMono600: require('./assets/fonts/RobotoMono-SemiBold.ttf'),
  RobotoMono700: require('./assets/fonts/RobotoMono-Bold.ttf'),
};

export default function App() {
  const [assets] = useAssets([require('./src/assets/imgs/v2/brand_logo.png')]);
  const [fontsLoaded] = useFonts(fontFamilyMap);

  useEffect(() => {
    if (fontsLoaded && !!assets) {
      SplashScreen.hideAsync();
    }
  }, [fontsLoaded, assets]);

  if (!fontsLoaded || !assets) {
    return null;
  }

  const AppComponent =
    STORYBOOK_ENABLED && __DEV__
      ? require('./storybook').default
      : require('./src').default;

  return <AppComponent />;
}
