import { ApolloCache } from '@apollo/client';
import {
  BASE_POST_FRAGMENT,
  POLLS,
  POLL_FRAGMENT,
  POLL_OPTION_FRAGMENT,
  POSTS,
  POST_FRAGMENT,
  COMMENT_FRAGMENT,
} from './';
import {
  CommentFragment as Comment,
  PollFragment as Poll,
  PollOptionFragment as PollOption,
  PollsQuery as Polls,
  PollsQueryVariables as PollsVariables,
  PostFragment as Post,
  PostsQuery as Posts,
  PostsQueryVariables as PostsVariables,
} from './../../types/graphql';

export function readPostsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: PostsVariables;
}): Posts | null {
  return cache.readQuery<Posts>({
    query: POSTS,
    variables,
  });
}

export function writePostsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: PostsVariables;
  data: Posts;
}): void {
  cache.writeQuery<Posts>({
    query: POSTS,
    variables,
    data,
  });
}

export function readBasePostFragment({
  cache,
  id,
  authUserGroupId,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  authUserGroupId: string;
}): Post | null {
  return cache.readFragment<Post, { authUserGroupId: string }>({
    id: `PostNode:${id}`,
    fragment: BASE_POST_FRAGMENT,
    fragmentName: 'BasePost',
    variables: { authUserGroupId },
  });
}

export function readPostFragment({
  cache,
  id,
  authUserGroupId,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  authUserGroupId: string;
}): Post | null {
  return cache.readFragment<Post, { authUserGroupId: string }>({
    id: `PostNode:${id}`,
    fragment: POST_FRAGMENT,
    fragmentName: 'Post',
    variables: { authUserGroupId },
  });
}

export function writePostFragment({
  cache,
  id,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  data: Post;
}): void {
  cache.writeFragment({
    id: `PostNode:${id}`,
    fragment: POST_FRAGMENT,
    fragmentName: 'Post',
    data,
  });
}

export function readPollsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: PollsVariables;
}): Polls | null {
  return cache.readQuery<Polls>({
    query: POLLS,
    variables,
  });
}

export function writePollsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: PollsVariables;
  data: Polls;
}): void {
  cache.writeQuery<Polls>({
    query: POLLS,
    variables,
    data,
  });
}

export function readPollFragment({
  cache,
  id,
  authUserGroupId,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  authUserGroupId: string;
}): Poll | null {
  return cache.readFragment<Poll, { authUserGroupId: string }>({
    id: `PollNode:${id}`,
    fragment: POLL_FRAGMENT,
    fragmentName: 'Poll',
    variables: { authUserGroupId },
  });
}

export function writePollFragment({
  cache,
  id,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  data: Poll;
}): void {
  cache.writeFragment({
    id: `PollNode:${id}`,
    fragment: POLL_FRAGMENT,
    fragmentName: 'Poll',
    data,
  });
}

export function readPollOptionFragment({
  cache,
  id,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
}): PollOption | null {
  return cache.readFragment<PollOption>({
    id: `PollOptionNode:${id}`,
    fragment: POLL_OPTION_FRAGMENT,
    fragmentName: 'PollOption',
  });
}

export function writePollOptionFragment({
  cache,
  id,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  data: PollOption;
}): void {
  cache.writeFragment({
    id: `PollOptionNode:${id}`,
    fragment: POLL_OPTION_FRAGMENT,
    fragmentName: 'PollOption',
    data,
  });
}

export function readCommentFragment({
  cache,
  id,
  authUserGroupId,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  authUserGroupId: string;
}): Comment | null {
  return cache.readFragment<Comment, { authUserGroupId: string }>({
    id: `CommentNode:${id}`,
    fragment: COMMENT_FRAGMENT,
    fragmentName: 'Comment',
    variables: { authUserGroupId },
  });
}

export function wirteCommentFragment({
  cache,
  id,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  data: Comment;
}): void {
  cache.writeFragment({
    id: `CommentNode:${id}`,
    fragment: COMMENT_FRAGMENT,
    fragmentName: 'Comment',
    data,
  });
}
