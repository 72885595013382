import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { SelectorItem } from '~/components/WebSelector';
import { AdminUser } from '~/data/models/user';
import {
  ADMIN_DELETE_USER,
  ADMIN_FORGET_PASSWORD_LINK,
  ADMIN_GET_USER,
  ADMIN_UPDATE_USER,
} from '~/data/operations/admin';
import {
  readAdminGetUserQuery,
  writeAdminGetUserQuery,
} from '~/data/operations/admin/helpers';
import { IMPORT_TO_ERP } from '~/data/operations/group';
import { ADD_USER_TO_TEAM } from '~/data/operations/team';
import { AdminGetUserQuery, AdminUpdateUserInput } from '~/data/types/graphql';
import { AdminStackParamList, SearchStackParamList } from '~/navigation/types';
import useCopyToClipboard from '~/utils/hooks/useCopyToClipboard';
import { t } from '~/utils/i18n';
import EditLayout from './layout';

type EditNavProp = StackNavigationProp<SearchStackParamList, 'EditUser'>;
type EditUserRouteProp = RouteProp<SearchStackParamList, 'EditUser'>;

export default function EditUser(): JSX.Element {
  const [submitting, setIsSubmitting] = useState<boolean>(false);

  const navigation = useNavigation<EditNavProp>();
  const { openDrawer } =
    useNavigation<DrawerNavigationProp<AdminStackParamList>>();

  const {
    params: { id },
  } = useRoute<EditUserRouteProp>();

  const userId = decodeURIComponent(id);

  const { data, loading } = useQuery(ADMIN_GET_USER, {
    variables: {
      id: userId,
    },
  });

  const { copy } = useCopyToClipboard();

  const [updateUser] = useMutation(ADMIN_UPDATE_USER);
  const [forgetPassword] = useMutation(ADMIN_FORGET_PASSWORD_LINK);
  const [addUserToTeam] = useMutation(ADD_USER_TO_TEAM, {
    update(cache, { data }) {
      const userData = readAdminGetUserQuery({
        cache,
        variables: {
          id: userId,
        },
      });

      const updatedUser = {
        ...userData?.user,
        teams: data?.addUserToTeam?.teams,
      };

      writeAdminGetUserQuery({
        cache,
        variables: {
          id: userId,
        },
        data: { user: updatedUser } as AdminGetUserQuery,
      });
    },
  });
  const [deleteUser] = useMutation(ADMIN_DELETE_USER);
  const [importToErp] = useMutation(IMPORT_TO_ERP);

  const user = data?.user as AdminUser;
  const onImportToERP = async (
    userId: string,
    groupId: SelectorItem,
    instituteId: SelectorItem,
  ) => {
    try {
      setIsSubmitting(true);
      const { data } = await importToErp({
        variables: {
          input: {
            userId,
            groupId: groupId.id,
            instituteId: instituteId.id,
          },
        },
      });

      if (!data?.importToErp?.success) {
        throw new Error(t('adminPanelSearch.importToErpFail'));
      }

      Snackbar.show(t('adminPanelSearch.importToErpSuccess'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onCreatePasswordForgetLink = async () => {
    try {
      setIsSubmitting(true);
      const { data } = await forgetPassword({
        variables: {
          input: {
            id: userId,
          },
        },
      });

      const link = data?.adminForgetPasswordLink?.forgetPasswordLink;

      if (!link) {
        throw new Error('Error: Forget password link was not created');
      }

      const success = await copy(link);

      if (success) {
        Snackbar.show(t('clipboardCopy.forgetPasswordLink'));
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onAddToTeam = async (team: SelectorItem) => {
    try {
      setIsSubmitting(true);
      await addUserToTeam({
        variables: {
          input: {
            userId,
            teamId: team.id,
          },
        },
      });

      Snackbar.show(
        t('adminPanelSearch.addToTeamSuccess', { teamName: team.name }),
      );
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onDeleteAccount = async () => {
    try {
      setIsSubmitting(true);
      await deleteUser({
        variables: {
          input: {
            id: userId,
          },
        },
      });

      navigation.replace('Search');
      //Reload page to refresh search results
      window.location.reload();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onUpdateUser = async ({
    email,
    firstName,
    lastName,
    gender,
    phone,
    isActive,
    phoneCountryCode,
    optIn,
  }: AdminUpdateUserInput) => {
    try {
      setIsSubmitting(true);
      const { data } = await updateUser({
        variables: {
          input: {
            id: userId,
            firstName,
            lastName,
            email,
            gender,
            isActive,
            phone,
            phoneCountryCode,
            optIn,
          },
        },
      });
      const messages = data?.adminUpdateUser?.errors?.map(
        (error) => error?.messages[0],
      );
      const errorMessage = messages?.[0];
      if (errorMessage) {
        Snackbar.show(errorMessage);
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onGroupPress = (id: string) => navigation.navigate('EditGroup', { id });

  return (
    <EditLayout
      user={user}
      onBack={() => navigation.navigate('Search')}
      navigationState={navigation.getState()}
      onImportToERP={onImportToERP}
      onCreatePasswordForgetLink={onCreatePasswordForgetLink}
      onAddToTeam={onAddToTeam}
      onDeleteAccount={onDeleteAccount}
      onUpdateUser={onUpdateUser}
      loading={loading || submitting}
      onGroupPress={onGroupPress}
      openDrawer={openDrawer}
    />
  );
}
