import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';

export const ScroolView = styled(KeyboardAwareScrollView)`
  padding-top: 8px;
  padding-horizontal: 16px;
`;

export const Content = styled.View`
  margin-top: 24px;
`;
