import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import { Module } from '~/data/models/yearbook';
import {
  CREATE_DATA_EXPORT,
  MODULE_INSTANCES,
} from '~/data/operations/yearbook';
import { ModuleType } from '~/data/types/graphql';
import { YearbookStackParamList } from '~/navigation/types';
import ExportDataLayout from './layout';

type ExportDataNavProp = StackNavigationProp<
  YearbookStackParamList,
  'ExportData'
>;

export default function ExportData(): JSX.Element {
  const navigation = useNavigation<ExportDataNavProp>();

  const { authGroupId } = useAuth();

  const { data: moduleInstancesData } = useQuery(MODULE_INSTANCES, {
    variables: { group: authGroupId, first: NO_PAG_LIST_OFFSET },
  });

  const [createDataExport, { loading: exportLoading }] =
    useMutation(CREATE_DATA_EXPORT);

  const modules: Module[] =
    (moduleInstancesData?.moduleInstances?.edges.map(
      (edge) => edge?.node?.module,
    ) as Module[]) || [];

  const supportedModuleTypes = [
    ModuleType.QUOTES,
    ModuleType.COLLAGES,
    ModuleType.PROFILE_PAGE,
    ModuleType.RANKINGS,
    ModuleType.REPORTS,
  ];

  const filteredModules = modules.filter(
    (module) => module.type && supportedModuleTypes.includes(module.type),
  );

  const onSendLink = async (moduleTypes: ModuleType[]) => {
    try {
      await createDataExport({
        variables: {
          input: {
            groupId: authGroupId,
            exportQuotes: moduleTypes.includes(ModuleType.QUOTES),
            exportCollages: moduleTypes.includes(ModuleType.COLLAGES),
            exportProfilePages: moduleTypes.includes(ModuleType.PROFILE_PAGE),
            exportRankings: moduleTypes.includes(ModuleType.RANKINGS),
            exportReports: moduleTypes.includes(ModuleType.REPORTS),
          },
        },
      });
      navigation.navigate('Exporting');
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };
  return (
    <ExportDataLayout
      modules={filteredModules}
      exportLoading={exportLoading}
      onBack={() => navigation.goBack()}
      onViewHistory={() => navigation.navigate('ExportHistory')}
      onSendLink={onSendLink}
    />
  );
}
