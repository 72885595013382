import React, { useEffect, useRef, useState } from 'react';
import { NavigationState } from '@react-navigation/native';
import { FlatList, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import AdminHeader from '~/components/AdminHeader';
import Avatar from '~/components/Avatar';
import Badge from '~/components/Badge';
import Button from '~/components/Button';
import InputField, { InputFieldHandle } from '~/components/InputField';
import Paginator from '~/components/Paginator';
import SchoolTypePicker from '~/components/SchoolTypePicker';
import SearchInputField from '~/components/SearchInputField/index';
import StepSizePicker from '~/components/StepSizePicker';
import TeamIcon from '~/components/TeamIcon';
import { WebContainer } from '~/components/WebGrid';
import WebModalConfirm, {
  WebModalConfirmHandler,
} from '~/components/WebModalConfirm';
import WebModalSelector from '~/components/WebModalSelector';
import YearSelector from '~/components/YearSelector';
import {
  FormContainer,
  FlexRowCentered,
  Spacer,
  Name,
  SectionLabel,
  ActionButtonsContainer,
  FlexRow,
  GrownFlex,
  FlexAuto,
  GroupImage,
  ActionsButton,
  Container,
  Spinner,
  InputContent,
} from '~/components/common/style.web';
import { AdminGroup, GroupMember } from '~/data/models/admin';
import { GraduationType } from '~/data/models/group';
import { Institute } from '~/data/models/institute';
import { EditGroupInput } from '~/screens/Main/GroupSetup/layout';
import { formattedDate } from '~/utils/dates';
import usePagination from '~/utils/hooks/usePagination';
import { t } from '~/utils/i18n';
import { shouldAllowToSaveChanges } from '../../utils/FormUtils';
import UsersInsideGroupHelpers from './helpers';
import {
  InstituteDetail,
  DetailLabel,
  DetailValue,
  ActionContainer,
  FlexEnd,
  PhoneColumn,
  MemberListControls,
  CreatedAt,
  UserNameColumn,
  MemberName,
  MemberRow,
  DetailText,
  TeamName,
  InstituteLink,
} from './style';

type EditGroupLayoutProps = {
  onBack: () => void;
  institutes: Institute[];
  graduationTypes: GraduationType[];
  navigationState?: NavigationState;
  loading?: boolean;
  group?: AdminGroup;
  onMoveGroup: (instituteId: string) => void;
  onCopyGroupId: () => void;
  onShareInviteLink: (token: string) => void;
  onToggleGroupActive: () => void;
  onUpdateGroup: (data: EditGroupInput) => void;
  setSearchInstituteId: (query: string) => void;
  onUserPress: (id: string) => void;
  onExportData: (onlyAgent: boolean) => void;
  openDrawer: () => void;
  onNavigateInstitute: (instituteId: string | undefined) => void;
};

type ConfirmModalAction =
  | 'deactivateGroup'
  | 'reactivateGroup'
  | 'exportDataGroup';

export default function EditGroup({
  onBack,
  graduationTypes,
  loading = false,
  group,
  onMoveGroup,
  onCopyGroupId,
  onShareInviteLink,
  onToggleGroupActive,
  onUpdateGroup,
  institutes,
  setSearchInstituteId,
  onUserPress,
  openDrawer,
  onNavigateInstitute,
  onExportData,
}: EditGroupLayoutProps): JSX.Element {
  const theme = useTheme();
  const nameRef = useRef<InputFieldHandle>(null);
  const confirmModalRef = useRef<WebModalConfirmHandler>(null);
  const exportModalRef = useRef<WebModalConfirmHandler>(null);
  const moveGroupModalRef = useRef<WebModalConfirmHandler>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const [name, setName] = useState<string>('');
  const [graduationType, setGraduationType] = useState<
    GraduationType | undefined
  >(undefined);
  const [yearKey, setYearKey] = useState<number>(new Date().getFullYear());

  const [modalAction, setModalAction] = useState<ConfirmModalAction | null>(
    null,
  );

  const [selectedInstitute, setSelectedInstitute] = useState<string | null>(
    null,
  );

  const [membersPerPage, setMembersPerPage] = useState(5);

  const membersCount = group?.allMembers.length || 0;

  const {
    allowNextPage,
    allowPrevPage,
    applyPaginationOnData,
    currentPage,
    decrementPage,
    incrementPage,
    resetPagination,
    totalNumberOfPages,
    updatePage,
  } = usePagination({
    itemsPerPage: membersPerPage,
    totalItems: membersCount,
  });

  useEffect(() => {
    if (!group) return;
    setYearKey(group?.year);
    setName(group.name);
    setGraduationType(group.type as GraduationType);
  }, [group]);

  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });

  const enableSaveChanges = shouldAllowToSaveChanges([
    [name, group?.name],
    [graduationType?.id, group?.type.id],
    [yearKey, group?.year.toString()],
  ]);

  const onSubmitChanges = () => {
    if (!group || !enableSaveChanges) {
      return;
    }

    const data: EditGroupInput = {
      name,
      year: yearKey.toString(),
      typeId: graduationType?.id || group.type.id,
    };

    onUpdateGroup(data);
  };

  const handleMoveGroup = () => {
    if (selectedInstitute === null) {
      return;
    }

    onMoveGroup(selectedInstitute);
    setSelectedInstitute(null);
    moveGroupModalRef.current?.close();
  };

  const renderMemberRow = ({ item: member }: { item: GroupMember }) => (
    <MemberRow
      key={member?.id}
      testID={`MemberRow:${member.user.id}`}
      onPress={() => onUserPress(member.user.id)}
    >
      <Avatar uri={member?.avatar} size={isDesktop ? 46 : 30} />
      <UserNameColumn isDesktop={isDesktop}>
        <MemberName>
          {member?.firstName} {member?.lastName}
        </MemberName>
        <DetailText>{member?.email}</DetailText>
        {!isDesktop && (
          <DetailText>
            {`${member?.phoneCountryCode}`} {`${member?.phone}`}
          </DetailText>
        )}
      </UserNameColumn>

      {isDesktop && (
        <PhoneColumn isCompact>
          <DetailText>
            {`${member?.phoneCountryCode}`} {`${member?.phone}`}
          </DetailText>
        </PhoneColumn>
      )}

      <FlexEnd>
        <View>
          {member?.teams && member?.teams.length === 0 ? (
            <DetailText>{t('adminPanelSearch.noTeams')}</DetailText>
          ) : (
            member?.teams
              ?.filter((team) => team?.group?.id === group?.id)
              .map((team) => (
                <React.Fragment key={`team-${team?.id}`}>
                  <Spacer h={15} />
                  <FlexRowCentered>
                    <TeamIcon
                      iconName={team?.icon?.icon}
                      size={24}
                      iconSize={10}
                      teamColor={team?.color?.hex}
                    />
                    <Spacer h={8} />
                    <TeamName>{team?.name}</TeamName>
                  </FlexRowCentered>
                </React.Fragment>
              ))
          )}
        </View>
      </FlexEnd>
    </MemberRow>
  );

  if (loading) {
    return (
      <Container centerContent>
        <Spinner testID="loadingSpinner" />
      </Container>
    );
  }

  const filteredMembers = UsersInsideGroupHelpers.filterMembers(
    searchValue.toLowerCase(),
    group?.allMembers as GroupMember[],
  );

  const paginatedMembers = applyPaginationOnData(
    filteredMembers || [],
  ) as GroupMember[];

  let cancelButtonText;
  if (
    t(`adminPanelSearch.${modalAction}Team`) ===
    t(`adminPanelSearch.exportDataGroupTeam`)
  ) {
    cancelButtonText = t(`adminPanelSearch.${modalAction}Team`);
  }
  return (
    <Container>
      <AdminHeader
        onOpenDrawer={openDrawer}
        title={t('adminPanelSearch.editGroup')}
        onBack={onBack}
        breadcrumbs={['Search', 'EditGroup']}
        enableSaveChanges={enableSaveChanges}
        onSubmitClick={onSubmitChanges}
        buttonTitle={t('adminPanelSearch.saveChanges')}
      />

      <WebModalConfirm
        title={t(`adminPanelSearch.${modalAction}ModalTitle`)}
        description={t(`adminPanelSearch.${modalAction}Warning`)}
        icon="settings"
        onCancel={() => {
          setModalAction(null);
          exportModalRef.current?.close();
        }}
        ref={exportModalRef}
        onConfirm={() => {
          onExportData(true);
          setModalAction(null);
          exportModalRef.current?.close();
        }}
        onConfirmSecondButton={() => {
          onExportData(false);
          setModalAction(null);
          exportModalRef.current?.close();
        }}
        buttonText={t(`adminPanelSearch.${modalAction}`)}
        cancelButtonText={cancelButtonText}
        confirmButtonTestId="ExportToMail"
        cancelButtonTestId="ExportToYearbookTeam"
      />
      <WebModalConfirm
        title={t(`adminPanelSearch.${modalAction}ModalTitle`, {
          groupName: group?.name,
        })}
        description={t(`adminPanelSearch.${modalAction}Warning`)}
        icon="settings"
        onCancel={() => {
          setModalAction(null);
          confirmModalRef.current?.close();
        }}
        ref={confirmModalRef}
        onConfirm={() => {
          onToggleGroupActive();
          setModalAction(null);
          confirmModalRef.current?.close();
        }}
        buttonText={t(`adminPanelSearch.${modalAction}`)}
        cancelButtonText={cancelButtonText}
        confirmButtonTestId="confirmIsActiveToggle"
      />
      <WebModalSelector
        title={t(`adminPanelSearch.moveGroup`)}
        buttonText={t(`adminPanelSearch.moveGroupBtn`)}
        ref={moveGroupModalRef}
        onConfirm={handleMoveGroup}
        confirmButtonTestId={'onMoveGroupConfirmBtn'}
        webSelectors={[
          {
            id: 'moveGroupSelector',
            title: t(`adminPanelSearch.chooseNewInstitute`),
            label: t(`adminPanelSearch.selectInstitute`),
            items: institutes.map((institute) => {
              return {
                id: institute.id,
                name: institute.name,
              };
            }),
            onItemSelect: (item) => setSelectedInstitute(item.id),
            iconName: 'icn/teams/home',
            isEmptyText: t(`adminPanelSearch.chooseInstitutePlaceholder`),
            onSearch: setSearchInstituteId,
            testID: 'instituteSelector',
          },
        ]}
      />

      <WebContainer isDesktop={isDesktop}>
        <FormContainer isDesktop={isDesktop}>
          <FlexRowCentered>
            <GroupImage
              source={{
                uri: group?.avatar || undefined,
              }}
              isSmall={!isDesktop}
            />

            {isDesktop ? (
              <>
                <Spacer h={32} />
                <View>
                  <FlexRowCentered>
                    <Name testID="grpName" isDesktop={isDesktop}>
                      {group?.name}
                    </Name>
                    <Spacer h={8} />
                    {group?.isImported && (
                      <Badge
                        text={t('adminPanelSearch.abihomeImport')}
                        colorTheme="primary"
                      />
                    )}
                  </FlexRowCentered>
                  <Spacer v={8} />
                  <CreatedAt isDesktop={isDesktop}>
                    {t('adminPanelSearch.createdAt', {
                      date: formattedDate(group?.created),
                    })}
                  </CreatedAt>
                </View>
              </>
            ) : (
              <>
                <Spacer h={10} />

                <View>
                  <Spacer v={24} />
                  <Name testID="grpName" isDesktop={isDesktop}>
                    {group?.name}
                  </Name>
                  <Spacer v={5} />
                  <CreatedAt isDesktop={isDesktop}>
                    {t('adminPanelSearch.createdAt', {
                      date: formattedDate(group?.created),
                    })}
                  </CreatedAt>
                  <Spacer v={8} />
                  <FlexRowCentered>
                    {group?.isImported && (
                      <Badge
                        text={t('adminPanelSearch.abihomeImport')}
                        colorTheme="primary"
                      />
                    )}
                  </FlexRowCentered>
                </View>
              </>
            )}
          </FlexRowCentered>
          <Spacer v={40} />
          <View>
            <ActionContainer>
              <SectionLabel>{t('adminPanelSearch.actions')}</SectionLabel>
            </ActionContainer>
            <Spacer v={16} />
            <ActionButtonsContainer isDesktop={isDesktop}>
              <ActionsButton
                testID="moveGroupBtn"
                type={'large'}
                text={t('adminPanelSearch.moveGroup')}
                onPress={() => moveGroupModalRef.current?.open()}
                iconStyled={'icn/teams/paper_plane'}
              />
              <ActionsButton
                testID="copyGroupIdBtn"
                type={'large'}
                text={t('adminPanelSearch.copyGroupId')}
                onPress={onCopyGroupId}
                iconStyled={'icn/teams/paper_plane'}
              />

              <ActionsButton
                testID="shareInviteLinkBtn"
                type={'large'}
                text={t('adminPanelSearch.shareInviteLink')}
                onPress={() => onShareInviteLink(group?.token || '')}
                iconStyled={'share'}
              />

              <ActionsButton
                testID="exportYearbookDataBtn"
                type={'large'}
                text={t('adminPanelSearch.yearBook')}
                onPress={() => {
                  setModalAction('exportDataGroup');
                  exportModalRef.current?.open();
                }}
                iconStyled={'icn/teams/book'}
              />
              {group?.isActive ? (
                <ActionsButton
                  testID="deactivateBtn"
                  type={'large'}
                  text={t('adminPanelSearch.deactivateGroup')}
                  onPress={() => {
                    setModalAction('deactivateGroup');
                    confirmModalRef.current?.open();
                  }}
                  iconStyled={'delete_red'}
                  textColor={theme.color.error}
                  intent="danger"
                />
              ) : (
                <ActionsButton
                  testID="reactivateBtn"
                  type={'large'}
                  text={t('adminPanelSearch.reactivateGroup')}
                  onPress={() => {
                    setModalAction('reactivateGroup');
                    confirmModalRef.current?.open();
                  }}
                  iconStyled={'flame_green'}
                  textColor={theme.color.success}
                  intent="success"
                />
              )}
            </ActionButtonsContainer>
          </View>
          <Spacer v={isDesktop ? 56 : 28} />
          <FlexRow isDesktop={isDesktop}>
            <GrownFlex>
              <SectionLabel>{t('adminPanelSearch.groupDetails')}</SectionLabel>
              <InputContent>
                <InputField
                  ref={nameRef}
                  testID={'inputName'}
                  iconName={'users-01'}
                  label={t('createGroup.nameLabel')}
                  placeholder={t('createGroup.namePlaceholder')}
                  value={name}
                  onChangeText={(value) => setName(value)}
                  blurOnSubmit={false}
                  returnKeyType={'next'}
                />
                <SchoolTypePicker
                  testID={'graduationTypePicker'}
                  toggleTestID={'buttonPickerToggle'}
                  label={t('graduationType')}
                  schoolTypes={graduationTypes}
                  selectedSchoolType={graduationType}
                  initialShowPicker={false}
                  onSelectedSchoolType={(schoolType) =>
                    setGraduationType(schoolType as GraduationType)
                  }
                />
                <Spacer v={24} />
                <YearSelector
                  initialYear={yearKey}
                  setYear={setYearKey}
                  sectionColor={theme.color.base.c7}
                ></YearSelector>
              </InputContent>
            </GrownFlex>
            {isDesktop && <Spacer h={48} />}
            <FlexAuto>
              <SectionLabel>
                {t('adminPanelSearch.instituteDetails')}
              </SectionLabel>
              <Spacer v={24} />
              <InstituteDetail>
                <DetailLabel>
                  {t('adminPanelSearch.instituteLabels.name')}
                </DetailLabel>
                <DetailValue>{group?.institute.name}</DetailValue>
              </InstituteDetail>
              <InstituteDetail>
                <DetailLabel>
                  {t('adminPanelSearch.instituteLabels.whuid')}
                </DetailLabel>
                <DetailValue>
                  <InstituteLink
                    onPress={() => {
                      onNavigateInstitute(group?.institute.id);
                    }}
                  >
                    {group?.institute.internalId}
                  </InstituteLink>
                </DetailValue>
              </InstituteDetail>
              <InstituteDetail>
                <DetailLabel>
                  {t('adminPanelSearch.instituteLabels.type')}
                </DetailLabel>
                <DetailValue>{group?.institute.type.name}</DetailValue>
              </InstituteDetail>
              <InstituteDetail>
                <DetailLabel>
                  {t('adminPanelSearch.instituteLabels.country')}
                </DetailLabel>
                <DetailValue>
                  {t(`adminPanelSearch.flags.${group?.institute?.country}`)}{' '}
                  {t(
                    `adminPanelSearch.countryNames.${group?.institute?.country}`,
                  )}
                </DetailValue>
              </InstituteDetail>
              <InstituteDetail>
                <DetailLabel>
                  {t('adminPanelSearch.instituteLabels.street')}
                </DetailLabel>
                <DetailValue>{group?.institute.street}</DetailValue>
              </InstituteDetail>
              <InstituteDetail>
                <DetailLabel>
                  {t('adminPanelSearch.instituteLabels.zip')}
                </DetailLabel>
                <DetailValue>{group?.institute.zip}</DetailValue>
              </InstituteDetail>
              <InstituteDetail>
                <DetailLabel>
                  {t('adminPanelSearch.instituteLabels.city')}
                </DetailLabel>
                <DetailValue>{group?.institute.city}</DetailValue>
              </InstituteDetail>
            </FlexAuto>
          </FlexRow>
          <Spacer v={isDesktop ? 80 : 36} />
          <FlatList
            data={paginatedMembers}
            renderItem={renderMemberRow}
            ListHeaderComponent={
              <ActionContainer>
                <SectionLabel>
                  {t('adminPanelSearch.members', { n: membersCount })}
                </SectionLabel>
                <SearchInputField
                  testID={'inputSearch'}
                  iconName={'search-md'}
                  label={t('g.search')}
                  value={searchValue}
                  onChangeText={(query) => {
                    setSearchValue(query);
                  }}
                  width={300}
                  height={15}
                />
              </ActionContainer>
            }
            ListFooterComponent={
              membersCount > 0 ? (
                <MemberListControls>
                  <StepSizePicker
                    title={t('adminPanelSearch.entries')}
                    onStepSizeChange={(v: number) => {
                      setMembersPerPage(v);
                      resetPagination();
                    }}
                    stepSize={membersPerPage}
                  />
                  <Paginator
                    currentPage={currentPage}
                    allowNext={allowNextPage}
                    allowPrev={allowPrevPage}
                    onNextClick={incrementPage}
                    onPrevClick={decrementPage}
                    numberOfPages={totalNumberOfPages}
                    onPageClick={updatePage}
                  />
                </MemberListControls>
              ) : null
            }
            keyExtractor={(member: GroupMember) => member.id}
          />
          {!isDesktop && (
            <View>
              <Spacer v={30} />
              <Button
                testID="saveChangesBtn"
                text={t('adminPanelSearch.saveChanges')}
                onPress={onSubmitChanges}
                state={enableSaveChanges ? 'activeWeb' : 'disabled'}
                size={'lg'}
              />
            </View>
          )}
        </FormContainer>
      </WebContainer>
    </Container>
  );
}
