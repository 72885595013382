import React, { useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import {
  CREATE_PROFILE_PAGE_ANSWER,
  DESTROY_PROFILE_PAGE_ANSWER,
  UPDATE_PROFILE_PAGE_ANSWER,
} from '~/data/operations/yearbook';
import {
  readProfilePageQuestionFragment,
  writeProfilePageQuestionFragment,
} from '~/data/operations/yearbook/helpers';
import { YearbookStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import { isEmpty } from '~/utils/validators';
import ProfilePageAnswerLayout from './layout';

type ProfilePageAnswerNavProp = StackNavigationProp<
  YearbookStackParamList,
  'ProfilePageAnswer'
>;

type ProfilePageAnswerRouteProp = RouteProp<
  YearbookStackParamList,
  'ProfilePageAnswer'
>;

export default function ProfilePageAnswer(): JSX.Element {
  const navigation = useNavigation<ProfilePageAnswerNavProp>();
  const isAnswerCreatedOnce = useRef(false);
  const {
    params: {
      questionId,
      questionTitle,
      maxChars,
      answerId,
      currentText,
      viewOnly,
    },
  } = useRoute<ProfilePageAnswerRouteProp>();

  const { authUserGroupId } = useAuth();

  const [createProfilePageAnswer, { loading: createAnswerLoading }] =
    useMutation(CREATE_PROFILE_PAGE_ANSWER);
  const [updateProfilePageAnswer, { loading: updateAnswerLoading }] =
    useMutation(UPDATE_PROFILE_PAGE_ANSWER);

  const [destroyProfilePageAnswer, { loading: destroyAnswerLoading }] =
    useMutation(DESTROY_PROFILE_PAGE_ANSWER);

  const onAnswerQuestion = async (text: string) => {
    const checkEmpty = isEmpty(text);

    if (checkEmpty) {
      Snackbar.show(t('profilePageAnswer.emptyWarning'));
      return;
    }

    try {
      if (answerId) {
        const { data } = await updateProfilePageAnswer({
          variables: {
            input: {
              id: answerId,
              text,
            },
          },
        });

        if (!data?.updateProfilePageAnswer?.profilePageAnswer) {
          const messages = data?.updateProfilePageAnswer?.errors?.map(
            (error) => error?.messages[0],
          );
          const errorMessage = messages?.[0] as string;
          Snackbar.show(errorMessage);
          return;
        }
      } else {
        if (!isAnswerCreatedOnce.current) {
          isAnswerCreatedOnce.current = true;
          const { data } = await createProfilePageAnswer({
            variables: {
              input: {
                profilePageQuestion: questionId,
                text,
              },
            },
            update(cache, { data }) {
              const newProfilePageAnswer =
                data?.createProfilePageAnswer?.profilePageAnswer;

              const currentQuestion = readProfilePageQuestionFragment({
                cache,
                id: questionId,
                ppqUserGroup: authUserGroupId,
              });

              if (currentQuestion && newProfilePageAnswer) {
                writeProfilePageQuestionFragment({
                  cache,
                  id: questionId,
                  ppqUserGroup: authUserGroupId,
                  data: {
                    ...currentQuestion,
                    profilePageAnswers: {
                      __typename: 'ProfilePageAnswerNodeConnection',
                      edges: [
                        {
                          __typename: 'ProfilePageAnswerNodeEdge',
                          node: newProfilePageAnswer,
                        },
                      ],
                    },
                  },
                });
              }
            },
          });
          if (!data?.createProfilePageAnswer?.profilePageAnswer) {
            const messages = data?.createProfilePageAnswer?.errors?.map(
              (error) => error?.messages[0],
            );
            const errorMessage = messages?.[0] as string;
            Snackbar.show(errorMessage);
            return;
          }
        }
      }
      navigation.goBack();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDeleteAnswer = async () => {
    if (!answerId) {
      return;
    }
    try {
      await destroyProfilePageAnswer({
        variables: {
          input: {
            id: answerId,
          },
        },
        update(cache) {
          const currentQuestion = readProfilePageQuestionFragment({
            cache,
            id: questionId,
            ppqUserGroup: authUserGroupId,
          });

          if (currentQuestion) {
            writeProfilePageQuestionFragment({
              cache,
              id: questionId,
              ppqUserGroup: authUserGroupId,
              data: {
                ...currentQuestion,
                profilePageAnswers: {
                  __typename: 'ProfilePageAnswerNodeConnection',
                  edges:
                    currentQuestion.profilePageAnswers?.edges.filter(
                      (edge) => edge?.node?.id !== answerId,
                    ) || [],
                },
              },
            });
          }
        },
      });
      navigation.goBack();
    } catch (err) {
      if (err instanceof Error) {
        Snackbar.show(err.message);
      }
    }
  };

  return (
    <ProfilePageAnswerLayout
      onDeleteAnswer={onDeleteAnswer}
      answerType={answerId ? 'update' : 'create'}
      questionTitle={questionTitle}
      currentText={currentText}
      maxChars={maxChars}
      loading={
        createAnswerLoading || updateAnswerLoading || destroyAnswerLoading
      }
      onBack={() => navigation.goBack()}
      onAnswerQuestion={onAnswerQuestion}
      viewOnly={viewOnly}
    />
  );
}
