import React, { useState, useRef } from 'react';
import Button from '~/components/Button';
import FlagButton from '~/components/FlagButton';
import InputField, { InputFieldHandle } from '~/components/InputField';
import InputHeader from '~/components/InputHeader';
import ModalCountryPicker, {
  ModalCountryPickerHandler,
} from '~/components/ModalCountryPicker';
import SchoolTypePicker from '~/components/SchoolTypePicker';
import { InstituteType } from '~/data/models/institute';
import { Country } from '~/data/models/marketProfile';
import { RequestMissingInstituteInput } from '~/data/types/graphql';
import { StackScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  ScroolView,
  InputContent,
  Footer,
  InputWrapper,
  InputLocationWrapper,
  InputZipWrapper,
} from './style';

export type CreateInstituteInput = {
  name: string;
  website: string;
  location: string;
  city: string;
  zip: string;
  address: string;
  typeId: string;
};

type JoinSelectInstituteLayoutProps = {
  country: Country;
  instituteTypes: InstituteType[];
  loading: boolean;
  onBack: () => void;
  onUpdateCountry: (country: Country) => void;
  onRequestCreation: (instituteInput: RequestMissingInstituteInput) => void;
};

export default function RequestInstitution({
  country,
  instituteTypes,
  loading,
  onBack,
  onUpdateCountry,
  onRequestCreation,
}: JoinSelectInstituteLayoutProps): JSX.Element {
  const countryModalizeRef = useRef<ModalCountryPickerHandler>(null);

  const nameRef = useRef<InputFieldHandle>(null);
  const websiteRef = useRef<InputFieldHandle>(null);
  const cityRef = useRef<InputFieldHandle>(null);
  const zipRef = useRef<InputFieldHandle>(null);
  const addressRef = useRef<InputFieldHandle>(null);

  const [name, setName] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [selectedInstituteType, setSelectedInstituteType] = useState<
    InstituteType | undefined
  >(undefined);

  const isValidInput = (): boolean => {
    return (
      name != '' &&
      website != '' &&
      city != '' &&
      zip != '' &&
      address != '' &&
      selectedInstituteType != undefined
    );
  };

  const handleNext = () => {
    isValidInput() &&
      selectedInstituteType &&
      onRequestCreation &&
      onRequestCreation({
        name,
        website,
        country,
        city,
        zip,
        street: address,
        type: selectedInstituteType.id,
      });
  };

  return (
    <StackScreenLayout
      backButtonTestID={'buttonBack'}
      onBackPress={onBack}
      RightComponent={
        country ? (
          <FlagButton
            testID={'buttonCountry'}
            country={country}
            onPress={() => countryModalizeRef.current?.open()}
          />
        ) : null
      }
    >
      <ScroolView>
        <InputHeader
          title={t('requestInstitution.title')}
          descp={t('requestInstitution.descp')}
        />
        <InputContent>
          <InputField
            ref={nameRef}
            testID={'inputName'}
            iconName={'home-02'}
            label={t('requestInstitution.nameLabel')}
            placeholder={t('requestInstitution.namePlaceholder')}
            value={name}
            onChangeText={(value) => setName(value)}
            blurOnSubmit={false}
            returnKeyType={'next'}
            onSubmitEditing={() => websiteRef.current?.focus()}
          />
          <InputField
            ref={websiteRef}
            testID={'inputWebsite'}
            pretag={'https://'}
            label={t('requestInstitution.websiteLabel')}
            placeholder={t('requestInstitution.websitePlaceholder')}
            value={website}
            onChangeText={(value) => setWebsite(value)}
            blurOnSubmit={false}
            returnKeyType={'next'}
            onSubmitEditing={() => websiteRef.current?.focus()}
          />
          <InputWrapper>
            <InputZipWrapper>
              <InputField
                ref={zipRef}
                testID={'inputZip'}
                label={t('requestInstitution.zipLabel')}
                placeholder={t('requestInstitution.zipPlaceholder')}
                value={zip}
                onChangeText={(value) => setZip(value)}
                blurOnSubmit={false}
                returnKeyType={'next'}
                onSubmitEditing={() => addressRef.current?.focus()}
              />
            </InputZipWrapper>
            <InputLocationWrapper>
              <InputField
                ref={cityRef}
                testID={'inputCity'}
                label={t('requestInstitution.locationLabel')}
                placeholder={t('requestInstitution.locationPlaceholder')}
                value={city}
                onChangeText={(value) => setCity(value)}
                blurOnSubmit={false}
                returnKeyType={'next'}
                onSubmitEditing={() => addressRef.current?.focus()}
              />
            </InputLocationWrapper>
          </InputWrapper>
          <InputField
            ref={addressRef}
            testID={'inputAddress'}
            iconName={'map-02'}
            label={t('requestInstitution.addressLabel')}
            placeholder={t('requestInstitution.addressPlaceholder')}
            value={address}
            onChangeText={(value) => setAddress(value)}
            blurOnSubmit={false}
            returnKeyType={'next'}
            onSubmitEditing={() => handleNext()}
          />
          <SchoolTypePicker
            testID={'pickerSchool'}
            toggleTestID={'buttonPickerToggle'}
            label={t('instituteType')}
            schoolTypes={instituteTypes}
            selectedSchoolType={selectedInstituteType}
            initialShowPicker={false}
            onSelectedSchoolType={(schoolType) => {
              setSelectedInstituteType(schoolType as InstituteType);
            }}
          />
        </InputContent>
        <Footer>
          <Button
            testID={'buttonRequestCreation'}
            flex
            flexScreenContainer="stack"
            size="2xl"
            state={isValidInput() ? 'default' : 'disabled'}
            text={t('requestInstitution.requestCreation')}
            loading={loading}
            onPress={() => handleNext()}
          />
        </Footer>
      </ScroolView>
      {country ? (
        <ModalCountryPicker
          testID={'countryPicker'}
          ref={countryModalizeRef}
          initialSelectedCountry={country}
          onUpdateCountry={(country) => {
            countryModalizeRef.current?.close();
            onUpdateCountry(country);
          }}
        />
      ) : null}
    </StackScreenLayout>
  );
}
