import React, { useState } from 'react';
import { Alert } from 'react-native';
import Avatar from '~/components/Avatar';
import Button from '~/components/Button';
import InputAreaField from '~/components/InputAreaField';
import { mapUser } from '~/data/mappers';
import { UserGroup } from '~/data/models/group';
import { ModalScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  ScroolView,
  InfoCell,
  UserName,
  InputAreaFieldContainer,
  ButtonContent,
} from './style';

export type ProfileCreateCommentLayoutProps = {
  userGroup?: UserGroup | null;
  currentText?: string;
  maxChars?: number;
  loading: boolean;
  viewOnly: boolean;
  onBack: () => void;
  onCreateComment: (text: string) => void;
  onDeleteComment: () => void;
};

export default function ProfileCreateComment({
  userGroup,
  currentText,
  maxChars,
  loading,
  viewOnly,
  onBack,
  onCreateComment,
  onDeleteComment,
}: ProfileCreateCommentLayoutProps): JSX.Element {
  const [text, setText] = useState(currentText || '');

  const mappedUser = mapUser(userGroup?.user);
  return (
    <ModalScreenLayout
      backButtonTestID={'buttonBack'}
      firstButtonTestID={'buttonDelete'}
      title={t('screens.profileCreateComment')}
      firstButtonIconName={currentText && !viewOnly ? 'x-close' : undefined}
      onBackPress={onBack}
      onFirstButtonPress={() =>
        Alert.alert(t('alert.deleteCommentTitle'), '', [
          {
            text: t('alert.cancel'),
            style: 'cancel',
          },
          {
            text: t('alert.delete'),
            onPress: () => {
              onDeleteComment();
            },
          },
        ])
      }
    >
      <ScroolView showsVerticalScrollIndicator={false}>
        <InfoCell>
          <Avatar userGroup={userGroup} />
          <UserName>
            {t('profileCreateComment.toUser', { userName: mappedUser?.name })}
          </UserName>
        </InfoCell>
        <InputAreaFieldContainer>
          <InputAreaField
            testID={'inputText'}
            value={text}
            placeholder={t('profileCreateComment.textPlaceholder')}
            maxCharCount={maxChars}
            onChangeText={setText}
            editable={!viewOnly}
          />
        </InputAreaFieldContainer>
        {!viewOnly && (
          <ButtonContent>
            <Button
              testID={'buttonCreateComment'}
              text={t('profileCreateComment.saveComment')}
              state={text.length > 0 ? 'default' : 'disabled'}
              loading={loading}
              flex
              onPress={() => onCreateComment(text)}
            />
          </ButtonContent>
        )}
      </ScroolView>
    </ModalScreenLayout>
  );
}
