import React from 'react';
import { useMutation } from '@apollo/client';
import {
  useNavigation,
  useRoute,
  RouteProp,
  CompositeNavigationProp,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import useAppLocale from '~/data/hooks/useAppLocale';
import { RESET_PASSWORD } from '~/data/operations/auth';
import { AuthStackParamList, RootStackParamList } from '~/navigation/types';
import ResetPasswordLayout from './layout';

type ResetPasswordNavProp = CompositeNavigationProp<
  StackNavigationProp<AuthStackParamList, 'ResetPassword'>,
  StackNavigationProp<RootStackParamList, 'BrokenLink'>
>;

type ResetPasswordRouteProp = RouteProp<AuthStackParamList, 'ResetPassword'>;

export default function ResetPassword(): JSX.Element {
  const navigation = useNavigation<ResetPasswordNavProp>();
  const {
    params: { t },
  } = useRoute<ResetPasswordRouteProp>();

  const { locale, onChangeLocale } = useAppLocale({
    onReload: () => navigation.replace('ResetPassword', { t }),
  });

  const isTokenValid = t && t.length === 128;
  if (!isTokenValid) {
    navigation.navigate('BrokenLink');
  }

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);

  const onResetPassword = async (
    password: string,
    passwordConfirmation: string,
  ) => {
    try {
      const { data } = await resetPassword({
        variables: {
          input: { token: t, password, passwordConfirmation },
        },
      });

      const errors = data?.resetPassword?.errors;
      if (errors && errors.length > 0) {
        const messages = errors.map((error) => error?.messages[0]);
        const errorMessage = messages?.[0] as string;
        Snackbar.show(errorMessage);
        return;
      }

      navigation.navigate('ResetPasswordSuccess');
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <ResetPasswordLayout
      loading={loading}
      selectedLocale={locale}
      onResetPassword={onResetPassword}
      onSelectedLocale={onChangeLocale}
    />
  );
}
