import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import useAppLocale from '~/data/hooks/useAppLocale';
import { USER_EXISTS } from '~/data/operations/auth';
import { AuthStackParamList } from '~/navigation/types';
import { validateExistingUser } from '~/utils/validators';
import SignUpContactLayout, { ContactInfo } from './layout';

type SignUpContactNavProp = StackNavigationProp<
  AuthStackParamList,
  'SignUpContact'
>;
type SignUpContactRouteProp = RouteProp<AuthStackParamList, 'SignUpContact'>;

export default function SignUpContact(): JSX.Element {
  const navigation = useNavigation<SignUpContactNavProp>();
  const {
    params: { g: groupId, t: groupToken, r: inviteCode, shopReturnUrl },
  } = useRoute<SignUpContactRouteProp>();

  const { locale, onChangeLocale } = useAppLocale({
    onReload: () =>
      navigation.replace('SignUpContact', {
        g: groupId,
        t: groupToken,
        r: inviteCode,
      }),
  });

  const [contactInfo, setContactInfo] = useState<ContactInfo>();

  const { country, onUpdateCountry, onSetSignUpUser, signUpData } = useAuth();

  const [userExists, { loading: loadingUserExists, data: userExistsData }] =
    useLazyQuery(USER_EXISTS, {
      fetchPolicy: 'network-only',
    });

  const emailExists = userExistsData?.userExists?.emailExists;
  const phoneExists = userExistsData?.userExists?.phoneExists;

  const onNext = async ({ email, phoneCountryCode, phone }: ContactInfo) => {
    try {
      setContactInfo({ email, phoneCountryCode, phone });
      userExists({ variables: { email, phoneCountryCode, phone } });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  useEffect(() => {
    if (
      contactInfo &&
      emailExists != undefined &&
      emailExists != null &&
      phoneExists != undefined &&
      phoneExists != null
    ) {
      const message = validateExistingUser(emailExists, phoneExists);
      if (message) {
        Snackbar.show(message);
      } else {
        onSetSignUpUser(contactInfo);
        navigation.navigate('SignUpPassword', {
          g: groupId,
          t: groupToken,
          r: inviteCode,
          shopReturnUrl,
        });
      }
    }
  }, [emailExists, phoneExists, loadingUserExists]);

  return (
    <SignUpContactLayout
      initialEmail={signUpData.email}
      initialPhone={signUpData.phone}
      inputProgress={2}
      firstName={signUpData.firstName || ''}
      country={country}
      loading={loadingUserExists}
      selectedLocale={locale}
      emailExists={emailExists}
      phoneExists={phoneExists}
      onBack={() => navigation.goBack()}
      onUpdateCountry={onUpdateCountry}
      onNext={onNext}
      onSelectedLocale={onChangeLocale}
    />
  );
}
