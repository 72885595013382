import { gql } from './../../types';

export const AUTH_USER_FRAGMENT = gql(`
  fragment AuthUser on UserNode {
    ...User
    email
    phoneCountryCode
    phone
    emailValidated
    communicationLanguage
    isSuperuser
    countryOfResidence
    inviteCode
    externalTokens {
      intercom
    }
  }
`);
export const AUTH_USER_GROUP_FRAGMENT = gql(`
  fragment AuthUserGroup on UserGroupNode {
    ...UserGroup
    user {
      ...AuthUser
    }
    isYearbookTeamMember
    isCoreTeamMember
  }
`);

export const ME = gql(`
  query Me {
    me {
      ...AuthUser
    }
  }
`);
export const AUTH_USER_GROUP = gql(`
  query AuthUserGroup($id: ID!) {
    userGroup(id: $id) {
      ...AuthUserGroup
    }
  }
`);

export const CREATE_USER = gql(`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        ...AuthUser
      }
    }
  }
`);

export const CREATE_TOKEN = gql(`
  mutation CreateToken($input: ObtainJSONWebTokenInput!) {
    createToken(input: $input) {
      token
      refreshToken
    }
  }
`);

export const UPDATE_ME = gql(`
  mutation UpdateMe($input: UpdateMeInput!) {
    updateMe(input: $input) {
      errors {
        field
        messages
      }
      user {
        ...AuthUser
      }
      token
      refreshToken
    }
  }
`);

export const FORGET_PASSWORD = gql(`
  mutation ForgetPassword($input: ForgetPasswordInput!) {
    forgetPassword(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`);

export const RESET_PASSWORD = gql(`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`);

export const DELETE_USER = gql(`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        ...AuthUser
      }
    }
  }
`);

export const USER_EXISTS = gql(`
  query UserExists(
    $email: String!
    $phoneCountryCode: String!
    $phone: String!
  ) {
    userExists(
      email: $email
      phoneCountryCode: $phoneCountryCode
      phone: $phone
    ) {
      emailExists
      phoneExists
    }
  }
`);
