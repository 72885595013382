import React, {
  Reducer,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  ReactNode,
} from 'react';
import {
  ApolloError,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { Platform } from 'react-native';
import Snackbar from '~/components/Snackbar';
import useEmarsys from '~/data/hooks/useEmarsys';
import { AppLocale } from '~/data/models/app';
import { Country } from '~/data/models/marketProfile';
import { AuthUser, AuthUserGroup } from '~/data/models/user';
import {
  AUTH_USER_GROUP,
  CREATE_TOKEN,
  ME,
  UPDATE_ME,
} from '~/data/operations/auth';
import {
  clearAuth,
  getAppInfo,
  getRefreshToken,
  getSignUpData,
  getToken,
  getUserGroupInfo,
  getUserId,
  removeSignUpData,
  removeUserGroupInfo,
  setAppInfo,
  setSignUpData,
  setAuth,
  setUserGroupInfo,
  setUserId,
  getAppLocale,
  setAppLocale,
} from '~/data/storage';
import {
  getCountryFromLocale,
  getCountryFromPhoneCountryCode,
  getNormalizedLocale,
} from '~/data/utils';
import { RootEntranceKey } from '~/navigation/types';
import { trackError } from '~/utils/sentry';
import { useIntercom } from './intercom';

/* 
auth logic relies on two layers of data
1. async storage to persist data (data/storage)
2. local state for reactive variables (using local reducer)

/-- AUTH --/ 
authUserId: ''
authUserGroup: null

/-- LOBBY --/
authUserId: 'user_id'
authUserGroup: null

/-- MAIN --/
authUserId: 'user_id'
authUserGroup: {
  authGroupId: 'group_id',
  authUserGroupId: 'user_group_id'
}
*/

//HELPER
export type Nullable<T> = {
  [K in keyof T]?: T[K];
};

//STATE
type BaseAppState = {
  locale: AppLocale;
  country?: Country;
  hiddenPostIds: string[];
};

type BaseAuthState = {
  authUserId: string;
  authGroupId: string;
  authUserGroupId: string;
};

type BaseStorageState = {
  rehydrated: boolean;
};

type BaseSignUpState = {
  signUpData: {
    firstName: string;
    lastName: string;
    email: string;
    phoneCountryCode: string;
    phone: string;
    password: string;
    passwordConfirmation: string;
    expiryTimestamp: number;
  };
};

// Note: In case user leaves the registration flow in the middle, we don't want to keep the data forever.
const SIGN_UP_DATA_EXPIRY_LIFE_DURATION = 1000 * 60 * 60; // 1 hour
const getExpiryTimestamp = () => {
  return Date.now() + SIGN_UP_DATA_EXPIRY_LIFE_DURATION;
};

export type AuthState = BaseAppState &
  BaseAuthState &
  BaseStorageState &
  BaseSignUpState;
//ACTIONS
enum AuthActionType {
  REHYDRATE = 'REHYDRATE',
  SET_APP_LOCALE = 'SET_APP_LOCALE',
  SET_COUNTRY = 'SET_COUNTRY',
  SET_HIDDEN_POST_IDS = 'SET_HIDDEN_POST_IDS',
  SET_AUTH = 'SET_AUTH',
  SET_GROUP_AUTH = 'SET_GROUP_AUTH',
  SET_SIGN_UP = 'SET_SIGN_UP',
  LOGOUT_GROUP_AUTH = 'LOGOUT_GROUP_AUTH',
  LOGOUT = 'LOGOUT',
}

type RehydrateAction = {
  type: AuthActionType.REHYDRATE;
  payload: BaseAppState & BaseAuthState & BaseSignUpState;
};

type SetAppLocaleAction = {
  type: AuthActionType.SET_APP_LOCALE;
  payload: {
    locale: AppLocale;
  };
};

type SetCountryAction = {
  type: AuthActionType.SET_COUNTRY;
  payload: {
    country: Country;
  };
};

type HidePostAction = {
  type: AuthActionType.SET_HIDDEN_POST_IDS;
  payload: {
    hiddenPostIds: string[];
  };
};

type SetAuthAction = {
  type: AuthActionType.SET_AUTH;
  payload: {
    authUserId: string;
  };
};

type SetGroupAuthAction = {
  type: AuthActionType.SET_GROUP_AUTH;
  payload: {
    authGroupId: string;
    authUserGroupId: string;
  };
};
type SetSignUpAction = {
  type: AuthActionType.SET_SIGN_UP;
  payload: BaseSignUpState;
};

type LogoutGroupAuthAction = { type: AuthActionType.LOGOUT_GROUP_AUTH };

type LogoutAction = { type: AuthActionType.LOGOUT };

type AuthActions =
  | RehydrateAction
  | SetAppLocaleAction
  | SetCountryAction
  | HidePostAction
  | SetAuthAction
  | SetGroupAuthAction
  | LogoutGroupAuthAction
  | LogoutAction
  | SetSignUpAction;

function authReducer(state: AuthState, action: AuthActions): AuthState {
  const { type } = action;
  switch (type) {
    case AuthActionType.REHYDRATE:
      return {
        ...state,
        authUserId: action.payload.authUserId,
        authGroupId: action.payload.authGroupId,
        authUserGroupId: action.payload.authUserGroupId,
        locale: action.payload.locale,
        country: action.payload.country,
        hiddenPostIds: action.payload.hiddenPostIds,
        signUpData: {
          firstName: action.payload.signUpData.firstName,
          lastName: action.payload.signUpData.lastName,
          email: action.payload.signUpData.email,
          phoneCountryCode: action.payload.signUpData.phoneCountryCode,
          phone: action.payload.signUpData.phone,
          password: action.payload.signUpData.password,
          passwordConfirmation: action.payload.signUpData.passwordConfirmation,
          expiryTimestamp: action.payload.signUpData.expiryTimestamp,
        },
        rehydrated: true,
      };
    case AuthActionType.SET_APP_LOCALE:
      return {
        ...state,
        locale: action.payload.locale,
      };
    case AuthActionType.SET_COUNTRY:
      return {
        ...state,
        country: action.payload.country,
      };
    case AuthActionType.SET_HIDDEN_POST_IDS:
      return {
        ...state,
        hiddenPostIds: action.payload.hiddenPostIds,
      };
    case AuthActionType.SET_AUTH:
      return {
        ...state,
        authUserId: action.payload.authUserId,
      };
    case AuthActionType.SET_GROUP_AUTH:
      return {
        ...state,
        authGroupId: action.payload.authGroupId,
        authUserGroupId: action.payload.authUserGroupId,
      };
    case AuthActionType.LOGOUT_GROUP_AUTH:
      return {
        ...state,
        authGroupId: '',
        authUserGroupId: '',
      };
    case AuthActionType.LOGOUT:
      return {
        ...state,
        authUserId: '',
        authGroupId: '',
        authUserGroupId: '',
      };
    case AuthActionType.SET_SIGN_UP:
      return {
        ...state,
        signUpData: action.payload.signUpData,
      };
    default:
      return state;
  }
}

export const ROOT_ENTRANCE = {
  AUTH: 'AuthStack', //if !authUserId
  LOBBY: 'LobbyStack', //if !!authUserId && !authGroupId
  MAIN: 'MainTab', //if !!authUserId && !!authGroupId
  ADMIN: 'AdminStack', //if !!authUserId && web && isSuperUser
} as const;

type AuthSuccessData = {
  authToken: string;
  refreshToken: string;
  authUser: AuthUser;
};

type AuthContextData = AuthState & {
  //associated to user auth (AuthStack)
  authUser?: AuthUser | null;
  //associated to user auth group (MainTab:groupId | LobbyStack)
  authUserGroup?: AuthUserGroup | null;

  yearbookAdmin: boolean;
  coreAdmin: boolean;

  loading: boolean;
  error?: ApolloError;

  rootEntranceKey: RootEntranceKey;
  onSetSignUpUser: (
    data: Partial<BaseSignUpState['signUpData']>,
  ) => Promise<void>;
  onClearSignUpUser: () => Promise<void>;
  refetchAuthUserGroup: () => void;
  onChangeLocale: (locale: AppLocale) => Promise<void>;
  onUpdateCountry: (country: Country) => Promise<void>;
  onHidePost: (postId: string) => Promise<void>;
  onAuth: (email: string, password: string) => Promise<AuthSuccessData>;
  onAuthGroup: (groupId: string, userGroupId: string) => Promise<void>;
  onLogoutGroup: () => Promise<void>;
  onLogout: () => Promise<void>;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export interface AuthProviderProps {
  children: ReactNode;
  initialState?: Nullable<AuthState>;
}

const INITIAL_SIGN_UP_STATE: BaseSignUpState = {
  signUpData: {
    firstName: '',
    lastName: '',
    email: '',
    phoneCountryCode: '',
    phone: '',
    password: '',
    passwordConfirmation: '',
    expiryTimestamp: getExpiryTimestamp(),
  },
};

export const INITIAL_STATE: AuthState = {
  rehydrated: false,
  authUserId: '',
  authGroupId: '',
  authUserGroupId: '',
  locale: getNormalizedLocale(),
  country: undefined,
  hiddenPostIds: [],
  ...INITIAL_SIGN_UP_STATE,
};

export const AuthProvider = ({
  children,
  initialState: nInitialState,
}: AuthProviderProps) => {
  const initialState: AuthState = {
    ...INITIAL_STATE,
    ...nInitialState,
  };
  const apollo = useApolloClient();

  const { clearContact } = useEmarsys();

  const [
    {
      rehydrated,
      locale,
      country,
      hiddenPostIds,
      authUserId,
      authGroupId,
      authUserGroupId,
      signUpData,
    },
    dispatch,
  ] = useReducer<Reducer<AuthState, AuthActions>>(authReducer, initialState);

  const {
    onLogin: onLoginIntercom,
    onLogout: onLogoutIntercom,
    onLoginAnon: onLoginAnonIntercom,
  } = useIntercom();

  const [createToken, { loading: createTokenLoading }] =
    useMutation(CREATE_TOKEN);

  const [loadMe, { loading: meLazyLoading }] = useLazyQuery(ME);

  //there is a known issue on resetStore, for some reason queries with no variables (me query) are not invalidated on logout resetStore
  //test to check, add console.log({ authUser, authUserGroup });  on line 370, after logout, authUser is not cleared, when skip not set
  //replace all uses of me with some variable would work but add more complexity/mess to the code
  //current solution is keep the     skip: !authUserId, on me query and just change with this issue in mind
  const {
    data: meData,
    loading: meLoading,
    error: meError,
  } = useQuery(ME, {
    skip: !authUserId, //don't change comment above
  });
  const [updateMe] = useMutation(UPDATE_ME);

  const authUser = meData?.me;

  const {
    data: authUserGroupData,
    loading: authUserLoading,
    error: authUserError,
    refetch: refetchAuthUserGroup,
  } = useQuery(AUTH_USER_GROUP, {
    skip: !authUserGroupId,
    variables: {
      id: authUserGroupId,
    },
  });
  const authUserGroup = authUserGroupData?.userGroup;

  const yearbookAdmin = authUserGroup?.isYearbookTeamMember || false;

  const coreAdmin = authUserGroup?.isCoreTeamMember || false;

  const loading =
    meLazyLoading || meLoading || authUserLoading || createTokenLoading;

  const error = meError || authUserError;

  // Get algorithm here:
  // 1. If user has countryOfResidence, use that
  // 2. If no countryOfResidence, use persisted country
  // 3. If no persisted country, generate country from phoneCountryCode
  // 4. If no phoneCountryCode, generate from locale

  // Set algorithm here:
  // 1. If meData exists, update countryOfResidence
  // 2. update persisted country always
  const getUserCountry = (authUser?: AuthUser): Country => {
    if (authUser?.countryOfResidence) {
      return authUser?.countryOfResidence as Country;
    }
    if (authUser?.phoneCountryCode) {
      return getCountryFromPhoneCountryCode(authUser.phoneCountryCode);
    }

    return getCountryFromLocale();
  };

  useEffect(() => {
    (async () => {
      if (rehydrated) {
        return;
      }

      const token = await getToken();
      const refreshToken = await getRefreshToken();
      const userId = await getUserId();
      const userGroupInfo = await getUserGroupInfo();

      const signUpDataRaw = await getSignUpData();
      const signUpDataParsed = signUpDataRaw
        ? JSON.parse(signUpDataRaw)
        : undefined;
      const isFreshSignUpData = signUpDataParsed?.expiryTimestamp > Date.now();

      const signUpData = isFreshSignUpData
        ? signUpDataParsed
        : INITIAL_SIGN_UP_STATE;

      const appLocale = await getAppLocale();
      const appInfo = await getAppInfo();

      dispatch({
        type: AuthActionType.REHYDRATE,
        payload: {
          authUserId: userId && token && refreshToken ? userId : '', //ensure user auth tokens/userId consistence
          authGroupId: userGroupInfo?.authGroupId || '',
          authUserGroupId: userGroupInfo?.authUserGroupId || '',
          locale: appLocale || getNormalizedLocale(),
          country: (appInfo?.country as Country) || getUserCountry(),
          hiddenPostIds: (appInfo?.hiddenPostIds as string[]) || [],
          signUpData: {
            firstName: signUpData?.firstName || '',
            lastName: signUpData?.lastName || '',
            email: signUpData?.email || '',
            phoneCountryCode: signUpData?.phoneCountryCode || '',
            phone: signUpData?.phone || '',
            password: signUpData?.password || '',
            passwordConfirmation: signUpData?.passwordConfirmation || '',
            expiryTimestamp:
              signUpData?.expiryTimestamp || getExpiryTimestamp(),
          },
        },
      });
    })();
  }, []);

  useEffect(() => {
    if (!authUser) {
      return;
    }

    const userCountry = getUserCountry(authUser);

    if (userCountry !== country) {
      setCountry(userCountry);
    }
  }, [authUser?.countryOfResidence, authUser?.phoneCountryCode]);

  // initialize intercom
  useEffect(() => {
    if (!authUser) {
      onLoginAnonIntercom();
    } else {
      onLoginIntercom(authUser);
    }
  }, [authUser?.externalTokens?.intercom]);

  async function onChangeLocale(newAppLocale: AppLocale) {
    await setAppLocale(newAppLocale);
    dispatch({
      type: AuthActionType.SET_APP_LOCALE,
      payload: {
        locale: newAppLocale,
      },
    });
  }

  async function setCountry(newCountry: Country) {
    await setAppInfo({
      country: newCountry,
      hiddenPostIds,
    });
    dispatch({
      type: AuthActionType.SET_COUNTRY,
      payload: {
        country: newCountry,
      },
    });
  }

  async function onUpdateCountry(newCountry: Country) {
    if (authUser) {
      try {
        const { data: updatedData } = await updateMe({
          variables: {
            input: {
              firstName: authUser.firstName,
              lastName: authUser.lastName,
              email: authUser.email,
              phone: authUser.phone,
              phoneCountryCode: authUser.phoneCountryCode,
              gender: authUser.gender,
              communicationLanguage: authUser.communicationLanguage,
              countryOfResidence: newCountry,
            },
          },
        });
        const messages = updatedData?.updateMe?.errors?.map(
          (error) => error?.messages[0],
        );

        const errorMessage = messages?.[0];
        if (errorMessage) {
          Snackbar.show(errorMessage);
          return;
        }
      } catch (e) {
        if (e instanceof Error) {
          Snackbar.show(e.message);
          return;
        }
      }
    }
    setCountry(newCountry);
  }

  async function onHidePost(postId: string) {
    const newHiddenPostIds = [...hiddenPostIds, postId];
    await setAppInfo({
      country: country as string,
      hiddenPostIds: newHiddenPostIds,
    });
    dispatch({
      type: AuthActionType.SET_HIDDEN_POST_IDS,
      payload: {
        hiddenPostIds: newHiddenPostIds,
      },
    });
  }

  async function onAuth(
    email: string,
    password: string,
  ): Promise<AuthSuccessData> {
    const { data } = await createToken({
      variables: {
        input: { email, password },
      },
    });

    const authToken = data?.createToken?.token;
    const refreshToken = data?.createToken?.refreshToken;

    if (!authToken || !refreshToken) {
      throw new Error('invalid token');
    }
    await setAuth(authToken, refreshToken);

    const { data: meData } = await loadMe();
    const authUser = meData?.me;
    const userId = authUser?.id;

    if (!userId) {
      throw new Error('invalid data');
    }

    await setUserId(userId);
    dispatch({
      type: AuthActionType.SET_AUTH,
      payload: {
        authUserId: userId,
      },
    });

    return {
      authToken,
      refreshToken,
      authUser,
    };
  }

  async function onAuthGroup(groupId: string, userGroupId: string) {
    await setUserGroupInfo({
      authGroupId: groupId,
      authUserGroupId: userGroupId,
    });
    dispatch({
      type: AuthActionType.SET_GROUP_AUTH,
      payload: {
        authGroupId: groupId,
        authUserGroupId: userGroupId,
      },
    });
  }

  async function onLogout() {
    try {
      await onLogoutIntercom();
      await clearAuth();
      apollo.stop();
      await apollo.resetStore();
      clearContact();
    } catch (e) {
      trackError(e);
    } finally {
      dispatch({
        type: AuthActionType.LOGOUT,
      });
    }
  }

  async function onLogoutGroup() {
    await removeUserGroupInfo();

    dispatch({
      type: AuthActionType.LOGOUT_GROUP_AUTH,
    });
  }
  const onSetSignUpUser = async (
    data: Partial<BaseSignUpState['signUpData']>,
  ) => {
    const newData = {
      ...signUpData,
      ...data,
      expiryTimestamp: getExpiryTimestamp(),
    };

    dispatch({
      type: AuthActionType.SET_SIGN_UP,
      payload: {
        signUpData: newData,
      },
    });
    await setSignUpData(JSON.stringify(newData));
  };

  const onClearSignUpUser = async () => {
    dispatch({
      type: AuthActionType.SET_SIGN_UP,
      payload: {
        signUpData: INITIAL_SIGN_UP_STATE.signUpData,
      },
    });
    await removeSignUpData();
  };

  const getRootEntranceKey = ({
    authUserId,
    authGroupId,
    isSuperUser,
  }: {
    authUserId: string;
    authGroupId: string;
    isSuperUser?: boolean;
  }): RootEntranceKey => {
    if (!!authUserId && isSuperUser && Platform.OS === 'web') {
      return ROOT_ENTRANCE.ADMIN;
    }
    if (!!authUserId && !authGroupId) {
      return ROOT_ENTRANCE.LOBBY;
    }
    if (!!authUserId && !!authGroupId) {
      return ROOT_ENTRANCE.MAIN;
    }
    return ROOT_ENTRANCE.AUTH;
  };

  const rootEntranceKey = getRootEntranceKey({
    authUserId,
    authGroupId,
    isSuperUser: authUser?.isSuperuser,
  });

  const value: AuthContextData = useMemo(
    () => ({
      locale,
      country,
      hiddenPostIds,
      authUserId,
      authUser,
      authGroupId,
      authUserGroupId,
      authUserGroup,
      yearbookAdmin,
      coreAdmin,
      rehydrated,
      loading,
      error,
      rootEntranceKey,
      signUpData,
      onSetSignUpUser,
      onClearSignUpUser,
      refetchAuthUserGroup,
      onChangeLocale,
      onUpdateCountry,
      onHidePost,
      onAuth,
      onAuthGroup,
      onLogoutGroup,
      onLogout,
    }),
    [
      locale,
      country,
      hiddenPostIds,
      authUserId,
      authUser,
      authGroupId,
      authUserGroupId,
      authUserGroup,
      yearbookAdmin,
      coreAdmin,
      rehydrated,
      loading,
      error,
      rootEntranceKey,
      signUpData,
      onSetSignUpUser,
      onClearSignUpUser,
      refetchAuthUserGroup,
      onChangeLocale,
      onUpdateCountry,
      onHidePost,
      onAuth,
      onAuthGroup,
      onLogoutGroup,
      onLogout,
    ],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}
