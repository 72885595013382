import styled from 'styled-components/native';

export const ScrollView = styled.ScrollView`
  padding-top: 8px;
`;

export const Section = styled.View`
  padding-top: 16px;
  padding-horizontal: 16px;
`;

export const BoxWrapper = styled.View`
  flex-direction: row;
  gap: 16px;
`;

export const AssetWrapper = styled.TouchableOpacity``;
