import React, { useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import { USER_GROUP } from '~/data/operations/group';
import {
  CREATE_PROFILE_PAGE_COMMENT,
  DESTROY_PROFILE_PAGE_COMMENT,
  MODULE_INSTANCE,
  UPDATE_PROFILE_PAGE_COMMENT,
} from '~/data/operations/yearbook';
import {
  readProfilePageCommentsQuery,
  writeProfilePageCommentsQuery,
} from '~/data/operations/yearbook/helpers';
import { RootStackParamList } from '~/navigation/types';
import ProfileCreateCommentLayout from './layout';

type ProfileCreateCommentNavProp = StackNavigationProp<
  RootStackParamList,
  'ProfileCreateComment'
>;
type ProfileCreateCommentRouteProp = RouteProp<
  RootStackParamList,
  'ProfileCreateComment'
>;
export default function ProfileCreateComment(): JSX.Element {
  const navigation = useNavigation<ProfileCreateCommentNavProp>();
  const isCommentCreatedOnce = useRef(false);
  const {
    params: {
      moduleInstanceId,
      profileUserGroupId,
      profilePageCommentId,
      currentText,
      viewOnly = false,
    },
  } = useRoute<ProfileCreateCommentRouteProp>();

  const { authUserGroupId } = useAuth();

  const { data: moduleInstanceData } = useQuery(MODULE_INSTANCE, {
    variables: {
      id: moduleInstanceId,
    },
  });

  const maxChars =
    moduleInstanceData?.moduleInstance?.profilePageSetup?.maxChars;

  const { data: profileUserGroupData } = useQuery(USER_GROUP, {
    variables: {
      id: profileUserGroupId,
    },
  });

  const userGroup = profileUserGroupData?.userGroup;

  const profilePageCommentsVar = {
    moduleInstance: moduleInstanceId,
    comentatorUserGroup: authUserGroupId,
    first: DEFAULT_LIST_OFFSET,
  };

  const [
    createProfilePageComment,
    { loading: createProfilePageCommentLoading },
  ] = useMutation(CREATE_PROFILE_PAGE_COMMENT, {
    update(cache, { data }) {
      const newProfilePageComment =
        data?.createProfilePageComment?.profilePageComment;

      const currentProfilePageComments = readProfilePageCommentsQuery({
        cache,
        variables: profilePageCommentsVar,
      });

      if (
        currentProfilePageComments?.profilePageComments?.edges &&
        newProfilePageComment
      ) {
        writeProfilePageCommentsQuery({
          cache,
          variables: profilePageCommentsVar,
          data: {
            ...currentProfilePageComments,
            profilePageComments: {
              ...currentProfilePageComments.profilePageComments,
              edges: [
                ...currentProfilePageComments.profilePageComments.edges,
                {
                  __typename: 'ProfilePageCommentNodeEdge',
                  node: newProfilePageComment,
                },
              ],
            },
          },
        });
      }
    },
  });
  const [
    updateProfilePageComment,
    { loading: updateProfilePageCommentLoading },
  ] = useMutation(UPDATE_PROFILE_PAGE_COMMENT);

  const [destroyProfilePageComment] = useMutation(DESTROY_PROFILE_PAGE_COMMENT);

  const onCreateComment = async (text: string) => {
    try {
      if (profilePageCommentId) {
        await updateProfilePageComment({
          variables: {
            input: {
              id: profilePageCommentId,
              text,
            },
          },
        });
      } else {
        if (!isCommentCreatedOnce.current) {
          isCommentCreatedOnce.current = true;
          await createProfilePageComment({
            variables: {
              input: {
                moduleInstance: moduleInstanceId,
                profileUserGroup: profileUserGroupId,
                text,
              },
            },
          });
        }
      }
      navigation.goBack();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDeleteComment = async () => {
    try {
      if (profilePageCommentId) {
        await destroyProfilePageComment({
          variables: {
            input: {
              id: profilePageCommentId,
            },
          },
          update(cache) {
            const currentProfilePageComments = readProfilePageCommentsQuery({
              cache,
              variables: profilePageCommentsVar,
            });

            if (currentProfilePageComments?.profilePageComments?.edges) {
              writeProfilePageCommentsQuery({
                cache,
                variables: profilePageCommentsVar,
                data: {
                  ...currentProfilePageComments,
                  profilePageComments: {
                    ...currentProfilePageComments.profilePageComments,
                    edges:
                      currentProfilePageComments.profilePageComments.edges.filter(
                        (edge) => edge?.node?.id !== profilePageCommentId,
                      ),
                  },
                },
              });
            }
          },
        });
        navigation.goBack();
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <ProfileCreateCommentLayout
      userGroup={userGroup}
      currentText={currentText}
      maxChars={maxChars}
      loading={
        createProfilePageCommentLoading || updateProfilePageCommentLoading
      }
      onBack={() => navigation.goBack()}
      onCreateComment={onCreateComment}
      onDeleteComment={onDeleteComment}
      viewOnly={viewOnly}
    />
  );
}
