import React, { useEffect, useState } from 'react';
import Button from '~/components/Button';
import InputField from '~/components/InputField';
import MemberCell from '~/components/MemberCell';
import TeamColorPicker from '~/components/TeamColorPicker';
import TeamIconPicker from '~/components/TeamIconPicker';
import { Color, Icon } from '~/data/models/custom';
import { UserGroup } from '~/data/models/group';
import { User } from '~/data/models/user';
import { TabScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  HeaderContainer,
  MemberList,
  MemberCellView,
  Header,
  Name,
  InputContent,
  SectionHeader,
  Footer,
} from './style';

export type CreateTeamInputProps = {
  name: string;
  colorId: string;
  iconId: string;
};

type CreateTeamLayoutProps = {
  authUserId: string;
  colors: Color[];
  icons: Icon[];
  userGroups: UserGroup[];
  loading: boolean;
  createTeamLoading: boolean;
  onBack: () => void;
  onCreateTeam: (teamInput: CreateTeamInputProps) => void;
  onAddMember: () => void;
  onRemoveMember: (userId: string) => void;
};

export default function CreateTeamLayout({
  authUserId,
  colors,
  icons,
  userGroups,
  createTeamLoading,
  onBack,
  onCreateTeam,
  onAddMember,
  onRemoveMember,
}: CreateTeamLayoutProps): JSX.Element {
  const [name, setName] = useState<string>('');
  const [selectedColor, setSelectedColor] = useState<Color | null>(null);
  const [selectedIcon, setSelectedIcon] = useState<Icon | null>(null);

  useEffect(() => {
    if (selectedIcon === null && icons.length > 0) {
      setSelectedIcon(icons[0]);
    }
  }, [icons]);

  const renderMemberItem = ({ item: userGroup }: { item: UserGroup }) => {
    const admin = userGroup.user.id === authUserId;
    return (
      <MemberCellView>
        <MemberCell
          testID={`MemberCell:${userGroup.id}`}
          userGroup={userGroup}
          checked={admin}
          onRemovePress={
            !admin ? () => onRemoveMember(userGroup.user.id) : undefined
          }
        />
      </MemberCellView>
    );
  };

  const createTeamEnabled =
    name != '' && selectedColor != null && selectedIcon != null;

  const onCreate = () => {
    if (name != '' && selectedColor != null && selectedIcon != null) {
      onCreateTeam({
        name,
        colorId: selectedColor.id,
        iconId: selectedIcon.id,
      });
    }
  };

  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.createTeam')}
      onBackPress={onBack}
    >
      <MemberList<React.ElementType>
        data={userGroups}
        renderItem={renderMemberItem}
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={
          <HeaderContainer>
            <TeamIconPicker
              testID={'iconPicker'}
              icons={icons}
              teamColor={selectedColor?.hex}
              onSelectedIcon={(icon) => setSelectedIcon(icon)}
            />
            <Header>
              <Name>{name || t('createTeam.insertTeamName')}</Name>
            </Header>
            <InputContent>
              <InputField
                testID={'inputName'}
                iconName={'users-01'}
                value={name}
                label={t('createTeam.inputName')}
                onChangeText={(value) => setName(value)}
              />
            </InputContent>
            <TeamColorPicker
              testID={'colorPicker'}
              colors={colors}
              selectedColor={selectedColor}
              onSelectedColor={(color) => setSelectedColor(color)}
            />
            <SectionHeader
              title={t('sections.teamMembers')}
              buttonTestID={'buttonAddMember'}
              buttonText={t('addMember')}
              buttonIcon={'plus'}
              buttonColor={selectedColor?.hex}
              onButtonPress={() => onAddMember()}
            />
          </HeaderContainer>
        }
        ListFooterComponent={
          <Footer>
            <Button
              testID={'buttonCreateTeam'}
              state={createTeamEnabled ? 'default' : 'disabled'}
              text={t('createTeam.createTeam')}
              loading={createTeamLoading}
              onPress={onCreate}
              flex
            />
          </Footer>
        }
        keyExtractor={(user: User) => user.id}
      />
    </TabScreenLayout>
  );
}
