import React from 'react';
import { ColorSchema } from 'gradoo-theme/lib/types';
import {
  SafeAreaView,
  SafeAreaViewProps,
} from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/native';
import NavHeader, { NavHeaderProps } from '~/components/NavHeader';
import {
  STACK_SCREEN_LANDSCAPE_LEFT_PADDING,
  STACK_SCREEN_LANDSCAPE_RIGHT_PADDING,
  TAB_SCREEN_LANDSCAPE_LEFT_PADDING,
  TAB_SCREEN_LANDSCAPE_RIGHT_PADDING,
  IS_LANDSCAPE,
  LANDSCAPE_MIN,
  IS_TABLET,
} from '~/data/constants';

type BgBrandColorKey = 'brand_01' | 'brand_02';

type BgColorKey = keyof ColorSchema | BgBrandColorKey;

interface BaseScreenContainer {
  edges?: SafeAreaViewProps['edges'];
  bgColorKey?: BgColorKey;
  BgComponent?: React.ReactNode;
  navPadding?: boolean;
}

interface BaseChildrenChildren {
  leftPadding?: number;
  rightPadding?: number;
}

export type BaseScreenLayout = {
  children: React.ReactNode;
} & NavHeaderProps &
  BaseScreenContainer &
  BaseChildrenChildren;

const NavContainer = styled.View<BaseChildrenChildren>`
  padding-left: ${({ leftPadding }) => leftPadding}px;
  padding-right: ${({ rightPadding }) => rightPadding}px;
`;

const ChildrenContainer = styled.View<BaseChildrenChildren>`
  flex: 1;

  padding-left: ${({ leftPadding }) => leftPadding}px;
  padding-right: ${({ rightPadding }) => rightPadding}px;
`;

const ScreenContainer = styled(SafeAreaView)<BaseScreenContainer>`
  flex: 1;
  background-color: ${({ theme, bgColorKey = 'c0' }) =>
    bgColorKey === 'brand_01' || bgColorKey === 'brand_02'
      ? theme.color[bgColorKey]
      : theme.color.base[bgColorKey]};
`;

const ScreenLayout = ({
  edges,
  leftPadding,
  rightPadding,
  children,
  bgColorKey,
  BgComponent,
  navPadding,
  ...navHeaderProps
}: BaseScreenLayout): JSX.Element => {
  const isLandscapeMin = (() => {
    if (IS_TABLET) {
      return true;
    }
    return useMediaQuery({
      minWidth: LANDSCAPE_MIN,
    });
  })();
  const hasPadding = IS_LANDSCAPE && isLandscapeMin;
  return (
    <ScreenContainer edges={edges} bgColorKey={bgColorKey}>
      {BgComponent}
      {!!navHeaderProps['title'] ||
      !!navHeaderProps['RightComponent'] ||
      !!navHeaderProps['onBackPress'] ? (
        <NavContainer
          leftPadding={navPadding && hasPadding ? leftPadding : 0}
          rightPadding={navPadding && hasPadding ? rightPadding : 0}
        >
          <NavHeader {...navHeaderProps} />
        </NavContainer>
      ) : null}
      <ChildrenContainer
        leftPadding={hasPadding ? leftPadding : 0}
        rightPadding={hasPadding ? rightPadding : 0}
      >
        {children}
      </ChildrenContainer>
    </ScreenContainer>
  );
};

//STACK
//for screens in AuthStackParamList, LobbyStackParamList, GroupStackParamList, et
export const StackScreenLayout = ({
  ...props
}: BaseScreenLayout): JSX.Element => (
  <ScreenLayout
    leftPadding={STACK_SCREEN_LANDSCAPE_LEFT_PADDING}
    rightPadding={STACK_SCREEN_LANDSCAPE_RIGHT_PADDING}
    navPadding
    {...props}
  />
);

//TAB
//for screens inside MainTabParamList
export const TabScreenContainer = styled(ScreenContainer).attrs({
  edges: ['right', 'left', 'top'],
})``;

export const TabScreenLayout = ({
  ...props
}: BaseScreenLayout): JSX.Element => (
  <ScreenLayout
    leftPadding={TAB_SCREEN_LANDSCAPE_LEFT_PADDING}
    rightPadding={TAB_SCREEN_LANDSCAPE_RIGHT_PADDING}
    edges={['right', 'left', 'top']}
    {...props}
  />
);

//MODAL
//for screens in RootStackParamList
export const ModalScreenLayout = ({
  ...props
}: BaseScreenLayout): JSX.Element => (
  <ScreenLayout
    leftPadding={TAB_SCREEN_LANDSCAPE_LEFT_PADDING}
    rightPadding={TAB_SCREEN_LANDSCAPE_RIGHT_PADDING}
    edges={
      IS_LANDSCAPE ? ['right', 'left', 'top'] : ['right', 'left', 'bottom']
    }
    {...props}
  />
);
