import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { DEFAULT_LIST_OFFSET, NO_PAG_LIST_OFFSET } from '~/data/constants';
import { Institute, InstituteType } from '~/data/models/institute';
import { INSTITUTE_TYPES, INSTITUTES } from '~/data/operations/institute';
import { InstitutesInstituteCountryChoices } from '~/data/types/graphql';
import { GroupStackParamList } from '~/navigation/types';
import useThrottledState from '~/utils/hooks/useThrottledState';
import JoinSelectInstituteLayout from './layout';

type JoinSelectInstituteNavProp = StackNavigationProp<
  GroupStackParamList,
  'JoinSelectInstitute'
>;

type JoinSelectInstituteRouteProp = RouteProp<
  GroupStackParamList,
  'JoinSelectInstitute'
>;

export default function JoinSelectInstitute(): JSX.Element {
  const navigation = useNavigation<JoinSelectInstituteNavProp>();
  const {
    params: { city },
  } = useRoute<JoinSelectInstituteRouteProp>();

  const { country } = useAuth();

  const [selectedInstituteType, setSelectedInstituteType] = useState<
    InstituteType | undefined
  >(undefined);
  const [searchingName, setSearchingName] = useThrottledState<string>('', 500);

  const institutesVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    name: searchingName || null,
    city: city,
    type: selectedInstituteType?.id || null,
  };

  const {
    loading: institutesLoading,
    data: institutesData,
    fetchMore: fetchMoreInstitutes,
  } = useQuery(INSTITUTES, {
    variables: institutesVar,
    notifyOnNetworkStatusChange: true,
  });

  const { data: instituteTypesData } = useQuery(INSTITUTE_TYPES, {
    variables: {
      first: NO_PAG_LIST_OFFSET,
      country: country && InstitutesInstituteCountryChoices[country],
    },
  });

  const institutes: Institute[] =
    (institutesData?.institutes?.edges.map(
      (edge) => edge?.node,
    ) as Institute[]) || [];

  const instituteTypes: InstituteType[] =
    (instituteTypesData?.instituteTypes?.edges.map(
      (edge) => edge?.node,
    ) as InstituteType[]) || [];

  const hasNextPage = institutesData?.institutes?.pageInfo.hasNextPage;
  const endCursor = institutesData?.institutes?.pageInfo.endCursor;

  return (
    <JoinSelectInstituteLayout
      inputProgressTotal={4}
      inputProgress={2}
      country={country}
      institutes={institutes}
      instituteTypes={instituteTypes}
      selectedInstituteType={selectedInstituteType}
      loading={institutesLoading}
      hasNextPage={hasNextPage}
      onBack={() => navigation.goBack()}
      onSearchInstituteChange={(searchingText) =>
        setSearchingName(searchingText)
      }
      onInstituteTypeChange={setSelectedInstituteType}
      onSelectedInstitute={(institute) =>
        navigation.navigate('JoinSelectGroup', { instituteId: institute.id })
      }
      onNotListed={() => navigation.navigate('RequestInstitution')}
      onLoadMore={() => {
        fetchMoreInstitutes({
          variables: {
            ...institutesVar,
            after: endCursor,
          },
        });
      }}
    />
  );
}

/**
 * TODO:
 * - Setup institute address and city
 * - Error state
 * - Handle country
 */
