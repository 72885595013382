import React, { useRef, useState } from 'react';
import { ScrollView } from 'react-native';
import Button from '~/components/Button';
import Dropdown, { OptionsInfo } from '~/components/Dropdown';
import GroupFilteringBlock from '~/components/GroupFilteringBlock';
import LocationFilteringBlockType from '~/components/LocationFilteringBlock';
import MapBoxWrapper, { MapBoxWrapperHandle } from '~/components/MapBoxWrapper';
import StatisticsCard from '~/components/StatisticsCard';
import UserTargetingBlock from '~/components/UserTargetingBlock';
import { LOCATION_DATA, createPlaceholderFilter } from '~/data/constants';
import { AdsTargeting, AdsTargetingFilter } from '~/data/models/admin';
import { Regions } from '~/data/models/campaign';
import { Country } from '~/data/models/marketProfile';
import {
  GraduationTypesQuery as GraduationTypes,
  InstituteTypesQuery as InstituteTypes,
} from '~/data/types/graphql';
import { StatisticsCardsContainer } from '~/screens/Admin/EditAsset/layout/style';
import { t } from '~/utils/i18n';
import { Statistic } from '~/utils/types/adminAds';
import helpers from './helpers';
import {
  Container,
  Title,
  TargetingContainer,
  MapContainer,
  FilteringContainer,
  ButtonAddFilterContainer,
  StatisticsContainer,
  PickerContainer,
  ButtonContent,
  Text,
  OrAndOptionsContainer,
  OptionsContainer,
} from './style';

type FilterOption = {
  key: 'location' | 'user' | 'group';
  name: string;
};

type CreateTargetingProps = {
  addFilter: (filter: AdsTargetingFilter) => void;
  updateFilter: (index: number, filter: AdsTargetingFilter) => void;
  filters: AdsTargetingFilter[];
  removeFilter: (index: number) => void;
  targetingData?: AdsTargeting;
  targetingLoading?: boolean;
  instituteTypes?: InstituteTypes;
  graduationTypes?: GraduationTypes;
  isDesktop?: boolean;
  regions: Regions[];
};

const orAndOptions: OptionsInfo[] = [
  {
    key: 'or',
    name: 'OR',
  },
  {
    key: 'and',
    name: 'AND',
  },
];

const filterOptions: FilterOption[] = [
  {
    key: 'location',
    name: 'Location',
  },
  {
    key: 'group',
    name: 'Group',
  },
  {
    key: 'user',
    name: 'User',
  },
];

export default function CreateTargeting({
  addFilter,
  updateFilter,
  targetingData,
  targetingLoading,
  instituteTypes,
  graduationTypes,
  filters,
  removeFilter,
  regions,
  isDesktop = true,
}: CreateTargetingProps): JSX.Element {
  const [showPicker, setShowPicker] = useState(false);

  const targetingStats: Statistic[] = [
    {
      statistic: helpers.stringify(
        targetingData?.institutesTargeting?.usersCount,
      ),
      description: t('createAssets.createTargeting.stats.users'),
    },
    {
      statistic: helpers.stringify(
        targetingData?.institutesTargeting?.institutes?.length,
      ),
      description: t('createAssets.createTargeting.stats.institutes'),
    },
    {
      statistic: helpers.stringify(
        targetingData?.institutesTargeting?.activeGroupsCount,
      ),
      description: t('createAssets.createTargeting.stats.activeGroups'),
    },
    {
      statistic: helpers.stringify(
        targetingData?.institutesTargeting?.inactiveGroupsCount,
      ),
      description: t('createAssets.createTargeting.stats.inactiveGroups'),
    },
  ];

  const map = useRef<MapBoxWrapperHandle | null>(null);
  const targetedInstitutes =
    targetingData?.institutesTargeting?.institutes?.filter(
      (institute) => institute?.location,
    ) || [];

  const renderStatisticsCard = ({
    item: statistic,
    index,
  }: {
    item: Statistic;
    index: number;
  }) => {
    return (
      <StatisticsCard
        key={`statisticsCard-${index}`}
        statistic={statistic.statistic}
        description={statistic.description}
        statisticFontSize={24}
        descriptionFontSize={12}
        horizontalPadding={1}
        width={isDesktop ? 130 : 160}
        height={80}
        isLoading={targetingLoading}
      />
    );
  };
  const renderFilter = (filterData: AdsTargetingFilter, index: number) => {
    const isFirstFilter = index === 0;
    switch (filterData.type) {
      case 'location':
        return (
          <OptionsContainer>
            {!isFirstFilter && (
              <OrAndOptionsContainer>
                <Dropdown
                  testID={'orAndDropdown'}
                  selectedLocale={filterData.operator}
                  selectableOptions={orAndOptions}
                  onSelectedLocale={(setSelectedOperator) => {
                    updateFilter(index, {
                      ...filterData,
                      operator: setSelectedOperator,
                    });
                  }}
                />
              </OrAndOptionsContainer>
            )}
            <LocationFilteringBlockType
              filterData={filterData}
              index={index}
              updateFilter={(index, filter) => {
                if (filter?.location?.country) {
                  const country = filter.location.country as Country;
                  map.current?.recenter(
                    LOCATION_DATA[country].coordinates,
                    LOCATION_DATA[country].zoom,
                  );
                }

                updateFilter(index, filter);
              }}
              regions={regions}
              removeFilter={removeFilter}
            />
          </OptionsContainer>
        );
      case 'group':
        return (
          <OptionsContainer>
            {!isFirstFilter && (
              <OrAndOptionsContainer>
                <Dropdown
                  testID={'orAndDropdown'}
                  selectedLocale={filterData.operator}
                  selectableOptions={orAndOptions}
                  onSelectedLocale={(setSelectedOperator) => {
                    updateFilter(index, {
                      ...filterData,
                      operator: setSelectedOperator,
                    });
                  }}
                />
              </OrAndOptionsContainer>
            )}
            <GroupFilteringBlock
              filterData={filterData}
              index={index}
              instituteTypes={instituteTypes}
              graduationTypes={graduationTypes}
              updateFilter={(index, filter) => {
                updateFilter(index, filter);
              }}
              removeFilter={removeFilter}
            />
          </OptionsContainer>
        );
      case 'user':
        return (
          <OptionsContainer>
            {!isFirstFilter && (
              <OrAndOptionsContainer>
                <Dropdown
                  testID={'orAndDropdown'}
                  selectedLocale={filterData.operator}
                  selectableOptions={orAndOptions}
                  onSelectedLocale={(setSelectedOperator) => {
                    updateFilter(index, {
                      ...filterData,
                      operator: setSelectedOperator,
                    });
                  }}
                />
              </OrAndOptionsContainer>
            )}
            <UserTargetingBlock
              filterData={filterData}
              index={index}
              updateFilter={(index, filter) => {
                updateFilter(index, filter);
              }}
              removeFilter={removeFilter}
            />
          </OptionsContainer>
        );
      default:
        return null;
    }
  };

  return (
    <Container testID="createAssetModalTargeting">
      <Title>{t('createAssets.createTargeting.title')}</Title>
      <TargetingContainer isDesktop={isDesktop}>
        <MapContainer isDesktop={isDesktop}>
          <MapBoxWrapper
            ref={(el) => (map.current = el)}
            iconMapper={helpers.iconMapper}
            clusterData={helpers.convertInstitutesToGeoJSON(targetedInstitutes)}
            isDesktop={isDesktop}
          ></MapBoxWrapper>
        </MapContainer>
        <FilteringContainer isDesktop={isDesktop}>
          <StatisticsContainer>
            <StatisticsCardsContainer<React.ElementType>
              testID={'statisticsCards'}
              data={targetingStats}
              renderItem={renderStatisticsCard}
              horizontal
              showsHorizontalScrollIndicator={false}
              isDesktop={isDesktop}
            />
          </StatisticsContainer>
          <ScrollView>
            {filters?.map((filterData, index) =>
              renderFilter(filterData, index),
            )}
            <ButtonAddFilterContainer>
              <Button
                testID={'buttonAddAssetFiltering'}
                text={t('createAssets.createTargeting.addNewFilterBtn')}
                size={'sm'}
                type={'secondary-base'}
                icon={'left'}
                iconName={'plus'}
                onPress={() => setShowPicker(!showPicker)}
              />
              {showPicker ? (
                <PickerContainer>
                  {filterOptions.map((filterOption) => (
                    <ButtonContent
                      testID={`filterOption_${filterOption.key}`}
                      key={filterOption.key}
                      showBorder={
                        filterOption.key !==
                        filterOptions[filterOptions.length - 1].key
                      }
                      onPress={() => {
                        addFilter(createPlaceholderFilter(filterOption.key));
                        setShowPicker(false);
                      }}
                    >
                      <Text>{filterOption.name}</Text>
                    </ButtonContent>
                  ))}
                </PickerContainer>
              ) : null}
            </ButtonAddFilterContainer>
          </ScrollView>
        </FilteringContainer>
      </TargetingContainer>
    </Container>
  );
}
