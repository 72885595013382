import React from 'react';
import Badge from '~/components/Badge';
import InputProgress from '~/components/InputProgress';
import Shimmer from '~/components/Shimmer';
import { ModuleInstanceResult } from '~/data/models/yearbook';
import { ModuleType } from '~/data/types/graphql';
import { t } from '~/utils/i18n';
import { Container, Image, Content, Name, Footer, Info } from './style';

type ModuleResultCellProps = {
  moduleInstanceResult?: ModuleInstanceResult;
  usersTotal: number;
  onSelectModule: (moduleType: ModuleType, moduleInstanceId: string) => void;
};

export default function ModuleResultCell({
  moduleInstanceResult,
  usersTotal,
  onSelectModule,
}: ModuleResultCellProps): JSX.Element {
  if (!moduleInstanceResult) {
    return (
      <Container disabled={true}>
        <Shimmer w={114} h={78} g={'g2'} />
        <Content>
          <Shimmer w={120} h={22} ml={16} g={'g3'} />
          <Shimmer w={200} h={8} ml={16} mt={8} g={'g1'} />
          <Footer>
            <Shimmer h={18} ml={16} />
            <Shimmer w={60} ml={60} h={24} br={24} g={'g2'} />
          </Footer>
        </Content>
      </Container>
    );
  }

  let progress = 1,
    total = 1,
    textInfo = '';

  if (moduleInstanceResult.module.type === ModuleType.PROFILE_PAGE) {
    progress = moduleInstanceResult.profilePageSetup?.usersDelivered || 0;
    total = usersTotal;
    textInfo = `${progress}/${total} ${t('g.delivered')}`;
  } else if (moduleInstanceResult.module.type === ModuleType.RANKINGS) {
    progress = moduleInstanceResult.rankingsSetup?.rankingsCount || 0;
    total = progress || 1;
    textInfo = `${progress} ${t('g.rankings')}`;
  } else if (moduleInstanceResult.module.type === ModuleType.COLLAGES) {
    progress = moduleInstanceResult.collagesSetup?.photosCount || 0;
    total = progress || 1;
    textInfo = `${progress} ${t('g.images')}`;
  } else if (moduleInstanceResult.module.type === ModuleType.REPORTS) {
    progress = moduleInstanceResult.reportsSetup?.reportsCount || 0;
    total = progress || 1;
    textInfo = `${progress} ${t('g.reports')}`;
  } else if (moduleInstanceResult.module.type === ModuleType.QUOTES) {
    progress = moduleInstanceResult.quotesSetup?.quotesCount || 0;
    total = progress || 1;
    textInfo = `${progress} ${t('g.quotes')}`;
  } else if (moduleInstanceResult.module.type === ModuleType.CUSTOM_PAGES) {
    progress = moduleInstanceResult.customPagesInstances?.edges.length || 0;
    total = progress || 1;
    textInfo = `${progress} ${t('g.customPages')}`;
  }

  const isActive = moduleInstanceResult.isActive;

  return (
    <Container
      testID={`ModuleResultCell:${moduleInstanceResult.id}`}
      onPress={() =>
        onSelectModule(
          moduleInstanceResult.module.type as ModuleType,
          moduleInstanceResult.id,
        )
      }
    >
      <Image source={{ uri: moduleInstanceResult.module.image || undefined }} />
      <Content>
        <Name>{moduleInstanceResult.module.name}</Name>
        <InputProgress progress={progress} total={total} fullWidth />
        <Footer>
          <Info>{textInfo}</Info>
          <Badge
            text={isActive ? t('g.open') : t('g.closed')}
            colorTheme={'darkGray'}
            iconName={isActive ? 'lock-unlocked-02' : 'lock-02'}
          />
        </Footer>
      </Content>
    </Container>
  );
}
