import { Platform } from 'react-native';
import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import Icon from '~/components/Icon';
import { IS_LANDSCAPE } from '~/data/constants';
import { NavHeaderStyle, NavHeaderType } from './types';

interface INavHeaderType {
  colorStyle: NavHeaderStyle;
}

interface ILeft {
  type: NavHeaderType;
}

interface ITitle extends INavHeaderType {
  type: NavHeaderType;
  textAlign: string;
}

interface IButtonIcon extends INavHeaderType {
  name: string;
}

export const Container = styled.View`
  min-height: 48px;
  flex-direction: row;
  padding-top: 8px;
  align-items: center;
  justify-content: center;
  padding-horizontal: 16px;
`;

export const Left = styled.View<ILeft>`
  width: ${({ type }) => (type === 'main' || IS_LANDSCAPE ? 'auto' : '80px')};
`;

export const LeftSideWrapper = styled.View<ILeft>`
  width: ${({ type }) => (type === 'main' || IS_LANDSCAPE ? 'auto' : '80px')};
  ${IS_LANDSCAPE &&
  `
    margin-top: -8px;
  `};
`;

export const Right = styled.View`
  width: 80px;
  justify-content: flex-end;
  flex-direction: row;
`;

export const BackButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  height: 24px;
  width: ${Platform.OS === 'web' ? 'auto' : '80px'};
  flex-direction: row;
  align-items: center;
`;

export const BackIcon = styled(Icon).attrs<INavHeaderType>(
  ({ theme, colorStyle }) => ({
    size: 24,
    color:
      colorStyle === 'default' ? theme.color.brand_02 : theme.color.base.c0,
  }),
)<INavHeaderType>`
  padding-right: 4px;
`;

export const Title = styled.Text<ITitle>`
  flex: 1;
  text-align: ${({ type, textAlign }) =>
    type === 'main' ? 'left' : textAlign};
  color: ${({ colorStyle, theme }) =>
    colorStyle === 'light' ? theme.color.base.c0 : theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: ${({ type }) =>
    type === 'main' || IS_LANDSCAPE ? '32px' : '17px'};
  height: ${({ type }) => (type === 'main' || IS_LANDSCAPE ? '41px' : '22px')};
`;

export const SmallerTitle = styled(Title)`
  font-size: ${({ type }) => (type === 'main' ? '22px' : '10px')};
  height: ${({ type }) => (type === 'main' ? '26px' : '12px')};
`;

export const FirstButtonIcon = styled(ButtonIcon).attrs<IButtonIcon>(
  ({ name, colorStyle, theme }) => ({
    name,
    size: 24,
    color: colorStyle === 'light' ? theme.color.base.c0 : theme.color.base.c9,
  }),
)<IButtonIcon>``;

export const SecondButtonIcon = styled(ButtonIcon).attrs<IButtonIcon>(
  ({ name, colorStyle, theme }) => ({
    name,
    size: 24,
    color: colorStyle === 'light' ? theme.color.base.c0 : theme.color.base.c9,
  }),
)<IButtonIcon>`
  padding-left: 18px;
  padding-right: 2px;
`;

export const StyledButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  size: 24,
  color: theme.color.base.c9,
}))``;

export const RightComponentContainer = styled.View`
  position: absolute;
  top: 8px;
  right: 16px;
  bottom: 8px;
`;

export const LeftTextOnBackButton = styled.Text<INavHeaderType>`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme, colorStyle }) =>
    colorStyle === 'default' ? theme.color.brand_02 : theme.color.base.c0};
  font-size: 17px;
`;

export const MobileLeftSideWrapper = styled.View`
  width: 80px;
  flex-direction: row;
`;
