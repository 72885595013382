import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import Image from '~/components/Image';

export const HeaderRightContainer = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const EditButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'edit-05',
  color: theme.color.base.c9,
  size: 20,
}))`
  margin-right: 16px;
`;

export const AddButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'plus',
  color: theme.color.base.c9,
  size: 20,
}))``;

export const PhotoList = styled.FlatList`
  padding-top: 24px;
  padding-horizontal: 16px;
`;

export const EmptyCard = styled.View`
  flex-grow: 1;
  overflow: hidden;
  aspect-ratio: 1;
`;

export const PhotoCard = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  flex-grow: 1;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 1;
`;

export const PhotoImage = styled(Image)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c1};
`;

export const LoadingView = styled.View`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled.ActivityIndicator``;

export const Footer = styled.View`
  padding-vertical: 24px;
`;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))``;
