import styled from 'styled-components/native';
import { MODAL_HORIZONTAL_PADDING } from '~/data/constants';

export const Container = styled.View``;

export const Content = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding-vertical: 24px;
  padding-horizontal: ${MODAL_HORIZONTAL_PADDING}px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

export const Title = styled.Text`
  margin-bottom: 16px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 18px;
  line-height: 22px;
`;

export const DayWrapper = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  width: 100%;
  align-items: center;
  align-self: stretch;
`;

export const DayBg = styled.View`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  flex-direction: row;
`;

interface DayBg {
  bg: boolean;
}
export const DayBgLeft = styled.View<DayBg>`
  flex: 1;
  ${({ bg, theme }) => bg && `background-color: ${theme.color.brand_02};`}
`;

export const DayBgRight = styled.View<DayBg>`
  flex: 1;
  ${({ bg, theme }) => bg && `background-color: ${theme.color.brand_02};`}
`;

interface IDayCell {
  marked: boolean;
}
export const DayCell = styled.View<IDayCell>`
  width: 34px;
  height: 34px;
  border-radius: 17px;
  ${({ marked, theme }) =>
    marked && `background-color: ${theme.color.brand_02};`}
  align-items: center;
  justify-content: center;
`;

interface IDayText {
  disabled: boolean;
  marked: boolean;
}
export const DayText = styled.Text<IDayText>`
  color: ${({ disabled, marked, theme }) =>
    disabled
      ? theme.color.base.c3
      : marked
      ? theme.color.base.c0
      : theme.color.base.c5};
  font-size: 14px;
  font-weight: 500;
`;
