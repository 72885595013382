import React, { useRef, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import AdminHeader from '~/components/AdminHeader';
import InputField, { InputFieldHandle } from '~/components/InputField';
import SchoolTypePicker, { SchoolType } from '~/components/SchoolTypePicker';
import { SettingLabelItem } from '~/components/SettingPanel';
import UserFilterDate from '~/components/UserFilterDate';
import { WebContainer } from '~/components/WebGrid';
import {
  FlexRow,
  FormContainer,
  GrownFlex,
  InputFormWrapper,
  SectionLabel,
  Spacer,
} from '~/components/common/style.web';
import { CalendarDateObject } from '~/data/models/calendar';
import { CampaignType, CreateCampaignInput } from '~/data/types/graphql';
import { calendarDateObject, formattedDate } from '~/utils/dates';
import {
  TouchableOpacityWithMeasure,
  useMeasurableTouchableOpacity,
} from '~/utils/hooks/useMeasurableTouchableOpacity';
import { t } from '~/utils/i18n';
import { SettingLabelContainer } from '../../EditAd/layout/style';
import { Container } from './style';

type AddCampaignLayoutProps = {
  onBack: () => void;
  openDrawer: () => void;
  onCreateCampaign: ({
    name,
    utm,
    startDate,
    endDate,
    type,
    customer,
    orderId,
  }: CreateCampaignInput) => void;
};

export type CampaignTypeOptions = {
  id: keyof typeof CampaignType;
  name: string;
};

const CAMPAIGN_TYPES: CampaignTypeOptions[] = [
  {
    id: CampaignType.INTERNAL,
    name: t('addCampaignPanel.internalType'),
  },
  {
    id: CampaignType.EXTERNAL,
    name: t('addCampaignPanel.externalType'),
  },
];

export default function AddCampaign({
  onBack,
  openDrawer,
  onCreateCampaign,
}: AddCampaignLayoutProps): JSX.Element {
  const campaignNameRef = useRef<InputFieldHandle>(null);
  const campaignUtmNameRef = useRef<InputFieldHandle>(null);
  const customerIdRef = useRef<InputFieldHandle>(null);
  const orderIdRef = useRef<InputFieldHandle>(null);
  const startModalizeRef = useRef<TouchableOpacityWithMeasure>(null);
  const endModalizeRef = useRef<TouchableOpacityWithMeasure>(null);

  const getInitialDueDay = (initialDay?: string) =>
    initialDay
      ? calendarDateObject(formattedDate(initialDay, 'yyyy-MM-dd'))
      : null;

  const [campaignName, setCampaignName] = useState<string>('');
  const [customerId, setCustomerId] = useState<string>('');
  const [orderId, setOrderId] = useState<string>('');
  const [showStartPicker, setShowStartPicker] = useState<boolean>(false);
  const [showEndPicker, setShowEndPicker] = useState<boolean>(false);
  const [utmCampaignName, setUtmCampaignName] = useState<string>('');
  const [campaignTypeSelected, setCampaignTypeSelected] = useState<
    CampaignTypeOptions | undefined
  >(undefined);
  const [startDay, setStartDay] = useState<CalendarDateObject | null>(
    getInitialDueDay(),
  );
  const [endDay, setEndDay] = useState<CalendarDateObject | null>(
    getInitialDueDay(),
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme?.breakpoints.desktopMin });

  const areAllFilled = (): boolean => {
    return Boolean(
      campaignName &&
        utmCampaignName &&
        campaignTypeSelected?.id &&
        customerId &&
        orderId &&
        startDay &&
        endDay,
    );
  };

  const toggleStartPicker = () => {
    if (startModalizeRef.current) {
      measureButton(startModalizeRef);
    }
    setShowStartPicker(!showStartPicker);
  };

  const toggleEndPicker = () => {
    if (endModalizeRef.current) {
      measureButton(endModalizeRef);
    }
    setShowEndPicker(!showEndPicker);
  };

  const { measureButton, buttonLayout } = useMeasurableTouchableOpacity();

  const handleSubmit = () => {
    if (areAllFilled()) {
      onCreateCampaign({
        name: campaignName,
        utm: utmCampaignName,
        startDate: startDay,
        endDate: endDay,
        type: campaignTypeSelected?.id as string,
        customer: customerId,
        orderId: orderId,
      });
    }
  };

  return (
    <Container>
      <AdminHeader
        title={t('screens.addCampaign')}
        onBack={onBack}
        breadcrumbs={['Campaigns', 'AddCampaigns']}
        onSubmitClick={handleSubmit}
        buttonTitle={t('addCampaignPanel.addCampaign')}
        onOpenDrawer={openDrawer}
        enableSaveChanges={areAllFilled()}
      />
      <ScrollView>
        <WebContainer isDesktop={isDesktop}>
          <FormContainer isDesktop={isDesktop}>
            <InputFormWrapper isDesktop={isDesktop}>
              <FlexRow>
                <GrownFlex>
                  <SectionLabel>
                    {' '}
                    {t('addCampaignPanel.campaignDetails')}
                  </SectionLabel>
                  <Spacer v={32} />
                  <InputField
                    ref={campaignNameRef}
                    testID={'inputName'}
                    iconName={'users-01'}
                    label={t('addCampaignPanel.nameInputLabel')}
                    placeholder={t('addCampaignPanel.nameInputPlaceholder')}
                    value={campaignName}
                    onChangeText={(value) => setCampaignName(value)}
                    blurOnSubmit={false}
                    returnKeyType={'next'}
                  />
                  <Spacer v={16} />
                  <SchoolTypePicker
                    testID={'pickCampaignType'}
                    toggleTestID={'buttonCampaignPickerToggle'}
                    label={t('addCampaignPanel.campaignType')}
                    schoolTypes={CAMPAIGN_TYPES as SchoolType[]}
                    selectedSchoolType={campaignTypeSelected as SchoolType}
                    initialShowPicker={false}
                    onSelectedSchoolType={(campaignType) => {
                      setCampaignTypeSelected(
                        campaignType as CampaignTypeOptions,
                      );
                    }}
                  />
                  <Spacer v={32} />
                  <InputField
                    ref={campaignUtmNameRef}
                    testID={'utmInputName'}
                    iconName={'users-01'}
                    label={t('addCampaignPanel.utmNameInputLabel')}
                    placeholder={t(
                      'addCampaignPanel.utmCampaignNamePlaceholder',
                    )}
                    value={utmCampaignName}
                    onChangeText={(value) => setUtmCampaignName(value)}
                    blurOnSubmit={false}
                    returnKeyType={'next'}
                  />
                  <Spacer v={40} />
                  <SectionLabel>
                    {' '}
                    {t('addCampaignPanel.campaignDuration')}
                  </SectionLabel>

                  <SettingLabelContainer>
                    <>
                      <SettingLabelItem
                        testID="startDateLabel"
                        buttonRef={startModalizeRef}
                        text={t('adminPanelAds.campaignStartDate')}
                        labelText={formattedDate(
                          startDay?.dateString as string,
                          'MMM d yyyy',
                        )}
                        onLabelPress={toggleStartPicker}
                      />
                      <SettingLabelItem
                        testID="endDateLabel"
                        buttonRef={endModalizeRef}
                        text={t('adminPanelAds.campaignEndDate')}
                        labelText={formattedDate(
                          endDay?.dateString as string,
                          'MMM d yyyy',
                        )}
                        onLabelPress={toggleEndPicker}
                      />
                    </>
                  </SettingLabelContainer>
                  <Spacer v={40} />
                  <SectionLabel>
                    {t('adminPanelAds.campaignDefineCustomer')}
                  </SectionLabel>
                  <Spacer v={16} />
                  <InputField
                    ref={customerIdRef}
                    testID={'inputCustomerId'}
                    iconName={'home-02'}
                    label={t('addCampaignPanel.selectCustomer')}
                    placeholder={t('addCampaignPanel.customerPlaceholder')}
                    value={customerId}
                    onChangeText={(value) => setCustomerId(value)}
                    blurOnSubmit={false}
                    returnKeyType={'next'}
                  />
                  <Spacer v={16} />
                  <InputField
                    ref={orderIdRef}
                    testID={'inputOrderId'}
                    iconName={'home-02'}
                    label={t('addCampaignPanel.selectOrder')}
                    placeholder={t('addCampaignPanel.orderPlaceholder')}
                    value={orderId}
                    onChangeText={(value) => setOrderId(value)}
                    blurOnSubmit={false}
                    returnKeyType={'next'}
                  />
                </GrownFlex>
              </FlexRow>
            </InputFormWrapper>
          </FormContainer>
        </WebContainer>
      </ScrollView>

      <UserFilterDate
        testID={'startDatePicker'}
        buttonText={t('g.confirmDate')}
        date={startDay}
        mode={'single'}
        togglePicker={toggleStartPicker}
        buttonLayout={buttonLayout}
        showPicker={showStartPicker}
        onConfirmDate={(dateObj: CalendarDateObject) => {
          setStartDay(dateObj);
          setShowStartPicker(false);
        }}
      />

      <UserFilterDate
        testID={'endDatePicker'}
        buttonText={t('g.confirmDate')}
        date={startDay}
        mode={'single'}
        togglePicker={toggleEndPicker}
        buttonLayout={buttonLayout}
        showPicker={showEndPicker}
        onConfirmDate={(dateObj: CalendarDateObject) => {
          setEndDay(dateObj);
          setShowEndPicker(false);
        }}
      />
    </Container>
  );
}
