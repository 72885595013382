import React, { useState } from 'react';
import { Keyboard } from 'react-native';
import { KeyboardAccessoryView } from 'react-native-keyboard-accessory';
import Button from '~/components/Button';
import CommentCell from '~/components/CommentCell';
import CommentInputField from '~/components/CommentInputField';
import PostCell, { PostCellActionProps } from '~/components/PostCell';
import { UserGroup } from '~/data/models/group';
import { Comment, Post } from '~/data/models/post';
import { Team } from '~/data/models/team';
import { TabScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  CommentList,
  CommentsTitle,
  PostCellCard,
  CommentButtonContainer,
} from './style';

export type CommentsLayoutProps = {
  comments: Comment[];
  post?: Post;
  authTeamIds: string[];
  teams: Team[];
  authUserGroup?: UserGroup | null;
  now?: Date;
  isPostOwner?: boolean;
  onBack: () => void;
  onLikeComment: (commentId: string, liked: boolean) => void;
  onCreateComment: (comment: string, authorTeamId?: string) => void;
  onDeleteComment: (commentId: string) => void;
} & PostCellActionProps;

export default function Comments({
  comments,
  post,
  authTeamIds,
  teams,
  authUserGroup,
  now = new Date(),
  onBack,
  onLikeComment,
  onCreateComment,
  onDeleteComment,
  onLike,
  onBlockUser,
  onHidePost,
  onReportPost,
  onReportUser,
  onEdit,
  isPostOwner,
}: CommentsLayoutProps): JSX.Element {
  const [comment, setComment] = useState('');
  const [commentInput, setCommentInput] = useState(false);
  const showCommentInput = () => {
    setCommentInput(true);
  };

  const hideCommentInput = () => {
    setCommentInput(false);
  };

  const renderItem = ({ item: comment }: { item: Comment }) => {
    return (
      <CommentCell
        comment={comment}
        now={now}
        deleteEnabled={authUserGroup?.id === comment.userGroup?.id}
        onLike={onLikeComment}
        onBlockUser={onBlockUser}
        onHidePost={onHidePost}
        onReportPost={onReportPost}
        onReportUser={onReportUser}
        onDelete={onDeleteComment}
      />
    );
  };
  return (
    <TabScreenLayout
      bgColorKey="c1"
      backButtonTestID={'buttonBack'}
      title={t('screens.comments')}
      onBackPress={onBack}
    >
      <CommentList<React.ElementType>
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={
          <>
            {post && (
              <PostCellCard>
                <PostCell
                  testID="postCell"
                  view={'comments'}
                  post={post}
                  isPostOwner={isPostOwner}
                  authTeamIds={authTeamIds}
                  onBlockUser={onBlockUser}
                  onHidePost={onHidePost}
                  onReportPost={onReportPost}
                  onReportUser={onReportUser}
                  onLike={onLike}
                  onEdit={onEdit}
                />
              </PostCellCard>
            )}
            <CommentsTitle>{t('screens.comments')}</CommentsTitle>
          </>
        }
        data={comments}
        renderItem={renderItem}
        keyExtractor={(item: Comment) => item.id}
      />
      <KeyboardAccessoryView
        alwaysVisible
        hideBorder
        inSafeAreaView
        androidAdjustResize
      >
        {commentInput ? (
          <CommentInputField
            value={comment}
            placeholder={t('comments.placeholder')}
            authUserGroup={authUserGroup}
            btnText={t('comments.post')}
            loading={false}
            teams={teams}
            hideTeams={false}
            onChangeText={(text) => setComment(text)}
            onPostComment={(authorTeamId) => {
              onCreateComment(comment, authorTeamId);
              hideCommentInput();
              setComment('');
              Keyboard.dismiss();
            }}
          />
        ) : (
          <CommentButtonContainer>
            <Button
              testID={'buttonToggleCommentInput'}
              text={t('post.comment')}
              flex
              onPress={showCommentInput}
            />
          </CommentButtonContainer>
        )}
      </KeyboardAccessoryView>
    </TabScreenLayout>
  );
}
