import { ResizeMode, Video } from 'expo-av';
import styled from 'styled-components/native';
import Image from '~/components/Image';

export const Container = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const HeaderContainer = styled.View`
  flex: 1;
`;

export const Header = styled.View`
  position: absolute;
  top: ${({ theme }) => theme.safeAreaInsets.top + 8}px;
  left: 16px;
  right: 16px;

  flex-direction: row;
  align-items: center;
`;

export const BrandImage = styled.Image`
  width: 211px;
  height: 62px;
`;

export const VideoContainer = styled.View`
  flex: 1;
`;

export const BGVideo = styled(Video).attrs({
  resizeMode: ResizeMode.COVER,
})`
  flex: 1;
  width: 100%;
`;

export const Title = styled.Text`
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_01};
  font-size: 46px;
  line-height: 54px;
`;

export const Footer = styled.View`
  padding-vertical: 44px;
  padding-horizontal: 16px;
  margin-bottom: ${({ theme }) => theme.safeAreaInsets.bottom}px;
`;

export const Logo = styled(Image)`
  width: 80%;
  height: 140px;
  margin-bottom: 40px;
`;

export const WebTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 60px;
  color: ${({ theme }) => theme.color.base.c10};
  line-height: 70px;
  margin-bottom: 32px;
`;

export const WebDescription = styled.Text<{ bold?: boolean }>`
  font-family: ${({ bold, theme }) =>
    bold ? theme.fontFamily.core.w600 : theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c7};
  font-size: 22px;
  line-height: 28px;
`;

export const WebButtonsContainer = styled.View`
  justify-content: stretch;
  width: 100%;
  margin-top: 50px;
`;

export const WebContentView = styled.View``;

export const WebImage = styled.Image`
  width: 120px;
  height: 98px;
  transform: rotate(-11deg);
`;
