import { gql } from './../../types';

export const INSTITUTE_TYPE_FRAGMENT = gql(`
  fragment InstituteType on InstituteTypeNode {
    id
    name
    country
    graduationTypes {
      id
      name
    }
  }
`);

export const INSTITUTE_FRAGMENT = gql(`
  fragment Institute on InstituteNode {
    id
    internalId
    name
    street
    zip
    city
    website
    type {
      ...InstituteType
    }
    country
  }
`);

export const INSTITUTE_TYPES = gql(`
  query InstituteTypes(
    $after: String
    $first: Int!
    $name: String
    $country: InstitutesInstituteCountryChoices
  ) {
    instituteTypes(
      after: $after
      first: $first
      name: $name
      country: $country
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...InstituteType
        }
      }
    }
  }
`);

export const INSTITUTES = gql(`
  query Institutes(
    $after: String
    $first: Int!
    $name: String
    $city: String
    $type: ID
    $country: InstitutesInstituteCountryChoices
    $internalId: String
    $offset: Int
  ) {
    institutes(
      after: $after
      first: $first
      name: $name
      city: $city
      type: $type
      country: $country
      internalId: $internalId
      offset: $offset
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Institute
        }
      }
      totalCount
    }
  }
`);

export const INSTITUTE = gql(`
  query Institute($id: ID!) {
    institute(id: $id) {
      ...Institute
    }
  }
`);

export const REQUEST_MISSING_INSTITUTE = gql(`
  mutation RequestMissingInstitute($input: RequestMissingInstituteInput!) {
    requestMissingInstitute(input: $input) {
      responseCode
      message
    }
  }
`);

export const CITIES_ZIP = gql(`
  query CitiesZip($query: String, $country: String) {
    citiesZip(query: $query, country: $country) {
      name
    }
  }
`);

export const CREATE_INSTITUTE = gql(`
  mutation CreateInstitute($input: CreateInstituteInput!) {
    createInstitute(input: $input) {
      responseCode
      message
      institute {
        ...Institute
      }
    }
  }
`);
