import React from 'react';
import Button from '~/components/Button';
import { LanguageProps } from '~/screens/Auth/layout/AuthLayout/types';
import { t } from '~/utils/i18n';
import {
  Container,
  Footer,
  BGVideo,
  Title,
  BrandImage,
  Header,
  VideoContainer,
} from '../style';

type LaunchLayoutProps = {
  onSignUp: () => void;
  onLogIn: () => void;
} & LanguageProps;

export default function MobileLaunch({
  onSignUp,
  onLogIn,
}: LaunchLayoutProps): JSX.Element {
  return (
    <Container>
      <VideoContainer>
        <BGVideo
          source={require('~/assets/imgs/video/launch.mov')}
          isLooping
          shouldPlay
        />
        <Header>
          <BrandImage
            source={require('~/assets/imgs/v2/brand_logo_white.png')}
          />
        </Header>
        <Title>{t('launch.title')}</Title>
      </VideoContainer>
      <Footer>
        <Button
          testID={'buttonSignUp'}
          type={'primary-brand-02'}
          text={t('launch.signUp')}
          bottomMargin={16}
          size={'2xl'}
          flex
          onPress={onSignUp}
        />
        <Button
          testID={'buttonLogIn'}
          type={'primary-brand-01'}
          text={t('launch.logIn')}
          size={'2xl'}
          flex
          onPress={onLogIn}
        />
      </Footer>
    </Container>
  );
}
