import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import { Institute, InstituteType } from '~/data/models/institute';
import { InstituteRequest as InstituteRequestModel } from '~/data/models/instituteRequest';
import { INSTITUTE_TYPES } from '~/data/operations/institute';
import {
  ACCEPT_INSTITUTE_REQUEST,
  DELETE_INSTITUTE_REQUEST,
  FIND_DUPLICATE_INSTITUTES,
  INSTITUTE_REQUEST,
  REJECT_INSTITUTE_REQUEST,
} from '~/data/operations/instituteRequest';
import {
  readInstitutesRequestQuery,
  writeInstitutesRequestQuery,
} from '~/data/operations/instituteRequest/helpers';
import { AdminAcceptInstituteRequestInput, Status } from '~/data/types/graphql';
import {
  AdminStackParamList,
  InstituteStackParamList,
} from '~/navigation/types';
import useDebouncedState from '~/utils/hooks/useDebouncedState';
import { t } from '~/utils/i18n';
import InstituteRequestLayout from './layout';

type InstituteRequestNavProp = StackNavigationProp<
  InstituteStackParamList,
  'InstituteRequest'
>;

type InstituteRequestRouteProp = RouteProp<
  InstituteStackParamList,
  'InstituteRequest'
>;

export default function InstituteRequest(): JSX.Element {
  const [institutesSearch, setInstitutesSearch] = useDebouncedState('');

  const navigation = useNavigation<InstituteRequestNavProp>();
  const { openDrawer } =
    useNavigation<DrawerNavigationProp<AdminStackParamList>>();

  const {
    params: { id },
  } = useRoute<InstituteRequestRouteProp>();
  const requestId = decodeURIComponent(id);

  const [acceptInstituteRequest] = useMutation(ACCEPT_INSTITUTE_REQUEST, {
    update(cache, { data }) {
      const updatedRequestId =
        data?.adminAcceptInstituteRequest?.instituteRequest?.id;
      const currentInstitutesRequestsQuery = readInstitutesRequestQuery({
        cache,
        variables: {
          status: Status.PENDING,
        },
      });

      if (
        currentInstitutesRequestsQuery &&
        currentInstitutesRequestsQuery?.instituteRequests &&
        currentInstitutesRequestsQuery?.instituteRequests.edges &&
        updatedRequestId
      ) {
        const currentInstituteRequests =
          (currentInstitutesRequestsQuery?.instituteRequests?.edges.map(
            (edge) => edge?.node,
          ) as InstituteRequestModel[]) || [];

        writeInstitutesRequestQuery({
          cache,
          variables: {
            status: Status.PENDING,
          },
          data: {
            __typename: 'Query',
            instituteRequests: {
              ...currentInstitutesRequestsQuery.instituteRequests,
              edges: currentInstituteRequests
                .filter(
                  (instituteRequest) =>
                    instituteRequest.id !== updatedRequestId,
                )
                .map((instituteRequest) => ({
                  __typename: 'InstituteRequestNodeEdge',
                  node: instituteRequest,
                })),
            },
          },
        });
      }
    },
  });

  const [declineInstituteRequest] = useMutation(REJECT_INSTITUTE_REQUEST, {
    update(cache, { data }) {
      const updatedRequestId =
        data?.adminRejectInstituteRequest?.instituteRequest?.id;
      const currentInstitutesRequestsQuery = readInstitutesRequestQuery({
        cache,
        variables: {
          status: Status.PENDING,
        },
      });

      if (
        currentInstitutesRequestsQuery &&
        currentInstitutesRequestsQuery?.instituteRequests &&
        currentInstitutesRequestsQuery?.instituteRequests.edges &&
        updatedRequestId
      ) {
        const currentInstituteRequests =
          (currentInstitutesRequestsQuery?.instituteRequests?.edges.map(
            (edge) => edge?.node,
          ) as InstituteRequestModel[]) || [];

        writeInstitutesRequestQuery({
          cache,
          variables: {
            status: Status.PENDING,
          },
          data: {
            __typename: 'Query',
            instituteRequests: {
              ...currentInstitutesRequestsQuery.instituteRequests,
              edges: currentInstituteRequests
                .filter(
                  (instituteRequest) =>
                    instituteRequest.id !== updatedRequestId,
                )
                .map((instituteRequest) => ({
                  __typename: 'InstituteRequestNodeEdge',
                  node: instituteRequest,
                })),
            },
          },
        });
      }
    },
  });

  const onDeclineInstituteRequest = async (
    id: string,
    existingInstitute: string,
  ) => {
    try {
      await declineInstituteRequest({
        variables: {
          input: {
            id,
            existingInstitute,
          },
        },
      });
      navigation.navigate('Institutes');
    } catch (error) {
      if (error instanceof Error) {
        Snackbar.show(error.message);
      }
    }
  };

  const { data: instituteTypesData, loading: itLoading } = useQuery(
    INSTITUTE_TYPES,
    {
      variables: {
        first: DEFAULT_LIST_OFFSET,
      },
    },
  );

  const { data: requestData, loading: requestLoading } = useQuery(
    INSTITUTE_REQUEST,
    {
      skip: !instituteTypesData,
      variables: {
        id: requestId,
      },
    },
  );

  const insituteRequest =
    requestData?.instituteRequest as InstituteRequestModel;

  const instituteTypes: InstituteType[] =
    (instituteTypesData?.instituteTypes?.edges
      .map((edge) => edge?.node)
      .filter((node) => Boolean(node)) as InstituteType[]) || [];

  const { data: duplicates, loading: duplicatesLoading } = useQuery(
    FIND_DUPLICATE_INSTITUTES,
    {
      skip: !insituteRequest,
      variables: {
        name: insituteRequest?.name,
        street: insituteRequest?.street,
        website: insituteRequest?.website,
        customSearch: institutesSearch,
      },
    },
  );

  const duplicateInstitutes =
    (duplicates?.findDuplicateInstitutes as Institute[]) || [];

  const onAcceptInstituteRequest = async ({
    name,
    country,
    city,
    street,
    type,
    website,
    zip,
  }: AdminAcceptInstituteRequestInput) => {
    try {
      const { data } = await acceptInstituteRequest({
        variables: {
          input: {
            id: requestId,
            name,
            country,
            city,
            street,
            type,
            website,
            zip,
          },
        },
      });

      const messages = data?.adminAcceptInstituteRequest?.errors?.map(
        (error) => error?.messages[0],
      );

      const errorMessage = messages?.[0];
      if (errorMessage) {
        Snackbar.show(errorMessage);
        return;
      }

      navigation.replace('Institutes');
    } catch (error) {
      if (error instanceof Error) {
        Snackbar.show(error.message);
      }
    }
  };

  const [deleteInstituteRequest] = useMutation(DELETE_INSTITUTE_REQUEST, {
    update(cache, { data }) {
      const success = data?.adminDeleteInstituteRequest?.success;

      if (success) {
        const currentInstitutesRequestsQuery = readInstitutesRequestQuery({
          cache,
          variables: {
            status: Status.PENDING,
          },
        });

        if (
          currentInstitutesRequestsQuery &&
          currentInstitutesRequestsQuery?.instituteRequests &&
          currentInstitutesRequestsQuery?.instituteRequests.edges &&
          requestId
        ) {
          const currentInstituteRequests =
            (currentInstitutesRequestsQuery?.instituteRequests?.edges.map(
              (edge) => edge?.node,
            ) as InstituteRequestModel[]) || [];

          const updatedInstituteRequests = currentInstituteRequests.filter(
            (instituteRequest) => instituteRequest.id !== requestId,
          );

          writeInstitutesRequestQuery({
            cache,
            variables: {
              status: Status.PENDING,
            },
            data: {
              __typename: 'Query',
              instituteRequests: {
                ...currentInstitutesRequestsQuery.instituteRequests,
                edges: updatedInstituteRequests.map((instituteRequest) => ({
                  __typename: 'InstituteRequestNodeEdge',
                  node: instituteRequest,
                })),
              },
            },
          });
        }
      }
    },
  });

  const onDeleteInstituteRequest = async () => {
    try {
      await deleteInstituteRequest({
        variables: {
          input: {
            id: requestId,
          },
        },
      });

      Snackbar.show(t('instituteRequest.deleteMsg'));
      navigation.replace('Institutes');
    } catch (error) {
      if (error instanceof Error) {
        Snackbar.show(error.message);
      }
    }
  };

  return (
    <InstituteRequestLayout
      onBack={() => navigation.navigate('Institutes')}
      instituteRequest={insituteRequest}
      duplicateInstitutes={duplicateInstitutes}
      openDrawer={openDrawer}
      duplicatesLoading={duplicatesLoading}
      requestLoading={requestLoading || itLoading}
      onDeclineInstituteRequestPress={onDeclineInstituteRequest}
      onAcceptInstituteRequest={onAcceptInstituteRequest}
      onDeleteInstituteRequest={onDeleteInstituteRequest}
      instituteTypes={instituteTypes}
      setInstitutesSearch={setInstitutesSearch}
      institutesSearch={institutesSearch}
    />
  );
}
