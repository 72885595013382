import React, { ReactNode, useRef } from 'react';
import { Platform } from 'react-native';
import { NeomorphBox } from 'react-native-neomorph-shadows';
import { useHover } from 'react-native-web-hooks';
import { useTheme } from 'styled-components/native';
import {
  STACK_SCREEN_CONTENT_WIDTH,
  TAB_SCREEN_CONTENT_WIDTH,
} from '~/data/constants';
import { IconName } from '../Icon';
import { IconStyledName } from '../IconStyled';
import { bySizeHelper, byTypeHelper } from './helpers';
import {
  PressableContainer,
  Text,
  CustomIcon,
  Loading,
  OpacityView,
  WebInnerWrapper,
  CustomStyledIcon,
} from './style';
import { ButtonIcon, ButtonType, ButtonState, ButtonSize } from './types';

interface InnerWrapperProps {
  children: ReactNode;
  backgroundColor: string;
  width: number | string;
  height: number | string;
}
function InnerWrapper({
  children,
  backgroundColor,
  width,
  height,
}: InnerWrapperProps): JSX.Element {
  if (Platform.OS === 'web') {
    return (
      <WebInnerWrapper
        style={{
          backgroundColor,
          width,
          height,
        }}
      >
        {children}
      </WebInnerWrapper>
    );
  }
  return (
    <NeomorphBox
      inner
      style={{
        flexDirection: 'row',
        borderRadius: 50,
        shadowRadius: 1,
        backgroundColor,
        width,
        height,
        shadowOpacity: 0.5,
        shadowOffset: { width: 0, height: -2 },
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      {children}
    </NeomorphBox>
  );
}

export type ButtonComponentProps = {
  testID?: string;
  text?: string;
  icon?: ButtonIcon;
  iconName?: IconName;
  iconStyledName?: IconStyledName;
  type?: ButtonType;
  state?: ButtonState;
  size?: ButtonSize;
  loading?: boolean;
  minWidth?: number;
  bottomMargin?: number;
  paddingTop?: number;
  flex?: boolean; //web: width 100% streches the button to full width when in flex-wrap: wrap; ios/android: we need to defined the width and height
  bypassDisabled?: boolean; // to have pressable button having disabled UI
  flexScreenContainer?: 'tab' | 'stack';
  onPress: () => void;
};

export default function Button({
  testID,
  text = '',
  icon = 'none',
  iconName,
  iconStyledName,
  type = 'primary-brand-01',
  state = 'default',
  size = 'lg',
  loading = false,
  minWidth,
  bottomMargin,
  paddingTop,
  flex = false,
  bypassDisabled,
  flexScreenContainer = 'tab',
  onPress,
}: ButtonComponentProps): JSX.Element {
  const theme = useTheme();
  const ref = useRef(null);
  const hovered = useHover(ref);
  const fullWidthInWrap = Platform.OS === 'web' ? flex : false;

  const getInnerState = (
    state: ButtonState,
    hovered: boolean,
    active: boolean,
  ): ButtonState => {
    if (active && state != 'disabled') {
      const activeState = Platform.OS === 'web' ? 'activeWeb' : 'activeMobile';
      return activeState;
    }
    if (state != 'disabled' && hovered) {
      return 'hover';
    }
    return state;
  };

  const onPressHandler = () => {
    if (!loading) {
      onPress();
    }
  };

  if (icon == 'only') {
    return (
      <PressableContainer
        ref={ref}
        type={type}
        state={state}
        testID={testID}
        disabled={!bypassDisabled && state === 'disabled'}
        onPress={onPressHandler}
        iconType={icon}
      >
        {({ pressed: active }) => {
          const innerState = getInnerState(state, hovered, active);
          const backgroundColor = byTypeHelper.bgColor(type, innerState, theme);
          const width =
            bySizeHelper.paddingV(size) * 2 + bySizeHelper.lineHeight(size);
          const height =
            bySizeHelper.paddingV(size) * 2 + bySizeHelper.lineHeight(size);
          return (
            <InnerWrapper
              backgroundColor={backgroundColor}
              width={width}
              height={height}
            >
              {innerState == 'hover' && <OpacityView opacity={0.2} />}
              {innerState == 'activeMobile' && <OpacityView opacity={0.1} />}
              {innerState == 'activeWeb' && <OpacityView opacity={0.3} />}
              {loading ? (
                <Loading type={type} state={innerState} />
              ) : iconName ? (
                <CustomIcon
                  type={type}
                  state={innerState}
                  buttonSize={size}
                  name={iconName}
                  iconType={icon}
                />
              ) : null}
            </InnerWrapper>
          );
        }}
      </PressableContainer>
    );
  }
  return (
    <PressableContainer
      ref={ref}
      testID={testID}
      type={type}
      state={state}
      disabled={!bypassDisabled && state === 'disabled'}
      onPress={onPressHandler}
      fullWidthInWrap={fullWidthInWrap}
      bottomMargin={bottomMargin}
      paddingTop={paddingTop}
      iconType={icon}
    >
      {({ pressed: active }) => {
        const innerState = getInnerState(state, hovered, active);
        const backgroundColor = byTypeHelper.bgColor(type, innerState, theme);

        const width = (() => {
          //width and height are needed to render svg on mobile, with default estimate based on text
          if (flex) {
            return flexScreenContainer === 'tab'
              ? TAB_SCREEN_CONTENT_WIDTH
              : STACK_SCREEN_CONTENT_WIDTH;
          }

          const defaultWidth =
            text.length * 8 +
            bySizeHelper.paddingH(size) * 2 +
            (icon != 'none' ? bySizeHelper.iconSize(size) : 0);

          return minWidth ?? defaultWidth;
        })();

        const height =
          bySizeHelper.paddingV(size) * 2 + bySizeHelper.lineHeight(size);
        return (
          <InnerWrapper
            backgroundColor={backgroundColor}
            width={fullWidthInWrap ? '100%' : width}
            height={height}
          >
            {innerState == 'hover' && <OpacityView opacity={0.1} />}
            {innerState == 'activeMobile' && <OpacityView opacity={0.05} />}
            {innerState == 'activeWeb' && <OpacityView opacity={0.15} />}
            {loading ? (
              <Loading type={type} state={innerState} />
            ) : (
              <>
                {icon === 'left' && iconName ? (
                  <CustomIcon
                    name={iconName}
                    type={type}
                    state={innerState}
                    buttonSize={size}
                    iconType={icon}
                  />
                ) : icon === 'left' && iconStyledName ? (
                  <CustomStyledIcon
                    type={type}
                    state={innerState}
                    buttonSize={size}
                    name={iconStyledName}
                    iconType={icon}
                  />
                ) : null}
                <Text
                  numberOfLines={1}
                  type={type}
                  state={innerState}
                  size={size}
                >
                  {text}
                </Text>
                {icon === 'right' && iconName ? (
                  <CustomIcon
                    name={iconName}
                    type={type}
                    state={innerState}
                    buttonSize={size}
                    iconType={icon}
                  />
                ) : icon === 'right' && iconStyledName ? (
                  <CustomStyledIcon
                    type={type}
                    state={innerState}
                    buttonSize={size}
                    name={iconStyledName}
                    iconType={icon}
                  />
                ) : null}
              </>
            )}
          </InnerWrapper>
        );
      }}
    </PressableContainer>
  );
}
