import React, { useRef, useState } from 'react';
import Tooltip from 'rn-tooltip';
import { useTheme } from 'styled-components/native';
import Avatar from '~/components/Avatar';
import LikeButton from '~/components/LikeButton';
import { mapUser } from '~/data/mappers';
import { Comment } from '~/data/models/post';
import { Owner } from '~/data/types/graphql';
import { isCommentLiked } from '~/data/utils';
import { formattedRelativeDate } from '~/utils/dates';
import { t } from '~/utils/i18n';
import {
  Container,
  Header,
  HeaderText,
  CommentText,
  Author,
  Footer,
  Date,
  Content,
  CommentCard,
  LikeCount,
  OptionsIcon,
  TooltipButtonText,
  TooltipButton,
  TOOLTIP_BUTTON_HEIGHT,
  AuthorTeamIcon,
} from './style';

export type CommentCellProps = {
  comment: Comment;
  footerEnabled?: boolean;
  reportEnabled?: boolean;
  reportUserEnabled?: boolean;
  blockEnabled?: boolean;
  hideEnabled?: boolean;
  deleteEnabled?: boolean;
  now?: Date;
  onLike: (commentId: string, liked: boolean) => void;
  onReportPost?: (commentId: string) => void;
  onReportUser?: (userGroupId: string) => void;
  onBlockUser?: (userGroupId: string) => void;
  onHidePost?: (commentId: string) => void;
  onDelete?: (commentId: string) => void;
};

type ActionInfo = {
  text: string;
  enabled: boolean;
  onPress: () => void;
};

export default function CommentCell({
  comment,
  footerEnabled = true,
  reportEnabled = true,
  reportUserEnabled = true,
  blockEnabled = true,
  hideEnabled = true,
  deleteEnabled,
  onLike,
  onReportPost,
  onReportUser,
  onBlockUser,
  onHidePost,
  onDelete,
}: CommentCellProps): JSX.Element {
  const likeCount = comment.likesCount || 0;
  const isCommentedByTeam = comment.owner === Owner.TEAM;
  const mappedUser = mapUser(comment.userGroup?.user);
  const [liked, setLiked] = useState(() => isCommentLiked(comment));
  const optionsTooltip = useRef<Tooltip>(null);
  const theme = useTheme();

  const actionInfos: ActionInfo[] = [
    {
      text: t('g.reportPost'),
      enabled: reportEnabled,
      onPress: () => onReportPost && onReportPost(comment.id),
    },
    {
      text: t('g.reportUser'),
      enabled: reportUserEnabled,
      onPress: () =>
        comment.userGroup && onReportUser && onReportUser(comment.userGroup.id),
    },
    {
      text: t('g.blockUser'),
      enabled: blockEnabled,
      onPress: () =>
        comment.userGroup && onBlockUser && onBlockUser(comment.userGroup.id),
    },
    {
      text: t('g.hidePost'),
      enabled: hideEnabled,
      onPress: () => onHidePost && onHidePost(comment.id),
    },
    {
      text: t('g.delete'),
      enabled: !!deleteEnabled,
      onPress: () => onDelete && onDelete(comment.id),
    },
  ].filter((actionInfo) => actionInfo.enabled);

  const actionsCount = actionInfos.length;

  const author =
    (isCommentedByTeam ? comment.team?.name : mappedUser?.name) || '';

  return (
    <Container testID={`CommentCell:${comment.id}`}>
      <Header>
        {isCommentedByTeam ? (
          <AuthorTeamIcon
            teamColor={comment.team?.color?.hex}
            iconName={comment.team?.icon?.icon}
            size={23}
            iconSize={14}
          />
        ) : (
          <Avatar size={23} userGroup={comment.userGroup} />
        )}
        <HeaderText>
          <Author>{author}</Author>
          <Date>{formattedRelativeDate(comment.created)}</Date>
        </HeaderText>
        {footerEnabled && actionsCount > 0 ? (
          <Tooltip
            width={172}
            ref={optionsTooltip}
            containerStyle={{
              alignItems: undefined,
              justifyContent: undefined,
              marginTop: -30,
              backgroundColor: theme.color.base.c0,
              padding: 0,
            }}
            height={TOOLTIP_BUTTON_HEIGHT * actionsCount}
            overlayColor={'rgba(0, 0, 0, 0.2)'}
            withPointer={false}
            actionType={'press'}
            popover={
              <>
                {actionInfos.map((actionInfo, i) => (
                  <TooltipButton
                    key={`actionInfo${i}`}
                    onPress={() => {
                      actionInfo.onPress();
                      optionsTooltip.current?.toggleTooltip();
                    }}
                    hasBorderBottom={i < actionInfos.length - 1}
                  >
                    <TooltipButtonText>{actionInfo.text}</TooltipButtonText>
                  </TooltipButton>
                ))}
              </>
            }
          >
            <OptionsIcon />
          </Tooltip>
        ) : null}
      </Header>
      <Content>
        <CommentCard>
          <CommentText>{comment.text}</CommentText>
          {footerEnabled ? (
            <Footer>
              <LikeButton
                id={comment.id}
                liked={liked}
                onLike={(id, liked) => {
                  if (onLike) {
                    setLiked(!liked);
                    onLike(id, liked);
                  }
                }}
              />
              <LikeCount liked={liked}>
                {t('comment.likes', {
                  count: likeCount,
                  likes: likeCount,
                })}
              </LikeCount>
            </Footer>
          ) : null}
        </CommentCard>
      </Content>
    </Container>
  );
}
