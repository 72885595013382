import { ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import Avatar from '~/components/Avatar';
import ButtonIcon from '~/components/ButtonIcon';
import Icon from '~/components/Icon';
import IconStyled from '~/components/IconStyled/index';
import Image from '~/components/Image';
import { DRAWER_WIDTH } from '~/data/constants';

export const DraweScrollView = styled.SafeAreaView`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const DESKTOP_DRAWER_STYLE: ViewStyle = {
  width: DRAWER_WIDTH,
};

export const DraweHeader = styled.View`
  padding-top: 12px;
  padding-horizontal: 16px;
`;

export const BrandImage = styled.Image`
  width: 110px;
  height: 32px;
`;

export const DraweContent = styled.View`
  flex: 1;
  padding-top: 32px;
  padding-left: 24px;
  gap: 24px;
`;

export const DrawerFooter = styled.View`
  padding: 16px 24px;
  gap: 24px;
`;

export const DrawerItem = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<IDrawer>`
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

interface IDrawer {
  isActive: boolean;
}

export const DrawerIcon = styled(Icon).attrs<IDrawer>(
  ({ isActive, theme }) => ({
    size: 24,
    color: isActive ? theme.color.brand_02 : theme.color.base.c6,
  }),
)<IDrawer>``;

export const StyledIcon = styled(IconStyled).attrs(({ theme }) => ({
  size: 24,
  color: theme.color.base.c0,
}))``;

export const DrawerLabel = styled.Text<IDrawer>`
  color: ${({ isActive, theme }) =>
    isActive ? theme.color.brand_02 : theme.color.base.c8};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 18px;
`;

export const CloseButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'x-close',
  size: 16,
  color: theme.color.base.c8,
}))`
  background-color: ${({ theme }) => theme.color.brand_02};
  align-self: flex-end;
  margin-top: ${({ theme }) => theme.spacing.medium};
  margin-right: ${({ theme }) => theme.spacing.medium};
  width: 32px;
  height: 32px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
`;

export const ProfileContainer = styled.Pressable.attrs({
  activeOpacity: 0.8,
})`
  flex-direction: row;
  gap: 16px;
`;

export const ProfileAvatar = styled(Avatar)`
  border-radius: 5px;
`;

export const ProfileName = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 24px;
  flex: 1;
`;

export const CardView = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  border-color: ${({ theme }) => theme.color.base.c2};
  border-width: 1px;
  box-shadow: 2px 3px 2px rgba(248, 249, 250, 0.8);
  border-radius: 5px;
  padding: 16px;
`;

export const CardHeader = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

export const GroupImage = styled(Image)`
  width: 48px;
  height: 48px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.base.c1};
`;

export const CardInfo = styled.View`
  flex: 1;
  padding-left: 16px;
`;

export const GroupName = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 18px;
`;

export const GroupDetail = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
`;
