import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  RouteProp,
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import useGroupUsers from '~/data/hooks/useGroupUsers';
import useTeams from '~/data/hooks/useTeams';
import { Event } from '~/data/models/calendar';
import { ME } from '~/data/operations/auth';
import { EVENT, JOIN_EVENT } from '~/data/operations/calendar';
import { RootStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import EventDetailLayout from './layout';

type EventDetailNavProp = StackNavigationProp<
  RootStackParamList,
  'EventDetail'
>;
type EventDetailsRouteProp = RouteProp<RootStackParamList, 'EventDetail'>;

export default function EventDetail(): JSX.Element {
  const navigation = useNavigation<EventDetailNavProp>();
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const {
    params: { eventId },
  } = useRoute<EventDetailsRouteProp>();
  const { data: userData } = useQuery(ME);
  const { groupUsers } = useGroupUsers();
  const { teams } = useTeams();
  const { authUserId } = useAuth();

  const { data, refetch } = useQuery(EVENT, {
    variables: { id: eventId },
  });
  const [joinEvent] = useMutation(JOIN_EVENT);
  const event = data?.event as Event;
  const onJoinEvent = async (event: Event) => {
    try {
      await joinEvent({
        variables: {
          input: {
            eventId: event.id,
          },
        },
      });
      Snackbar.show(t('screens.successfullyJoinedEvent'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const navigateToEditEvent = () => {
    navigation.navigate('EditEvent', {
      eventId,
    });
  };

  useFocusEffect(
    React.useCallback(() => {
      refetch();
    }, [refetch]),
  );
  useEffect(() => {
    let enabled = false;
    if (event?.authorType === 'USER') {
      const isAuthorUser = event.authorUser?.id === userData?.me?.id;
      enabled = isAuthorUser;
    } else if (event?.authorType === 'GROUP') {
      groupUsers.forEach((group) => {
        if (group.group?.id === event?.authorGroup?.id) {
          enabled = true;
        }
      });
    } else if (event?.authorType === 'TEAM') {
      teams.forEach((team) => {
        if (team.id === event?.authorTeam?.id) {
          enabled = true;
        }
      });
    }
    setIsEditEnabled(enabled);
  }, [event, userData, groupUsers, teams]);

  const isUserJoined = !!event?.attendees?.some((attendee) => {
    return attendee?.user.id === authUserId;
  });
  return (
    <EventDetailLayout
      onBack={() => navigation.goBack()}
      event={event}
      onJoinEvent={onJoinEvent}
      navigateToEditEvent={navigateToEditEvent}
      onAttendeesList={(event) =>
        navigation.navigate('AttendeesList', { event })
      }
      isEditEnabled={isEditEnabled}
      isUserJoined={isUserJoined}
    />
  );
}
