import styled from 'styled-components/native';
import ImageComp from '~/components/Image';

export const FlatList = styled.FlatList`
  background: ${({ theme }) =>
    theme.color.base
      .c0}; // TODO: this is not the color from figma (there it is #e5e5e5), but base.c0 resembles the design more
`;

export const Header = styled.View`
  padding: 0px 16px 16px;
`;

export const Content = styled.View`
  background-color: ${({ theme }) => theme.color.base.c2};
  padding: 32px 16px 200px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c6,
}))``;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  tintColor: theme.color.base.c6,
  colors: [theme.color.base.c6],
}))``;

export const Footer = styled.View`
  padding-bottom: 200px;
`;

export const EmptyTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 24px;
  color: ${({ theme }) => theme.color.base.c10};
`;
export const EmptyDesc = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 32px;
`;
export const EmptyImage = styled(ImageComp)`
  height: 90px;
  width: 110px;
  transform: rotate(15deg);
  margin-top: 56px;
`;

export const EmptyWrapper = styled.View`
  align-items: flex-start;
  padding-horizontal: 16px;
`;
