import styled from 'styled-components/native';

export const ScrollView = styled.ScrollView`
  flex: 1;
  padding-top: 8px;
  padding-horizontal: 16px;
`;

export const Loading = styled.ActivityIndicator.attrs(() => ({
  paddingVertical: 24,
}))``;

export const ErrorMessage = styled.Text`
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 16px;
`;

export const Footer = styled.View`
  padding-bottom: 24px;
  flex-direction: row;
  justify-content: center;
`;
