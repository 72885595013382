import { gql } from './../../types';

export const CITY_LOCATION_FRAGMENT = gql(`
  fragment CityLocation on CityLocationNode {
    region
    country
  }
`);

export const CAMPAIGN_FRAGMENT = gql(`
  fragment Campaign on CampaignNode {
    id
    name
    utm
    startDate
    endDate
    customer
    status
    orderId
    type
    isDeleted
  }
`);

export const ASSET_FRAGMENT = gql(`
  fragment Asset on AssetNode {
    id
    assetId
    channelType
    name
    channelData {
      trackingUrl
      imageLarge
      imageSmall
    }
    targetingConfig
    startDate
    endDate
    visibility
    targetingFilters {
      location {
        country
        zip
        region
        city
        radius
      }
      user {
        gender
        registration {
          date
          operator
        }
        userIds
      }
      group {
        graduationType
        instituteType
        graduationYear
        members {
          value
          operator
        }
        groupIds
        teams
      }
      type
      operator
    }
    totalViews
    totalClicks
    conversionRate
  }
`);

export const MATCHED_ASSET_FRAGMENT = gql(`
  fragment MatchedAsset on MatchedAssetNode {
    id
    assetId
    channelType
    name
    channelData {
      trackingUrl
      imageLarge
      imageSmall
    }
    targetingConfig
    startDate
    endDate
    visibility
    isActive
    targetingFilters {
      location {
        country
        zip
        region
        city
        radius
      }
      user {
        gender
        registration {
          date
          operator
        }
        userIds
      }
      group {
        graduationType
        instituteType
        graduationYear
        members {
          value
          operator
        }
        groupIds
        teams
      }
      type
      operator
    }
  }
`);

export const CAMPAIGN_DETAILS_FRAGMENT = gql(`
  fragment CampaignDetails on CampaignNode {
    id
    name
    utm
    startDate
    endDate
    type
    customer
    orderId
    totalViews
    totalClicks
    status
    assets {
      ...Asset
    }
    conversionRate
  }
`);

export const COUNTRY_DATA_FRAGMENT = gql(`
  fragment CountryData on CountryData {
    country
    counts {
      date
      count
    }
  }
`);

export const STATISTICS_FRAGMENT = gql(`
  fragment Statistics on StatisticsNode {
    institutesCount
    usersCount
    activeGroupsCount
    inactiveGroupsCount
  }
`);

export const CAMPAIGNS = gql(`
  query Campaigns(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $searchFilter: String
    $orderBy: String
  ) {
    campaigns(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      searchFilter: $searchFilter
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...Campaign
        }
      }
      totalCount
    }
  }
`);

export const CAMPAIGN = gql(`
  query Campaign($id: ID!) {
    campaign(id: $id) {
      ...CampaignDetails
    }
  }
`);

// createBannerAsset(input: CreateBannerAssetInput!): CreateBannerAssetPayload
// export const ADMIN_MOVE_GROUP_TO_INSTITUTE = gql(`
//   ${ADMIN_GROUP_FRAGMENT}
//   mutation AdminMoveGroupToInstitute($input: MoveGroupToInstituteInput!) {
//     moveGroupToInstitute(input: $input) {
//       group {
//         ...AdminGroup
//       }
//     }
//   }
// `);

export const CREATE_BANNER_ASSET = gql(`
  mutation CreateBannerAsset($input: CreateBannerAssetInput!) {
    createBannerAsset(input: $input) {
      asset {
        ...Asset
      }
    }
  }
`);

export const CREATE_CAMPAIGN = gql(`
  mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      campaign {
        ...Campaign
      }
    }
  }
`);

export const ASSET = gql(`
  query Asset($id: ID!) {
    asset(id: $id) {
      ...Asset
    }
  }
`);

export const EDIT_BANNER_ASSET = gql(`
  mutation EditBannerAsset($input: EditBannerAdAssetInput!) {
    editBannerAdAsset(input: $input) {
      asset {
        ...Asset
      }
    }
  }
`);

export const DUPLICATE_BANNER_ASSET = gql(`
  mutation DuplicateBannerAsset($input: DuplicateAssetInput!) {
    duplicateAsset(input: $input) {
      newAsset {
        ...Asset
      }
    }
  }
`);

export const DELETE_BANNER_ASSET = gql(`
  mutation DeleteBannerAsset($input: DeleteAssetInput!) {
    deleteAsset(input: $input) {
      success
      assetId
    }
  }
`);

export const BEST_MATCH_ASSET = gql(`
  query BestMatchAsset($channelType: ChannelType, $userId: String) {
    bestMatchAssets(channelType: $channelType, userId: $userId) {
      ...MatchedAsset
    }
  }
`);

export const CREATE_ASSET_EVENT = gql(`
  mutation CreateAssetEvent($input: CreateAssetEventInput!) {
    createAssetEvent(input: $input) {
      success
    }
  }
`);

export const REGIONS = gql(`
  query Regions($country: String) {
    regions(country: $country) {
      ...CityLocation
    }
  }
`);

export const EDIT_CAMPAIGN = gql(`
  mutation EditCampaign($input: EditCampaignInput!) {
    editCampaign(input: $input) {
      campaign {
        ...Campaign
      }
    }
  }
`);

export const DELETE_CAMPAIGN = gql(`
  mutation DeleteCampaign($input: DeleteCampaignInput!) {
    deleteCampaign(input: $input) {
      success
    }
  }
`);

export const STATISTICS = gql(`
  query Statistics($filter: DashboardFilterInput) {
    statistics(filter: $filter) {
      ...Statistics
    }
  }
`);

export const SIGNUPS = gql(`
  query Signups($filter: DashboardFilterInput) {
    signups(filter: $filter) {
      ...CountryData
    }
  }
`);

export const K_P_IS = gql(`
  query KPIs($filter: DashboardFilterInput) {
    kpis(filter: $filter) {
      averageStudentsPerGroup
      groupTimeToActive
      profileQuestionsPerYear {
        year
        average
        instances
        groups
      }
      quotesPerGroupPerYear {
        year
        average
        instances
        groups
      }
      rankingQuestionsPerGroupPerYear {
        year
        average
        instances
        groups
      }
      albumsPerGroupPerYear {
        year
        average
        instances
        groups
      }
      photosPerGroupPerYear {
        year
        average
        instances
        groups
      }
    }
  }
`);
