import styled from 'styled-components/native';
import ImageComp from '~/components/Image';
import SectionButton from '~/components/SectionButton';
import { TabScreenContainer } from '~/screens/style';

export type ResponsiveProps = {
  status?: string | null;
  isDesktop?: boolean;
};

type IContainerProps = {
  centerContent?: boolean;
  isDesktop?: boolean;
};

export const Container = styled(TabScreenContainer)<IContainerProps>`
  flex: 1;
  background-color: ${({ theme, isDesktop }) =>
    !isDesktop ? theme.color.base.c0 : theme.color.base.c0};
  overflow: auto;

  ${({ centerContent }) =>
    centerContent &&
    `
    justify-content: center;
    align-items: center;
  `}
`;

export const FormContainer = styled.View<ResponsiveProps>`
  padding: ${({ isDesktop }) => (isDesktop ? 48 : 15)}px;
  background: ${({ theme }) => theme.color.base.c0};
  box-shadow: ${({ isDesktop }) =>
    isDesktop ? '9px 7px 38px rgba(29, 53, 71, 0.08)' : 'none'};
  border-radius: 16px;
  margin-bottom: 24px;
`;

export const Spacer = styled.View<{ h?: number; v?: number }>`
  width: ${({ h }) => h || 1}px;
  height: ${({ v }) => v || 1}px;
`;

export const FlexRow = styled.View<ResponsiveProps>`
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
`;

export const GrownFlex = styled.View`
  flex-grow: 1;
`;

export const FlexAuto = styled.View`
  flex: auto;
`;

export const SectionLabel = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  color: ${({ theme }) => theme.color.base.c9};
`;

export const ActionsButton = styled(SectionButton)<ResponsiveProps>`
  text-align: center;
  justify-content: ${({ isDesktop }) => (isDesktop ? 'flex-start' : 'center')};
`;

export const GroupImage = styled(ImageComp)<{ isSmall?: boolean }>`
  width: ${({ isSmall }) => (isSmall ? 68 : 132)}px;
  height: ${({ isSmall }) => (isSmall ? 68 : 132)}px;
  border-radius: 12px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.color.base.c2};
`;

export const Name = styled.Text.attrs(() => ({
  accessibilityRole: 'header',
}))<ResponsiveProps>`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: ${({ isDesktop }) => (isDesktop ? 32 : 24)}px;
  user-select: text;
`;

export const FlexRowCentered = styled.View<ResponsiveProps>`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ isDesktop }) => (isDesktop ? '8' : '2')}px;
  flex-shrink: 1;
`;

export const InputWrapper = styled.View<ResponsiveProps>`
  width: ${({ isDesktop }) => (isDesktop ? '230px' : '180px')};
  justify-content: center;
  flex: 1;
`;

export const InputFormWrapper = styled.View<ResponsiveProps>`
  width: ${({ isDesktop }) => (isDesktop ? '460px' : '100%')};
`;

export const InputContent = styled.View`
  padding: 24px 0;
`;

export const ActionButtonsContainer = styled.View<ResponsiveProps>`
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  column-gap: ${({ isDesktop }) => (isDesktop ? '32px' : '13px')};
  row-gap: 8px;
`;

export const VSpacer = styled.View`
  height: 32px;
`;

export const Spinner = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
  size: 'large',
}))`
  margin: 20px 0;
`;

export const CommonTouchableOpacity = styled.TouchableOpacity.attrs(
  ({ activeOpacity }) => ({
    activeOpacity: activeOpacity || 0.9,
  }),
);

export const CommonPressable = styled.Pressable;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))``;

export const Content = styled.View`
  padding-vertical: 24px;
  align-items: center;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c7,
}))``;

export const AdsImage = styled.Image`
  height: 100px;
  margin-bottom: 16px;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 5px;
`;

export const AdsText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
  color: ${({ theme }) => theme.color.base.c6};
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: -17px;
`;

export const AdsBiggerImage = styled.Image`
  height: 200px;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 5px;
`;
