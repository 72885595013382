import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Base64 } from 'js-base64';
import Snackbar from '~/components/Snackbar';
import { AuthUser } from '~/data/models/user';
import { t } from '~/utils/i18n';
import { trackError } from '~/utils/sentry';

const INTERCOM_APP_ID = 'af64lt83'; // there is a HTML script as well, incase changing app id, update html script too.
const INTERCOM_BASE_URL = 'https://api-iam.intercom.io';

const IntercomWebAPI = {
  boot: (options?: object) => {
    window.Intercom('boot', {
      api_base: INTERCOM_BASE_URL,
      app_id: INTERCOM_APP_ID,
      ...options,
    });
  },
  shutdown: () => {
    window.Intercom('shutdown');
  },
  update: (options?: object) => {
    window.Intercom('update', options);
  },
  show: () => {
    window.Intercom('showMessages');
  },
  help: () => {
    window.Intercom('showSpace', 'help');
  },
  unReadCount: (handler: (count: number) => void) => {
    return window.Intercom('onUnreadCountChange', handler);
  },
};

export const INITIAL_STATE: IntercomContextAPI = {
  unReadMessageCount: 0,
  onLogin: async () => {},
  onHelpCenter: async () => {},
  onMessenger: async () => {},
  onLogout: async () => {},
  onLoginAnon: async () => {},
};

const IntercomContext = createContext<IntercomContextAPI>(INITIAL_STATE);

export const IntercomProvider: FC<PropsWithChildren> = ({ children }) => {
  const [unReadMessageCount, setUnReadMessageCount] = useState<number>(0);

  useEffect(() => {
    IntercomWebAPI.unReadCount((count) => {
      setUnReadMessageCount(count);
    });
  }, []);

  const onLoginAnon = useCallback(async () => {
    try {
      IntercomWebAPI.boot();
    } catch (e) {
      trackError(e);
    }
  }, []);

  const onLogin = useCallback(async (authUser: AuthUser) => {
    const decodedUserId = Base64.decode(authUser.id);
    const rawUserId = decodedUserId?.split(':')?.[1];

    try {
      IntercomWebAPI.update({
        name: authUser.firstName,
        email: authUser.email,
        user_id: rawUserId,
      });
    } catch (err) {
      Snackbar.show(t('intercom.loginError'));
    }
  }, []);

  const onHelpCenter = useCallback(async () => {
    try {
      IntercomWebAPI.help();
    } catch {
      Snackbar.show(t('intercom.helpCenterError'));
    }
  }, []);

  const onMessenger = useCallback(async () => {
    try {
      IntercomWebAPI.show();
    } catch {
      Snackbar.show(t('intercom.messengerError'));
    }
  }, []);

  const onLogout = useCallback(async () => {
    try {
      IntercomWebAPI.shutdown();
      IntercomWebAPI.boot();
    } catch {
      Snackbar.show(t('intercom.logoutError'));
    }
  }, []);

  const value = {
    unReadMessageCount,
    onLogin,
    onHelpCenter,
    onMessenger,
    onLogout,
    onLoginAnon,
  };

  return (
    <IntercomContext.Provider value={value}>
      {children}
    </IntercomContext.Provider>
  );
};

export function useIntercom(): IntercomContextAPI {
  const context = useContext(IntercomContext);

  if (!context) {
    throw new Error('useIntercom must be used within an IntercomProvider.');
  }

  return context;
}
