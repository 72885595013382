import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  CompositeNavigationProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import { GroupUser } from '~/data/models/group';
import {
  ADD_USER_TO_GROUP,
  GROUP_SAMPLE,
  GROUP_USERS,
} from '~/data/operations/group';
import { Status, UserType } from '~/data/types/graphql';
import { GroupStackParamList, RootStackParamList } from '~/navigation/types';
import JoinGroupDetailLayout from './layout';

type JoinGroupDetailNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList>,
  StackNavigationProp<GroupStackParamList, 'JoinGroupDetail'>
>;

type JoinGroupDetailRouteProp = RouteProp<
  GroupStackParamList,
  'JoinGroupDetail'
>;
export default function JoinGroupDetail(): JSX.Element {
  const navigation = useNavigation<JoinGroupDetailNavProp>();
  const {
    params: { groupId },
  } = useRoute<JoinGroupDetailRouteProp>();

  const { authUserId, onLogout, onAuthGroup } = useAuth();

  const { loading: loadingGroup, data: groupData } = useQuery(GROUP_SAMPLE, {
    variables: {
      id: groupId,
    },
  });

  const groupSample = groupData?.group;

  const { loading: loadingGroupUsers, data: groupUsersData } = useQuery(
    GROUP_USERS,
    {
      skip: !authUserId,
      variables: {
        user: authUserId,
        first: NO_PAG_LIST_OFFSET,
      },
    },
  );

  const groupUsers: GroupUser[] =
    (groupUsersData?.userGroups?.edges.map(
      (edge) => edge?.node,
    ) as GroupUser[]) || [];

  const acceptedGroupIds = groupUsers
    .filter((groupUser) => groupUser.status === Status.ACCEPTED)
    .map((groupUser) => groupUser.group?.id);

  const pendingGroupIds = groupUsers
    .filter((groupUser) => groupUser.status === Status.PENDING)
    .map((groupUser) => groupUser.group?.id);

  const isPartOfGroup = acceptedGroupIds.includes(groupId);

  const isPendingRequest = pendingGroupIds.includes(groupId);

  const [addUserToGroup, { loading: requestAccessLoading }] =
    useMutation(ADD_USER_TO_GROUP);

  const onEnterGroup = async (authGroupId: string, authUserGroupId: string) => {
    await onAuthGroup(authGroupId, authUserGroupId);

    navigation.reset({
      routes: [{ name: 'MainTab' }],
    });
  };

  async function onRequestAccess() {
    try {
      await addUserToGroup({
        variables: {
          input: { groupId, userId: authUserId, role: UserType.STUDENT },
        },
        refetchQueries: [
          {
            query: GROUP_USERS,
            variables: {
              user: authUserId,
              first: NO_PAG_LIST_OFFSET,
            },
          },
        ],
      });
      navigation.navigate('JoinGroupRequestSent');
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  }

  const logoutHandler = async () => {
    await onLogout();

    navigation.reset({
      routes: [{ name: 'AuthStack' }],
    });
  };

  return (
    <JoinGroupDetailLayout
      onLogout={logoutHandler}
      groupSample={groupSample}
      isPartOfGroup={isPartOfGroup}
      isPendingRequest={isPendingRequest}
      loading={loadingGroup || loadingGroupUsers}
      requestAccessLoading={requestAccessLoading}
      onBack={() => navigation.goBack()}
      onRequestAccess={() => onRequestAccess()}
      onEnterGroup={() => onEnterGroup(groupId, authUserId)}
    />
  );
}
