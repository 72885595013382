import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  NestableDraggableFlatList,
  NestableScrollContainer,
  RenderItemParams,
} from 'react-native-draggable-flatlist';
import Button from '~/components/Button';
import CommonIconCell, {
  CommonIconCellHandle,
} from '~/components/CommonIconCell';
import ModalDatePicker, {
  ModalDatePickerHandler,
} from '~/components/ModalDatePicker';
import ModalTimePicker, {
  ModalTimePickerHandler,
} from '~/components/ModalTimePicker';
import NumberInput from '~/components/NumberInput';
import SectionHeader from '~/components/SectionHeader';
import {
  SettingPanel,
  SettingToggleItem,
  SettingLabelItem,
} from '~/components/SettingPanel';
import { CalendarDateObject } from '~/data/models/calendar';
import {
  ProfilePageSetupConfig,
  ProfilePageQuestion,
  ProfilePagePhotoCategory,
  ModuleInstance,
} from '~/data/models/yearbook';
import { PhotoFormat } from '~/data/types/graphql';
import { TabScreenLayout } from '~/screens/style';
import { calendarDateObject, formattedDate, getUTCTime } from '~/utils/dates';
import { t } from '~/utils/i18n';
import PhotoFormatCell from './PhotoFormatCell';
import {
  PaddedView,
  Header,
  PhotoCategoryContent,
  PhotoButtonContainer,
  PhotoFormatContent,
  QuestionButtonContainer,
  CharCountContainer,
  Footer,
  PHOTO_FORMAT_CELL_WIDTH,
  ViewContainer,
} from './style';

export type UpdateModuleInstanceInput = {
  isActive: boolean;
  dueDate: string | null;
};

export type UpdateProfilePageSetupInput = {
  photoFormat: PhotoFormat | null;
  maxChars: number;
  anonymousComments: boolean;
  visibleComments: boolean;
};

type ProfilePageSetupLayoutProps = {
  moduleInstance: ModuleInstance;
  profilePageSetupConfig: ProfilePageSetupConfig;
  photoCategories: ProfilePagePhotoCategory[];
  questions: ProfilePageQuestion[];
  onBack: () => void;
  onAddQuestion: () => void;
  onAddPhotoCategory: () => void;
  onUpdateQuestion: (id: string, currentText: string) => void;
  onUpdateQuestionsOrder: (
    questionId: string,
    newPosition: number,
    updatedQuestionOrderIds: string[],
  ) => void;
  onDeleteQuestion: (questionId: string) => void;
  onUpdateModuleInstance: (
    updateModuleInstanceInput: UpdateModuleInstanceInput,
  ) => void;
  onUpdateProfilePageSetup: (
    updateProfilePageSetupInput: UpdateProfilePageSetupInput,
  ) => void;
  onUpdatePhotoCategory: (photoCategoryId: string, name: string) => void;
  onDeletePhotoCategory: (photoCategoryId: string) => void;
};

export type ProfilePageQuestionMap = { [key: string]: ProfilePageQuestion };

export default function ProfilePageSetup({
  moduleInstance,
  profilePageSetupConfig,
  questions,
  onBack,
  onAddQuestion,
  onAddPhotoCategory,
  onUpdateQuestion,
  onUpdateQuestionsOrder,
  onDeleteQuestion,
  onUpdateModuleInstance,
  onUpdateProfilePageSetup,
  photoCategories,
  onDeletePhotoCategory,
  onUpdatePhotoCategory,
}: ProfilePageSetupLayoutProps): JSX.Element {
  const commonQuestionIconCellsRef = useRef<(CommonIconCellHandle | null)[]>(
    [],
  );
  const commonPhotoCategoryIconCellsRef = useRef<
    (CommonIconCellHandle | null)[]
  >([]);
  const dateModalizeRef = useRef<ModalDatePickerHandler>(null);
  const timeModalizeRef = useRef<ModalTimePickerHandler>(null);
  const getInitialDueDay = () =>
    moduleInstance.dueDate
      ? calendarDateObject(formattedDate(moduleInstance.dueDate, 'yyyy-MM-dd'))
      : null;

  const getInitialDueTime = () =>
    moduleInstance.dueDate
      ? new Date(Date.parse(moduleInstance.dueDate))
      : undefined;

  const [isActive, setActive] = useState(() => moduleInstance.isActive);

  const [dueDay, setDueDay] = useState<CalendarDateObject | null>(
    getInitialDueDay(),
  );

  const [dueTime, setDueTime] = useState<Date | undefined>(getInitialDueTime());

  const getDueDate = (): string | null => {
    if (dueDay && dueTime) {
      const day = formattedDate(dueDay.dateString, 'yyyy-MM-dd');
      const time = getUTCTime(dueTime);

      return `${day}T${time}`;
    }
    return null;
  };

  const photoFormats: PhotoFormat[] = [
    PhotoFormat.PORTRAIT,
    PhotoFormat.SQUARE,
    PhotoFormat.AS_UPLOADED,
  ];

  const [selectedPhotoFormat, setSelectedPhotoFormat] =
    useState<PhotoFormat | null>(() => profilePageSetupConfig.photoFormat);

  // questions state
  const [questionOrderIds, setQuestionOrderIds] = useState<string[]>([]);
  const currentQuestionOrderIds = questions.map((question) => question.id);
  const currentQuestionOrderIdsHash = currentQuestionOrderIds.join('|');
  const questionsMap: ProfilePageQuestionMap =
    questions.reduce<ProfilePageQuestionMap>((obj, question) => {
      obj[question.id] = question;
      return obj;
    }, {});

  const [maxChars, setMaxChars] = useState(
    () => profilePageSetupConfig.maxChars,
  );

  const [anonymousComments, setAnonymousComments] = useState(
    () => profilePageSetupConfig.anonymousComments,
  );
  const [moderateContent, setModerateContent] = useState(
    () => profilePageSetupConfig.visibleComments,
  );
  useEffect(() => {
    if (currentQuestionOrderIdsHash != '') {
      setQuestionOrderIds(currentQuestionOrderIds);
    }
  }, [currentQuestionOrderIdsHash]);

  useEffect(() => {
    const updatedIsActive =
      moduleInstance && isActive != moduleInstance.isActive ? true : false;

    const newDueDate = getDueDate();

    const initialTime = getInitialDueTime();

    const updatedDueDate =
      newDueDate &&
      (dueDay?.dateString != getInitialDueDay()?.dateString ||
        (dueTime && initialTime && dueTime.getTime() != initialTime.getTime()))
        ? true
        : false;

    if (updatedIsActive || updatedDueDate) {
      onUpdateModuleInstance({
        isActive,
        dueDate: updatedDueDate ? newDueDate : moduleInstance.dueDate,
      });
    }
  }, [isActive, dueDay, dueTime]);

  useEffect(() => {
    const updatedPhotoFormat =
      profilePageSetupConfig &&
      selectedPhotoFormat &&
      selectedPhotoFormat != profilePageSetupConfig.photoFormat
        ? true
        : false;

    const updatedMaxChars =
      profilePageSetupConfig && maxChars != profilePageSetupConfig.maxChars
        ? true
        : false;

    const updatedAnonymousComments =
      profilePageSetupConfig &&
      anonymousComments != profilePageSetupConfig.anonymousComments
        ? true
        : false;

    const updatedVisibleComments =
      profilePageSetupConfig &&
      moderateContent != profilePageSetupConfig.visibleComments
        ? true
        : false;

    if (
      updatedPhotoFormat ||
      updatedMaxChars ||
      updatedAnonymousComments ||
      updatedVisibleComments
    ) {
      onUpdateProfilePageSetup({
        maxChars,
        photoFormat: selectedPhotoFormat,
        anonymousComments,
        visibleComments: moderateContent,
      });
    }
  }, [selectedPhotoFormat, maxChars, anonymousComments, moderateContent]);

  const renderQuestionsItem = useCallback(
    ({
      item: question,
      getIndex,
      drag,
    }: RenderItemParams<ProfilePageQuestion>) => {
      const value = question.text || t('profilePageSetup.questionPlaceholder');
      const index = (getIndex && getIndex()) || 0;
      return (
        <CommonIconCell
          testID={`CommonIconQuestionCell:${question.id}`}
          ref={(el) => (commonQuestionIconCellsRef.current[index] = el)}
          key={question.id}
          info={{ text: value }}
          swipeable
          onPress={() => onUpdateQuestion(question.id, question.text)}
          onLongPress={drag}
          onDelete={() => {
            commonQuestionIconCellsRef?.current?.[index]?.collapse();
            onDeleteQuestion(question.id);
          }}
        />
      );
    },
    [],
  );

  const renderItemPhotoCategory = useCallback(
    ({
      item: photoCategory,
      getIndex,
      drag,
    }: RenderItemParams<ProfilePagePhotoCategory>) => {
      const value =
        photoCategory.name || t('profilePageSetup.questionPlaceholder');
      const index = (getIndex && getIndex()) || 0;
      return (
        <CommonIconCell
          testID={`CommonIconPhotoCategoryCell:${photoCategory.id}`}
          ref={(el) => (commonPhotoCategoryIconCellsRef.current[index] = el)}
          key={photoCategory.id}
          info={{ text: value }}
          swipeable
          onPress={() => {
            onUpdatePhotoCategory(photoCategory.id, photoCategory.name || '');
          }}
          onLongPress={drag}
          onDelete={() => {
            commonPhotoCategoryIconCellsRef?.current?.[index]?.collapse();
            onDeletePhotoCategory(photoCategory.id);
          }}
        />
      );
    },
    [],
  );

  const orderedQuestions = questionOrderIds
    .map((qId) => questionsMap[qId])
    .filter((question) => !!question);

  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.profilePageSetup')}
      onBackPress={onBack}
    >
      <NestableScrollContainer showsVerticalScrollIndicator={false}>
        <Header>
          <PaddedView>
            <SectionHeader sub title={t('profilePageSetup.activateSection')} />
            <SettingPanel>
              <SettingToggleItem
                testID={'isActivateToggleItem'}
                text={t('profilePageSetup.moduleActivate')}
                checked={isActive}
                onToggleChange={setActive}
              />
            </SettingPanel>
            <SectionHeader sub title={t('g.deadline')} />
            <SettingPanel>
              <SettingLabelItem
                testID={'buttonDueDate'}
                text={t('g.dueDate')}
                labelText={
                  dueDay ? formattedDate(dueDay.dateString, 'MMM d yyyy') : ''
                }
                onLabelPress={() => dateModalizeRef.current?.open()}
              />
              <SettingLabelItem
                testID={'buttonDueTime'}
                text={t('g.dueTime')}
                labelText={dueTime ? formattedDate(dueTime, 'hh:mm a') : ''}
                onLabelPress={() => timeModalizeRef.current?.open()}
              />
            </SettingPanel>
            <SectionHeader
              sub
              title={t('profilePageSetup.photoCategoriesSection')}
            />
          </PaddedView>

          <PhotoCategoryContent>
            <NestableDraggableFlatList
              testID={'categoriesDraggableFlatList'}
              data={photoCategories}
              activationDistance={30}
              renderItem={renderItemPhotoCategory}
              keyExtractor={(item) => `photoCategory${item.id}`}
              onDragEnd={() => {
                // waiting BE
              }}
            />
            <PhotoButtonContainer>
              <Button
                testID={'addPhotoCategoryButton'}
                text={t('profilePageSetup.addPhotoCategory')}
                icon={'left'}
                iconName={'plus'}
                size={'sm'}
                type={'secondary-base'}
                flex
                onPress={onAddPhotoCategory}
              />
            </PhotoButtonContainer>
          </PhotoCategoryContent>
          <PaddedView>
            <SectionHeader
              sub
              title={t('profilePageSetup.photoFormatSection')}
            />
            <PhotoFormatContent>
              {photoFormats.map((photoFormat) => (
                <PhotoFormatCell
                  key={photoFormat}
                  testID={`buttonFormat${photoFormat}`}
                  format={photoFormat}
                  selected={photoFormat === selectedPhotoFormat}
                  width={PHOTO_FORMAT_CELL_WIDTH}
                  onPress={() => setSelectedPhotoFormat(photoFormat)}
                />
              ))}
            </PhotoFormatContent>
            <SectionHeader sub title={t('profilePageSetup.questionSection')} />
          </PaddedView>
        </Header>
        <NestableDraggableFlatList
          testID={'questionsDraggableFlatList'}
          data={orderedQuestions}
          activationDistance={30}
          renderItem={renderQuestionsItem}
          keyExtractor={(item) => `di${item.id}`}
          onDragEnd={({ data: updatedQuestions, from, to }) => {
            const updatedQuestionOrderIds = updatedQuestions.map((q) => q.id);
            setQuestionOrderIds(updatedQuestionOrderIds);
            onUpdateQuestionsOrder(
              questions?.[from]?.id,
              to + 1,
              updatedQuestionOrderIds,
            );
          }}
        />
        <Footer>
          <QuestionButtonContainer>
            <Button
              testID={'addQuestionButton'}
              text={t('profilePageSetup.addQuestion')}
              icon={'left'}
              iconName={'plus'}
              size={'sm'}
              type={'secondary-base'}
              flex
              onPress={onAddQuestion}
            />
          </QuestionButtonContainer>
          <CharCountContainer>
            <ViewContainer>
              <SectionHeader
                sub
                title={t('profilePageSetup.charCountSection')}
              />
            </ViewContainer>
            <NumberInput
              testID={'charCountNumberInput'}
              number={maxChars}
              step={100}
              min={500}
              max={10000}
              onChangeNumber={(number) => setMaxChars(number)}
            />
          </CharCountContainer>
          <SectionHeader sub title={t('profilePageSetup.commentsSection')} />
          <SettingPanel>
            <SettingToggleItem
              testID={'anonymousCommentsToggleItem'}
              text={t('profilePageSetup.anonymousComments')}
              checked={anonymousComments}
              onToggleChange={setAnonymousComments}
            />
            <SettingToggleItem
              testID={'moderateContentToggleItem'}
              text={t('profilePageSetup.moderateContent')}
              checked={moderateContent}
              onToggleChange={setModerateContent}
            />
          </SettingPanel>
        </Footer>
      </NestableScrollContainer>
      <ModalDatePicker
        testID={'datePicker'}
        ref={dateModalizeRef}
        title={t('g.deadlineTime')}
        buttonText={t('g.confirmDate')}
        date={dueDay}
        onConfirmDate={(dateObj) => {
          setDueDay(dateObj);
          dateModalizeRef.current?.close();
        }}
      />
      <ModalTimePicker
        testID={'timePicker'}
        ref={timeModalizeRef}
        title={t('g.deadlineTime')}
        buttonText={t('g.confirmTime')}
        time={dueTime}
        onConfirmTime={(time) => {
          setDueTime(time);
          timeModalizeRef.current?.close();
        }}
      />
    </TabScreenLayout>
  );
}
