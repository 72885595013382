import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';

export const ScroolView = styled(KeyboardAwareScrollView)`
  padding-top: 24px;
  padding-horizontal: 16px;
`;

export const InfoCell = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const UserName = styled.Text`
  margin-left: 12px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 12px;
  color: ${({ theme }) => theme.color.base.c9};
`;

export const InputAreaFieldContainer = styled.View`
  padding-vertical: 16px;
`;

export const ButtonContent = styled.View`
  padding-bottom: 24px;
`;
