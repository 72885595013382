import React, { useState, useRef } from 'react';
import { Alert } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { getImageFromLibrary } from '~/components/ImagePicker';
import InputField, { InputFieldHandle } from '~/components/InputField';
import SectionHeader from '~/components/SectionHeader';
import Shimmer from '~/components/Shimmer';
import TabBar from '~/components/TabBar';
import TabSectionButton from '~/components/TabSectionButton';
import TrackingFlatlist from '~/components/TrackingFlatList';
import { AdsImage, AdsText } from '~/components/common/style';
import { BestMatchedAsset } from '~/data/models/campaign';
import {
  ProfilePageQuestion,
  ProfilePageComment,
  ProfilePageAnswer,
  ProfilePagePhotoCategory,
} from '~/data/models/yearbook';
import { PhotoFormat, VariantTypeEnum } from '~/data/types/graphql';
import useAdsTracking from '~/utils/hooks/useAdsTracking';
import { t } from '~/utils/i18n';
import ModuleLayout from '../../layout/ModuleLayout';
import PageCommentCell from './PageCommentCell';
import PagePhotoCategoryCard from './PagePhotoCategoryCard';
import {
  PaddedCell,
  TouchView,
  Header,
  PhotosFlatList,
  TabBarContainer,
  TabSectionButtonContainer,
  RefreshControl,
  PlaceholderContainer,
} from './style';

export type ProfilePageLayoutProps = {
  photoCategories: ProfilePagePhotoCategory[];
  photoCategoriesLoading: boolean;
  questions: ProfilePageQuestion[];
  questionsLoading: boolean;
  comments: ProfilePageComment[];
  commentsLoading: boolean;
  anonymousComments?: boolean;
  photoFormat?: PhotoFormat;
  initialTabItemKey?: 'qa' | 'comments';
  yearbookAdmin?: boolean;
  isActive?: boolean;
  userName?: string; //viewOnly
  loadingLocalUris: string[];
  isDeadlinePassed?: boolean | null;
  disableEdit?: boolean;
  collectedDataView?: boolean;
  authUserId: string;
  onBack: () => void;
  onSetup: () => void;
  onHelp: () => void;
  onRequestYearbookTeam: () => void;
  onUploadImage: (
    imgUri: string,
    photoCategoryId: string,
    photoId?: string,
  ) => void;
  onCreateComment: (text?: string, disableEdit?: boolean) => void;
  onReportComment: (commentId: string) => void;
  onDeleteComment: (commentId: string) => void;
  onAnswerQuestion: (
    questionTitle: string,
    questionId: string,
    answerId?: string,
    text?: string,
    disableEdit?: boolean,
  ) => void;
  onRemovePhoto: (photoCategoryId: string, photoId: string) => void;
  onRefresh: () => void;
  onLoadMoreCategories: () => void;
  onLoadMoreQuestions: () => void;
  onLoadMoreComments: () => void;
  onUpdateComment: (
    userGroupId: string,
    profilePageCommentId: string,
    currentText: string,
    disableEdit: boolean,
  ) => void;
  openBannersInBrowser: (url: string) => void;
};

type StringMap = { [key: string]: string };

export default function ProfilePage({
  photoFormat = PhotoFormat.PORTRAIT,
  photoCategories,
  photoCategoriesLoading,
  questions,
  questionsLoading,
  comments,
  commentsLoading,
  anonymousComments,
  initialTabItemKey = 'qa',
  yearbookAdmin,
  isActive,
  userName,
  loadingLocalUris,
  isDeadlinePassed,
  disableEdit = false,
  collectedDataView,
  authUserId,
  onBack,
  onSetup,
  onHelp,
  onRequestYearbookTeam,
  onUploadImage,
  onCreateComment,
  onReportComment,
  onDeleteComment,
  onAnswerQuestion,
  onRemovePhoto,
  onRefresh,
  onLoadMoreCategories,
  onLoadMoreQuestions,
  onLoadMoreComments,
  onUpdateComment,
  openBannersInBrowser,
}: ProfilePageLayoutProps): JSX.Element {
  const viewOnly = !!userName;

  const questionsRef = useRef<InputFieldHandle[]>([]);

  const [selectedTabItemKey, setSelectedTabItemKey] =
    useState<string>(initialTabItemKey);

  const [localImgsMap, setLocalImgsMap] = useState<StringMap>({});

  const { handleViewTracking, handleAdsClick, bestMatchedAsset, randomIndex } =
    useAdsTracking({ authUserId, variantType: VariantTypeEnum.SMALL });

  const onPickImage = async (photoCategoryId: string, photoId?: string) => {
    if (viewOnly) {
      return;
    }

    const imgUris = await getImageFromLibrary();

    const uri = imgUris?.[0];
    if (uri) {
      setLocalImgsMap((localImgsMap) => ({
        ...localImgsMap,
        [photoCategoryId]: uri,
      }));
      onUploadImage(photoCategoryId, uri, photoId);
    }
  };

  const renderPhotoCategoryCard = ({
    item: photoCategory,
  }: {
    item?: ProfilePagePhotoCategory;
    index: number;
  }) => {
    const localUri = photoCategory?.id
      ? localImgsMap[photoCategory.id]
      : undefined;
    return (
      <PagePhotoCategoryCard
        key={photoCategory?.id}
        photoCategory={photoCategory}
        localImgUri={localUri}
        loading={localUri ? loadingLocalUris.includes(localUri) : false}
        photoFormat={photoFormat}
        onPickImage={onPickImage}
        onRemove={onRemovePhoto}
        disableRemoveButton={disableEdit}
      />
    );
  };

  const renderItem = ({
    item,
    index,
  }: {
    item?: BestMatchedAsset | ProfilePageQuestion | ProfilePageComment;
    index: number;
  }) => {
    if (item?.__typename === 'MatchedAssetNode') {
      const post = item as BestMatchedAsset;
      return (
        <TouchableOpacity
          testID={`${index}:AdsCell:${post?.id}`}
          onPress={() => {
            handleAdsClick(
              post?.id as string,
              post?.channelData?.[0]?.trackingUrl as string,
              openBannersInBrowser,
            );
          }}
        >
          <AdsText>{t('asset.gratitude')}</AdsText>
          <AdsImage
            source={{ uri: post?.channelData?.[0]?.imageSmall as string }}
          />
        </TouchableOpacity>
      );
    }
    if (selectedTabItemKey === 'qa') {
      if (!item) {
        return (
          <PaddedCell>
            <PlaceholderContainer>
              <Shimmer w={120} h={14} />
              <Shimmer w={240} h={24} mt={12} />
            </PlaceholderContainer>
          </PaddedCell>
        );
      }

      const question = item as ProfilePageQuestion;
      const currentAnswers: ProfilePageAnswer[] =
        (question.profilePageAnswers?.edges.map(
          (edge) => edge?.node,
        ) as ProfilePageAnswer[]) || [];
      const answerId =
        currentAnswers.length == 1 ? currentAnswers[0].id : undefined;
      const answerText =
        currentAnswers.length == 1 && currentAnswers[0].text
          ? currentAnswers[0].text
          : '';
      return (
        <TouchView
          testID={`InputFieldView:${question.id}`}
          onPress={() =>
            onAnswerQuestion(
              question.text,
              question.id,
              answerId,
              answerText,
              disableEdit,
            )
          }
        >
          <PaddedCell pointerEvents="none">
            <InputField
              ref={(ref) => {
                if (ref) {
                  questionsRef.current[index] = ref;
                }
              }}
              label={question.text}
              value={answerText}
              editable={false}
              selectTextOnFocus={false}
            />
          </PaddedCell>
        </TouchView>
      );
    }
    const comment = item as ProfilePageComment;

    return (
      <PaddedCell>
        <PageCommentCell
          profilePageComment={comment}
          viewOnly={viewOnly}
          onCommentPress={onUpdateComment}
          disableEdit={disableEdit}
          anonymous={anonymousComments}
          onReport={() => onReportComment(comment.id)}
          onDelete={() =>
            Alert.alert(t('alert.deleteCommentTitle'), '', [
              {
                text: t('alert.cancel'),
                style: 'cancel',
              },
              {
                text: t('alert.delete'),
                onPress: () => {
                  onDeleteComment(comment.id);
                },
              },
            ])
          }
        />
      </PaddedCell>
    );
  };

  const questionsData =
    questionsLoading && questions.length == 0 ? [undefined] : questions;
  const commentsData =
    commentsLoading && comments.length == 0 ? [undefined] : comments;
  const data = selectedTabItemKey === 'qa' ? questionsData : commentsData;
  const photoCategoryData =
    photoCategoriesLoading && photoCategories.length == 0
      ? [undefined]
      : photoCategories;

  const combinedData = bestMatchedAsset?.[randomIndex]
    ? [...data, bestMatchedAsset?.[randomIndex]]
    : data;

  return (
    <ModuleLayout
      title={userName || t('screens.profilePage')}
      yearbookAdmin={yearbookAdmin && !viewOnly}
      viewOnly={collectedDataView}
      onBack={onBack}
      onSetup={onSetup}
      isActive={isActive}
      isDeadlinePassed={isDeadlinePassed}
      onHelp={onHelp}
      onRequestYearbookTeam={onRequestYearbookTeam}
    >
      <TrackingFlatlist
        testID={'flatList'}
        refreshControl={
          <RefreshControl refreshing={false} onRefresh={onRefresh} />
        }
        handleViewTracking={handleViewTracking}
        ListHeaderComponent={
          <>
            <PhotosFlatList<React.ElementType>
              testID={'photoCategoriesList'}
              data={photoCategoryData}
              horizontal
              showsHorizontalScrollIndicator={false}
              renderItem={renderPhotoCategoryCard}
              keyExtractor={(item: ProfilePagePhotoCategory) => `p${item?.id}`}
              onEndReachedThreshold={0.3}
              onEndReached={() => onLoadMoreCategories()}
            />
            <Header>
              <TabBarContainer>
                <TabBar
                  testID={'tabBar'}
                  selectedTabItemKey={selectedTabItemKey}
                  tabItems={[
                    {
                      key: 'qa',
                      title: t('g.q&a'),
                    },
                    {
                      key: 'comments',
                      title: t('g.comments'),
                    },
                  ]}
                  onTabItemPress={setSelectedTabItemKey}
                />
              </TabBarContainer>
              {selectedTabItemKey === 'comments' && !viewOnly && (
                <TabSectionButtonContainer>
                  <TabSectionButton
                    testID={'buttonCreateComment'}
                    title={t('profilePage.createCommentTitle')}
                    detail={t('profilePage.createCommentDetail')}
                    iconName={'message-chat-circle'}
                    rightIconName={'chevron-right'}
                    onPress={onCreateComment}
                  />
                </TabSectionButtonContainer>
              )}
              <SectionHeader
                title={
                  selectedTabItemKey === 'qa'
                    ? userName
                      ? t('profilePage.qaProfileSection', { name: userName })
                      : t('profilePage.qaSection')
                    : userName
                    ? t('profilePage.commentsProfileSection', {
                        name: userName,
                      })
                    : t('profilePage.commentsSection')
                }
              />
            </Header>
          </>
        }
        data={combinedData as BestMatchedAsset[]}
        renderItem={renderItem}
        onEndReachedThreshold={0.3}
        onEndReached={() =>
          selectedTabItemKey === 'qa'
            ? onLoadMoreQuestions()
            : onLoadMoreComments()
        }
      />
    </ModuleLayout>
  );
}
