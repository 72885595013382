import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';

export const ScrollView = styled(KeyboardAwareScrollView).attrs({
  contentContainerStyle: {
    flexGrow: 1,
  },
})`
  padding: 16px;
  padding-bottom: 100px;
`;

export const InstituteInfoContainer = styled.View`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.color.base.c2};
`;

export const InstituteInfoTextContainer = styled.View`
  padding: 15px;
`;

export const InstituteInfoName = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
`;

export const InstituteInfoParagraph = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
  margin-top: 5px;
`;

export const SectionLabel = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 18px;
  color: ${({ theme }) => theme.color.base.c9};
`;

export const LoadingContent = styled.View`
  padding-vertical: 32px;
  align-items: center;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))``;
