import React from 'react';
import { Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import Button from '~/components/Button';
import { ModalScreenLayout } from '~/screens/style';
import useOpenInBrowser from '~/utils/hooks/useOpenInBrowser';
import { t } from '~/utils/i18n';
import { IFrame } from './style';

type BrowserLayoutProps = {
  url: string;
  title?: string;
  onBack: () => void;
};

export default function Browser({
  url,
  title,
  onBack,
}: BrowserLayoutProps): JSX.Element {
  const { openInBrowser } = useOpenInBrowser();

  return (
    <ModalScreenLayout
      backButtonTestID={'buttonBack'}
      title={title || ''}
      onBackPress={onBack}
      titleAlign="left"
      RightComponent={
        <Button
          text={t('inAppBrowser.openInBrowser')}
          onPress={() => openInBrowser(url)}
          size="sm"
        ></Button>
      }
    >
      {Platform.OS === 'web' ? (
        <IFrame src={url} />
      ) : (
        <WebView source={{ uri: url }} />
      )}
    </ModalScreenLayout>
  );
}
