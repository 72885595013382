import styled from 'styled-components/native';

export const Content = styled.View`
  background-color: ${({ theme }) => theme.color.base.c1};
  padding: 32px 16px 200px;
`;

export const EmptyText = styled.Text`
  margin-vertical: 24px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c6,
}))``;
