import React, { useRef } from 'react';
import { View } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import { general } from '~/assets/imgs';
import Avatar from '~/components/Avatar';
import Button from '~/components/Button';
import { GroupSample } from '~/data/models/group';
import { StackScreenLayout } from '~/screens/style';
import { formattedDate } from '~/utils/dates';
import { t } from '~/utils/i18n';
import {
  Content,
  Title,
  SmallImage,
  SectionTitle,
  SectionValue,
  MembersAvatarRow,
  AvatarContent,
  AvatarMore,
  AvatarMoreText,
  ModalContainer,
  ModalContent,
  ModalTitle,
  ModalDescp,
  ColorBox,
  ImageWrapper,
  RowSpaceBetween,
  Image,
  ScrollContent,
  Footer,
} from './style';

type JoinGroupDetailLayoutProps = {
  onLogout: () => void;
  groupSample?: GroupSample | null;
  isPartOfGroup: boolean;
  isPendingRequest: boolean;
  loading: boolean;
  requestAccessLoading: boolean;
  onBack: () => void;
  onRequestAccess: () => void;
  onEnterGroup: () => void;
};

export default function JoinGroupDetail({
  groupSample,
  isPartOfGroup,
  isPendingRequest,
  loading,
  requestAccessLoading,
  onBack,
  onRequestAccess,
  onLogout,
  onEnterGroup,
}: JoinGroupDetailLayoutProps): JSX.Element {
  const modalizeRef = useRef<Modalize>(null);

  const usersCount = groupSample?.usersCount || 0;
  const userSampleCount = groupSample?.sampleMembers?.length || 0;

  const usersLeft = usersCount - userSampleCount;
  return (
    <StackScreenLayout
      backButtonTestID={'buttonBack'}
      colorStyle={'light'}
      onBackPress={onBack}
      RightComponent={
        <Button
          testID="buttonLogout"
          size="sm"
          text={t('g.logout')}
          type="secondary-base"
          onPress={onLogout}
        />
      }
      BgComponent={<ColorBox />}
    >
      <ScrollContent>
        <Content>
          <ImageWrapper>
            <Image
              source={{
                uri:
                  groupSample?.avatar || groupSample?.icon?.icon || undefined,
              }}
            />
            <SmallImage source={general.double_hearts} />
          </ImageWrapper>
          <Title>
            {groupSample?.name || ''} {groupSample?.year || ''}
          </Title>
          <RowSpaceBetween>
            <View>
              <SectionTitle>
                {t('joinGroupDetail.sectionRegisteredDate')}
              </SectionTitle>
              <SectionValue>{formattedDate(groupSample?.created)}</SectionValue>
            </View>
            <View>
              <SectionTitle>{t('joinGroupDetail.sectionMembers')}</SectionTitle>
              <SectionValue>{usersCount}</SectionValue>
            </View>
          </RowSpaceBetween>

          <MembersAvatarRow>
            {groupSample?.sampleMembers?.slice(0, 5).map((userGroup, index) => (
              <AvatarContent key={index} orderNo={index}>
                <Avatar size={59} userGroup={userGroup} />
              </AvatarContent>
            ))}
            {usersLeft > 0 && (
              <AvatarContent>
                <AvatarMore>
                  <AvatarMoreText>{`+${usersLeft}`}</AvatarMoreText>
                </AvatarMore>
              </AvatarContent>
            )}
          </MembersAvatarRow>
        </Content>
        <Footer>
          <Button
            testID={'buttonAskAccess'}
            state={isPartOfGroup || isPendingRequest ? 'disabled' : 'default'}
            type="primary-brand-02"
            flex
            flexScreenContainer="stack"
            bottomMargin={8}
            size="2xl"
            text={
              isPartOfGroup
                ? t('joinGroupDetail.partOfGroup')
                : isPendingRequest
                ? t('joinGroupDetail.pendingRequest')
                : t('joinGroupDetail.askAccess')
            }
            loading={loading}
            onPress={() => modalizeRef.current?.open()}
          />
          {!isPendingRequest && (
            <Button
              testID={'buttonEnterOrLeave'}
              type="secondary-contrast"
              flex
              flexScreenContainer="stack"
              size="2xl"
              text={
                isPartOfGroup
                  ? t('joinGroupDetail.enterGroup')
                  : t('joinGroupDetail.notNow')
              }
              loading={loading}
              onPress={() => {
                isPartOfGroup ? onEnterGroup() : onBack();
              }}
            />
          )}
        </Footer>
      </ScrollContent>
      <Portal>
        <Modalize
          ref={modalizeRef}
          handlePosition={'inside'}
          adjustToContentHeight
        >
          <ModalContainer>
            <ModalContent>
              <ModalTitle>{t('joinGroupDetail.modalTitle')}</ModalTitle>
              <ModalDescp>{t('joinGroupDetail.modalDescp')}</ModalDescp>
            </ModalContent>
            <Button
              testID={'buttonRequestAccess'}
              flex
              flexScreenContainer="stack"
              text={t('joinGroupDetail.confirmRequest')}
              loading={requestAccessLoading}
              type="primary-brand-02"
              size="2xl"
              onPress={() => {
                modalizeRef.current?.close();
                onRequestAccess && onRequestAccess();
              }}
            />
          </ModalContainer>
        </Modalize>
      </Portal>
    </StackScreenLayout>
  );
}
