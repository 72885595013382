import { ApolloCache } from '@apollo/client';
import {
  GROUP_FRAGMENT,
  GROUP_USERS,
  USER_GROUPS,
  USER_GROUP_FRAGMENT,
} from './';
import {
  GroupFragment as Group,
  GroupUsersQuery as GroupUsers,
  GroupUsersQueryVariables as GroupUsersVariables,
  UserGroupFragment as UserGroup,
  UserGroupsQuery as UserGroups,
  UserGroupsQueryVariables as UserGroupsVariables,
} from './../../types/graphql';

export function readUserGroupsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: UserGroupsVariables;
}): UserGroups | null {
  return cache.readQuery<UserGroups>({
    query: USER_GROUPS,
    variables,
  });
}

export function writeUserGroupsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: UserGroupsVariables;
  data: UserGroups;
}): void {
  cache.writeQuery<UserGroups>({
    query: USER_GROUPS,
    variables,
    data,
  });
}

export function readGroupUsersQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: GroupUsersVariables;
}): GroupUsers | null {
  return cache.readQuery<GroupUsers>({
    query: GROUP_USERS,
    variables,
  });
}

export function writeGroupUsersQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: GroupUsersVariables;
  data: GroupUsers;
}): void {
  cache.writeQuery<GroupUsers>({
    query: GROUP_USERS,
    variables,
    data,
  });
}

export function readUserGroupFragment({
  cache,
  id,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
}): UserGroup | null {
  return cache.readFragment<UserGroup>({
    id: `UserGroupNode:${id}`,
    fragment: USER_GROUP_FRAGMENT,
    fragmentName: 'UserGroup',
  });
}

export function readGroupFragment({
  cache,
  id,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
}): Group | null {
  return cache.readFragment<Group>({
    id: `GroupNode:${id}`,
    fragment: GROUP_FRAGMENT,
    fragmentName: 'Group',
  });
}
