import React, { useEffect, useState } from 'react';
import Dropdown, { OptionsInfo } from '~/components/Dropdown';
import IconStyled from '~/components/IconStyled';
import { MAX_ALLOWED_ZIPCODES } from '~/data/constants';
import { AdsTargetingFilter } from '~/data/models/admin';
import {
  GraduationTypesQuery as GraduationTypes,
  InstituteTypesQuery as InstituteTypes,
  TargetingFilterGroupMembersOperation,
  TeamType,
} from '~/data/types/graphql';
import useUploadCSV from '~/utils/hooks/useUploadCSV';
import { t } from '~/utils/i18n';
import CheckBox from '../CheckBox';
import CsvInputField from '../CsvInputField';
import InputField from '../InputField';
import Snackbar from '../Snackbar';
import { Spacer } from '../common/style.web';
import generateGraduationYears from './helpers';
import {
  Container,
  Title,
  TitleContainer,
  HeaderContainer,
  DeleteButton,
  LabelContainer,
  Label,
  MembersContainer,
  CheckboxContainer,
  MembersNumber,
  CheckboxSelectionContainer,
  CheckboxLabelContainer,
  CheckboxText,
} from './style';

type GroupFilteringBlockProps = {
  filterData: AdsTargetingFilter;
  index: number;
  instituteTypes?: InstituteTypes;
  graduationTypes?: GraduationTypes;
  updateFilter: (index: number, filter: AdsTargetingFilter) => void;
  removeFilter: (index: number) => void;
};

const Members: OptionsInfo[] = [
  { key: 'all', name: 'ALL' },
  {
    key: TargetingFilterGroupMembersOperation.LARGER_THAN as string,
    name: t('createAssets.createTargeting.groupFilteringBlock.largerThan'),
  },
  {
    key: TargetingFilterGroupMembersOperation.BELOW as string,
    name: t('createAssets.createTargeting.groupFilteringBlock.below'),
  },
  {
    key: TargetingFilterGroupMembersOperation.EXACTLY as string,
    name: t('createAssets.createTargeting.groupFilteringBlock.exactly'),
  },
];

export default function GroupFilteringBlock({
  filterData,
  updateFilter,
  instituteTypes,
  graduationTypes,
  index,
  removeFilter,
}: GroupFilteringBlockProps): JSX.Element {
  const [members, setMembers] = useState<string>(
    (filterData?.group?.members?.operator as string) || 'all',
  );
  const [membersNumber, setMembersNumber] = useState<string>(
    (filterData?.group?.members?.value?.toString() as string) || '',
  );
  const [selectedTeams, setSelectedTeams] = useState<TeamType[]>(
    (filterData.group?.teams as TeamType[]) || [],
  );
  const [inputedGroupsID, setInputedGroupsID] = useState<(string | null)[]>(
    filterData.group?.groupIds || [],
  );

  const {
    uploadingState,
    handleUploadCSV,
    removeUploadedFile,
    uploadedCSVFileName,
    uploadedCSVFileLength,
    uploadedInput,
  } = useUploadCSV();

  useEffect(() => {
    updateGroupIds(index, uploadedInput, inputedGroupsID);
  }, [uploadedInput, inputedGroupsID]);

  useEffect(() => {
    updateMembers(index, members as string, membersNumber as string);
  }, [members, membersNumber]);
  const updateGraduationType = (idx: number, graduationType: string) => {
    let graduationTypesString: string | null = graduationType;
    if (graduationType === 'all') {
      graduationTypesString = null;
    }

    const updatedFilterData: AdsTargetingFilter = {
      ...filterData,
      group: {
        ...filterData.group,
        graduationType: graduationTypesString,
      },
    };

    updateFilter(idx, updatedFilterData);
  };

  const handleTeamCheckboxChange = (teamKey: TeamType) => {
    let updatedTeams: TeamType[] = [];

    if (selectedTeams.includes(teamKey)) {
      updatedTeams = selectedTeams.filter((team) => team !== teamKey);
      setSelectedTeams(updatedTeams);
    } else {
      updatedTeams = [...selectedTeams, teamKey];
    }

    setSelectedTeams(updatedTeams);
    const updatedFilterData: AdsTargetingFilter = {
      ...filterData,
      group: {
        ...filterData.group,
        teams: updatedTeams as TeamType[] | null,
      },
    };

    updateFilter(index, updatedFilterData);
  };

  const handleGroupsIdInputChange = (groupsId: string) => {
    const groups = groupsId.split(',').map((group) => group.trim());

    if (groups.length > MAX_ALLOWED_ZIPCODES) {
      Snackbar.show(
        t('createAssets.createTargeting.groupFilteringBlock.cantInputGroupIds'),
      );
    } else {
      setInputedGroupsID(groups);
    }
  };

  const graduationYears = generateGraduationYears();

  const graduationTypesOptions: OptionsInfo[] = [
    { key: 'all', name: 'all' },
    ...(graduationTypes?.graduationTypes?.edges.map((edge) => ({
      key: edge?.node?.id as string,
      name: edge?.node?.name as string,
    })) || []),
  ];
  const instituteTypesOptions: OptionsInfo[] = [
    { key: 'all', name: 'all' },
    ...(instituteTypes?.instituteTypes?.edges.map((edge) => ({
      key: edge?.node?.id as string,
      name: edge?.node?.name as string,
    })) || []),
  ];

  const groupsInputValue = inputedGroupsID
    .map((item) => (item === null ? '' : item))
    .join(',');

  const updateGraduationYear = (idx: number, graduationYear: string) => {
    const yearToNumber = parseFloat(graduationYear);
    if (graduationYear === 'all') {
      const updatedFilterData: AdsTargetingFilter = {
        ...filterData,
        group: {
          ...filterData.group,
          graduationYear: null,
        },
      };

      updateFilter(idx, updatedFilterData);
      return;
    }
    const updatedFilterData: AdsTargetingFilter = {
      ...filterData,
      group: {
        ...filterData.group,
        graduationYear: [yearToNumber],
      },
    };

    updateFilter(idx, updatedFilterData);
  };

  const updateInstituteType = (idx: number, instituteType: string) => {
    let institutesTypeString: string | null = instituteType;
    if (instituteType === 'all') {
      institutesTypeString = null;
    }
    const updatedFilterData: AdsTargetingFilter = {
      ...filterData,
      group: {
        ...filterData.group,
        instituteType: institutesTypeString,
      },
    };

    updateFilter(idx, updatedFilterData);
  };

  const updateMembers = (
    idx: number,
    members: string,
    membersNumber: string,
  ) => {
    let membersOperator: string | null = members;
    let membersValue: number | null = parseFloat(membersNumber);
    if (members === 'all') {
      membersOperator = null;
      membersValue = null;
    }
    const updatedFilterData: AdsTargetingFilter = {
      ...filterData,
      group: {
        ...filterData.group,
        members: {
          value: membersValue,
          operator: membersOperator as TargetingFilterGroupMembersOperation,
        },
      },
    };

    updateFilter(idx, updatedFilterData);
  };

  const updateGroupIds = (
    idx: number,
    groupIdUploaded: (string | number)[],
    groupIdInput: (string | null)[],
  ) => {
    // Convert null values in zipInput to a placeholder string
    const groupIdInputProcessed = groupIdInput.map((item) =>
      item === null ? 'null' : item,
    );

    const combinedGroupId = groupIdUploaded.concat(groupIdInputProcessed);

    let groupIdsArray: string[] | null = combinedGroupId.map((item) =>
      String(item),
    );

    if (groupIdsArray.length === 1 && groupIdsArray[0] === '') {
      groupIdsArray = null;
    }

    const updatedFilterData: AdsTargetingFilter = {
      ...filterData,
      group: {
        ...filterData.group,
        groupIds: groupIdsArray,
      },
    };

    updateFilter(idx, updatedFilterData);
  };

  const selectedGraduationYear =
    filterData.group?.graduationYear?.toString() === 'NaN'
      ? 'all'
      : filterData.group?.graduationYear?.toString() ?? 'all';
  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <IconStyled name="location_dark" size={14} />
          <Title>
            {t('createAssets.createTargeting.groupFilteringBlock.title')}
          </Title>
        </TitleContainer>{' '}
        <DeleteButton onPress={() => removeFilter(index)}>
          {t('createAssets.createTargeting.groupFilteringBlock.deleteBtn')}
        </DeleteButton>
      </HeaderContainer>
      <LabelContainer>
        <Label>
          {t('createAssets.createTargeting.groupFilteringBlock.graduationType')}
        </Label>
        <Dropdown
          testID={'graduationTypeDropdown'}
          selectedLocale={filterData.group?.graduationType ?? 'all'}
          selectableOptions={graduationTypesOptions}
          onSelectedLocale={(selectedGraduationType) =>
            updateGraduationType(index, selectedGraduationType)
          }
        ></Dropdown>
      </LabelContainer>
      <LabelContainer>
        <Label>
          {t('createAssets.createTargeting.groupFilteringBlock.graduationYear')}
        </Label>
        <Dropdown
          testID={'graduationYearDropdown'}
          selectedLocale={selectedGraduationYear}
          selectableOptions={graduationYears}
          onSelectedLocale={(selectedGraduationType) =>
            updateGraduationYear(index, selectedGraduationType)
          }
        ></Dropdown>
      </LabelContainer>
      <LabelContainer>
        <Label>
          {t('createAssets.createTargeting.groupFilteringBlock.instituteType')}
        </Label>
        <Dropdown
          testID={'InstituteTypeDropdown'}
          selectedLocale={filterData.group?.instituteType ?? 'all'}
          selectableOptions={instituteTypesOptions}
          onSelectedLocale={(selectedInstituteType) =>
            updateInstituteType(index, selectedInstituteType)
          }
        ></Dropdown>
      </LabelContainer>
      <LabelContainer>
        <Label>
          {t('createAssets.createTargeting.groupFilteringBlock.members')}
        </Label>
        <MembersContainer>
          <Dropdown
            testID={'MembersDropdown'}
            selectedLocale={filterData.group?.members?.operator ?? 'all'}
            selectableOptions={Members}
            onSelectedLocale={(selectedMembers) => setMembers(selectedMembers)}
          ></Dropdown>
          {!!members && members !== 'all' && (
            <MembersNumber
              testID={'MembersNumber'}
              value={
                filterData.group?.members?.value
                  ? filterData.group?.members?.value?.toString()
                  : ''
              }
              onChangeText={(number) => setMembersNumber(number)}
            />
          )}
        </MembersContainer>
      </LabelContainer>
      <CheckboxLabelContainer>
        <Label>
          {t('createAssets.createTargeting.groupFilteringBlock.teams')}
        </Label>
        <Spacer v={10} />
        <CheckboxSelectionContainer>
          {Object.entries(TeamType).map(([key, value]) => (
            <CheckboxContainer key={key}>
              <CheckBox
                testID={key}
                size={'small'}
                colorStyle={'tertiary'}
                checked={selectedTeams.includes(key as TeamType)}
                onChange={() => handleTeamCheckboxChange(key as TeamType)}
              />
              <CheckboxText>{value}</CheckboxText>
            </CheckboxContainer>
          ))}
        </CheckboxSelectionContainer>
      </CheckboxLabelContainer>
      <Spacer v={15} />
      <LabelContainer>
        <Label>
          {t('createAssets.createTargeting.groupFilteringBlock.groupId')}
        </Label>
        <CsvInputField
          uploadingState={uploadingState}
          handleUploadCSV={handleUploadCSV}
          removeUploadedFile={removeUploadedFile}
          uploadedCSVFileName={uploadedCSVFileName}
          uploadedCSVFileLength={uploadedCSVFileLength}
        />
      </LabelContainer>
      <InputField
        testID={'assetsGroupIdInput'}
        value={groupsInputValue}
        onChangeText={(groupsID) => {
          handleGroupsIdInputChange(groupsID);
        }}
        placeholder={t(
          'createAssets.createTargeting.groupFilteringBlock.groupIdPlaceholder',
        )}
        size={'small'}
      />
    </Container>
  );
}
