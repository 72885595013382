import React from 'react';
import { useQuery } from '@apollo/client';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { general } from '~/assets/imgs';
import { BannerInfo } from '~/components/BannerCarousel';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import { LINKS, NO_PAG_LIST_OFFSET } from '~/data/constants';
import { ModuleInstance } from '~/data/models/yearbook';
import { GROUP } from '~/data/operations/group';
import { MODULE_INSTANCES } from '~/data/operations/yearbook';
import {
  InstitutesInstituteCountryChoices,
  ModuleType,
} from '~/data/types/graphql';
import { getYearbookTypeformQueryParams } from '~/data/utils';
import {
  PlanningStackParamList,
  RootStackParamList,
  YearbookStackParamList,
} from '~/navigation/types';
import YearbookLayout from './layout';

type CombinedNavProp = CompositeNavigationProp<
  StackNavigationProp<YearbookStackParamList, 'Yearbook'>,
  StackNavigationProp<RootStackParamList & PlanningStackParamList>
>;

export default function Yearbook(): JSX.Element {
  const navigation = useNavigation<CombinedNavProp>();
  const { onHelpCenter } = useIntercom();

  const {
    authGroupId,
    authUserGroup,
    yearbookAdmin,
    authUserId,
    refetchAuthUserGroup,
  } = useAuth();

  const { data: groupData } = useQuery(GROUP, {
    skip: !authGroupId,
    variables: { id: authGroupId },
  });

  const groupDetail = groupData?.group;

  const {
    data: moduleInstancesData,
    loading: moduleInstancesLoading,
    refetch: refetchModuleInstances,
  } = useQuery(MODULE_INSTANCES, {
    skip: !authGroupId,
    variables: { group: authGroupId, first: NO_PAG_LIST_OFFSET },
  });

  const moduleInstances: ModuleInstance[] =
    (moduleInstancesData?.moduleInstances?.edges.map(
      (edge) => edge?.node,
    ) as ModuleInstance[]) || [];

  const trBanners: BannerInfo[] = [
    {
      id: 'banner_slide_tr_1',
      imgUri: general.yearbook_slide_tr_1,
      link: LINKS.TR.SLIDE_1,
    },
    {
      id: 'banner_slide_tr_2',
      imgUri: general.yearbook_slide_tr_2,
      link: LINKS.TR.SLIDE_2,
    },
  ];

  const defaultBanners: BannerInfo[] = [
    {
      id: 'banner_slide_1',
      imgUri: general.yearbook_slide_1,
      link:
        authUserGroup && groupDetail
          ? `${LINKS.DE.YEARBOOK_QUOTE}?${getYearbookTypeformQueryParams(
              authUserGroup.user,
              groupDetail,
              'banner',
            )}`
          : undefined,
    },
    {
      id: 'banner_slide_2',
      imgUri: general.yearbook_slide_2,
      link:
        authUserGroup && groupDetail
          ? `${LINKS.DE.SLIDE_2}?${getYearbookTypeformQueryParams(
              authUserGroup.user,
              groupDetail,
              'banner',
            )}`
          : undefined,
    },
    {
      id: 'banner_slide_3',
      imgUri: general.yearbook_slide_3,
      link: LINKS.DE.SLIDE_3,
    },
  ];

  const banners = !groupDetail
    ? []
    : groupDetail.institute.country === InstitutesInstituteCountryChoices.TR
    ? trBanners
    : defaultBanners;

  const openBannersInBrowser = (url: string) => {
    navigation.navigate('Browser', {
      title: 'Banner',
      url,
    });
  };
  return (
    <YearbookLayout
      banners={banners}
      yearbookAdmin={yearbookAdmin}
      moduleInstances={moduleInstances}
      loading={moduleInstancesLoading}
      authUserId={authUserId}
      openBannersInBrowser={openBannersInBrowser}
      onYearbookModuleClick={(type, moduleInstanceId) => {
        if (type === ModuleType.PROFILE_PAGE) {
          navigation.navigate('ProfilePage', { moduleInstanceId });
        } else if (type === ModuleType.QUOTES) {
          navigation.navigate('Quotes', { moduleInstanceId });
        } else if (type === ModuleType.COLLAGES) {
          navigation.navigate('Collages', { moduleInstanceId });
        } else if (type === ModuleType.REPORTS) {
          navigation.navigate('Reports', { moduleInstanceId });
        } else if (type === ModuleType.RANKINGS) {
          navigation.navigate('Rankings', { moduleInstanceId });
        } else if (type === ModuleType.CUSTOM_PAGES) {
          navigation.navigate('Custom', { moduleInstanceId });
        }
      }}
      onOpenLink={(url) =>
        navigation.navigate('Browser', { title: '', url: url.replace(' ', '') })
      }
      onHelp={onHelpCenter}
      onRefresh={() => {
        refetchAuthUserGroup();
        refetchModuleInstances();
      }}
      onManage={() => navigation.navigate('Manage')}
      onManageRequest={() => {
        navigation.navigate('ManageRequest');
      }}
    />
  );
}
