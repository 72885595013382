import { ApolloCache } from '@apollo/client';
import {
  MODULE_INSTANCE_DETAIL_FRAGMENT,
  PROFILE_PAGE_SETUP_FRAGMENT,
  PROFILE_PAGE_QUESTION_FRAGMENT,
  PROFILE_PAGE_QUESTIONS,
  PROFILE_PAGE_PHOTO_CATEGORY_FRAGMENT,
  PROFILE_PAGE_PHOTO_CATEGORIES,
  PROFILE_PAGE_COMMENTS,
  COLLAGES_ALBUMS,
  REPORTS_INSTANCES,
  QUOTES_INSTANCES,
  QUOTE_FRAGMENT,
  RANKINGS_QUESTIONS,
  MODULE_INSTANCES,
  CUSTOM_LISTS,
  CUSTOM_LIST_OPTIONS,
  COLLAGES_PHOTOS,
  CUSTOM_PAGES_INSTANCES,
} from './';
import {
  CollagesAlbumsQuery as CollagesAlbums,
  CollagesAlbumsQueryVariables as CollagesAlbumsVariables,
  CollagesPhotosQuery as CollagesPhotos,
  CollagesPhotosQueryVariables as CollagesPhotosVariables,
  CustomListOptionsQuery as CustomListOptions,
  CustomListOptionsQueryVariables as CustomListOptionsVariables,
  CustomListsQuery as CustomLists,
  CustomListsQueryVariables as CustomListsVariables,
  CustomPagesInstancesQuery as CustomPagesInstances,
  CustomPagesInstancesQueryVariables as CustomPagesInstancesVariables,
  ModuleInstanceDetailFragment as ModuleInstanceDetail,
  ModuleInstancesQuery as ModuleInstances,
  ModuleInstancesQueryVariables as ModuleInstancesVariables,
  ProfilePageCommentsQuery as ProfilePageComments,
  ProfilePageCommentsQueryVariables as ProfilePageCommentsVariables,
  ProfilePagePhotoCategoriesQuery as ProfilePagePhotoCategories,
  ProfilePagePhotoCategoriesQueryVariables as ProfilePagePhotoCategoriesVariables,
  ProfilePagePhotoCategoryFragment as ProfilePagePhotoCategory,
  ProfilePageQuestionFragment as ProfilePageQuestion,
  ProfilePageQuestionsQuery as ProfilePageQuestions,
  ProfilePageQuestionsQueryVariables as ProfilePageQuestionsVariables,
  ProfilePageSetupFragment as ProfilePageSetup,
  QuoteFragment as Quote,
  QuotesInstancesQuery as QuotesInstances,
  QuotesInstancesQueryVariables as QuotesInstancesVariables,
  RankingsQuestionsQuery as RankingsQuestions,
  RankingsQuestionsQueryVariables as RankingsQuestionsVariables,
  ReportsInstancesQuery as ReportsInstances,
  ReportsInstancesQueryVariables as ReportsInstancesVariables,
} from './../../types/graphql';

export function readModuleInstanceDetailFragment({
  cache,
  id,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
}): ModuleInstanceDetail | null {
  return cache.readFragment<ModuleInstanceDetail>({
    id: `ModuleInstanceNode:${id}`,
    fragment: MODULE_INSTANCE_DETAIL_FRAGMENT,
    fragmentName: 'ModuleInstanceDetail',
  });
}

export function readModuleInstancesQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ModuleInstancesVariables;
}): ModuleInstances | null {
  return cache.readQuery<ModuleInstances>({
    query: MODULE_INSTANCES,
    variables,
  });
}

export function writeModuleInstancesQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ModuleInstancesVariables;
  data: ModuleInstances;
}): void {
  cache.writeQuery<ModuleInstances>({
    query: MODULE_INSTANCES,
    variables,
    data,
  });
}

export function readProfilePageSetupFragment({
  cache,
  id,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
}): ProfilePageSetup | null {
  return cache.readFragment<ProfilePageSetup>({
    id: `ProfilePageSetupNode:${id}`,
    fragment: PROFILE_PAGE_SETUP_FRAGMENT,
    fragmentName: 'ProfilePageSetup',
  });
}

export function readProfilePageQuestionFragment({
  cache,
  id,
  ppqUserGroup,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  ppqUserGroup: string;
}): ProfilePageQuestion | null {
  return cache.readFragment<ProfilePageQuestion, { ppqUserGroup: string }>({
    id: `ProfilePageQuestionNode:${id}`,
    fragment: PROFILE_PAGE_QUESTION_FRAGMENT,
    fragmentName: 'ProfilePageQuestion',
    variables: { ppqUserGroup },
  });
}

export function writeProfilePageQuestionFragment({
  cache,
  id,
  ppqUserGroup,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  ppqUserGroup: string;
  data: ProfilePageQuestion;
}): void {
  cache.writeFragment<ProfilePageQuestion, { ppqUserGroup: string }>({
    id: `ProfilePageQuestionNode:${id}`,
    fragment: PROFILE_PAGE_QUESTION_FRAGMENT,
    fragmentName: 'ProfilePageQuestion',
    variables: { ppqUserGroup },
    data,
  });
}

export function readProfilePagePhotoCategoryFragment({
  cache,
  id,
  ppqUserGroup,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  ppqUserGroup: string;
}): ProfilePagePhotoCategory | null {
  return cache.readFragment<ProfilePagePhotoCategory, { ppqUserGroup: string }>(
    {
      id: `ProfilePagePhotoCategoryNode:${id}`,
      fragment: PROFILE_PAGE_PHOTO_CATEGORY_FRAGMENT,
      fragmentName: 'ProfilePagePhotoCategory',
      variables: { ppqUserGroup },
    },
  );
}

export function writeProfilePagePhotoCategoryFragment({
  cache,
  id,
  ppqUserGroup,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  ppqUserGroup: string;
  data: ProfilePagePhotoCategory;
}): void {
  cache.writeFragment<ProfilePagePhotoCategory, { ppqUserGroup: string }>({
    id: `ProfilePagePhotoCategoryNode:${id}`,
    fragment: PROFILE_PAGE_PHOTO_CATEGORY_FRAGMENT,
    fragmentName: 'ProfilePagePhotoCategory',
    variables: { ppqUserGroup },
    data,
  });
}

export function readProfilePagePhotoCategoriesQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ProfilePagePhotoCategoriesVariables;
}): ProfilePagePhotoCategories | null {
  return cache.readQuery<ProfilePagePhotoCategories>({
    query: PROFILE_PAGE_PHOTO_CATEGORIES,
    variables,
  });
}

export function writeProfilePagePhotoCategoriesQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ProfilePagePhotoCategoriesVariables;
  data: ProfilePagePhotoCategories;
}): void {
  cache.writeQuery<ProfilePagePhotoCategories>({
    query: PROFILE_PAGE_PHOTO_CATEGORIES,
    variables,
    data,
  });
}

export function readProfilePageQuestionsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ProfilePageQuestionsVariables;
}): ProfilePageQuestions | null {
  return cache.readQuery<ProfilePageQuestions>({
    query: PROFILE_PAGE_QUESTIONS,

    variables,
  });
}

export function writeProfilePageQuestionsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ProfilePageQuestionsVariables;
  data: ProfilePageQuestions;
}): void {
  cache.writeQuery<ProfilePageQuestions>({
    query: PROFILE_PAGE_QUESTIONS,
    variables,
    data,
  });
}

export function readProfilePageCommentsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ProfilePageCommentsVariables;
}): ProfilePageComments | null {
  return cache.readQuery<ProfilePageComments>({
    query: PROFILE_PAGE_COMMENTS,
    variables,
  });
}

export function writeProfilePageCommentsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ProfilePageCommentsVariables;
  data: ProfilePageComments;
}): void {
  cache.writeQuery<ProfilePageComments>({
    query: PROFILE_PAGE_COMMENTS,
    variables,
    data,
  });
}

export function readCollagesAlbumsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CollagesAlbumsVariables;
}): CollagesAlbums | null {
  return cache.readQuery<CollagesAlbums>({
    query: COLLAGES_ALBUMS,
    variables,
  });
}

export function writeCollagesAlbumsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CollagesAlbumsVariables;
  data: CollagesAlbums;
}): void {
  cache.writeQuery<CollagesAlbums>({
    query: COLLAGES_ALBUMS,
    variables,
    data,
  });
}

export function readCollagesPhotosQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CollagesPhotosVariables;
}): CollagesPhotos | null {
  return cache.readQuery<CollagesPhotos>({
    query: COLLAGES_PHOTOS,
    variables,
  });
}

export function writeCollagesPhotosQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CollagesPhotosVariables;
  data: CollagesPhotos;
}): void {
  cache.writeQuery<CollagesPhotos>({
    query: COLLAGES_PHOTOS,
    variables,
    data,
  });
}

export function readReportsInstancesQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ReportsInstancesVariables;
}): ReportsInstances | null {
  return cache.readQuery<ReportsInstances>({
    query: REPORTS_INSTANCES,
    variables,
  });
}

export function writeReportsInstancesQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ReportsInstancesVariables;
  data: ReportsInstances;
}): void {
  cache.writeQuery<ReportsInstances>({
    query: REPORTS_INSTANCES,
    variables,
    data,
  });
}

export function readQuotesInstancesQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: QuotesInstancesVariables;
}): QuotesInstances | null {
  return cache.readQuery<QuotesInstances>({
    query: QUOTES_INSTANCES,
    variables,
  });
}

export function writeQuotesInstancesQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: QuotesInstancesVariables;
  data: QuotesInstances;
}): void {
  cache.writeQuery<QuotesInstances>({
    query: QUOTES_INSTANCES,
    variables,
    data,
  });
}

export function readQuotesInstanceFragment({
  cache,
  id,
  authUserGroupId,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  authUserGroupId: string;
}): Quote | null {
  return cache.readFragment<Quote>({
    id: `QuotesInstanceNode:${id}`,
    fragment: QUOTE_FRAGMENT,
    fragmentName: 'Quote',
    variables: { authUserGroupId },
  });
}

export function readRankingsQuestionsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: RankingsQuestionsVariables;
}): RankingsQuestions | null {
  return cache.readQuery<RankingsQuestions>({
    query: RANKINGS_QUESTIONS,
    variables,
  });
}

export function writeRankingsQuestionsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: RankingsQuestionsVariables;
  data: RankingsQuestions;
}): void {
  cache.writeQuery<RankingsQuestions>({
    query: RANKINGS_QUESTIONS,
    variables,
    data,
  });
}

export function readCustomListsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CustomListsVariables;
}): CustomLists | null {
  return cache.readQuery<CustomLists>({
    query: CUSTOM_LISTS,
    variables,
  });
}

export function writeCustomListsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CustomListsVariables;
  data: CustomLists;
}): void {
  cache.writeQuery<CustomLists>({
    query: CUSTOM_LISTS,
    variables,
    data,
  });
}

export function readCustomListOptionsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CustomListOptionsVariables;
}): CustomListOptions | null {
  return cache.readQuery<CustomListOptions>({
    query: CUSTOM_LIST_OPTIONS,
    variables,
  });
}

export function writeCustomListOptionsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CustomListOptionsVariables;
  data: CustomListOptions;
}): void {
  cache.writeQuery<CustomListOptions>({
    query: CUSTOM_LIST_OPTIONS,
    variables,
    data,
  });
}

export function readCustomPagesInstanceQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CustomPagesInstancesVariables;
}): CustomPagesInstances | null {
  return cache.readQuery<CustomPagesInstances>({
    query: CUSTOM_PAGES_INSTANCES,
    variables,
  });
}

export function writeCustomPagesInstanceQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  data: CustomPagesInstances;
  variables?: CustomPagesInstancesVariables;
}): void {
  cache.writeQuery<CustomPagesInstances>({
    query: CUSTOM_PAGES_INSTANCES,
    variables,
    data,
  });
}
