import React, { useState } from 'react';
import Button from '~/components/Button';
import InputField from '~/components/InputField';
import SectionHeader from '~/components/SectionHeader';
import { ProfilePageSuggestedQuestion } from '~/data/models/yearbook';
import { ModalScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import InspirationalQuestionCell from './InspirationalQuestionCell';
import RecommendedQuestionCell from './RecommendedQuestionCell';
import {
  SectionContainer,
  RecommendedList,
  InspirationalList,
  ButtonContainer,
} from './style';

type ProfilePageAddQuestionLayoutProps = {
  text?: string;
  recommendedQuestions: ProfilePageSuggestedQuestion[];
  inspirationalQuestions: ProfilePageSuggestedQuestion[];
  hasNextPage?: boolean;
  onBack: () => void;
  onLoadMore: () => void;
  onAddQuestion: (text: string) => void;
};

export default function ProfilePageAddQuestion({
  text = '',
  recommendedQuestions,
  inspirationalQuestions,
  hasNextPage,
  onBack,
  onLoadMore,
  onAddQuestion,
}: ProfilePageAddQuestionLayoutProps): JSX.Element {
  const [question, setQuestion] = useState<string>(text);

  const renderRecommendedlItem = ({
    item: profilePageQuestion,
  }: {
    item: ProfilePageSuggestedQuestion;
    index: number;
  }) => (
    <RecommendedQuestionCell
      profilePageQuestion={profilePageQuestion}
      onPress={() => setQuestion(profilePageQuestion.text)}
    />
  );

  const renderInspirationalItem = ({
    item: profilePageQuestion,
  }: {
    item: ProfilePageSuggestedQuestion;
    index: number;
  }) => (
    <InspirationalQuestionCell
      profilePageQuestion={profilePageQuestion}
      onPress={() => setQuestion(profilePageQuestion.text)}
      onAdd={() => setQuestion(profilePageQuestion.text)}
    />
  );

  return (
    <ModalScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.profilePageAddQuestion')}
      onBackPress={onBack}
    >
      <InspirationalList<React.ElementType>
        data={inspirationalQuestions}
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={
          <>
            <SectionContainer>
              <InputField
                testID={'inputQuestion'}
                value={question}
                label={t('profilePageAddQuestion.inputQuestionLabel')}
                onChangeText={(value) => setQuestion(value)}
              />
            </SectionContainer>
            <SectionContainer>
              <SectionHeader
                title={t('profilePageAddQuestion.recommendationSection')}
                sub
              />
            </SectionContainer>
            <RecommendedList<React.ElementType>
              data={recommendedQuestions}
              horizontal
              showsHorizontalScrollIndicator={false}
              renderItem={renderRecommendedlItem}
              keyExtractor={(item: ProfilePageSuggestedQuestion) =>
                `rq${item.id}`
              }
            />
            <SectionContainer>
              <SectionHeader
                title={t('profilePageAddQuestion.inspirationSection')}
                sub
              />
            </SectionContainer>
          </>
        }
        renderItem={renderInspirationalItem}
        keyExtractor={(item: ProfilePageSuggestedQuestion) => `iq${item.id}`}
        onEndReached={() => hasNextPage && onLoadMore()}
      />
      <ButtonContainer>
        <Button
          testID={'buttonAddQuestion'}
          text={t('profilePageAddQuestion.addQuestion')}
          flex
          onPress={() => onAddQuestion(question)}
        />
      </ButtonContainer>
    </ModalScreenLayout>
  );
}
