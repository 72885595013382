import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CompositeNavigationProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Platform } from 'react-native';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import useAppLocale from '~/data/hooks/useAppLocale';
import { Group } from '~/data/models/group';
import { ADD_USER_TO_GROUP, GROUP_USERS } from '~/data/operations/group';
import { GENERATE_SHOPIFY_URL } from '~/data/operations/shop';
import { clearAuth } from '~/data/storage';
import { UserType } from '~/data/types/graphql';
import { AuthStackParamList, RootStackParamList } from '~/navigation/types';
import {
  redirectToCC,
  redirectToLayoutCreatorWithToken,
  redirectToShop,
} from '~/utils/helpers';
import LoginLayout from './layout';

type LoginNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList>,
  StackNavigationProp<AuthStackParamList, 'Login'>
>;
type LoginRouteProp = RouteProp<AuthStackParamList, 'Login'>;

export default function Login(): JSX.Element {
  const navigation = useNavigation<LoginNavProp>();
  const route = useRoute<LoginRouteProp>();
  const groupId = route.params?.g;
  const groupToken = route.params?.t;
  const hasInviteParams = groupId && groupToken;
  const hasCcParam = Boolean(route.params?.cc);
  const hasLayoutCreatorParam = route.params?.layoutcreator;
  const shopReturnUrlParam = route.params?.shopReturnUrl;
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { locale, onChangeLocale } = useAppLocale({
    onReload: () => navigation.replace('Login', { g: groupId, t: groupToken }),
  });

  const { loading: authLoading, onAuth } = useAuth();

  const [loadGroupUsers] = useLazyQuery(GROUP_USERS);

  const [addUserToGroup, { loading: addUserToGroupLoading }] =
    useMutation(ADD_USER_TO_GROUP);

  const [generateShopifyUrl] = useMutation(GENERATE_SHOPIFY_URL);

  async function onLogin(email: string, password: string) {
    try {
      setErrorMessage(undefined);

      const { authToken, refreshToken, authUser } = await onAuth(
        email,
        password,
      );

      const userId = authUser.id;

      if (Platform.OS === 'web') {
        if (hasLayoutCreatorParam) {
          redirectToLayoutCreatorWithToken({ token: authToken, refreshToken });
          return;
        }

        if (hasCcParam && authUser) {
          const { data: groupUsersData } = await loadGroupUsers({
            variables: {
              user: userId,
              first: NO_PAG_LIST_OFFSET,
            },
          });

          const groups =
            (groupUsersData?.userGroups?.edges.map(
              (edge) => edge?.node?.group,
            ) as Group[]) || null;

          redirectToCC(authUser, groups);
          return;
        }

        if (shopReturnUrlParam) {
          const { data } = await generateShopifyUrl({
            variables: {
              input: {
                returnUrl: decodeURIComponent(shopReturnUrlParam),
              },
            },
          });

          if (data?.generateShopifyUrl?.redirectUrl) {
            redirectToShop(data?.generateShopifyUrl?.redirectUrl);
            return;
          }
        }
      }

      if (hasInviteParams) {
        handleInvite(userId, groupId, groupToken);
        return;
      }
    } catch (e) {
      clearAuth();
      if (e instanceof Error) {
        Snackbar.show(e.message);
        setErrorMessage(e.message);
      }
    }
  }

  async function handleInvite(
    userId: string,
    groupId: string,
    groupToken: string,
  ) {
    try {
      await addUserToGroup({
        variables: {
          input: { groupId, userId, role: UserType.STUDENT, token: groupToken },
        },
        refetchQueries: [
          {
            query: GROUP_USERS,
            variables: {
              user: userId,
              first: NO_PAG_LIST_OFFSET,
            },
          },
        ],
      });
      navigation.navigate('InviteSuccess', { groupId });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  }

  return (
    <LoginLayout
      loading={authLoading || addUserToGroupLoading}
      selectedLocale={locale}
      errorMessage={errorMessage}
      onBack={() => navigation.goBack()}
      onForgotPassword={(email) =>
        navigation.navigate('ForgotPassword', { email })
      }
      onNext={(email, password) => onLogin(email, password)}
      onSelectedLocale={onChangeLocale}
    />
  );
}
