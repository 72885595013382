import styled from 'styled-components/native';

type PaginationContainerType = {
  noPaginationMargin?: boolean;
};

export const Container = styled.View``;

export const NotificationCellContainer = styled.View<{
  containerWidth: number;
}>`
  width: ${({ containerWidth }) => containerWidth}px;
`;

export const PaginationContainer = styled.View<PaginationContainerType>`
  align-self: start;
`;
