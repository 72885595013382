import styled from 'styled-components/native';
import Image from '~/components/Image';
import TrackingFlatlist from '~/components/TrackingFlatList';

export const Header = styled.View`
  padding-bottom: 12px;
`;

export const AdsContainer = styled.View`
  height: 240px;
`;

export const Section = styled.View`
  padding-horizontal: 16px;
`;

export const PollList = styled(TrackingFlatlist)`
  padding-top: 16px;
  padding-bottom: 32px;
  padding-left: 16px;
`;

export const AnswerAllCard = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  flex: 1;
  width: 75px;

  margin-right: 8px;
  background-color: ${({ theme }) => theme.color.brand_02};
  border-radius: 14px;
  padding-horizontal: 8px;
  align-items: center;
  justify-content: center;
`;

export const AnswerAllImage = styled(Image)`
  width: 42px;
  height: 42px;
`;

export const AnswerAllText = styled.Text`
  margin-top: 12px;
  text-align: center;
  color: ${({ theme }) => theme.color.brand_02};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 14px;
`;

export const PollListSeparator = styled.View`
  width: 8px;
`;

export const FeedList = styled.FlatList`
  background-color: ${({ theme }) => theme.color.base.c1};
`;

export const Content = styled.View`
  padding-vertical: 24px;
  align-items: center;
`;

export const EmptyContent = styled.View`
  flex: 1;
  padding-top: 70px;
  padding-horizontal: 16px;
`;

export const EmptyText = styled.Text`
  margin-top: 16px;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.color.base.c10};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 24px;
`;

export const EmptyImage = styled.Image`
  width: 128px;
  height: 130px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c7,
}))``;

export const HSpacer = styled.View`
  width: 32px;
`;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  tintColor: theme.color.base.c6,
  colors: [theme.color.base.c6],
}))``;

export const BannersWrapper = styled.TouchableOpacity`
  margin-top: -19px;
  width: 340px;
  height: 200px;
`;

export const AdsTouch = styled.TouchableOpacity`
  padding-top: 22px;
`;

export const TrackingFlatlistWrapper = styled.View`
  height: 250px;
`;
