import { LinearGradient } from 'expo-linear-gradient';
import { Animated } from 'react-native';
import Swipeable from 'react-native-swipeable';
import styled from 'styled-components/native';
import Icon from '~/components/Icon';
import Image from '~/components/Image';
import { TAB_SCREEN_CONTENT_WIDTH } from '~/data/constants';
import IconStyled from '../IconStyled';

const BASE_PADDING = 16;
const CARD_RATIO = 0.52; //180 / 343
export const CELL_CONTAINER_HEIGHT =
  (TAB_SCREEN_CONTENT_WIDTH - 2 * BASE_PADDING) * CARD_RATIO;
const BASE_CARD_PADDING = 8;
const CARD_SUB_WIDTH = (CELL_CONTAINER_HEIGHT - BASE_CARD_PADDING) / 2;
export const SWIPEABLE_BUTTON_WIDTH = 92;

export const Container = styled.View``;

const SwipeableContainer = styled(Swipeable)`
  overflow: hidden;
`;

export const AnimatedSwipeableContainer =
  Animated.createAnimatedComponent(SwipeableContainer);

export const DeleteButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  flex: 1;
  width: ${SWIPEABLE_BUTTON_WIDTH}px;
  align-items: center;
  justify-content: center;
  margin-bottom: ${BASE_PADDING}px;
  padding-right: 16px;
`;

export const DeleteIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'x-close',
  size: 16,
  color: theme.color.error,
}))``;

export const DeleteText = styled.Text`
  color: ${({ theme }) => theme.color.error};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
`;

export const Content = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  height: ${CELL_CONTAINER_HEIGHT}px;
  background-color: ${({ theme }) => theme.color.base.c6};
  border-radius: 14px;
  overflow: hidden;
  margin-horizontal: 16px;
`;

export const LNContent = styled(LinearGradient).attrs(({ theme }) => ({
  colors: ['rgba(226, 232, 240, 0)', theme.color.base.c9],
  start: { x: 0, y: 0 },
  end: { x: 0, y: 1 },
}))`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
`;

export const Footer = styled.View`
  position: absolute;
  left: 16px;
  bottom: 16px;
  right: 16px;
`;

export const Name = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c0};
  font-size: 24px;
`;

interface ICol {
  sub?: boolean;
}
export const Col = styled.View<ICol>`
  ${({ sub }) => (sub ? `width: ${CARD_SUB_WIDTH}px;` : 'flex: 1;')}
`;

export const CollSpacer = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  height: ${BASE_CARD_PADDING}px;
`;

export const Row = styled.View`
  flex: 1;
  flex-direction: row;
`;

export const RowSpacer = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  width: ${BASE_CARD_PADDING}px;
`;

export const PhotoImage = styled(Image)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c1};
`;

export const EmptyImageView = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const EmptyImageIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'image_empty',
  color: theme.color.base.c2,
  size: 120,
}))``;
