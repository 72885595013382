import { Dimensions } from 'react-native';
import styled from 'styled-components/native';
import Image from '~/components/Image';
import { IS_LANDSCAPE } from '~/data/constants';
const { width } = Dimensions.get('window');

export const CONTAINER_WIDTH = width;
const CONTAINER_PADDING = 16;
export const NEWS_ITEM_WIDTH = IS_LANDSCAPE
  ? 343
  : width - 2 * CONTAINER_PADDING;
export const NEWS_ITEM_HEIGHT = NEWS_ITEM_WIDTH * 0.57;

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const NewsCard = styled.TouchableOpacity.attrs({ activeOpacity: 0.9 })``;

interface INewsCardImage {
  heightRatio: number;
}
export const NewsCardImage = styled(Image)<INewsCardImage>`
  background-color: ${({ theme }) => theme.color.base.c1};
  width: ${NEWS_ITEM_WIDTH}px;
  height: ${({ heightRatio }) => heightRatio * NEWS_ITEM_WIDTH}px;
  border-radius: 5px;
`;

export const NewsCardSeparator = styled.View`
  width: 8px;
`;

export const PaginationContainer = styled.View`
  align-items: center;
  padding-top: 8px;
  padding-bottom: 16px;
`;
