import { Dimensions } from 'react-native';
import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import Image from '~/components/Image';
import { IS_LANDSCAPE, STACK_SCREEN_CONTENT_WIDTH } from '~/data/constants';

const { width } = Dimensions.get('window');

export const CONTAINER_WIDTH = width;

export const IMAGE_WIDTH = STACK_SCREEN_CONTENT_WIDTH;

export const IMAGE_RATIO = IS_LANDSCAPE ? 0.8 : 1.5;

export const Container = styled.View``;

export const Content = styled.View``;

export const CarouselContainer = styled.View`
  flex: 1;
`;

export const CloseButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'x-close',
  size: 24,
  color: theme.color.brand_02,
}))`
  background-color: 'rgba(224, 231, 255, 1)';
  align-self: center;
  margin-top: 32px;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
`;

export const FullImageContainer = styled.View`
  width: ${CONTAINER_WIDTH}px;
  height: ${IMAGE_WIDTH * IMAGE_RATIO}px;
  justify-content: center;
  align-items: center;
`;

export const FullImage = styled(Image).attrs({
  autoHeightFromWidth: IMAGE_WIDTH,
})`
  width: ${IMAGE_WIDTH}px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.color.base.c1};
`;

export const PaginationContainer = styled.View`
  align-items: center;
  padding-top: 24px;
  padding-bottom: 16px;
`;
