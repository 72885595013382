import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { DATA_POLICY, TERM } from '~/data/derivedConstants';
import { IMPORT_FROM_ABIHOME } from '~/data/operations/importer';
import { clearAbihomeUser, getAbihomeUser } from '~/data/storage';
import { ImporterStackParamList, RootStackParamList } from '~/navigation/types';
import OverviewLayout from './layout';

type OverviewNavProp = CompositeNavigationProp<
  StackNavigationProp<ImporterStackParamList, 'Overview'>,
  StackNavigationProp<RootStackParamList>
>;
type OverviewRouteProp = RouteProp<ImporterStackParamList, 'Overview'>;

export default function Overview(): JSX.Element {
  const navigation = useNavigation<OverviewNavProp>();
  const {
    params: {
      userCount,
      avatar,
      groupName,
      iconId,
      graduationYear,
      graduationType,
    },
  } = useRoute<OverviewRouteProp>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasSuccess, setHasSuccess] = useState<boolean>(false);

  const [importFromAbihome, { loading: importFromAbihomeLoading }] =
    useMutation(IMPORT_FROM_ABIHOME);

  const onNext = async (accepted: boolean) => {
    try {
      const abihomeUser = await getAbihomeUser();
      const response = await importFromAbihome({
        variables: {
          groupName,
          iconId,
          avatar,
          graduationYear,
          graduationType,
          email: abihomeUser?.email,
          password: abihomeUser?.password,
          approved: accepted,
        },
      });

      await clearAbihomeUser();

      if (response.data?.importAbihome?.ok) {
        setHasSuccess(true);
      } else if (response.data?.importAbihome?.error) {
        setHasError(true);
      }
    } catch (e) {
      setHasError(true);
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <OverviewLayout
      userCount={userCount}
      onBack={() => navigation.goBack()}
      onShowPolicy={() =>
        navigation.navigate('Browser', {
          title: DATA_POLICY.title,
          url: DATA_POLICY.url,
        })
      }
      onShowTerm={() =>
        navigation.navigate('Browser', {
          title: TERM.title,
          url: TERM.url,
        })
      }
      onNext={onNext}
      loading={importFromAbihomeLoading}
      onBackToBeginning={() =>
        navigation.reset({
          routes: [{ name: 'AbihomeLogin' }],
        })
      }
      hasError={hasError}
      hasSuccess={hasSuccess}
    />
  );
}
