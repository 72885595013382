import React from 'react';
import { PhotoFormat } from '~/data/types/graphql';
import { t } from '~/utils/i18n';
import { Container, Text, FormatViewContainer, FormatView } from './style';

type PhotoFormatCellProps = {
  testID?: string;
  format: PhotoFormat;
  selected: boolean;
  width?: number;
  onPress: () => void;
};

type TextMap = { [key in PhotoFormat]: string };
type FormatMap = { [key in PhotoFormat]: React.ReactNode };

export default function PhotoFormatCell({
  testID,
  format,
  selected,
  width = 140,
  onPress,
}: PhotoFormatCellProps): JSX.Element {
  const FormatPortrait = (
    <FormatView width={36} height={50} center selected={selected} />
  );
  const FormatSquare = (
    <FormatView width={50} height={50} selected={selected} />
  );
  const FormatAsUploaded = (
    <>
      <FormatView width={50} height={50} selected={selected} />
      <FormatView width={30} height={37} dark selected={selected} />
    </>
  );

  const formatMap: FormatMap = {
    PORTRAIT: FormatPortrait,
    SQUARE: FormatSquare,
    AS_UPLOADED: FormatAsUploaded,
  };

  const Format = formatMap[format];

  const textMap: TextMap = {
    PORTRAIT: t('g.format4:3'),
    SQUARE: t('g.format1:1'),
    AS_UPLOADED: t('g.formatx:x'),
  };
  const text = textMap[format];
  return (
    <Container
      testID={testID}
      width={width}
      selected={selected}
      onPress={onPress}
    >
      <FormatViewContainer>{Format}</FormatViewContainer>
      <Text selected={selected}>{text}</Text>
    </Container>
  );
}
