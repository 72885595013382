import { gql } from './../../types';

export const EVENT_ATTENDEE_FRAGMENT = gql(`
  fragment EventAttendee on EventAttendeeNode {
      id
      user {
        id
        firstName
        lastName
        avatar
        email
      }
    }
`);

export const EVENT_FRAGMENT = gql(`
  fragment Event on EventNode {
    title
    id
    location
    companyName
    companyLogo
    bannerImage
    isSponsored
    companyName
    eventAuthor
    authorUser {
      id
      firstName
      lastName 
      avatar
      email
    }
    authorGroup {
      id
      name
      avatar
    }
    authorTeam {
      id
      name
      avatar
    }
    authorType
    eventType
    description
    startDateTime
    endDateTime
    isAllDay
    isJoinable
    isPublic
    attendees {
      ...EventAttendee
    }
  }
`);

export const EXTERNAL_EVENT_FRAGMENT = gql(`
  fragment ExternalEventAttendee on ExternalEventAttendeeNode {
    id
    attendee {
      email
    }
    platform
    eventId
  }
`);

//Query

export const EVENTS = gql(`
  query Events($offset: Int, $before: String, $after: String, $first: Int) {
    events(offset: $offset, before: $before, after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Event
        }
      }
    }
  }
`);

export const EVENT = gql(`
  query Event($id: ID!) {
    event(id: $id) {
      ...Event
    }
  }
`);

//Mutations

export const CREATE_EVENT = gql(`
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      event {
        ...Event
      }

      errors {
        field
        messages
      }
    }
  }
`);

export const JOIN_EVENT = gql(`
  mutation JoinEvent($input: JoinEventInput!) {
    joinEvent(input: $input) {
      attendee {
        id
        event {
          ...Event
        }
        user {
          id
        }
      }
    }
  }
`);

export const LEAVE_EVENT = gql(`
  mutation LeaveEvent($input: LeaveEventInput!) {
    leaveEvent(input: $input) {
      success
    }
  }
`);

export const EDIT_EVENT = gql(`
  mutation EditEvent($input: EditEventInput!) {
    editEvent(input: $input) {
      event {
        ...Event
      }

      errors {
        field
        messages
      }
    }
  }
`);

export const DELETE_EVENT = gql(`
  mutation DeleteEvent($input: DeleteEventInput!) {
    deleteEvent(input: $input) {
      success
    }
  }
`);

export const SET_EVENT_BANNER = gql(`
  mutation SetEventBanner($input: SetEventBannerInput!) {
    setEventBanner(input: $input) {
      event {
        ...Event
      }
    }
  }
`);
export const SAVE_EXTERNAL_EVENT = gql(`
  mutation SaveExternalEvent($input: SaveExternalEventInput!) {
    saveExternalEvent(input: $input) {
      externalEvent {
        ...ExternalEventAttendee
      }
      success
    }
  }
`);

export const REMOVE_EXTERNAL_EVENT = gql(`
  mutation RemoveExternalEvent($input: RemoveExternalEventInput!) {
    removeExternalEvent(input: $input) {
      externalEvent {
        ...ExternalEventAttendee
      }
      success
    }
  }
`);

export const TOGGLE_EXTERNAL_EVENT_SAVE = gql(`
  mutation ToggleExternalEventSave($input: ToggleExternalEventSaveInput!) {
    toggleExternalEventSave(input: $input) {
      externalEvent {
        ...ExternalEventAttendee
      }
      success
    }
  }
`);
