import React, { useMemo } from 'react';
import { Platform } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { web_side_images } from '~/assets/imgs/web';
import Button from '~/components/Button';
import InputHeader from '~/components/InputHeader';
import LanguageDropdown from '~/components/LanguageDropdown';
import WebFooter from '~/components/WebFooter';
import WebHeader from '~/components/WebHeader';
import { IS_TABLET } from '~/data/constants';
import { t } from '~/utils/i18n';
import { AuthLayoutProps } from './../types';
import {
  ScrollContainer,
  MainContainer,
  CenterContainer,
  WrapperHeader,
  ChildrenContainer,
  WrapperContent,
  WebFormControls,
  FooterContainer,
  RightBG,
  WrapperFooter,
  LanguageAbsolutePlaceholder,
  LeftPanel,
  RightPanel,
  RightPanelImage,
  MAIN_WIDTH,
  MAIN_WIDTH_LEFT_MIN,
  MAIN_WIDTH_RIGHT_MIN,
  WRAPPER_WIDTH,
} from './style';

const getSideImage = (timestamp: number): number => {
  return web_side_images[timestamp % web_side_images.length];
};

type MediaQueries = {
  isMainCentered: boolean;
  isMainLeftFlex: boolean;
  isMainRightFlex: boolean;
  isMainFixed: boolean;
};
const getMediaQueries = (): MediaQueries => {
  if (IS_TABLET) {
    return {
      isMainCentered: true,
      isMainLeftFlex: true,
      isMainRightFlex: true,
      isMainFixed: true,
    };
  }
  return {
    isMainCentered: useMediaQuery({
      minWidth: MAIN_WIDTH,
    }),
    isMainLeftFlex: useMediaQuery({
      minWidth: MAIN_WIDTH_LEFT_MIN,
    }),
    isMainRightFlex: useMediaQuery({
      minWidth: MAIN_WIDTH_RIGHT_MIN,
    }),
    isMainFixed: useMediaQuery({
      minWidth: WRAPPER_WIDTH,
    }),
  };
};

export default function AuthWebLayout({
  headerProps,
  submitButtonProps,
  languageProps,
  onBack,
  onCustom,
  children,
  UnderActionsComponent,
  HeaderRightComponent,
  isContentCentered = true,
}: AuthLayoutProps): JSX.Element {
  //showed when landscape for iPad and web
  const isWeb = Platform.OS === 'web';
  const timestamp = useMemo(() => new Date().getTime(), []);
  const selectedLocale = languageProps?.selectedLocale;
  const onSelectedLocale = languageProps?.onSelectedLocale;

  const { isMainCentered, isMainLeftFlex, isMainRightFlex, isMainFixed } =
    getMediaQueries();

  return (
    <ScrollContainer showsVerticalScrollIndicator={false}>
      {isMainCentered && <RightBG />}
      <CenterContainer isMainCentered={isMainCentered}>
        <MainContainer isMainRightFlex={isMainRightFlex}>
          <LeftPanel
            isMainCentered={isMainCentered}
            isMainLeftFlex={isMainLeftFlex}
            isMainRightFlex={isMainRightFlex}
            isMainFixed={isMainFixed}
          >
            <WrapperHeader isMainRightFlex={isMainRightFlex}>
              <WebHeader
                selectedLocale={selectedLocale}
                onCustomPress={() => onCustom && onCustom()}
                HeaderRightComponent={HeaderRightComponent}
              />
            </WrapperHeader>
            <WrapperContent
              isMainRightFlex={isMainRightFlex}
              isMainFixed={isMainFixed}
              isContentCentered={isContentCentered}
            >
              {(() => {
                if (headerProps) {
                  const { title, descp, total, progress } = headerProps;
                  return (
                    <InputHeader
                      title={title}
                      descp={descp}
                      total={total}
                      progress={progress}
                    />
                  );
                }
                return null;
              })()}
              <ChildrenContainer>{children}</ChildrenContainer>
              {(() => {
                if (submitButtonProps) {
                  const {
                    isValidInput,
                    loading,
                    text,
                    size,
                    flex,
                    minWidth,
                    onPress,
                  } = submitButtonProps;
                  return (
                    <WebFormControls>
                      {onBack && (
                        <Button
                          type="secondary-base"
                          testID={'buttonBack'}
                          onPress={onBack}
                          text={t('goBack')}
                          size={size}
                          minWidth={140}
                        />
                      )}
                      <Button
                        testID={'buttonNext'}
                        state={isValidInput() ? 'default' : 'disabled'}
                        text={text}
                        loading={loading}
                        onPress={onPress}
                        flex={flex}
                        minWidth={minWidth}
                        size={size}
                      />
                    </WebFormControls>
                  );
                }
                return null;
              })()}
              {(() => {
                return UnderActionsComponent ? UnderActionsComponent : null;
              })()}
            </WrapperContent>
            {isWeb && selectedLocale && onSelectedLocale && (
              <WrapperFooter>
                <LanguageAbsolutePlaceholder>
                  <LanguageDropdown
                    testID={'languageDropdown'}
                    selectedLocale={selectedLocale}
                    onSelectedLocale={onSelectedLocale}
                  />
                </LanguageAbsolutePlaceholder>
              </WrapperFooter>
            )}
          </LeftPanel>
          {isMainRightFlex && (
            <RightPanel isMainLeftFlex={isMainLeftFlex}>
              <RightPanelImage
                source={getSideImage(timestamp)}
                resizeMode={'cover'}
              />
            </RightPanel>
          )}
        </MainContainer>
        {isWeb && (
          <FooterContainer>
            <WebFooter isCompact={!isMainLeftFlex} />
          </FooterContainer>
        )}
      </CenterContainer>
    </ScrollContainer>
  );
}
