import React from 'react';
import { format } from 'date-fns-tz';
import { useTheme } from 'styled-components/native';
import Avatar from '~/components/Avatar';
import Button from '~/components/Button';
import EventDate from '~/components/EventDate';
import IconStyled from '~/components/IconStyled';
import { Spacer } from '~/components/common/style.web';
import { Event } from '~/data/models/calendar';
import { TabScreenLayout } from '~/screens/style';
import { getAvatarAndName } from '~/utils/helpers';
import { t } from '~/utils/i18n';
import {
  AboutEvent,
  Desc,
  HrLine,
  IconList,
  DetailsWrapper,
  SubTitle,
  Title,
  EventImage,
  ImageBox,
  TitleAndDate,
  TitleWrapper,
  RowWrapper,
  LocationText,
  AttendeesText,
  ScrollView,
  Loading,
  AvatarsGroup,
  CreateEventContainer,
  UserIsJoinedText,
} from './style';

type EventDetailLayoutProps = {
  onBack: () => void;
  event?: Event;
  isEditEnabled?: boolean;
  onAttendeesList: (event: Event) => void;
  onJoinEvent: (event: Event) => void;
  navigateToEditEvent: () => void;
  isUserJoined: boolean;
};

export default function EventDetail({
  onBack,
  onJoinEvent,
  event,
  navigateToEditEvent,
  onAttendeesList,
  isEditEnabled,
  isUserJoined,
}: EventDetailLayoutProps): JSX.Element {
  const theme = useTheme();
  const { authorName, avatar } = getAvatarAndName(event as Event);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const formattedDate = format(date, "do 'of' MMMM, HH:mm", {
      timeZone: 'UTC',
    });
    return formattedDate;
  };

  if (!event) {
    return <Loading testID="spinner" />;
  }

  function renderAttendeeAvatars() {
    const attendees = event?.attendees;

    return attendees?.map((attendee, index) => (
      <Avatar
        key={`attendee-avatar-${index}`}
        uri={attendee?.user?.avatar}
        size={16}
      />
    ));
  }

  const {
    title = '',
    bannerImage = '',
    location = '',
    startDateTime = '',
    attendees = [],
    description = '',
    isSponsored = false,
    eventType = '',
  } = event;

  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.event')}
      RightComponent={
        isEditEnabled ? (
          <Button
            testID={'editEvent'}
            text={t('g.editEvent')}
            minWidth={80}
            size={'sm'}
            onPress={navigateToEditEvent}
            type={'secondary-base'}
          />
        ) : isUserJoined ? (
          <UserIsJoinedText onPress={() => onAttendeesList(event as Event)}>
            {t('g.userAttendingEvent')}
          </UserIsJoinedText>
        ) : (
          <Button
            testID={'buttonSetup'}
            text={t('g.join')}
            minWidth={80}
            size={'sm'}
            onPress={() => onJoinEvent(event)}
            type={'secondary-base'}
          />
        )
      }
      onBackPress={onBack}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <EventImage
          source={{ uri: bannerImage as string }}
          autoHeightMax={200}
          autoHeightFromWidth={800}
        />
        <DetailsWrapper>
          <ImageBox
            source={{
              uri: avatar,
            }}
          />
          <TitleAndDate>
            <TitleWrapper>
              <Title>{title}</Title>
              <SubTitle>
                {' '}
                {isSponsored && t('cardEvent.sponsored')}
                {t('cardEvent.createdBy', { by: authorName })}
              </SubTitle>
            </TitleWrapper>
            <EventDate
              date={
                event?.startDateTime
                  ? new Date(event.startDateTime)
                  : new Date()
              }
            />
          </TitleAndDate>
        </DetailsWrapper>
        <IconList>
          <RowWrapper>
            <IconStyled name="location_dark" size={16} />
            <LocationText>{location}</LocationText>
          </RowWrapper>
          <AvatarsGroup>{renderAttendeeAvatars()}</AvatarsGroup>
        </IconList>
        <IconList>
          <RowWrapper>
            <IconStyled
              name="icn/teams/clock"
              size={16}
              color={theme.color.base.c5}
            />
            <LocationText>
              {startDateTime && formatDate(startDateTime)}
            </LocationText>
          </RowWrapper>
          <AttendeesText
            onPress={
              isUserJoined ? () => onAttendeesList(event as Event) : undefined
            }
          >
            {!attendees
              ? '-'
              : t('g.attendees', { attendeesCount: attendees.length })}{' '}
          </AttendeesText>
        </IconList>
        <HrLine />
        <AboutEvent>
          <Title>{t('g.aboutEventDetails')}</Title>
          <Desc>{description}</Desc>
          <Spacer v={15} />
        </AboutEvent>
        <Spacer v={300} />
      </ScrollView>
      {!isUserJoined && !isEditEnabled ? (
        <CreateEventContainer>
          <Button
            testID={'buttonJoinEvent'}
            text={t(`cardEvent.button${eventType}`)}
            size={'lg'}
            type={'primary-brand-01'}
            iconName={'plus'}
            onPress={() => onJoinEvent(event)}
          />
        </CreateEventContainer>
      ) : null}
    </TabScreenLayout>
  );
}
