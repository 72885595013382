import { gql } from './../../types';

export const COMMENT_FRAGMENT = gql(`
  fragment Comment on CommentNode {
    id
    text
    owner
    userGroup {
      ...UserGroup
    }
    team {
      ...Team
    }
    likes(userGroup: $authUserGroupId) {
      edges {
        node {
          userGroup {
            id
          }
        }
      }
    }
    likesCount
    created
  }
`);

export const BASE_POST_FRAGMENT = gql(`
  fragment BasePost on PostNode {
    id
    owner
    text
    createdBy {
      ...UserGroup
    }
    images
    likesCount
    team {
      ...Team
    }
    comments {
      ...Comment
    }
    created
  }
`);

export const POLL_VOTE_FRAGMENT = gql(`
  fragment PollVote on PollVoteNode {
    id
    userGroup {
      user {
        id
      }
    }
  }
`);

export const POLL_OPTION_FRAGMENT = gql(`
  fragment PollOption on PollOptionNode {
    id
    text
    image
    votes {
      ...PollVote
    }
    percentage
    votesNumber
  }
`);

export const BASE_POLL_FRAGMENT = gql(`
  fragment BasePoll on PollNode {
    id
    question
    deadline
    answersAllowed
    randomizeOptions
    allowRevote
    allowComments
    anonymousVotes
    privateResults
    options {
      ...PollOption
    }
    created
  }
`);

export const POST_FRAGMENT = gql(`
  fragment Post on PostNode {
    ...BasePost
    poll {
      ...BasePoll
    }
    likes(userGroup: $authUserGroupId) {
      edges {
        node {
          userGroup {
            id
          }
        }
      }
    }
  }
`);

export const POLL_FRAGMENT = gql(`
  fragment Poll on PollNode {
    ...BasePoll
    post {
      ...BasePost
      likes(userGroup: $authUserGroupId) {
        edges {
          node {
            userGroup {
              id
            }
          }
        }
      }
    }
  }
`);

export const POSTS = gql(`
  query Posts(
    $after: String
    $first: Int!
    $owner: Owner
    $createdBy: ID
    $team: ID
    $text: String
    $createdBy_Group: ID
    $authUserGroupId: ID!
  ) {
    posts(
      after: $after
      first: $first
      owner: $owner
      createdBy: $createdBy
      team: $team
      text: $text
      createdBy_Group: $createdBy_Group
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Post
        }
      }
    }
  }
`);

export const POST = gql(`
  query Post($id: ID!, $authUserGroupId: ID!) {
    post(id: $id) {
      ...Post
    }
  }
`);

export const CREATE_POST = gql(`
  mutation CreatePost($input: CreatePostInput!, $authUserGroupId: ID!) {
    createPost(input: $input) {
      post {
        ...Post
      }
      errors {
        field
        messages
      }
    }
  }
`);

export const DELETE_POST = gql(`
  mutation DeletePost($input: DeletePostInput!, $authUserGroupId: ID!) {
    deletePost(input: $input) {
      post {
        ...Post
      }
    }
  }
`);

export const REPORT_POST_CONTENT = gql(`
  mutation ReportPostContent($input: ReportPostContentInput!) {
    reportPostContent(input: $input) {
      success
      message
    }
  }
`);

export const BLOCK_USER = gql(`
  mutation BlockUser($input: BlockUserMutationInput!) {
    blockUser(input: $input) {
      success
      message
    }
  }
`);

export const REPORT_MUTATION = gql(`
  mutation ReportMutation($input: ReportMutationInput!) {
    reportMutation(input: $input) {
      success
      message
    }
  }
`);

export const ADD_IMAGES_TO_POST = gql(`
  mutation AddImagesToPost(
    $input: AddImagesToPostInput!
    $authUserGroupId: ID!
  ) {
    addImagesToPost(input: $input) {
      post {
        ...Post
      }
    }
  }
`);

export const POLLS = gql(`
  query Polls(
    $after: String
    $first: Int!
    $post_Team: ID
    $post_CreatedBy_Group: ID
    $authUserGroupId: ID!
  ) {
    polls(
      after: $after
      first: $first
      post_Team: $post_Team
      post_CreatedBy_Group: $post_CreatedBy_Group
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Poll
        }
      }
    }
  }
`);

// export const POLL = gql(`
//   ${POLL_FRAGMENT}
//   query Poll($id: ID!) {
//     poll(id: $id) {
//       ...Poll
//     }
//   }
// `);

export const CREATE_POLL = gql(`
  mutation CreatePoll($input: CreatePollInput!, $authUserGroupId: ID!) {
    createPoll(input: $input) {
      poll {
        ...Poll
      }
      errors {
        field
        messages
      }
    }
  }
`);

export const DELETE_POLL = gql(`
  mutation DeletePoll($input: DeletePollInput!, $authUserGroupId: ID!) {
    deletePoll(input: $input) {
      poll {
        ...Poll
      }
    }
  }
`);

export const CREATE_POLL_OPTION = gql(`
  mutation CreatePollOption($input: CreatePollOptionInput!) {
    createPollOption(input: $input) {
      pollOption {
        ...PollOption
      }
    }
  }
`);

export const CREATE_POLL_VOTE = gql(`
  mutation CreatePollVote($input: CreatePollVoteInput!) {
    createPollVote(input: $input) {
      pollVote {
        ...PollVote
      }
    }
  }
`);

export const UPDATE_POLL = gql(`
  mutation UpdatePoll($input: UpdatePollInput!) {
    updatePoll(input: $input) {
      poll {
        ...BasePoll
      }
      errors {
        field
        messages
      }
    }
  }
`);

export const DELETE_POLL_VOTE = gql(`
  mutation DeletePollVote($input: DeletePollVoteInput!) {
    deletePollVote(input: $input) {
      pollVote {
        ...PollVote
      }
    }
  }
`);

export const LIKE_POST = gql(`
  mutation LikePost($input: LikePostInput!, $authUserGroupId: ID!) {
    likePost(input: $input) {
      post {
        ...Post
      }
    }
  }
`);

export const REMOVE_LIKE_FROM_POST = gql(`
  mutation RemoveLikeFromPost(
    $input: RemoveLikeFromPostInput!
    $authUserGroupId: ID!
  ) {
    removeLikeFromPost(input: $input) {
      post {
        ...Post
      }
    }
  }
`);

export const COMMENT_POST = gql(`
  mutation CommentPost($input: CommentPostInput!, $authUserGroupId: ID!) {
    commentPost(input: $input) {
      post {
        ...Post
      }
    }
  }
`);

export const LIKE_COMMENT = gql(`
  mutation LikeComment($input: LikeCommentInput!, $authUserGroupId: ID!) {
    likeComment(input: $input) {
      post {
        ...Post
      }
    }
  }
`);

export const REMOVE_LIKE_FROM_COMMENT = gql(`
  mutation RemoveLikeFromComment(
    $input: RemoveLikeFromCommentInput!
    $authUserGroupId: ID!
  ) {
    removeLikeFromComment(input: $input) {
      post {
        ...Post
      }
    }
  }
`);

export const DELETE_COMMENT = gql(`
  mutation DeleteComment($input: DeleteCommentInput!, $authUserGroupId: ID!) {
    deleteComment(input: $input) {
      post {
        ...Post
      }
    }
  }
`);
