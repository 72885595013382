import React from 'react';
import CardOptionCell from '~/components/CardOptionCell';
import CustomIconPicker from '~/components/CustomIconPicker';
import SectionHeader from '~/components/SectionHeader';
import { DefaultAvatar } from '~/data/models/custom';
import { UserGroup } from '~/data/models/group';
import { UsersUserGenderChoices, UserType } from '~/data/types/graphql';
import { t } from '~/utils/i18n';
import {
  Container,
  ImageView,
  TopTitle,
  ProfileHeader,
  Content,
  Title,
  ContentText,
  SectionView,
  SectionRow,
  TopImage,
} from './style';

type InfoPageKey =
  | 'core-1'
  | 'core-2'
  | 'core-3'
  | 'core-5-notification'
  | 'core-6';

type SetupPageKey = 'core-4-profile';

export type PageKey = InfoPageKey | SetupPageKey;

type InfoPageItem = {
  pageType: 'info';
  imgSrc: number;
  headerTitle?: string;
  title: string;
  content: string;
  FooterComponent: JSX.Element;
};

type SetupPageItem = {
  pageType: 'setup';
  headerTitle: string;
  FooterComponent: JSX.Element;
};

export type PageItemMap = {
  [key in InfoPageKey]: InfoPageItem;
} & {
  [key in SetupPageKey]: SetupPageItem;
};

interface InfoPageProps {
  testID?: string;
  pageItem: InfoPageItem;
}

export function InfoPage({ pageItem }: InfoPageProps): JSX.Element {
  const { imgSrc, headerTitle, title, content, FooterComponent } = pageItem;
  return (
    <Container>
      <ImageView>
        <TopImage source={imgSrc} />
      </ImageView>
      <Content>
        <Title>{title}</Title>
        <ContentText>{content}</ContentText>
        {FooterComponent}
      </Content>
      {headerTitle ? <TopTitle>{headerTitle}</TopTitle> : null}
    </Container>
  );
}

interface ProfilePageProps {
  testID?: string;
  pageItem: SetupPageItem;
  userGroup?: UserGroup | null;
  avatarUri: string | null;
  defaultAvatars: DefaultAvatar[];
  uploadingImage: boolean;
  gender?: UsersUserGenderChoices | null;
  role?: UserType | null;
  onUpdateAvatar: (uri: string | null, iconId: string | null) => void;
  onSelectedGender: (gender: UsersUserGenderChoices) => void;
  onSelectedRole: (role: UserType) => void;
}

export function ProfilePage({
  testID,
  pageItem,
  userGroup,
  avatarUri,
  defaultAvatars,
  uploadingImage,
  gender,
  role,
  onUpdateAvatar,
  onSelectedGender,
  onSelectedRole,
}: ProfilePageProps): JSX.Element {
  const { headerTitle, FooterComponent } = pageItem;

  return (
    <Container testID={testID}>
      <ProfileHeader>
        <CustomIconPicker
          testID={'customIconPicker'}
          modalTitle={t('g.setProfileImage')}
          customIcons={defaultAvatars.map((defaultAvatar) => ({
            id: defaultAvatar.id,
            uri: defaultAvatar.avatar,
          }))}
          selectedIcon={
            userGroup?.defaultAvatar
              ? {
                  id: userGroup.defaultAvatar.id,
                  uri: userGroup.defaultAvatar.avatar,
                }
              : null
          }
          selectedUri={!userGroup?.defaultAvatar ? avatarUri : null}
          loading={uploadingImage}
          center
          onSelect={({ imgUrl, customIcon }) =>
            onUpdateAvatar(imgUrl, customIcon?.id || null)
          }
        />
      </ProfileHeader>
      <SectionView>
        <SectionHeader title={t('onboarding.yourGender')} sub />
      </SectionView>
      <SectionRow>
        <CardOptionCell
          testID={'femaleCardOption'}
          text={t('g.female')}
          iconStyledName={'gender_women'}
          isSelected={gender === UsersUserGenderChoices.FEMALE}
          onPress={() => onSelectedGender(UsersUserGenderChoices.FEMALE)}
        />
        <CardOptionCell
          testID={'maleCardOption'}
          text={t('g.male')}
          iconStyledName={'gender_men'}
          isSelected={gender === UsersUserGenderChoices.MALE}
          onPress={() => onSelectedGender(UsersUserGenderChoices.MALE)}
        />
        <CardOptionCell
          testID={'diversCardOption'}
          text={t('g.divers')}
          iconStyledName={'gender_transgender'}
          isSelected={gender === UsersUserGenderChoices.OTHER}
          onPress={() => onSelectedGender(UsersUserGenderChoices.OTHER)}
        />
      </SectionRow>
      <SectionView>
        <SectionHeader title={t('onboarding.yourRole')} sub />
      </SectionView>
      <SectionRow>
        <CardOptionCell
          testID={'studentCardOption'}
          text={t('g.student')}
          iconName={'graduation-hat-02'}
          isSelected={role === UserType.STUDENT}
          onPress={() => onSelectedRole(UserType.STUDENT)}
        />
        <CardOptionCell
          testID={'teacherCardOption'}
          text={t('g.instituteStaff')}
          iconName={'briefcase-02'}
          isSelected={role === UserType.TEACHER}
          onPress={() => onSelectedRole(UserType.TEACHER)}
        />
      </SectionRow>
      <Content>{FooterComponent}</Content>
      {headerTitle ? <TopTitle>{headerTitle}</TopTitle> : null}
    </Container>
  );
}
