import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';

export const ScroolView = styled(KeyboardAwareScrollView)`
  padding-top: 24px;
  padding-horizontal: 16px;
`;

export const QuestionTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.base.c9};
`;

export const InputAreaFieldContainer = styled.View`
  padding-vertical: 16px;
`;

export const ButtonContent = styled.View`
  padding-bottom: 24px;
`;

export const ActionButtonsWrapper = styled.View`
  flex-direction: 'column';
  gap: 8px;
`;
