import React, { useRef, useState } from 'react';
import Tooltip from 'rn-tooltip';
import { useTheme } from 'styled-components/native';
import Avatar from '~/components/Avatar';
import LikeButton from '~/components/LikeButton';
import ModalFullImage, {
  ModalFullImageHandler,
} from '~/components/ModalFullImage';
import PollCell from '~/components/PollCell';
import TeamIcon from '~/components/TeamIcon';
import { mapUser } from '~/data/mappers';
import { PollVoteOption, Post } from '~/data/models/post';
import { Owner } from '~/data/types/graphql';
import { isPostLiked } from '~/data/utils';
import { formattedDate, formattedRelativeDate } from '~/utils/dates';
import { t } from '~/utils/i18n';
import Shimmer from '../Shimmer';
import {
  Container,
  Header,
  HeaderContent,
  OptionsIcon,
  TeamTitle,
  Created,
  Content,
  Text,
  ImageContainer,
  Image,
  CountInfoWrapper,
  CountInfo,
  CountValue,
  CountRow,
  ClockIcon,
  Footer,
  FooterCol,
  ActionButton,
  ActionButtonText,
  PollContent,
  TooltipButton,
  TooltipButtonText,
  CommentStyledIcon,
  ShareStyledIcon,
  TOOLTIP_BUTTON_HEIGHT,
  ScalableImage,
  TouchableScalableImage,
  TouchableImage,
  DeadlineRow,
} from './style';

export type PostCellActionProps = {
  onPollVote?: (postId: string, optionId: string, revoteId?: string) => void;
  onLike?: (postId: string, liked: boolean) => void;
  onComment?: (postId: string) => void;
  onShare?: () => void;
  onReportPost: (postId: string) => void;
  onReportUser: (userGroupId: string, teamId?: string) => void;
  onBlockUser: (userGroupId: string, teamId?: string) => void;
  onHidePost: (postId: string) => void;
  onDelete?: (postId: string, pollId?: string) => void;
  onEdit?: (postId: string) => void;
};

type ActionInfo = {
  text: string;
  enabled: boolean;
  onPress: () => void;
};

type postCellView = 'default' | 'comments';

export type PostCellProps = {
  testID?: string;
  post?: Post;
  view?: postCellView;
  pollVotedOptions?: PollVoteOption[];
  shareEnabled?: boolean;
  reportEnabled?: boolean;
  reportUserEnabled?: boolean;
  blockEnabled?: boolean;
  hideEnabled?: boolean;
  isPostOwner?: boolean;
  authTeamIds: string[];
  now?: Date;
} & PostCellActionProps;

export default function PostCell({
  testID,
  post,
  view = 'default',
  pollVotedOptions,
  shareEnabled, //mvp-disable
  reportEnabled = true,
  isPostOwner,
  authTeamIds,
  reportUserEnabled = true,
  blockEnabled = true,
  hideEnabled = true,
  now = new Date(),
  onPollVote,
  onLike,
  onComment,
  onShare,
  onReportPost,
  onReportUser,
  onBlockUser,
  onHidePost,
  onDelete,
  onEdit,
}: PostCellProps): JSX.Element {
  if (!post) {
    return (
      <Container>
        <Header>
          <Shimmer w={40} h={40} br={20} g={'g3'} />
          <HeaderContent>
            <Shimmer w={140} h={16} br={8} g={'g3'} />
            <Shimmer w={140} h={10} br={5} mt={4} g={'g2'} />
          </HeaderContent>
        </Header>
        <Content>
          <Shimmer w={300} h={16} br={8} g={'g2'} mt={24} />
          <Shimmer w={300} h={16} br={8} g={'g2'} mt={4} />
          <Shimmer w={300} h={16} br={8} g={'g2'} mt={4} />
          <Shimmer w={300} h={16} br={8} g={'g2'} mt={4} />
          <Shimmer w={100} h={16} br={8} g={'g2'} mt={4} />
          <CountRow>
            <Shimmer w={100} h={10} br={5} g={'g2'} mt={24} />
            <Shimmer w={100} h={10} br={5} g={'g2'} mt={24} ml={8} />
          </CountRow>
        </Content>
        <Footer>
          <FooterCol>
            <Shimmer w={80} h={24} br={4} g={'g3'} />
          </FooterCol>
          <FooterCol rightBorder={!!shareEnabled}>
            <Shimmer w={80} h={24} br={4} g={'g3'} />
          </FooterCol>
        </Footer>
      </Container>
    );
  }
  const modalizeFullImageRef = useRef<ModalFullImageHandler>(null);
  const optionsTooltip = useRef<Tooltip>(null);
  const theme = useTheme();

  const [liked, setLiked] = useState(() => isPostLiked(post));
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);
  const { poll } = post;
  const teamColor = post.team?.color?.hex;
  const iconName = post.team?.icon?.icon;
  const likeCount = post.likesCount || 0;
  const commentCount = post.comments?.length || 0;
  const mappedUser = mapUser(post.createdBy?.user);
  const ownerName =
    post.owner === Owner.USER ? mappedUser?.name : post.team?.name;
  const imagesCount = post.images?.length || 0;
  const isTeamOwner = post.owner === Owner.TEAM;
  const isCommentEnabled = !post.poll || post.poll.allowComments;

  const actionInfos: ActionInfo[] = [
    {
      text: t('g.reportPost'),
      enabled: reportEnabled,
      onPress: () => onReportPost(post.id),
    },
    {
      text: isTeamOwner ? t('g.reportTeam') : t('g.reportUser'),
      enabled: reportUserEnabled,
      onPress: () =>
        post.createdBy &&
        onReportUser(
          post.createdBy.id,
          isTeamOwner ? post.team?.id : undefined,
        ),
    },
    {
      text: isTeamOwner ? t('g.blockTeam') : t('g.blockUser'),
      enabled: blockEnabled,
      onPress: () =>
        post.createdBy &&
        onBlockUser(post.createdBy.id, isTeamOwner ? post.team?.id : undefined),
    },
    {
      text: t('g.hidePost'),
      enabled: hideEnabled,
      onPress: () => onHidePost(post.id),
    },
    {
      text: t('g.editPoll'),
      enabled: !!isPostOwner && Boolean(poll && poll.id),
      onPress: () => poll && poll.id && post.id && onEdit && onEdit(post.id),
    },
    {
      text: t('g.delete'),
      enabled: !!isPostOwner,
      onPress: () => onDelete && onDelete(post.id, poll?.id || undefined),
    },
  ].filter((actionInfo) => actionInfo.enabled);

  const actionsCount = actionInfos.length;

  return (
    <Container testID={`${testID}:PostCell:${post.id}`}>
      <Header>
        {post.owner === Owner.USER ? (
          <Avatar size={40} userGroup={post.createdBy} />
        ) : (
          <TeamIcon
            teamColor={teamColor}
            iconName={iconName}
            size={40}
            borderRadius={5}
            defaultBg
          />
        )}
        <HeaderContent>
          <TeamTitle>{ownerName || ''}</TeamTitle>
          <Created>{formattedRelativeDate(post.created)}</Created>
        </HeaderContent>
        {reportEnabled || blockEnabled || isPostOwner ? (
          <Tooltip
            width={172}
            ref={optionsTooltip}
            containerStyle={{
              alignItems: undefined,
              justifyContent: undefined,
              marginTop: -30,
              backgroundColor: theme.color.base.c0,
              padding: 0,
            }}
            height={TOOLTIP_BUTTON_HEIGHT * actionsCount}
            overlayColor={'rgba(0, 0, 0, 0.2)'}
            withPointer={false}
            actionType={'press'}
            popover={
              <>
                {actionInfos.map((actionInfo, i) => (
                  <TooltipButton
                    key={`actionInfo${i}`}
                    onPress={() => {
                      actionInfo.onPress();
                      optionsTooltip.current?.toggleTooltip();
                    }}
                    hasBorderBottom={i < actionInfos.length - 1}
                  >
                    <TooltipButtonText>{actionInfo.text}</TooltipButtonText>
                  </TooltipButton>
                ))}
              </>
            }
          >
            <OptionsIcon />
          </Tooltip>
        ) : null}
      </Header>
      <Content>
        {(() => {
          if (poll) {
            return (
              <PollContent>
                <PollCell
                  now={now}
                  poll={poll}
                  votedOptions={pollVotedOptions}
                  isPostOwner={isPostOwner}
                  ownerTeamId={
                    post.owner === Owner.TEAM ? post.team?.id : undefined
                  }
                  authTeamIds={authTeamIds}
                  onVote={(optionId, revoteId) =>
                    onPollVote && onPollVote(post.id, optionId, revoteId)
                  }
                />
              </PollContent>
            );
          }
          const firstImageUri = post.images?.[0];
          return (
            <>
              <Text>{post.text}</Text>
              <ImageContainer>
                {post.images?.length === 1 && firstImageUri ? (
                  <TouchableScalableImage
                    onPress={() => modalizeFullImageRef.current?.open()}
                  >
                    <ScalableImage source={{ uri: firstImageUri }} />
                  </TouchableScalableImage>
                ) : (
                  post.images?.map((imgUri, i) =>
                    imgUri ? (
                      <TouchableImage
                        key={`img${i}`}
                        last={i === imagesCount - 1}
                        onPress={() => {
                          setSelectedImgIndex(i);
                          modalizeFullImageRef.current?.open();
                        }}
                      >
                        <Image source={{ uri: imgUri }} />
                      </TouchableImage>
                    ) : null,
                  )
                )}
              </ImageContainer>
            </>
          );
        })()}
        {poll && view === 'comments' ? (
          <DeadlineRow>
            <ClockIcon />
            <CountInfo>{formattedDate(poll.deadline, `dd.MM.yyyy`)}</CountInfo>
          </DeadlineRow>
        ) : null}
        <CountRow>
          {view === 'comments' && (
            <LikeButton
              id={post.id}
              liked={liked}
              onLike={(id, liked) => {
                if (onLike) {
                  setLiked(!liked);
                  onLike(id, liked);
                }
              }}
            />
          )}
          <CountInfo liked={liked} view={view}>
            <CountValue liked={liked}>{likeCount}</CountValue>
            {` ${t('post.likes')}`}
          </CountInfo>
          <CountInfoWrapper onPress={() => onComment && onComment(post.id)}>
            <CountInfo>
              <CountValue>{commentCount}</CountValue>
              {` ${t('post.comments')}`}
            </CountInfo>
          </CountInfoWrapper>
          {poll && view === 'default' ? (
            <>
              <ClockIcon />
              <CountInfo>
                {formattedDate(poll.deadline, `dd.MM.yyyy`)}
              </CountInfo>
            </>
          ) : null}
        </CountRow>
      </Content>
      {view === 'default' && (
        <Footer>
          <FooterCol>
            <LikeButton
              id={post.id}
              liked={liked}
              onLike={(id, liked) => {
                if (onLike) {
                  setLiked(!liked);
                  onLike(id, liked);
                }
              }}
            />
          </FooterCol>
          <FooterCol rightBorder={!!shareEnabled}>
            <ActionButton
              testID={`${testID}:PostCell:${post.id}:buttonCreateComment`}
              onPress={() => onComment && onComment(post.id)}
              disabled={!isCommentEnabled}
            >
              <CommentStyledIcon />
              <ActionButtonText>{t('post.comment')}</ActionButtonText>
            </ActionButton>
          </FooterCol>
          {shareEnabled ? (
            <FooterCol rightBorder={false}>
              <ActionButton onPress={() => onShare && onShare()}>
                <ShareStyledIcon />
                <ActionButtonText>{t('post.share')}</ActionButtonText>
              </ActionButton>
            </FooterCol>
          ) : null}
        </Footer>
      )}
      <ModalFullImage
        testID={'modalFullImage'}
        ref={modalizeFullImageRef}
        imgUrls={(post.images as string[]) || []}
        selectedImgIndex={selectedImgIndex}
        onClose={() => {
          modalizeFullImageRef.current?.close();
        }}
      />
    </Container>
  );
}
