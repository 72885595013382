import React, { useEffect, useRef, useState } from 'react';
import Button from '~/components/Button';
import ModalDatePicker, {
  ModalDatePickerHandler,
} from '~/components/ModalDatePicker';
import ModalTimePicker, {
  ModalTimePickerHandler,
} from '~/components/ModalTimePicker';
import SectionHeader from '~/components/SectionHeader';
import { SettingLabelItem, SettingPanel } from '~/components/SettingPanel';
import { CalendarDateObject } from '~/data/models/calendar';
import { PostQuery } from '~/data/types/graphql';
import { TabScreenLayout } from '~/screens/style';
import { calendarDateObject, formattedDate } from '~/utils/dates';
import { t } from '~/utils/i18n';
import { Loading, ErrorMessage, Footer, ScrollView } from './style';

type EditPollLayoutProps = {
  onBack: () => void;
  loading: boolean;
  errorMessage: string | undefined;
  post?: PostQuery;
  onUpdatePoll: (
    dueDate: CalendarDateObject | null,
    dueTime: Date | undefined,
  ) => void;
};

export default function EditPoll({
  onBack,
  loading,
  errorMessage,
  post,
  onUpdatePoll,
}: EditPollLayoutProps): JSX.Element {
  const dateModalizeRef = useRef<ModalDatePickerHandler>(null);
  const timeModalizeRef = useRef<ModalTimePickerHandler>(null);
  const [dueDate, setDueDate] = useState<CalendarDateObject | null>(null);
  const [dueTime, setDueTime] = useState<Date | undefined>(undefined);

  useEffect(() => {
    const deadline = post?.post?.poll?.deadline;
    if (deadline) {
      const dateString = deadline.split('T')[0];
      const calenderObj = calendarDateObject(dateString);
      setDueDate(calenderObj);
      setDueTime(deadline);
    }
  }, [post?.post?.poll?.deadline]);

  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.editPoll')}
      onBackPress={onBack}
      edges={['right', 'left', 'bottom']}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        {loading && <Loading />}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {!loading && !errorMessage && post && post.post?.poll?.deadline && (
          <>
            <SectionHeader sub title={t('editPoll.deadlineSection')} />
            <SettingPanel>
              <SettingLabelItem
                testID={'buttonDueDate'}
                text={t('editPoll.dueDate')}
                labelText={
                  dueDate ? formattedDate(dueDate.dateString, 'MMM d yyyy') : ''
                }
                onLabelPress={() => dateModalizeRef.current?.open()}
              />
              <SettingLabelItem
                testID={'buttonDueTime'}
                text={t('editPoll.dueTime')}
                labelText={dueTime ? formattedDate(dueTime, 'hh:mm a') : ''}
                onLabelPress={() => timeModalizeRef.current?.open()}
              />
            </SettingPanel>
          </>
        )}
      </ScrollView>
      <Footer>
        <Button
          testID={'buttonUpdatePoll'}
          state={'default'}
          text={t('editPoll.save')}
          loading={loading}
          flex
          onPress={() => onUpdatePoll(dueDate, dueTime)}
        />
      </Footer>
      <ModalDatePicker
        testID={'datePicker'}
        ref={dateModalizeRef}
        title={t('editPoll.deadlineTime')}
        buttonText={t('editPoll.confirmDate')}
        date={dueDate}
        onConfirmDate={(dateObj) => {
          setDueDate(dateObj);
          dateModalizeRef.current?.close();
        }}
      />
      <ModalTimePicker
        testID={'timePicker'}
        ref={timeModalizeRef}
        title={t('editPoll.deadlineTime')}
        buttonText={t('editPoll.confirmTime')}
        time={dueTime}
        onConfirmTime={(time) => {
          setDueTime(time);
          timeModalizeRef.current?.close();
        }}
      />
    </TabScreenLayout>
  );
}
