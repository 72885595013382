import React, { useState, useRef } from 'react';
import { useTheme } from 'styled-components/native';
import Button from '~/components/Button';
import CustomIconPicker from '~/components/CustomIconPicker';
import InputField, { InputFieldHandle } from '~/components/InputField';
import SchoolTypePicker from '~/components/SchoolTypePicker';
import YearSelector from '~/components/YearSelector';
import { GroupIcon } from '~/data/models/custom';
import { GraduationType } from '~/data/models/group';
import { StackScreenLayout } from '~/screens/style';
import { Spacer } from '~/theme/wrappers';
import { t } from '~/utils/i18n';
import { ScrollView, InputContent, Footer, ColorBox, Content } from './style';
// TODO: add web support like in src/screens/Importer/SetupGroup/layout/index.tsx

export type CreateGroupInput = {
  name: string;
  typeId: string;
  year: string;
  avatarUri: string | null;
  iconId: string | null;
};

export type CreateGroupLayoutProps = {
  graduationTypes: GraduationType[];
  groupIcons: GroupIcon[];
  loading: boolean;
  onBack: () => void;
  onHelp: () => void;
  onCreateGroup: (groupInput: CreateGroupInput) => void;
};

export default function CreateGroup({
  graduationTypes,
  groupIcons,
  loading,
  onBack,
  onCreateGroup,
  onHelp,
}: CreateGroupLayoutProps): JSX.Element {
  const theme = useTheme();
  const nameRef = useRef<InputFieldHandle>(null);

  const [name, setName] = useState<string>('');
  const [avatarUri, setAvatarUri] = useState<string | null>(null);
  const [groupIcon, setGroupIcon] = useState<GroupIcon | null>(null);
  const [graduationType, setGraduationType] = useState<
    GraduationType | undefined
  >(undefined);
  const [yearKey, setYearKey] = useState<number>(new Date().getFullYear());

  const isValidInput = (): boolean => {
    return (
      name != '' &&
      graduationType != undefined &&
      (avatarUri !== null || groupIcon !== null)
    );
  };

  const handleNext = () => {
    isValidInput() &&
      graduationType &&
      onCreateGroup &&
      onCreateGroup({
        name,
        avatarUri: avatarUri || null,
        iconId: groupIcon?.id || null,
        typeId: graduationType.id,
        year: yearKey.toString(),
      });
  };

  return (
    <StackScreenLayout
      backButtonTestID={'buttonBack'}
      onBackPress={onBack}
      title={t('createGroup.headerTitle')}
      RightComponent={
        <Button
          text={t('getHelp')}
          testID="getHelp"
          size={'sm'}
          type={'secondary-base'}
          onPress={onHelp}
        />
      }
      BgComponent={<ColorBox />}
    >
      <ScrollView>
        <Content>
          <CustomIconPicker
            testID={'pickerImage'}
            modalTitle={t('g.setGroupImage')}
            selectedIconStyle={'create_group'}
            customIcons={groupIcons.map((groupIcon) => ({
              id: groupIcon.id,
              uri: groupIcon.icon,
            }))}
            onSelect={({ imgUrl, customIcon }) => {
              setAvatarUri(imgUrl);
              setGroupIcon(
                customIcon
                  ? {
                      __typename: 'GroupIconNode',
                      id: customIcon.id,
                      icon: customIcon.uri,
                    }
                  : null,
              );
            }}
          />
          <InputContent>
            <InputField
              ref={nameRef}
              testID={'inputName'}
              iconName={'flag-01'}
              label={t('createGroup.nameLabel')}
              placeholder={t('createGroup.namePlaceholder')}
              value={name}
              onChangeText={(value) => setName(value)}
              blurOnSubmit={false}
              returnKeyType={'next'}
            />
            <Spacer size={10} />
            <SchoolTypePicker
              testID={'pickerSchool'}
              toggleTestID={'buttonPickerToggle'}
              label={t('createGroup.instituteType')}
              schoolTypes={graduationTypes}
              selectedSchoolType={graduationType}
              initialShowPicker={false}
              onSelectedSchoolType={(schoolType) =>
                setGraduationType(schoolType as GraduationType)
              }
            />
            <Spacer size={30} />

            <YearSelector
              initialYear={yearKey}
              setYear={setYearKey}
              sectionColor={theme.color.base.c7}
            ></YearSelector>
          </InputContent>
          <Footer>
            <Button
              testID={'buttonCreateGroup'}
              state={isValidInput() ? 'default' : 'disabled'}
              text={t('createGroup.createGroup')}
              loading={loading}
              flex
              flexScreenContainer="stack"
              size="2xl"
              type="primary-brand-02"
              onPress={() => handleNext()}
            />
          </Footer>
        </Content>
      </ScrollView>
    </StackScreenLayout>
  );
}
