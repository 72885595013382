import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  useNavigation,
  useRoute,
  RouteProp,
  CompositeNavigationProp,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import useAuthTeams from '~/data/hooks/useAuthTeams';
import useLikeComment from '~/data/hooks/useLikeComment';
import usePosts from '~/data/hooks/usePosts';
import { Comment, Post } from '~/data/models/post';
import { USER_GROUP } from '~/data/operations/group';
import { COMMENT_POST, DELETE_COMMENT, POST } from '~/data/operations/post';
import { Owner } from '~/data/types/graphql';
import { RootStackParamList } from '~/navigation/types';
import CommentsLayout from './layout';

type CommentsNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList, 'Comments'>,
  StackNavigationProp<RootStackParamList, 'EditPoll'>
>;

type CommentsRouteProp = RouteProp<RootStackParamList, 'Comments'>;

export default function Comments(): JSX.Element {
  const navigation = useNavigation<CommentsNavProp>();
  const {
    params: { postId },
  } = useRoute<CommentsRouteProp>();

  const {
    hiddenPostIds,
    onReportPost,
    onReportUser,
    onBlockUser,
    onHidePost,
    onLikePost,
  } = usePosts();

  const { authUserGroupId, authUserId } = useAuth();
  const { authTeamIds, teams } = useAuthTeams();

  const { data: userGroupData } = useQuery(USER_GROUP, {
    skip: !authUserGroupId,
    variables: {
      id: authUserGroupId,
    },
  });

  const { data: postData } = useQuery(POST, {
    variables: {
      id: postId,
      authUserGroupId,
    },
  });

  const userGroup = userGroupData?.userGroup;

  const post = postData?.post as Post | undefined;

  const comments = (postData?.post?.comments as Comment[]) || [];

  const filteredComments = comments.filter(
    (post) => !hiddenPostIds.includes(post.id),
  );

  const { onLikeComment } = useLikeComment();

  const [commentPost] = useMutation(COMMENT_POST);

  const [deleteComment] = useMutation(DELETE_COMMENT);

  const onCreateComment = async (comment: string, authorTeamId?: string) => {
    try {
      await commentPost({
        variables: {
          input: {
            id: postId,
            text: comment,
            owner: authorTeamId ? Owner.TEAM : Owner.USER,
            team: authorTeamId,
          },
          authUserGroupId: authUserGroupId,
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDeleteComment = async (commentId: string) => {
    try {
      await deleteComment({
        variables: {
          input: {
            id: commentId,
          },
          authUserGroupId,
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };
  const isPostOwner = authUserId === post?.createdBy?.user.id;

  return (
    <CommentsLayout
      post={post}
      teams={teams}
      authTeamIds={authTeamIds}
      comments={filteredComments}
      authUserGroup={userGroup}
      onBack={() => navigation.goBack()}
      onLike={onLikePost}
      onLikeComment={onLikeComment}
      onCreateComment={onCreateComment}
      onDeleteComment={onDeleteComment}
      onBlockUser={onBlockUser}
      onHidePost={onHidePost}
      isPostOwner={isPostOwner}
      onEdit={
        isPostOwner
          ? () => navigation.navigate('EditPoll', { postId: postId })
          : undefined
      }
      onReportPost={onReportPost}
      onReportUser={onReportUser}
    />
  );
}
