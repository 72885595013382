import React from 'react';
import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { useFocusEffect } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import { Event } from '~/data/models/calendar';
import { EVENTS } from '~/data/operations/calendar';
import { PlanningStackParamList } from '~/navigation/types';
import CalendarLayout from './layout';

type CalendarNavProp = StackNavigationProp<PlanningStackParamList, 'Calendar'>;

export default function Calendar(): JSX.Element {
  const navigation = useNavigation<CalendarNavProp>();

  const { data, refetch } = useQuery(EVENTS, {
    variables: { after: null, first: DEFAULT_LIST_OFFSET },
  });

  useFocusEffect(
    React.useCallback(() => {
      refetch();
    }, [refetch]),
  );

  const getUpcomingEvents = (events: Event[]): Event[] => {
    const currentDate = new Date();
    return events.filter((event) => {
      const eventDate = new Date(event.startDateTime);
      const dayDifference =
        (eventDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24);

      return (
        eventDate >= currentDate && dayDifference > 0 && dayDifference <= 14
      );
    });
  };

  const events: Event[] =
    (data?.events?.edges.map((edge) => edge?.node) as Event[]) || [];

  const upcomingEvents = getUpcomingEvents(events);

  return (
    <CalendarLayout
      calendarEvents={events}
      onBack={() => navigation.goBack()}
      onAddEvent={() => navigation.navigate('CreateEvent')}
      onEventOpen={(id) => navigation.navigate('EventDetail', { eventId: id })}
      upcomingEvents={upcomingEvents}
    />
  );
}
