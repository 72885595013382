import React, { useEffect, useState } from 'react';
import { Alert } from 'react-native';
import AuthorPicker, {
  AuthorTeamType,
  AuthorUserType,
} from '~/components/AuthorPicker';
import Button from '~/components/Button';
import InputField from '~/components/InputField';
import NumberInput from '~/components/NumberInput';
import SectionHeader from '~/components/SectionHeader';
import { DEFAULT_MAX_CHARS } from '~/data/constants';
import { UserGroup } from '~/data/models/group';
import { Team } from '~/data/models/team';
import { ReportInstance } from '~/data/models/yearbook';
import { TabScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  InputContainer,
  CenterContent,
  Loading,
  ScrollView,
  ViewContainer,
  MaxCharsContainer,
  HPadding,
} from './style';

type ReportSettingsLoadingProps = {
  onBack: () => void;
};

export function ReportSettingsLoading({
  onBack,
}: ReportSettingsLoadingProps): JSX.Element {
  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('g.settings')}
      onBackPress={onBack}
    >
      <CenterContent>
        <Loading />
      </CenterContent>
    </TabScreenLayout>
  );
}

export type UpdateReportInstanceInput = {
  title: string;
  editTeamId: string | null;
  viewTeamId: string | null;
  maxChars: number;
};

type ReportSettingsLayoutProps = {
  loggedUserGroup?: UserGroup | null;
  reportInstance: ReportInstance;
  teams: Team[];
  yearbookAdmin: boolean;
  deleteLoading: boolean;
  inputAutoFocus?: boolean;
  onBack: () => void;
  onUpdateReportInstance: (
    updateReportInstanceInput: UpdateReportInstanceInput,
  ) => void;
  onDeleteReport: () => void;
  onLoadMoreTeams: () => void;
};

export default function ReportSettings({
  loggedUserGroup,
  reportInstance,
  teams,
  yearbookAdmin,
  deleteLoading,
  inputAutoFocus = true,
  onBack,
  onUpdateReportInstance,
  onDeleteReport,
  onLoadMoreTeams,
}: ReportSettingsLayoutProps): JSX.Element {
  const getInitialTitle = () => reportInstance?.title || '';

  const getInitialEditTeamId = () => reportInstance?.editTeam?.id || null;

  const getInitialViewTeamId = () => reportInstance?.viewTeam?.id || null;

  const getInitialMaxChars = () =>
    reportInstance?.maxChars || DEFAULT_MAX_CHARS;

  const [title, setTitle] = useState(getInitialTitle());

  const [editTeamId, setEditTeamId] = useState(getInitialEditTeamId());

  const [viewTeamId, setViewTeam] = useState(getInitialViewTeamId());

  const [maxChars, setMaxChars] = useState(getInitialMaxChars());

  const authorMe: AuthorUserType | null = loggedUserGroup
    ? {
        id: loggedUserGroup.user.id,
        authorType: 'user',
        userGroup: loggedUserGroup,
      }
    : null;

  const teamAuthors: AuthorTeamType[] = teams.map((team) => {
    return {
      id: team.id,
      authorType: 'team',
      team,
    };
  });

  const authors = authorMe ? [authorMe, ...teamAuthors] : teamAuthors;

  const handleUpdate = () => {
    const updatedTitle =
      reportInstance && title != getInitialTitle() ? true : false;

    const updatedEditTeamId =
      reportInstance && editTeamId != getInitialEditTeamId() ? true : false;

    const updatedViewTeamId =
      reportInstance && viewTeamId != getInitialViewTeamId() ? true : false;

    const updatedMaxChars =
      reportInstance && maxChars != getInitialMaxChars() ? true : false;

    if (
      updatedTitle ||
      updatedEditTeamId ||
      updatedViewTeamId ||
      updatedMaxChars
    ) {
      onUpdateReportInstance({
        title,
        editTeamId: editTeamId === authorMe?.id ? null : editTeamId,
        viewTeamId: viewTeamId === authorMe?.id ? null : viewTeamId,
        maxChars,
      });
    }
  };

  useEffect(() => {
    handleUpdate();
  }, [editTeamId, viewTeamId, maxChars]);

  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('g.settings')}
      onBackPress={onBack}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <InputContainer>
          <InputField
            testID={'inputTitle'}
            value={title}
            label={t('g.title')}
            autoFocus={inputAutoFocus}
            onChangeText={setTitle}
            onEndEditing={() => handleUpdate()}
          />
        </InputContainer>
        <HPadding>
          <SectionHeader sub title={t('reportsSettings.editSection')} />
        </HPadding>
        {authors.length > 0 ? (
          <AuthorPicker
            testID={'editTeamAuthorPicker'}
            authors={authors}
            selectedAuthorId={editTeamId || authorMe?.id}
            onSelectedAuthor={(authorId) => setEditTeamId(authorId)}
            onLoadMore={onLoadMoreTeams}
          />
        ) : null}
        <InputContainer>
          <SectionHeader sub title={t('reportsSettings.viewSection')} />
        </InputContainer>
        {authors.length > 0 ? (
          <AuthorPicker
            testID={'viewTeamAuthorPicker'}
            authors={authors}
            selectedAuthorId={viewTeamId || authorMe?.id}
            onSelectedAuthor={(authorId) => setViewTeam(authorId)}
            onLoadMore={onLoadMoreTeams}
          />
        ) : null}
        <MaxCharsContainer>
          <ViewContainer>
            <SectionHeader sub title={t('reportsSettings.maxCharSection')} />
          </ViewContainer>
          <NumberInput
            testID={'charCountNumberInput'}
            number={maxChars}
            step={100}
            min={100}
            max={10000}
            onChangeNumber={(number) => setMaxChars(number)}
          />
        </MaxCharsContainer>
        <HPadding>
          {yearbookAdmin ? (
            <Button
              testID={'buttonDelete'}
              type={'destructive'}
              text={t('reportsSettings.deleteReport')}
              loading={deleteLoading}
              flex
              onPress={() =>
                Alert.alert(t('alert.deleteReportTitle'), '', [
                  {
                    text: t('alert.cancel'),
                    style: 'cancel',
                  },
                  {
                    text: t('alert.delete'),
                    onPress: () => {
                      onDeleteReport();
                    },
                  },
                ])
              }
            />
          ) : null}
        </HPadding>
      </ScrollView>
    </TabScreenLayout>
  );
}
