import styled from 'styled-components/native';

export const InputContainer = styled.View`
  padding-vertical: 16px;
  padding-horizontal: 16px;
`;

export const CenterContent = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled.ActivityIndicator``;

export const ScrollView = styled.ScrollView`
  padding-vertical: 16px;
`;

export const HPadding = styled.View`
  padding-horizontal: 16px;
`;

export const MaxCharsContainer = styled.View`
  flex-direction: row;
  padding-bottom: 24px;
  padding-horizontal: 16px;
`;

export const ViewContainer = styled.View`
  flex: 1;
`;
