import React from 'react';
import AddModuleCell from '~/components/AddModuleCell';
import SectionHeader from '~/components/SectionHeader';
import { Module } from '~/data/models/yearbook';
import { ModalScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import { Header, ModuleList } from './style';

type AddModuleLayoutProps = {
  modules: Module[];
  addedModuleIds?: string[];
  onBack: () => void;
  onSelectModule: (moduleId: string) => void;
  onAddModule: (moduleId: string) => void;
};

export default function AddModule({
  modules,
  onBack,
  onSelectModule,
  onAddModule,
  addedModuleIds = [],
}: AddModuleLayoutProps): JSX.Element {
  const renderItem = ({ item: module }: { item: Module }) => {
    const isAlreadyAdded = addedModuleIds.some(
      (moduleId) => moduleId === module.id,
    );

    return (
      <AddModuleCell
        testID={`AddModuleCell:${module.id}`}
        module={module}
        isAdded={isAlreadyAdded}
        disableRemove
        disclaimerText={t('g.moduleAlreadyAdded')}
        onCellPress={() => onSelectModule(module.id)}
        onAddPress={() => onAddModule(module.id)}
      />
    );
  };

  return (
    <ModalScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.addModule')}
      onBackPress={onBack}
    >
      <ModuleList<React.ElementType>
        data={modules}
        renderItem={renderItem}
        ListHeaderComponent={
          <Header>
            <SectionHeader title={t('g.inspirationForYou')} sub />
          </Header>
        }
      />
    </ModalScreenLayout>
  );
}
