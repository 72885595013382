import styled from 'styled-components/native';

export const UserList = styled.FlatList`
  padding-vertical: 16px;
`;

export const Header = styled.View`
  padding-horizontal: 16px;
`;

export const Section = styled.View`
  padding: 16px;
`;

export const MemberCellContainer = styled.View`
  padding-horizontal: 16px;
`;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  tintColor: theme.color.base.c4,
  colors: [theme.color.base.c4],
}))``;

export const Footer = styled.View`
  padding-vertical: 32px;
  align-items: center;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c6,
}))``;
