import React from 'react';
import { useQuery } from '@apollo/client';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { GRADUATION_YEARS, NO_PAG_LIST_OFFSET } from '~/data/constants';
import { GroupIcon } from '~/data/models/custom';
import { GraduationType } from '~/data/models/group';
import { GROUP_ICONS } from '~/data/operations/custom';
import { ImporterStackParamList } from '~/navigation/types';
import SetupGroupLayout from './layout';

type SetupGroupNavProp = StackNavigationProp<
  ImporterStackParamList,
  'SetupGroup'
>;
type SetupGroupRouteProp = RouteProp<ImporterStackParamList, 'SetupGroup'>;

export type OnSetupGroupNextArgs = {
  groupName: string;
  graduationType: string;
  graduationYear: number;
  iconId: string | null;
  avatar: string | null;
};

export const GRADUATION_TYPES: GraduationType[] = [
  { id: 'ID1', name: 'Abitur', __typename: 'GraduationTypeNode' },
  {
    id: 'ID2',
    name: 'Hauptschulabschluss',
    __typename: 'GraduationTypeNode',
  },
  {
    id: 'ID3',
    name: 'Mittlerer Schulabschluss',
    __typename: 'GraduationTypeNode',
  },
  { id: 'ID4', name: 'Bachelor', __typename: 'GraduationTypeNode' },
  { id: 'ID5', name: 'Master', __typename: 'GraduationTypeNode' },
  {
    id: 'ID6',
    name: 'Grundschulabschluss',
    __typename: 'GraduationTypeNode',
  },
  {
    id: 'ID7',
    name: 'Förderschulabschluss',
    __typename: 'GraduationTypeNode',
  },
  { id: 'ID8', name: 'Berufsausbildung', __typename: 'GraduationTypeNode' },
  { id: 'ID9', name: 'Staatsexamen', __typename: 'GraduationTypeNode' },
  { id: 'ID10', name: 'Anderer Abschluss', __typename: 'GraduationTypeNode' },
];

export default function SetupGroup(): JSX.Element {
  const navigation = useNavigation<SetupGroupNavProp>();
  const {
    params: { name, userCount },
  } = useRoute<SetupGroupRouteProp>();

  const { data: groupIconsData } = useQuery(GROUP_ICONS, {
    variables: {
      first: NO_PAG_LIST_OFFSET,
    },
  });

  const groupIcons: GroupIcon[] =
    (groupIconsData?.groupIcons?.edges.map(
      (edge) => edge?.node,
    ) as GroupIcon[]) || [];

  const onNext = (args: OnSetupGroupNextArgs): void => {
    navigation.navigate('Overview', { userCount, ...args });
  };

  return (
    <SetupGroupLayout
      name={name}
      onBack={() => navigation.goBack()}
      onNext={onNext}
      years={GRADUATION_YEARS}
      groupIcons={groupIcons}
      graduationTypes={GRADUATION_TYPES}
      loading={false}
    />
  );
}
