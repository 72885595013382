import { useQuery } from '@apollo/client';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '../constants';
import { Team } from '../models/team';
import { TEAMS } from '../operations/team';

type AuthTeamsOptions = {
  skip?: boolean;
};

type AuthTeamsHookProps = {
  teams: Team[];
  authTeamIds: string[];
  teamsLoading: boolean;
};

const useAuthTeams = (options?: AuthTeamsOptions): AuthTeamsHookProps => {
  const skip = options?.skip;

  const { authUserGroupId } = useAuth();

  const { loading: teamsLoading, data: teamsData } = useQuery(TEAMS, {
    skip: !authUserGroupId && skip,
    variables: {
      first: NO_PAG_LIST_OFFSET,
      userGroupId: authUserGroupId,
    },
  });

  const teams: Team[] =
    (teamsData?.teams?.edges.map((edge) => edge?.node) as Team[]) || [];

  const authTeamIds = teams.map((team) => team.id);

  return {
    teams,
    authTeamIds,
    teamsLoading,
  };
};

export default useAuthTeams;
