import React from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import { StackScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  Title,
  TopContainer,
  BottomContainer,
  ImageContainer,
  TitleWrapper,
  BadgeText,
  DescWrapper,
  Desc,
} from './style';

type JoinGroupRequestSentLayoutProps = {
  onLogout: () => void;
  onGoToLobby: () => void;
};

export default function JoinGroupRequestSent({
  onLogout,
  onGoToLobby,
}: JoinGroupRequestSentLayoutProps): JSX.Element {
  return (
    <StackScreenLayout
      bgColorKey="brand_01"
      backButtonTestID={'buttonBack'}
      colorStyle={'light'}
      RightComponent={
        <Button
          testID="buttonLogout"
          size="sm"
          text={t('g.logout')}
          type="secondary-base"
          onPress={onLogout}
        />
      }
    >
      <TopContainer>
        <ImageContainer source={general.invite_success} />
        <TitleWrapper>
          <BadgeText>{t('joinGroupRequestSent.badgeText')}</BadgeText>
          <Title>{t('joinGroupRequestSent.title')}</Title>
        </TitleWrapper>
      </TopContainer>
      <BottomContainer>
        <DescWrapper>
          <Desc>{t('joinGroupRequestSent.desc1')}</Desc>
          <Desc>{t('joinGroupRequestSent.desc2')}</Desc>
        </DescWrapper>
        <Button
          size="2xl"
          flex
          flexScreenContainer="stack"
          testID={'buttonGoToLobby'}
          text={t('joinGroupRequestSent.continueToLobby')}
          type="secondary-contrast"
          onPress={() => onGoToLobby && onGoToLobby()}
        />
      </BottomContainer>
    </StackScreenLayout>
  );
}
