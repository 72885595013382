import { LinearGradient } from 'expo-linear-gradient';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import Image from '~/components/Image';
import TabBarComp from '~/components/TabBar';
import TabSectionButton from '~/components/TabSectionButton';

export const AvatarContainer = styled.View`
  padding-top: 24px;
  padding-bottom: 36px;
  align-items: center;
`;

export const GroupAvatar = styled(Image)`
  width: 80px;
  height: 80px;
  background-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 16px;
`;

export const CheerStickerImage = styled(Image)`
  width: 90px;
  height: 67px;
`;

export const AdminTabSectionButton = styled(TabSectionButton).attrs(
  ({ theme }) => ({
    styledIconColor: theme.color.brand_02,
  }),
)`
  padding-horizontal: 16px;
`;

export const BGHeader = styled(LinearGradient).attrs(({ theme }) => ({
  colors: theme.gradient.primary.cs02,
  start: { x: 0, y: 0 },
  end: { x: 1, y: 0 },
}))`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: ${({ theme }) => 120 + theme.safeAreaInsets.bottom}px;
  background-color: ${({ theme }) => theme.color.brand_02};
`;

export const FlatList = styled.FlatList`
  flex: 1;
`;

export const Header = styled.View``;

export const Title = styled.Text`
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 22px;
  padding-horizontal: 16px;
`;

export const TabBar = styled(TabBarComp)`
  padding-top: 32px;
  padding-horizontal: 16px;
`;

export const SettingsContent = styled.View`
  padding-top: 32px;
  padding-horizontal: 16px;
`;

export const HeaderContent = styled.View`
  padding-vertical: 32px;
  padding-horizontal: 16px;
`;

export const InputView = styled.View`
  padding-top: 32px;
`;

export const VSpacer = styled.View`
  height: 32px;
`;

export const MemberCellView = styled.View`
  padding-horizontal: 16px;
`;

export const LoadingContent = styled.View`
  padding-vertical: 32px;
  align-items: center;
`;
export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))``;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  tintColor: theme.color.base.c4,
  colors: [theme.color.base.c4],
}))``;

export const ShadowContainer = styled.View`
  box-shadow: 3px 2px 2px ${({ theme }) => theme.color.base.c2};
`;

export const AllMembersContainer = styled.View`
  height: 100px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.color.base.c2};
  border-radius: 5px;
  background-color: white;
  ${Platform.select({
    android: `
      elevation: 2;
    `,
  })}
`;
export const AllMembersTextContainer = styled.View``;
export const AllMembersNumber = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 32px;
`;
export const AllMembersText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
`;
