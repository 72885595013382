import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { CountryType } from '~/components/CountryPicker';
import Snackbar from '~/components/Snackbar';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import {
  EditInstituteActivity,
  AdminInstitute,
  EditInstituteGroup,
} from '~/data/models/admin';
import { InstituteType } from '~/data/models/institute';
import { MarketProfile } from '~/data/models/marketProfile';
import {
  ADMIN_GET_INSTITUTE,
  ADMIN_UPDATE_INSTITUTE,
} from '~/data/operations/admin';
import { INSTITUTE_TYPES } from '~/data/operations/institute';
import { MARKET_PROFILES } from '~/data/operations/marketProfile';
import {
  AdminUpdateInstituteInput,
  InstitutesInstituteCountryChoices,
} from '~/data/types/graphql';
import { AdminStackParamList, SearchStackParamList } from '~/navigation/types';
import useDebouncedState from '~/utils/hooks/useDebouncedState';
import EditInstituteLayout from './layout';

type EditInstituteNavProp = StackNavigationProp<
  SearchStackParamList,
  'EditInstitute'
>;

type EditInstituteRouteProp = RouteProp<SearchStackParamList, 'EditInstitute'>;

export default function EditInstitute(): JSX.Element {
  const [submitting, setIsSubmitting] = useState<boolean>(false);
  const navigation = useNavigation<EditInstituteNavProp>();
  const { openDrawer } =
    useNavigation<DrawerNavigationProp<AdminStackParamList>>();

  const [selectedCountry, setSelectedCountry] = useDebouncedState<
    CountryType | undefined
  >(undefined, 200);
  const {
    params: { id },
  } = useRoute<EditInstituteRouteProp>();
  const instituteId = decodeURIComponent(id);

  const [updateInstitute] = useMutation(ADMIN_UPDATE_INSTITUTE);

  const { data: marketProfileData, loading: mpLoading } =
    useQuery(MARKET_PROFILES);
  const { data: instituteTypesData, loading: itLoading } = useQuery(
    INSTITUTE_TYPES,
    {
      variables: {
        first: DEFAULT_LIST_OFFSET,
        country: selectedCountry?.country as InstitutesInstituteCountryChoices,
      },
    },
  );

  const { data: instituteData, loading: instituteLoading } = useQuery(
    ADMIN_GET_INSTITUTE,
    {
      skip: !marketProfileData || !instituteTypesData,
      variables: {
        id: instituteId,
      },
    },
  );

  const loading = mpLoading || itLoading || instituteLoading;

  const onUpdateInstitute = async ({
    name,
    country,
    marketProfile,
    marketProfileName,
    instituteTypeName,
    city,
    street,
    zip,
    type,
    website,
  }: AdminUpdateInstituteInput) => {
    try {
      setIsSubmitting(true);
      const { data } = await updateInstitute({
        variables: {
          input: {
            id: instituteId,
            name,
            country,
            marketProfile,
            city,
            street,
            zip,
            type,
            marketProfileName,
            instituteTypeName,
            website,
          },
        },
      });

      const messages = data?.adminUpdateInstitute?.errors?.map(
        (error) => error?.messages[0],
      );

      const errorMessage = messages?.[0];
      if (errorMessage) {
        Snackbar.show(errorMessage);
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const institute: AdminInstitute = instituteData?.institute as AdminInstitute;
  const instituteActivity: EditInstituteActivity[] =
    (institute?.instituteActivity || []) as EditInstituteActivity[];
  const groups: EditInstituteGroup[] = (institute?.groups ||
    []) as EditInstituteGroup[];
  const marketProfiles: MarketProfile[] =
    (marketProfileData?.marketProfiles?.edges
      .map((edge) => edge?.node)
      .filter((node) => Boolean(node)) as MarketProfile[]) || [];
  const instituteTypes: InstituteType[] =
    (instituteTypesData?.instituteTypes?.edges
      .map((edge) => edge?.node)
      .filter((node) => Boolean(node)) as InstituteType[]) || [];

  const handleOpeningGroups = (id: string) => {
    const route = 'EditGroup';
    navigation.navigate(route, {
      id,
    });
  };

  return (
    <EditInstituteLayout
      institute={institute}
      groups={groups}
      instituteActivity={instituteActivity}
      marketProfiles={marketProfiles}
      onUpdateInstitute={onUpdateInstitute}
      onBack={() => navigation.navigate('Search')}
      loading={loading || submitting}
      instituteTypes={instituteTypes}
      openDrawer={openDrawer}
      onOpenGroups={handleOpeningGroups}
      onCountryChange={(newCountry) => {
        setSelectedCountry(newCountry);
      }}
    />
  );
}
