import React, { useEffect, useRef, useState } from 'react';
import Button from '~/components/Button';
import CardOptionCell from '~/components/CardOptionCell';
import CustomIconPicker from '~/components/CustomIconPicker';
import InputField from '~/components/InputField';
import ModalCountryPicker, {
  ModalCountryPickerHandler,
} from '~/components/ModalCountryPicker';
import ResidenceCountryCell from '~/components/ResidenceCountryCell';
import SectionHeader from '~/components/SectionHeader';
import { COUNTRIES } from '~/data/constants';
import { DefaultAvatar } from '~/data/models/custom';
import { UserGroup } from '~/data/models/group';
import { Country } from '~/data/models/marketProfile';
import { AuthUser } from '~/data/models/user';
import { UsersUserGenderChoices, UserType } from '~/data/types/graphql';
import { TabScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import { Loading, ScrollView, Content, SectionView } from './style';

type ProfileSetupLoadingLayoutProps = {
  onBack: () => void;
  onLogout: () => void;
};

export function ProfileSetupLoading({
  onBack,
  onLogout,
}: ProfileSetupLoadingLayoutProps): JSX.Element {
  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.profileSetup')}
      RightComponent={
        <Button
          testID={'buttonLogout'}
          text={t('g.logout')}
          minWidth={80}
          size={'sm'}
          type={'secondary-base'}
          onPress={onLogout}
        />
      }
      onBackPress={onBack}
    >
      <ScrollView>
        <Loading />
      </ScrollView>
    </TabScreenLayout>
  );
}

export type MeInput = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  gender: UsersUserGenderChoices | null;
  updateToken: boolean;
};

export type ProfileSetupLayoutProps = {
  authUser: AuthUser;
  userGroup: UserGroup;
  uploadingImage: boolean;
  defaultAvatars: DefaultAvatar[];
  onBack: () => void;
  onLogout: () => void;
  onUpdateMe: (input: MeInput) => void;
  onUpdateRole: (role: UserType) => void;
  onUpdateAvatar: (uri: string | null, iconId: string | null) => void;
  onDeleteAccount: () => void;
  country: Country;
  onUpdateCountry: (country: Country) => Promise<void>;
};

export default function ProfileSetup({
  authUser,
  userGroup,
  uploadingImage,
  defaultAvatars,
  onBack,
  onLogout,
  onUpdateMe,
  onUpdateRole,
  onDeleteAccount,
  onUpdateAvatar,
  country,
  onUpdateCountry,
}: ProfileSetupLayoutProps): JSX.Element {
  const [firstName, setFirstName] = useState<string>(() => authUser.firstName);
  const [lastName, setLastName] = useState<string>(() => authUser.lastName);
  const [email, setEmail] = useState<string>(() => authUser.email);
  const [phone, setPhone] = useState<string>(() => authUser.phone || '');
  const [gender, setGender] = useState<UsersUserGenderChoices | null>(
    () => authUser.gender || null,
  );
  const countryModalizeRef = useRef<ModalCountryPickerHandler>(null);

  const [role, setRole] = useState<UserType | undefined>(
    () => userGroup.role || undefined,
  );

  function handleUpdateMe() {
    const updatedFirstName = authUser.firstName !== firstName;

    const updatedLastName = authUser.lastName !== lastName;

    const updatedEmail = authUser.email !== email;

    const updatedPhone = authUser.phone !== phone;

    const updatedGender = authUser.gender !== gender;

    if (
      updatedFirstName ||
      updatedLastName ||
      updatedPhone ||
      updatedGender ||
      updatedEmail
    ) {
      onUpdateMe({
        firstName,
        lastName,
        email,
        phone,
        gender,
        updateToken: !!updatedEmail,
      });
    }
  }

  useEffect(() => {
    handleUpdateMe();
  }, [gender]);

  const countryInfos = Object.values(COUNTRIES);
  const countryInfo = countryInfos.find(
    (countryInfo) => countryInfo.code === authUser.phoneCountryCode,
  );

  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.profileSetup')}
      RightComponent={
        <Button
          testID={'buttonLogout'}
          text={t('g.logout')}
          minWidth={80}
          size={'sm'}
          type={'secondary-base'}
          onPress={onLogout}
        />
      }
      onBackPress={onBack}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <CustomIconPicker
          testID={'customIconPicker'}
          modalTitle={t('g.setProfileImage')}
          customIcons={defaultAvatars.map((defaultAvatar) => ({
            id: defaultAvatar.id,
            uri: defaultAvatar.avatar,
          }))}
          selectedIcon={
            userGroup?.defaultAvatar
              ? {
                  id: userGroup.defaultAvatar.id,
                  uri: userGroup.defaultAvatar.avatar,
                }
              : null
          }
          selectedUri={!userGroup?.defaultAvatar ? userGroup.avatar : null}
          loading={uploadingImage}
          center
          onSelect={({ imgUrl, customIcon }) =>
            onUpdateAvatar(imgUrl, customIcon?.id || null)
          }
        />
        <Content>
          <SectionHeader title={t('profileSetup.contactSecion')} sub />
          <SectionView>
            <InputField
              testID={'inputFirstName'}
              iconName={'user-03'}
              label={t('signUpName.firstNameLabel')}
              value={firstName}
              onChangeText={(value) => setFirstName(value)}
              blurOnSubmit={false}
              returnKeyType={'done'}
              onSubmitEditing={() => handleUpdateMe()}
            />
            <InputField
              testID={'inputLastName'}
              iconName={'user-03'}
              label={t('signUpName.lastNameLabel')}
              value={lastName}
              blurOnSubmit={false}
              returnKeyType={'done'}
              onChangeText={(value) => setLastName(value)}
              onSubmitEditing={() => handleUpdateMe()}
            />
            <InputField
              testID={'inputEmail'}
              iconName={'mail-01'}
              label={t('signUpContact.emailLabel')}
              placeholder={t('signUpContact.emailPlaceholder')}
              value={email}
              blurOnSubmit={false}
              returnKeyType={'done'}
              onChangeText={(value) => setEmail(value)}
              onSubmitEditing={() => handleUpdateMe()}
            />
            <InputField
              testID={'inputPhone'}
              country={countryInfo?.country}
              keyboardType={'numeric'}
              label={t('signUpContact.phoneLabel')}
              placeholder={t('signUpContact.phonePlaceholder')}
              value={phone}
              blurOnSubmit={false}
              returnKeyType={'done'}
              onChangeText={(value) => setPhone(value)}
              onSubmitEditing={() => handleUpdateMe()}
            />
          </SectionView>

          <SectionHeader title={t('profileSetup.countryOfResidence')} sub />
          <SectionView>
            <ResidenceCountryCell
              country={country}
              onSelect={() => {
                countryModalizeRef.current?.open();
              }}
            />
          </SectionView>

          <SectionHeader title={t('profileSetup.genderSection')} sub />
          <SectionView row>
            <CardOptionCell
              testID={'femaleCardOption'}
              text={t('g.female')}
              iconStyledName={'gender_women'}
              isSelected={gender === UsersUserGenderChoices.FEMALE}
              onPress={() => setGender(UsersUserGenderChoices.FEMALE)}
            />
            <CardOptionCell
              testID={'maleCardOption'}
              text={t('g.male')}
              iconStyledName={'gender_men'}
              isSelected={gender === UsersUserGenderChoices.MALE}
              onPress={() => setGender(UsersUserGenderChoices.MALE)}
            />
            <CardOptionCell
              testID={'diversCardOption'}
              text={t('g.divers')}
              iconStyledName={'gender_transgender'}
              isSelected={gender === UsersUserGenderChoices.OTHER}
              onPress={() => setGender(UsersUserGenderChoices.OTHER)}
            />
          </SectionView>
          <SectionHeader title={t('profileSetup.roleSection')} sub />
          <SectionView row>
            <CardOptionCell
              testID={'studentCardOption'}
              text={t('g.student')}
              iconName={'graduation-hat-02'}
              isSelected={role === UserType.STUDENT}
              onPress={() => {
                setRole(UserType.STUDENT);
                onUpdateRole(UserType.STUDENT);
              }}
            />
            <CardOptionCell
              testID={'teacherCardOption'}
              text={t('g.teacher')}
              iconName={'briefcase-02'}
              isSelected={role === UserType.TEACHER}
              onPress={() => {
                setRole(UserType.TEACHER);
                onUpdateRole(UserType.TEACHER);
              }}
            />
          </SectionView>
          <SectionHeader title={t('profileSetup.accountSection')} sub />
          <SectionView>
            <Button
              testID={'buttonDelete'}
              type={'destructive'}
              text={t('profileSetup.deleteAccount')}
              flex
              onPress={onDeleteAccount}
            />
          </SectionView>
        </Content>
        <ModalCountryPicker
          testID={'countryPicker'}
          ref={countryModalizeRef}
          initialSelectedCountry={country}
          onUpdateCountry={(countryPicked) => {
            onUpdateCountry(countryPicked);
            countryModalizeRef.current?.close();
          }}
        />
      </ScrollView>
    </TabScreenLayout>
  );
}
