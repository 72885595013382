import styled from 'styled-components/native';

export const PageList = styled.FlatList.attrs(({ theme }) => ({
  decelerationRate: 0,
  snapToInterval: theme.dimensions.screenWidth,
  snapToAlignment: 'center',
}))``;

export const Footer = styled.View`
  margin-bottom: ${({ theme }) => theme.safeAreaInsets.bottom}px;
`;
