import React from 'react';
import Button from '~/components/Button';
import { Event, EventAttendees } from '~/data/models/calendar';
import { TabScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  JoinedUserContainer,
  JoinedUser,
  NumberOfAttendees,
  AttendeeAvatar,
  AttendeeName,
  AttendeeContainer,
  AttendeeLine,
  AttendeesScrollView,
  RemoveAttendanceContainer,
} from './style';

type AttendeesListLayoutProps = {
  event: Event;
  onLeaveEvent: (event: Event) => void;
  onBack: () => void;
};

export default function AttendeesList({
  onBack,
  event,
  onLeaveEvent,
}: AttendeesListLayoutProps): JSX.Element {
  const attendees = event.attendees as EventAttendees[];

  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.Attendees')}
      onBackPress={onBack}
    >
      <AttendeesScrollView>
        <JoinedUserContainer>
          <JoinedUser>{t('attendeesList.youAreJoined')}</JoinedUser>
        </JoinedUserContainer>

        <NumberOfAttendees>
          {t('attendeesList.numberOfAttendees', { number: attendees.length })}
        </NumberOfAttendees>
        {attendees.length !== 0 &&
          attendees.map((attendee) => (
            <>
              <AttendeeContainer>
                <AttendeeAvatar uri={attendee.user.avatar} />
                <AttendeeName>
                  {`${attendee.user.firstName} ${attendee.user.lastName}`}
                </AttendeeName>
              </AttendeeContainer>
              <AttendeeLine />
            </>
          ))}
      </AttendeesScrollView>
      <RemoveAttendanceContainer>
        <Button
          testID={'buttonRemoveAttendance'}
          text={t('attendeesList.removeAttendance')}
          size={'lg'}
          flex
          type={'secondary-base'}
          iconName={'plus'}
          onPress={() => onLeaveEvent(event)}
        />
      </RemoveAttendanceContainer>
    </TabScreenLayout>
  );
}
