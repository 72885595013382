import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import useReportInstances from '~/data/hooks/useReportInstances';
import useTeams from '~/data/hooks/useTeams';
import {
  REPORTS_INSTANCE,
  UPDATE_REPORTS_INSTANCE,
} from '~/data/operations/yearbook';
import { YearbookStackParamList } from '~/navigation/types';
import ReportSettingsLayout, {
  ReportSettingsLoading,
  UpdateReportInstanceInput,
} from './layout';

type ReportSettingsNavProp = StackNavigationProp<
  YearbookStackParamList,
  'ReportSettings'
>;
type ReportSettingsRouteProp = RouteProp<
  YearbookStackParamList,
  'ReportSettings'
>;

export default function ReportSettings(): JSX.Element {
  const navigation = useNavigation<ReportSettingsNavProp>();
  const {
    params: { moduleInstanceId, reportInstanceId },
  } = useRoute<ReportSettingsRouteProp>();

  const { authUserGroup, yearbookAdmin } = useAuth();

  const { teams, onLoadMore: onLoadMoreTeams } = useTeams();

  const { onDeleteReport, deleteLoading } = useReportInstances({
    moduleInstanceId,
  });

  const { data: reportsInstanceData } = useQuery(REPORTS_INSTANCE, {
    variables: {
      id: reportInstanceId,
    },
  });

  const [updateReportsInstance] = useMutation(UPDATE_REPORTS_INSTANCE);

  const reportInstance = reportsInstanceData?.reportsInstance;

  const onUpdateReportInstance = async ({
    title,
    editTeamId,
    viewTeamId,
    maxChars,
  }: UpdateReportInstanceInput) => {
    try {
      if (reportInstance) {
        await updateReportsInstance({
          variables: {
            input: {
              id: reportInstanceId,
              title,
              text: reportInstance.text,
              editTeam: editTeamId,
              viewTeam: viewTeamId,
              maxChars,
            },
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  if (!reportInstance) {
    return <ReportSettingsLoading onBack={() => navigation.goBack()} />;
  }

  return (
    <ReportSettingsLayout
      loggedUserGroup={authUserGroup}
      reportInstance={reportInstance}
      teams={teams}
      yearbookAdmin={yearbookAdmin}
      deleteLoading={deleteLoading}
      onBack={() => navigation.goBack()}
      onUpdateReportInstance={onUpdateReportInstance}
      onDeleteReport={() => {
        onDeleteReport(reportInstanceId, () =>
          navigation.navigate('Reports', { moduleInstanceId }),
        );
      }}
      onLoadMoreTeams={onLoadMoreTeams}
    />
  );
}
