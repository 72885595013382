import React from 'react';
import { useQuery } from '@apollo/client';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import { ProfilePageSuggestedQuestion } from '~/data/models/yearbook';
import { PROFILE_PAGE_SUGGESTED_QUESTIONS } from '~/data/operations/yearbook';
import { RootStackParamList } from '~/navigation/types';
import ProfilePageAddQuestionLayout from './layout';

type ProfilePageAddQuestionNavProp = StackNavigationProp<
  RootStackParamList,
  'ProfilePageAddQuestion'
>;
type ProfilePageAddQuestionRouteProp = RouteProp<
  RootStackParamList,
  'ProfilePageAddQuestion'
>;

export default function ProfilePageAddQuestion(): JSX.Element {
  const navigation = useNavigation<ProfilePageAddQuestionNavProp>();
  const {
    params: { currentText, onAddedQuestion },
  } = useRoute<ProfilePageAddQuestionRouteProp>();

  const {
    data: suggestedQuestionsData,
    fetchMore: fetchMoreSuggestedQuestions,
  } = useQuery(PROFILE_PAGE_SUGGESTED_QUESTIONS, {
    variables: {
      first: DEFAULT_LIST_OFFSET,
    },
  });
  const endCursor =
    suggestedQuestionsData?.profilePageSuggestedQuestions?.pageInfo.endCursor;

  const hasNextPage =
    suggestedQuestionsData?.profilePageSuggestedQuestions?.pageInfo.hasNextPage;

  const onLoadMore = () => {
    fetchMoreSuggestedQuestions({
      variables: {
        first: DEFAULT_LIST_OFFSET,
        after: endCursor,
      },
    });
  };

  const suggestedQuestions: ProfilePageSuggestedQuestion[] =
    (suggestedQuestionsData?.profilePageSuggestedQuestions?.edges.map(
      (edge) => edge?.node,
    ) as ProfilePageSuggestedQuestion[]) || [];

  //TODO validate difference between recommended and inspirational questions
  const recommendedQuestions = suggestedQuestions.slice(0, 2);

  const inspirationalQuestions = suggestedQuestions.slice(
    2,
    suggestedQuestions.length,
  );

  return (
    <ProfilePageAddQuestionLayout
      text={currentText}
      recommendedQuestions={recommendedQuestions}
      hasNextPage={hasNextPage}
      onLoadMore={onLoadMore}
      inspirationalQuestions={inspirationalQuestions}
      onBack={() => navigation.goBack()}
      onAddQuestion={(text) => {
        onAddedQuestion(text);
        navigation.goBack();
      }}
    />
  );
}
