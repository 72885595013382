import { CountryType } from '~/components/CountryPicker';
import { SchoolType } from '~/components/SchoolTypePicker';
import { UsersUserGenderChoices } from '~/data/types/graphql';

type FieldType =
  | string
  | boolean
  | number
  | UsersUserGenderChoices
  | CountryType
  | SchoolType
  | null
  | undefined;

const isFieldDirty = (field: FieldType, defaultValue: FieldType) =>
  field !== defaultValue;

export const shouldAllowToSaveChanges = (form: FieldType[][]) =>
  form.some(([field, defaultValue]) => isFieldDirty(field, defaultValue));

export const allowToSaveChangesAllFilled = (form: FieldType[][]) =>
  form.every(([field, defaultValue]) => isFieldDirty(field, defaultValue));
