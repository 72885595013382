import React from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import { StackScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  ImageContainer,
  Title,
  TopContainer,
  TitleWrapper,
  BadgeText,
  BottomContainer,
  DescWrapper,
  Desc,
} from './style';
type RequestInstitutionSentLayoutProps = {
  onLogout: () => void;
  onGoToLobby: () => void;
};

export default function RequestInstitutionSent({
  onGoToLobby,
  onLogout,
}: RequestInstitutionSentLayoutProps): JSX.Element {
  return (
    <StackScreenLayout
      bgColorKey="brand_01"
      backButtonTestID={'buttonBack'}
      colorStyle={'light'}
      RightComponent={
        <Button
          testID="buttonLogout"
          size="sm"
          text={t('g.logout')}
          type="secondary-base"
          onPress={onLogout}
        />
      }
    >
      <TopContainer>
        <ImageContainer source={general.institute_request_sent} />
        <TitleWrapper>
          <BadgeText>{t('requestInstitutionSent.badgeText')}</BadgeText>
          <Title>{t('requestInstitutionSent.title')}</Title>
        </TitleWrapper>
      </TopContainer>
      <BottomContainer>
        <DescWrapper>
          <Desc>{t('requestInstitutionSent.desc1')}</Desc>
          <Desc>{t('requestInstitutionSent.desc2')}</Desc>
        </DescWrapper>
        <Button
          size="2xl"
          flex
          flexScreenContainer="stack"
          testID={'buttonGoToLobby'}
          text={t('requestInstitutionSent.continueToLobby')}
          type="secondary-contrast"
          onPress={() => onGoToLobby()}
        />
      </BottomContainer>
    </StackScreenLayout>
  );
}
