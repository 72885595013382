import styled from 'styled-components/native';

export const Header = styled.View`
  padding-bottom: 12px;
`;

export const TeamList = styled.FlatList`
  padding: 16px;
`;

export const Content = styled.View`
  padding-top: 24px;
  padding-bottom: 48px;
  align-items: center;
`;

export const EmptyText = styled.Text`
  color: ${({ theme }) => theme.color.base.c7};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 16px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c7,
}))``;

export const SkeletonContainer = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
`;

export const SkeletonName = styled.Text`
  flex: 1;
  padding-left: 16px;
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
`;
