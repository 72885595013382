import React, { useRef } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { createStackNavigator } from '@react-navigation/stack';
import { Platform } from 'react-native';
import ModalSwitchGroups from '~/components/ModalSwitchGroups';
import { ModalSwitchGroupsHandler } from '~/components/ModalSwitchGroups';
import { useAuth } from '~/context/auth';
import { IS_LANDSCAPE } from '~/data/constants';
import useGroupUsers from '~/data/hooks/useGroupUsers';
import {
  CommonPlanningStackParamList,
  MobileRootPlanningStackParamList,
  MobileRootYearbookStackParamList,
  RootStackParamList,
} from '~/navigation/types';
import {
  MainTabParamList,
  FeedStackParamList,
  PlanningStackParamList,
  ProfileStackParamList,
  YearbookStackParamList,
} from '~/navigation/types';
import AddTeamMember from '~/screens/Main/AddTeamMember';
import AppSetup from '~/screens/Main/AppSetup';
import AttendeesList from '~/screens/Main/AttendeesList';
import Calendar from '~/screens/Main/Calendar';
import Comments from '~/screens/Main/Comments';
import CreateEvent from '~/screens/Main/CreateEvent';
import CreatePoll from '~/screens/Main/CreatePoll';
import CreatePost from '~/screens/Main/CreatePost';
import CreateTeam from '~/screens/Main/CreateTeam';
import EditEvent from '~/screens/Main/EditEvent';
import EditPoll from '~/screens/Main/EditPoll';
import EditTeam from '~/screens/Main/EditTeam';
import EventDetail from '~/screens/Main/EventDetail';
import Feed from '~/screens/Main/Feed';
import GroupSettings from '~/screens/Main/GroupSettings';
import GroupSetup from '~/screens/Main/GroupSetup';
import NotificationSettings from '~/screens/Main/NotificationSettings';
import Planning from '~/screens/Main/Planning';
import Polls from '~/screens/Main/Polls';
import Profile from '~/screens/Main/Profile';
import ProfileSetup from '~/screens/Main/ProfileSetup';
import TeamDetail from '~/screens/Main/TeamDetail';
import Teams from '~/screens/Main/Teams';
import ToDoLists from '~/screens/Main/ToDoLists';
import ToDoTasks from '~/screens/Main/ToDoTasks';
import AddModule from '~/screens/Yearbook/AddModule';
import AddQuote from '~/screens/Yearbook/AddQuote';
import BookStatistics from '~/screens/Yearbook/BookStatistics';
import Collages from '~/screens/Yearbook/Collages';
import CollagesAlbumDetail from '~/screens/Yearbook/CollagesAlbumDetail';
import CollagesPhotoDetail from '~/screens/Yearbook/CollagesPhotoDetail';
import CollagesSetup from '~/screens/Yearbook/CollagesSetup';
import CollectedData from '~/screens/Yearbook/CollectedData';
import CreateCollage from '~/screens/Yearbook/CreateCollage';
import CreateCustomPage from '~/screens/Yearbook/CreateCustomPage';
import Custom from '~/screens/Yearbook/Custom';
import CustomPageResult from '~/screens/Yearbook/CustomPageResult';
import CustomPageResults from '~/screens/Yearbook/CustomPageResults';
import CustomSetup from '~/screens/Yearbook/CustomSetup';
import EditCustomPage from '~/screens/Yearbook/EditCustomPage';
import ExportData from '~/screens/Yearbook/ExportData';
import ExportHistory from '~/screens/Yearbook/ExportHistory';
import Exporting from '~/screens/Yearbook/Exporting';
import Yearbook from '~/screens/Yearbook/Home';
import Manage from '~/screens/Yearbook/Manage';
import ManageRequest from '~/screens/Yearbook/ManageRequest';
import ModuleDetail from '~/screens/Yearbook/ModuleDetail';
import ProfileCreateComment from '~/screens/Yearbook/ProfileCreateComment';
import ProfilePage from '~/screens/Yearbook/ProfilePage';
import ProfilePageAddPhotoCategory from '~/screens/Yearbook/ProfilePageAddPhotoCategory';
import ProfilePageAddQuestion from '~/screens/Yearbook/ProfilePageAddQuestion';
import ProfilePageAnswer from '~/screens/Yearbook/ProfilePageAnswer';
import ProfilePageComments from '~/screens/Yearbook/ProfilePageComments';
import ProfilePageResults from '~/screens/Yearbook/ProfilePageResults';
import ProfilePageSetup from '~/screens/Yearbook/ProfilePageSetup';
import Quotes from '~/screens/Yearbook/Quotes';
import QuotesSetup from '~/screens/Yearbook/QuotesSetup';
import RankingVote from '~/screens/Yearbook/RankingVote';
import Rankings from '~/screens/Yearbook/Rankings';
import RankingsAddQuestion from '~/screens/Yearbook/RankingsAddQuestion';
import RankingsCustomList from '~/screens/Yearbook/RankingsCustomList';
import RankingsCustomListOption from '~/screens/Yearbook/RankingsCustomListOption';
import RankingsSetup from '~/screens/Yearbook/RankingsSetup';
import ReportDetail from '~/screens/Yearbook/ReportDetail';
import ReportSettings from '~/screens/Yearbook/ReportSettings';
import Reports from '~/screens/Yearbook/Reports';
import ReportsSetup from '~/screens/Yearbook/ReportsSetup';
import { PROFILE_NAV } from '~/utils/helpers/const';
import CustomDrawerContent from './layout/CustomDrawerContent';
import { DESKTOP_DRAWER_STYLE } from './layout/CustomDrawerContent/style';
import CustomTabBar from './layout/CustomTabBar';

const FeedStackNavigator = createStackNavigator<FeedStackParamList>();
const PlanningStackNavigator = createStackNavigator<PlanningStackParamList>();
const YearbookStackNavigator = createStackNavigator<YearbookStackParamList>();
const ProfileStackNavigator = createStackNavigator<ProfileStackParamList>();

const COMMON_PLANNING_SCREENS: {
  name: keyof CommonPlanningStackParamList;
  component: () => JSX.Element;
}[] = [
  { name: 'Teams', component: Teams },
  { name: 'CreateTeam', component: CreateTeam },
  { name: 'TeamDetail', component: TeamDetail },
  { name: 'EditTeam', component: EditTeam },
  { name: 'Polls', component: Polls },
  { name: 'ToDoLists', component: ToDoLists },
  { name: 'Calendar', component: Calendar },
  { name: 'ToDoTasks', component: ToDoTasks },
  { name: 'EditEvent', component: EditEvent },
];

export const MOBILE_ROOT_PLANNING_SCREENS: {
  name: keyof MobileRootPlanningStackParamList;
  component: () => JSX.Element;
  isModalPresentationIOS?: boolean;
}[] = [
  { name: 'Comments', component: Comments, isModalPresentationIOS: false },
  { name: 'CreateEvent', component: CreateEvent, isModalPresentationIOS: true },
  { name: 'CreatePoll', component: CreatePoll, isModalPresentationIOS: true },
  { name: 'CreatePost', component: CreatePost, isModalPresentationIOS: true },
  { name: 'EditPoll', component: EditPoll, isModalPresentationIOS: true },
  {
    name: 'AddTeamMember',
    component: AddTeamMember,
    isModalPresentationIOS: true,
  },
  {
    name: 'EventDetail',
    component: EventDetail,
    isModalPresentationIOS: false,
  },
  {
    name: 'AttendeesList',
    component: AttendeesList,
    isModalPresentationIOS: false,
  },
  {
    name: 'CreateCustomPage',
    component: CreateCustomPage,
    isModalPresentationIOS: true,
  },
];

function FeedStack(): JSX.Element {
  return (
    <FeedStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <FeedStackNavigator.Screen name={'Feed'} component={Feed} />
      {COMMON_PLANNING_SCREENS.map((screen) => (
        <FeedStackNavigator.Screen key={screen.name} {...screen} />
      ))}
      {IS_LANDSCAPE &&
        MOBILE_ROOT_PLANNING_SCREENS.map((screen) => (
          <PlanningStackNavigator.Screen key={screen.name} {...screen} />
        ))}
    </FeedStackNavigator.Navigator>
  );
}

function PlanningStack(): JSX.Element {
  return (
    <PlanningStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName={'Planning'}
    >
      <PlanningStackNavigator.Screen name={'Planning'} component={Planning} />
      {COMMON_PLANNING_SCREENS.map((screen) => (
        <FeedStackNavigator.Screen key={screen.name} {...screen} />
      ))}
      {IS_LANDSCAPE &&
        MOBILE_ROOT_PLANNING_SCREENS.map((screen) => (
          <PlanningStackNavigator.Screen key={screen.name} {...screen} />
        ))}
    </PlanningStackNavigator.Navigator>
  );
}

export const MOBILE_ROOT_YEARBOOK_SCREENS: {
  name: keyof MobileRootYearbookStackParamList;
  component: () => JSX.Element;
  isModalPresentationIOS?: boolean;
}[] = [
  {
    name: 'ProfileCreateComment',
    component: ProfileCreateComment,
    isModalPresentationIOS: true,
  },
  {
    name: 'ProfilePageAddQuestion',
    component: ProfilePageAddQuestion,
    isModalPresentationIOS: true,
  },
  {
    name: 'ProfilePageAddPhotoCategory',
    component: ProfilePageAddPhotoCategory,
    isModalPresentationIOS: true,
  },
  {
    name: 'CreateCollage',
    component: CreateCollage,
    isModalPresentationIOS: true,
  },
  {
    name: 'CollagesPhotoDetail',
    component: CollagesPhotoDetail,
    isModalPresentationIOS: false,
  },
  {
    name: 'ReportDetail',
    component: ReportDetail,
    isModalPresentationIOS: false,
  },
  {
    name: 'ReportSettings',
    component: ReportSettings,
    isModalPresentationIOS: false,
  },
  {
    name: 'AddQuote',
    component: AddQuote,
    isModalPresentationIOS: true,
  },
  {
    name: 'RankingsAddQuestion',
    component: RankingsAddQuestion,
    isModalPresentationIOS: true,
  },
  {
    name: 'RankingsCustomList',
    component: RankingsCustomList,
    isModalPresentationIOS: true,
  },
  {
    name: 'RankingsCustomListOption',
    component: RankingsCustomListOption,
    isModalPresentationIOS: true,
  },
  {
    name: 'RankingVote',
    component: RankingVote,
    isModalPresentationIOS: true,
  },
  {
    name: 'AddModule',
    component: AddModule,
    isModalPresentationIOS: true,
  },
  {
    name: 'ModuleDetail',
    component: ModuleDetail,
    isModalPresentationIOS: true,
  },
  {
    name: 'ProfilePageAnswer',
    component: ProfilePageAnswer,
    isModalPresentationIOS: true,
  },
  {
    name: 'ExportData',
    component: ExportData,
    isModalPresentationIOS: true,
  },
  {
    name: 'Exporting',
    component: Exporting,
    isModalPresentationIOS: true,
  },
  {
    name: 'ExportHistory',
    component: ExportHistory,
    isModalPresentationIOS: true,
  },
];

function YearbookStack(): JSX.Element {
  return (
    <YearbookStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <YearbookStackNavigator.Screen name={'Yearbook'} component={Yearbook} />
      <YearbookStackNavigator.Screen
        name={'ProfilePage'}
        component={ProfilePage}
      />
      <YearbookStackNavigator.Screen
        name={'ProfilePageComments'}
        component={ProfilePageComments}
      />
      <YearbookStackNavigator.Screen
        name={'ProfilePageSetup'}
        component={ProfilePageSetup}
      />
      <YearbookStackNavigator.Screen
        name={'ProfilePageResults'}
        component={ProfilePageResults}
      />
      <YearbookStackNavigator.Screen name={'Quotes'} component={Quotes} />
      <YearbookStackNavigator.Screen
        name={'QuotesSetup'}
        component={QuotesSetup}
      />
      <YearbookStackNavigator.Screen name={'Rankings'} component={Rankings} />
      <YearbookStackNavigator.Screen
        name={'RankingsSetup'}
        component={RankingsSetup}
      />
      <YearbookStackNavigator.Screen name={'Collages'} component={Collages} />
      <YearbookStackNavigator.Screen
        name={'CollagesAlbumDetail'}
        component={CollagesAlbumDetail}
      />
      <YearbookStackNavigator.Screen
        name={'CollagesSetup'}
        component={CollagesSetup}
      />
      <YearbookStackNavigator.Screen name={'Reports'} component={Reports} />
      <YearbookStackNavigator.Screen
        name={'ReportsSetup'}
        component={ReportsSetup}
      />
      <YearbookStackNavigator.Screen name={'Manage'} component={Manage} />
      <YearbookStackNavigator.Screen
        name={'ManageRequest'}
        component={ManageRequest}
      />

      <YearbookStackNavigator.Screen
        name={'CollectedData'}
        component={CollectedData}
      />
      <YearbookStackNavigator.Screen
        name={'BookStatistics'}
        component={BookStatistics}
      />
      <YearbookStackNavigator.Screen name={'Custom'} component={Custom} />
      <YearbookStackNavigator.Screen
        name={'EditCustomPage'}
        component={EditCustomPage}
      />
      <YearbookStackNavigator.Screen
        name={'CustomSetup'}
        component={CustomSetup}
      />
      <YearbookStackNavigator.Screen
        name={'CustomPageResults'}
        component={CustomPageResults}
      />
      <YearbookStackNavigator.Screen
        name={'CustomPageResult'}
        component={CustomPageResult}
      />
      {IS_LANDSCAPE &&
        MOBILE_ROOT_YEARBOOK_SCREENS.map((screen) => (
          <YearbookStackNavigator.Screen key={screen.name} {...screen} />
        ))}
    </YearbookStackNavigator.Navigator>
  );
}

function ProfileStack(): JSX.Element {
  return (
    <ProfileStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <ProfileStackNavigator.Screen name={'Profile'} component={Profile} />
      <ProfileStackNavigator.Screen
        name={'ProfileSetup'}
        component={ProfileSetup}
      />
      <ProfileStackNavigator.Screen
        name={'GroupSetup'}
        component={GroupSetup}
      />
      <ProfileStackNavigator.Screen
        name={'GroupSettings'}
        component={GroupSettings}
      />
      <ProfileStackNavigator.Screen
        name={'NotificationSettings'}
        component={NotificationSettings}
      />
      <ProfileStackNavigator.Screen name={'AppSetup'} component={AppSetup} />
    </ProfileStackNavigator.Navigator>
  );
}

type MainNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList, 'MainTab'>,
  StackNavigationProp<MainTabParamList>
>;

const DrawerNavigator = createDrawerNavigator<MainTabParamList>();
const BottomTabNavigator = createBottomTabNavigator<MainTabParamList>();

const SCREENS: {
  name: keyof MainTabParamList;
  component: () => JSX.Element;
}[] = [
  { name: 'FeedStack', component: FeedStack },
  { name: 'PlanningStack', component: PlanningStack },
  { name: 'YearbookStack', component: YearbookStack },
  { name: 'ProfileStack', component: ProfileStack },
];

export default function MainTab(): JSX.Element {
  const INITIAL_ROUTE = 'FeedStack';

  const switchGroupsModalRef = useRef<ModalSwitchGroupsHandler>(null);

  const navigation = useNavigation<MainNavProp>();

  const { onAuthGroup } = useAuth();

  const onSelectedGroup = async (groupId: string, userGroupId: string) => {
    onAuthGroup(groupId, userGroupId);
    switchGroupsModalRef?.current?.close();
  };

  const onJoinGroup = () => {
    navigation.navigate('GroupStack', {
      screen: 'JoinSelectLocation',
    });
    switchGroupsModalRef?.current?.close();
  };

  const onLongPress = (label: string) => {
    PROFILE_NAV.includes(label) && switchGroupsModalRef?.current?.open();
  };

  const { groupUsers, loading, error } = useGroupUsers();

  if (Platform.OS === 'web' || IS_LANDSCAPE) {
    return (
      <DrawerNavigator.Navigator
        initialRouteName={INITIAL_ROUTE}
        screenOptions={{
          drawerType: 'permanent',
          drawerStyle: DESKTOP_DRAWER_STYLE,
          headerShown: false,
        }}
        drawerContent={(props) => <CustomDrawerContent {...props} />}
      >
        {SCREENS.map((screen) => (
          <DrawerNavigator.Screen key={screen.name} {...screen} />
        ))}
      </DrawerNavigator.Navigator>
    );
  }

  return (
    <>
      <ModalSwitchGroups
        ref={switchGroupsModalRef}
        onSelectedGroup={onSelectedGroup}
        onJoinGroup={onJoinGroup}
        groupUsers={groupUsers}
        loading={loading}
        error={error}
      />
      <BottomTabNavigator.Navigator
        initialRouteName={INITIAL_ROUTE}
        tabBar={(props) => {
          return <CustomTabBar {...props} onLongPress={onLongPress} />;
        }}
        screenOptions={{
          headerShown: false,
        }}
      >
        {SCREENS.map((screen) => (
          <BottomTabNavigator.Screen key={screen.name} {...screen} />
        ))}
      </BottomTabNavigator.Navigator>
    </>
  );
}
