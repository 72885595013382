import React, { useState } from 'react';
import Button from '~/components/Button';
import InputAreaField from '~/components/InputAreaField';
import { ModalScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  ScroolView,
  QuestionTitle,
  InputAreaFieldContainer,
  ButtonContent,
  ActionButtonsWrapper,
} from './style';

type AnswerType = 'update' | 'create';

export type ProfilePageAnswerLayoutProps = {
  questionTitle: string;
  maxChars: number;
  currentText?: string;
  loading: boolean;
  onBack: () => void;
  onAnswerQuestion: (text: string) => void;
  viewOnly?: boolean;
  answerType: AnswerType;
  onDeleteAnswer: () => void;
};

export default function ProfilePageAnswer({
  questionTitle,
  maxChars,
  currentText = '',
  loading,
  onBack,
  onAnswerQuestion,
  viewOnly = false,
  answerType,
  onDeleteAnswer,
}: ProfilePageAnswerLayoutProps): JSX.Element {
  const [text, setText] = useState(currentText);
  return (
    <ModalScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.answerQuestion')}
      onBackPress={onBack}
    >
      <ScroolView showsVerticalScrollIndicator={false}>
        <QuestionTitle>{questionTitle}</QuestionTitle>
        <InputAreaFieldContainer>
          <InputAreaField
            testID={'inputText'}
            value={text}
            placeholder={t('profilePageAnswer.textPlaceholder')}
            maxCharCount={maxChars}
            onChangeText={setText}
            editable={!viewOnly}
          />
        </InputAreaFieldContainer>
        <ButtonContent>
          {!viewOnly && (
            <ActionButtonsWrapper>
              <Button
                testID={'buttonSaveAnswer'}
                state={text.length > maxChars ? 'disabled' : 'default'}
                text={t('profilePageAnswer.saveAnswer')}
                loading={loading}
                flex
                onPress={() => onAnswerQuestion(text)}
              />
              {answerType === 'update' && (
                <Button
                  testID={'buttonDeleteAnswer'}
                  text={t('profilePageAnswer.deleteAnswer')}
                  type="destructive"
                  loading={loading}
                  flex
                  onPress={() => onDeleteAnswer()}
                />
              )}
            </ActionButtonsWrapper>
          )}
        </ButtonContent>
      </ScroolView>
    </ModalScreenLayout>
  );
}
