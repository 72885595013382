import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import Button from '~/components/Button';
import { Container, Content, Title, Descp } from './style';

export type ModalConfirmHandler = Modalize;

export type ModalConfirmProps = {
  testID?: string;
  buttonTestID?: string;
  title: string;
  descp: string;
  buttonText: string;
  loading?: boolean;
  contentOnly?: boolean;
  onConfirm: () => void;
};

const ModalConfirm: ForwardRefRenderFunction<
  ModalConfirmHandler,
  ModalConfirmProps
> = (
  {
    testID,
    buttonTestID,
    title,
    descp,
    buttonText,
    loading,
    contentOnly,
    onConfirm,
  },
  ref,
) => {
  const renderContent = () => {
    return (
      <Content>
        <Title>{title}</Title>
        <Descp>{descp}</Descp>
        <Button
          testID={buttonTestID}
          loading={loading}
          text={buttonText}
          flex
          flexScreenContainer="stack"
          onPress={() => onConfirm()}
        />
      </Content>
    );
  };

  if (contentOnly) {
    return renderContent();
  }

  return (
    <Container testID={testID}>
      <Portal>
        <Modalize ref={ref} adjustToContentHeight handlePosition={'inside'}>
          {renderContent()}
        </Modalize>
      </Portal>
    </Container>
  );
};

export default forwardRef(ModalConfirm);
