import { gql } from './../../types';

export const MARKET_PROFILE_FRAGMENT = gql(`
  fragment MarketProfile on MarketProfileNode {
    id
    name
  }
`);

export const MARKET_PROFILES = gql(`
  query MarketProfiles {
    marketProfiles {
      edges {
        node {
          ...MarketProfile
        }
      }
    }
  }
`);
