import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';
import Avatar from '~/components/Avatar';
import Image from '~/components/Image';

const LNBG_HEIGHT = 220;

export const ScrollView = styled.ScrollView``;

export const LNContainer = styled(LinearGradient).attrs(({ theme }) => ({
  colors: [theme.color.brand_01, theme.color.brand_01, theme.color.brand_01],
  start: { x: 0, y: 0 },
  end: { x: 1, y: 1 },
}))`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: ${({ theme }) => LNBG_HEIGHT + theme.safeAreaInsets.top}px;
`;

export const ProfileAvatar = styled(Avatar)`
  border-radius: 5px;
  margin-right: 16px;
`;

export const NameText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 24px;
  flex: 1;
`;

export const Header = styled.View`
  padding-top: 24px;
  padding-horizontal: 16px;
  padding-bottom: 24px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

export const Content = styled.View`
  padding-horizontal: 16px;
  padding-bottom: 48px;
`;

export const CardView = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  box-shadow: 1px 2px 6px rgb(233, 236, 239);
  border-radius: 5px;
  padding: 16px;
`;

export const CardHeader = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

export const GroupImage = styled(Image)`
  width: 48px;
  height: 48px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.base.c1};
`;

export const CardInfo = styled.View`
  flex: 1;
  padding-left: 16px;
`;

export const GroupName = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 18px;
`;

export const GroupDetail = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
`;

interface IVSpacer {
  height: number;
}
export const VSpacer = styled.View<IVSpacer>`
  height: ${({ height }) => height}px;
`;

export const LatestVersionText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 14px;
  line-height: 24px;
`;

export const VersionText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
  line-height: 24px;
`;

export const TeamButtonWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const MiniAdsTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.base.c6};
  margin-bottom: -8px;
`;
