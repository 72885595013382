import React, { useRef } from 'react';
import ToDoItemCell, { ToDoItemHandle } from '~/components/ToDoItemCell';
import { ToDoItem } from '~/data/models/todo';
import { TabScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  AddIcon,
  CircularButton,
  CircularButtonText,
  List,
  SectionHeader,
} from './style';

type ToDoTasksLayoutProps = {
  title: string;
  items: ToDoItem[];
  onBack: () => void;
  onStateChanged: (item: ToDoItem, checked: boolean) => void;
  onPressed: (item: ToDoItem) => void;
  onAddTask: () => void;
  onDeleteTask: (itemId: string) => void;
  onEditTask: (itemId: string) => void;
};

type Section = {
  title: string;
  data: ToDoItem[];
  finished: boolean;
};

export default function ToDoTasks({
  title,
  items,
  onBack,
  onStateChanged,
  onPressed,
  onAddTask,
  onDeleteTask,
  onEditTask,
}: ToDoTasksLayoutProps): JSX.Element {
  const todosRef = useRef<{ [key: string]: ToDoItemHandle }>({});

  const finishedItems = items.filter((item) => item.finished);
  const unfinishedItems = items.filter((item) => !item.finished);

  const renderItem = ({ item: todo }: { item: ToDoItem }) => {
    return (
      <>
        <ToDoItemCell
          testID={todo.id}
          ref={(ref) => {
            if (ref) {
              todosRef.current[todo.id] = ref;
            }
          }}
          item={todo}
          onChecked={() => onStateChanged(todo, !todo.finished)}
          onPress={() => onPressed(todo)}
          onDelete={() => {
            //collapse via ref so it's easier to integrate to a confirmation dialog or something`,
            todosRef.current[todo.id].collapse();
            onDeleteTask(todo.id);
          }}
          onEdit={() => onEditTask(todo.id)}
        />
      </>
    );
  };

  const renderSectionItem = ({ section: { title } }: { section: Section }) => (
    <SectionHeader title={title} />
  );

  const renderSectionFooter = ({
    section: { finished },
  }: {
    section: Section;
  }) =>
    !finished && (
      <CircularButton testID={'buttonAddOption'} onPress={onAddTask}>
        <AddIcon />
        <CircularButtonText numberOfLines={1}>
          {t('g.addOption')}
        </CircularButtonText>
      </CircularButton>
    );

  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={title}
      onBackPress={onBack}
    >
      <List<React.ElementType>
        sections={[
          {
            title: t('toDoList.todosHeader'),
            data: unfinishedItems,
            finished: false,
          },
          {
            title: t('toDoList.finishedHeader'),
            data: finishedItems,
            finished: true,
          },
        ]}
        renderItem={renderItem}
        renderSectionHeader={renderSectionItem}
        renderSectionFooter={renderSectionFooter}
        keyExtractor={(item: ToDoItem) => item.id}
      />
    </TabScreenLayout>
  );
}
