import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import AdminHeader from '~/components/AdminHeader';
import CountryTypePicker, { CountryType } from '~/components/CountryPicker';
import InputField, { InputFieldHandle } from '~/components/InputField';
import SchoolTypePicker, { SchoolType } from '~/components/SchoolTypePicker';
import { WebContainer } from '~/components/WebGrid';
import {
  Container,
  FormContainer,
  SectionLabel,
  FlexRow,
  Spacer,
  GrownFlex,
  InputWrapper,
  InputFormWrapper,
} from '~/components/common/style.web';
import { GraduationType } from '~/data/models/group';
import { InstituteType } from '~/data/models/institute';
import { MarketProfile } from '~/data/models/marketProfile';
import { CreateInstituteInput } from '~/data/types/graphql';
import { t } from '~/utils/i18n';
import { allowToSaveChangesAllFilled } from '../../utils/FormUtils';

type InstituteAddLayoutProps = {
  onBack: () => void;
  openDrawer: () => void;
  onCreateInstitute: (intitute: CreateInstituteInput) => Promise<boolean>;
  instituteType: InstituteType[];
  marketProfiles: MarketProfile[];
  loading: boolean;
  countrySelected: CountryType | undefined;
  setCountrySelected: React.Dispatch<
    React.SetStateAction<CountryType | undefined>
  >;
};

export default function InstituteAdd({
  onBack,
  openDrawer,
  onCreateInstitute,
  instituteType = [],
  marketProfiles = [],
  loading,
  countrySelected,
  setCountrySelected,
}: InstituteAddLayoutProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });

  // ____FORM REFS____
  const instituteNameRef = useRef<InputFieldHandle>(null);
  const instituteStreetRef = useRef<InputFieldHandle>(null);
  const instituteZipRef = useRef<InputFieldHandle>(null);
  const instituteCityRef = useRef<InputFieldHandle>(null);
  const instituteWebsiteRef = useRef<InputFieldHandle>(null);

  // ____FORM STATE____
  const [instituteName, setInstituteName] = useState<string>('');
  const [instituteStreet, setInstituteStreet] = useState<string>('');
  const [instituteZip, setInstituteZip] = useState<string>('');
  const [instituteCity, setInstituteCity] = useState<string>('');
  const [instituteWebsite, setInstituteWebsite] =
    useState<string>('wwww.grado.com');

  const [instituteSelected, setInstituteSelected] = useState<
    SchoolType | undefined
  >(undefined);

  const [marketProfileType, setMarketProfileType] = useState<
    SchoolType | undefined
  >(undefined);

  const enableSaveChanges = allowToSaveChangesAllFilled([
    ['', instituteName],
    ['', instituteStreet],
    ['', instituteZip],
    ['', instituteCity],
    ['', instituteWebsite],
    [undefined, countrySelected],
    [undefined, instituteSelected],
    [undefined, marketProfileType],
  ]);
  const resetForm = () => {
    setInstituteName('');
    setInstituteStreet('');
    setInstituteZip('');
    setInstituteCity('');
    setInstituteWebsite('');
    setCountrySelected(undefined);
    setInstituteSelected(undefined);
    setMarketProfileType(undefined);
  };

  const submitInstitute = async () => {
    const result = await onCreateInstitute({
      name: instituteName,
      zip: instituteZip,
      country: countrySelected?.country as string,
      marketProfileName: marketProfileType?.name as string,
      city: instituteCity,
      instituteTypeName: instituteSelected?.name as string,
      street: instituteStreet,
      website: instituteWebsite,
      isPrivate: false,
      location: instituteStreet,
    });

    if (result) {
      resetForm();
    }
  };

  return (
    <Container>
      <AdminHeader
        onBack={onBack}
        title={t('screens.instituteAdd')}
        breadcrumbs={['Institutes', 'InstituteAdd']}
        onSubmitClick={submitInstitute}
        enableSaveChanges={enableSaveChanges && !loading}
        buttonTitle={t('adminPanelSearch.addInstitute')}
        onOpenDrawer={openDrawer}
      />
      <WebContainer isDesktop={isDesktop}>
        <FormContainer isDesktop={isDesktop}>
          <InputFormWrapper isDesktop={isDesktop}>
            <FlexRow>
              <GrownFlex>
                <SectionLabel>
                  {t('adminPanelInstitutes.instituteDetails')}
                </SectionLabel>
                <Spacer v={24} />
                <InputFormWrapper>
                  <InputField
                    ref={instituteNameRef}
                    testID={'inputName'}
                    iconName={'users-01'}
                    label={t('instituteRequest.instituteName')}
                    placeholder={t('createGroup.namePlaceholder')}
                    value={instituteName}
                    onChangeText={(value) => setInstituteName(value)}
                    blurOnSubmit={false}
                    returnKeyType={'next'}
                  />
                  <InputField
                    ref={instituteStreetRef}
                    testID={'inputName'}
                    iconName={'users-01'}
                    label={t('instituteRequest.street')}
                    placeholder={t('createGroup.namePlaceholder')}
                    value={instituteStreet}
                    onChangeText={(value) => setInstituteStreet(value)}
                    blurOnSubmit={false}
                    returnKeyType={'next'}
                  />
                  <FlexRow isDesktop>
                    <InputWrapper>
                      <InputField
                        ref={instituteZipRef}
                        testID={'inputName'}
                        iconName={'users-01'}
                        label={t('instituteRequest.zip')}
                        placeholder={t('createGroup.namePlaceholder')}
                        value={instituteZip}
                        onChangeText={(value) => setInstituteZip(value)}
                        blurOnSubmit={false}
                        returnKeyType={'next'}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <InputField
                        ref={instituteCityRef}
                        testID={'inputName'}
                        iconName={'users-01'}
                        label={t('instituteRequest.city')}
                        placeholder={t('createGroup.namePlaceholder')}
                        value={instituteCity}
                        onChangeText={(value) => setInstituteCity(value)}
                        blurOnSubmit={false}
                        returnKeyType={'next'}
                      />
                    </InputWrapper>
                  </FlexRow>
                </InputFormWrapper>
                <FlexRow>
                  <InputFormWrapper>
                    <CountryTypePicker
                      testID={'pickerSchool'}
                      toggleTestID={'buttonPickerToggle'}
                      label={t('adminPanelInstitutes.country')}
                      selectedCountryType={countrySelected}
                      initialShowPicker={false}
                      onSelectedCountryType={(countryType) => {
                        setCountrySelected(countryType);
                      }}
                    />
                  </InputFormWrapper>
                </FlexRow>
                <Spacer v={20} />
                <FlexRow>
                  <InputFormWrapper>
                    <SchoolTypePicker
                      label={t('adminPanelInstitutes.instituteType')}
                      schoolTypes={instituteType}
                      selectedSchoolType={instituteSelected}
                      initialShowPicker={false}
                      onSelectedSchoolType={(instituteType) =>
                        setInstituteSelected(instituteType as InstituteType)
                      }
                    />
                  </InputFormWrapper>
                </FlexRow>
                <Spacer v={20} />
                <FlexRow>
                  <InputFormWrapper>
                    <SchoolTypePicker
                      label={t('adminPanelInstitutes.marketProfile')}
                      schoolTypes={marketProfiles}
                      selectedSchoolType={marketProfileType}
                      initialShowPicker={false}
                      onSelectedSchoolType={(MarketProfileType) =>
                        setMarketProfileType(
                          MarketProfileType as GraduationType,
                        )
                      }
                    />
                  </InputFormWrapper>
                </FlexRow>
                <Spacer v={25} />
                <FlexRow>
                  <InputFormWrapper>
                    <InputField
                      ref={instituteWebsiteRef}
                      testID={'inputName'}
                      iconName={'users-01'}
                      label={t('instituteRequest.website')}
                      placeholder={t('createGroup.namePlaceholder')}
                      value={instituteWebsite}
                      onChangeText={(value) => setInstituteWebsite(value)}
                      blurOnSubmit={false}
                      returnKeyType={'next'}
                    />
                  </InputFormWrapper>
                </FlexRow>
              </GrownFlex>
            </FlexRow>
          </InputFormWrapper>
        </FormContainer>
      </WebContainer>
    </Container>
  );
}
