import React, { useRef, useState } from 'react';
import InputField, { InputFieldHandle } from '~/components/InputField';
import { SIGN_UP_TOTAL_STEPS } from '~/screens/Auth/constants';
import AuthLayout from '~/screens/Auth/layout/AuthLayout';
import {
  LanguageProps,
  SubmitButtonProps,
} from '~/screens/Auth/layout/AuthLayout/types';
import validateField from '~/screens/Auth/utils/validateField';
import { t } from '~/utils/i18n';
import { validatePassword } from '~/utils/validators';

type SignUpPasswordLayoutProps = {
  inputProgress: number;
  initialPassword: string;
  initalPasswordConfirmation: string;
  onBack: () => void;
  onNext: (password: string) => void;
} & LanguageProps;

export default function SignUpPassword({
  inputProgress,
  selectedLocale,
  onBack,
  onNext,
  onSelectedLocale,
  initialPassword,
  initalPasswordConfirmation,
}: SignUpPasswordLayoutProps): JSX.Element {
  const passwordRef = useRef<InputFieldHandle>(null);
  const passwordConfirmationRef = useRef<InputFieldHandle>(null);

  const [password, setPassword] = useState<string>(initialPassword || '');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>(
    initalPasswordConfirmation || '',
  );

  const { state: passwordState, info: passwordInfo } = validateField({
    field: password,
    detectErrors: validatePassword,
    info: {
      success: t('inputValidation.validPassword'),
    },
  });

  const { state: passwordConfirmationState, info: passwordConfirmationInfo } =
    validateField({
      field: passwordConfirmation,
      detectErrors: validatePassword,
      isValidField: (field) => password === field,
      info: {
        error: t('inputValidation.passwordsNotMatch'),
        success: t('inputValidation.passwordsMatch'),
      },
    });

  const isValidInput = (): boolean => {
    return (
      passwordState === 'success' && passwordConfirmationState === 'success'
    );
  };

  const handleNext = () => {
    isValidInput() && onNext && onNext(password);
  };

  const headerProps = {
    title: t('signUpPassword.title'),
    descp: t('signUpPassword.descp'),
    progress: inputProgress,
    total: SIGN_UP_TOTAL_STEPS,
  };

  const submitButtonProps: SubmitButtonProps = {
    isValidInput,
    text: t('next'),
    onPress: handleNext,
    minWidth: 260,
    size: '2xl',
  };

  const languageProps = {
    selectedLocale,
    onSelectedLocale,
  };

  return (
    <AuthLayout
      onBack={onBack}
      headerProps={headerProps}
      submitButtonProps={submitButtonProps}
      languageProps={languageProps}
    >
      <>
        <InputField
          ref={passwordRef}
          testID={'inputPassword'}
          state={passwordState}
          infoLabel={passwordInfo}
          iconName={'lock-02'}
          label={t('signUpPassword.passwordLabel')}
          placeholder={t('signUpPassword.passwordPlaceholder')}
          value={password}
          onChangeText={(value) => setPassword(value)}
          blurOnSubmit={false}
          returnKeyType={'next'}
          handleSecureTextEntry
          autoCapitalize={'none'}
          onSubmitEditing={() => passwordConfirmationRef.current?.focus()}
        />
        <InputField
          ref={passwordConfirmationRef}
          testID={'inputPasswordConfirmation'}
          state={passwordConfirmationState}
          infoLabel={passwordConfirmationInfo}
          iconName={'lock-02'}
          label={t('signUpPassword.passwordConfirmationLabel')}
          placeholder={t('signUpPassword.passwordConfirmationPlaceholder')}
          value={passwordConfirmation}
          blurOnSubmit={false}
          handleSecureTextEntry
          returnKeyType={'next'}
          autoCapitalize={'none'}
          onChangeText={(value) => setPasswordConfirmation(value)}
          onSubmitEditing={() => handleNext()}
        />
      </>
    </AuthLayout>
  );
}
