import { EditInstituteGroup } from '~/data/models/admin';

interface IGroupsDatabaseHelpers {
  filterGroupsByYear(
    year: string,
    groups: EditInstituteGroup[],
  ): EditInstituteGroup[];
}

export default <IGroupsDatabaseHelpers>{
  filterGroupsByYear(year: string, groups: EditInstituteGroup[]) {
    return groups.filter((group) =>
      String(group.year).toLocaleLowerCase().includes(year.toLocaleLowerCase()),
    );
  },
};
