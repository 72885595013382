import React, { useState } from 'react';
import Carousel from 'react-native-snap-carousel';
import { CollagesPhoto } from '~/data/models/yearbook';
import { isCollagePhotoLiked } from '~/data/utils';
import { TabScreenLayout } from '~/screens/style';
import { formattedDate } from '~/utils/dates';
import { t } from '~/utils/i18n';
import {
  Content,
  PhotoCardContainer,
  PhotoCardImage,
  PhotoCardInfo,
  PhotoCardInfoText,
  PhotoCardInfoTextBold,
  Footer,
  LikeButtonIcon,
  DeleteButtonIcon,
  ButtonWrapper,
  ButtonText,
  CONTAINER_WIDTH,
  PHOTO_CARD_WIDTH,
  DownloadButtonIcon,
} from './style';

export type CollagesPhotoDetailLayoutProps = {
  collageAlbumName?: string;
  collagesPhotos: CollagesPhoto[];
  initialPhotoIndex: number;
  deletePhotoLoading: boolean;
  viewOnly?: boolean;
  onBack: () => void;
  onLikePhoto: (collagePhotoId: string, liked: boolean) => void;
  onDeletePhoto: (collagePhotoId: string) => void;
  onDownloadImage: (collagePhotoUri: string) => void;
  authUserGroupId: string;
  yearbookAdmin: boolean;
};

export default function CollagesPhotoDetail({
  collageAlbumName,
  collagesPhotos,
  initialPhotoIndex,
  deletePhotoLoading,
  viewOnly,
  onBack,
  onLikePhoto,
  onDeletePhoto,
  onDownloadImage,
  authUserGroupId,
  yearbookAdmin,
}: CollagesPhotoDetailLayoutProps): JSX.Element {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(initialPhotoIndex);

  const renderCardItem = ({ item: collagesPhoto }: { item: CollagesPhoto }) => (
    <PhotoCardContainer>
      <PhotoCardImage
        source={{ uri: collagesPhoto.photo }}
        contentFit={'contain'}
      />
      <PhotoCardInfo>
        <PhotoCardInfoText>
          <PhotoCardInfoTextBold>
            {collagesPhoto.collagesPhotoLikesCount}
          </PhotoCardInfoTextBold>
          {` ${t('g.likes').toLowerCase()}`}
        </PhotoCardInfoText>
        <PhotoCardInfoText>
          {`${t('g.uploadedAt')} ${formattedDate(
            collagesPhoto.created,
            'dd.MM.yyyy',
          )}`}
        </PhotoCardInfoText>
      </PhotoCardInfo>
    </PhotoCardContainer>
  );
  const currentPhoto = collagesPhotos[currentPhotoIndex];

  const isDeletable =
    currentPhoto?.userGroup.id === authUserGroupId || yearbookAdmin;

  const currentPhotoLiked = currentPhoto?.id
    ? isCollagePhotoLiked(currentPhoto)
    : false;

  return (
    <TabScreenLayout
      bgColorKey="c9"
      firstButtonTestID={'buttonBack'}
      title={collageAlbumName || ''}
      colorStyle={'light'}
      firstButtonIconName={'x-close'}
      onFirstButtonPress={onBack}
    >
      <Content>
        <Carousel
          data={collagesPhotos}
          renderItem={renderCardItem}
          sliderWidth={CONTAINER_WIDTH}
          itemWidth={PHOTO_CARD_WIDTH}
          inactiveSlideScale={0.9}
          inactiveSlideOpacity={0.7}
          initialNumToRender={collagesPhotos.length}
          firstItem={initialPhotoIndex}
          onSnapToItem={setCurrentPhotoIndex}
          removeClippedSubviews={true}
        />
      </Content>
      {!viewOnly && (
        <Footer>
          <ButtonWrapper>
            <LikeButtonIcon
              testID={'likeButton'}
              name={'thumbs-up'}
              liked={currentPhotoLiked}
              onPress={() => onLikePhoto(currentPhoto.id, currentPhotoLiked)}
            />
            <ButtonText>{t('g.like')}</ButtonText>
          </ButtonWrapper>
          <ButtonWrapper>
            <DownloadButtonIcon
              testID={'downloadButton'}
              styledName="download"
              onPress={() => onDownloadImage(currentPhoto.photo)}
            />
            <ButtonText>{t('g.download')}</ButtonText>
          </ButtonWrapper>
          {isDeletable && (
            <ButtonWrapper>
              <DeleteButtonIcon
                testID={'deleteButton'}
                name={'trash-01'}
                loading={deletePhotoLoading}
                onPress={() => onDeletePhoto(currentPhoto.id)}
              />
              <ButtonText>{t('g.delete')}</ButtonText>
            </ButtonWrapper>
          )}
        </Footer>
      )}
    </TabScreenLayout>
  );
}
