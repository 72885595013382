import { ScrollView } from 'react-native-gesture-handler';
import styled from 'styled-components/native';
import ImageComp from '~/components/Image';

export const ImageBanner = styled(ImageComp)`
  background-color: ${({ theme }) => theme.color.base.c3};
  aspect-ratio: 1.8;
  align-items: center;
  justify-content: center;
  height: 310px;
  max-width: 220px;
  margin: 0 16px;
`;

export const ImageContent = styled.View`
  padding: 8px 0;
  background-color: ${({ theme }) => theme.color.base.c1};
  height: 370px;
`;

export const ImageDescriptionContainer = styled.View`
  margin: 20px 16px;
  justify-content: flex-end;
  align-items: end;
`;

export const ImageDescription = styled.Text`
  color: ${({ theme }) => theme.color.base.c8};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  line-height: 20px;
  margin-bottom: 4px;
`;

export const FlexContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
`;

export const OwnerContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

export const OwnerName = styled.Text`
  font-size: 18px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  margin-left: 8px;
`;

export const SaveButton = styled.View`
  position: fixed;
  margin-bottom: 0px;
  padding: 16px;
`;

export const ContainerWrapper = styled(ScrollView)`
  height: 500px;
`;

export const PDFWrapper = styled.View`
  padding-horizontal: 16px;
`;
