import { gql } from './../../types';

export const GRADUATION_TYPE_FRAGMENT = gql(`
  fragment GraduationType on GraduationTypeNode {
    id
    name
  }
`);

export const GROUP_FRAGMENT = gql(`
  fragment Group on GroupNode {
    id
    name
    avatar
    icon {
      ...GroupIcon
    }
    type {
      ...GraduationType
    }
    year
    token
    created
    institute {
      id
      internalId
      type {
        id
        name
      }
      country
      name
      street
      zip
      city
    }
  }
`);

export const USER_GROUP_FRAGMENT = gql(`
  fragment UserGroup on UserGroupNode {
    id
    role
    avatar
    defaultAvatar {
      ...DefaultAvatar
    }
    status
    user {
      ...User
    }
    created
  }
`);

export const GROUP_USER_FRAGMENT = gql(`
  fragment GroupUser on UserGroupNode {
    ...UserGroup
    group {
      ...Group
    }
  }
`);

export const GROUP_SAMPLE_FRAGMENT = gql(`
  fragment GroupSample on GroupNode {
    ...Group
    usersCount
    sampleMembers {
      ...UserGroup
    }
  }
`);

export const GROUP_DETAIL_FRAGMENT = gql(`
  fragment GroupDetail on GroupNode {
    ...Group
    usersCount
    institute {
      ...Institute
    }
  }
`);

export const GRADUATION_TYPES = gql(`
  query GraduationTypes($after: String, $first: Int!) {
    graduationTypes(after: $after, first: $first) {
      edges {
        node {
          ...GraduationType
        }
      }
    }
  }
`);

export const GRADUATION_TYPES_COUNTRY = gql(`
  query GraduationTypesCountry($id: String, $name: String, $country: String) {
    graduationTypesCountry(id: $id, name: $name, country: $country) {
      id
      name
      country
    }
  }
`);

export const YEARBOOK_STATISTICS_FRAGMENT = gql(`
  fragment YearbookStatistics on YearbookStatisticsNode {
    profilePagesFilledIn
    usersWithQuote
    lastDeadline
    contentModules
    estimatedPages
    imagesUploaded
    wordCount
  }
`);

export const GROUP_STATISTICS_FRAGMENT = gql(`
  fragment GroupStatistics on GroupNode {
    id
    yearbookStatistics {
      ...YearbookStatistics
    }
  }
`);

export const GROUPS = gql(`
  query Groups(
    $after: String
    $first: Int!
    $name: String
    $type: ID
    $year: Int
    $institute: ID
    $marketProfile: ID
  ) {
    groups(
      after: $after
      first: $first
      name: $name
      type: $type
      year: $year
      institute: $institute
      marketProfile: $marketProfile
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Group
        }
      }
    }
  }
`);

export const GROUP = gql(`
  query Group($id: ID!) {
    group(id: $id) {
      ...GroupDetail
      teams {
        id
        name
      }
    }
  }
`);

export const GROUP_SAMPLE = gql(`
  query GroupSample($id: ID!) {
    group(id: $id) {
      ...GroupSample
    }
  }
`);

export const GROUP_STATISTICS = gql(`
  query GroupStatistics($id: ID!) {
    group(id: $id) {
      ...GroupStatistics
    }
  }
`);

export const ADD_USER_TO_GROUP = gql(`
  mutation AddUserToGroup($input: AddUserToGroupInput!) {
    addUserToGroup(input: $input) {
      userGroup {
        ...UserGroup
      }
    }
  }
`);

export const ACCEPT_USER_IN_GROUP = gql(`
  mutation AcceptUserInGroup($input: AcceptUserInGroupInput!) {
    acceptUserInGroup(input: $input) {
      userGroup {
        ...UserGroup
      }
    }
  }
`);

export const REMOVE_USER_FROM_GROUP = gql(`
  mutation RemoveUserFromGroup($input: RemoveUserFromGroupInput!) {
    removeUserFromGroup(input: $input) {
      userGroup {
        ...UserGroup
      }
    }
  }
`);

//UserGroupNode by USER_GROUPS query returns all user (assuming the user inside the app) for a given group
export const USER_GROUPS = gql(`
  query UserGroups(
    $after: String
    $first: Int!
    $group: ID
    $status: GroupsUserGroupStatusChoices
  ) {
    userGroups(after: $after, first: $first, group: $group, status: $status) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...UserGroup
        }
      }
    }
  }
`);

//UserGroupNode by GROUP_USERS query returns all user (with group) for a given user
export const GROUP_USERS = gql(`
  query GroupUsers(
    $after: String
    $first: Int!
    $user: ID
    $status: GroupsUserGroupStatusChoices
  ) {
    userGroups(after: $after, first: $first, user: $user, status: $status) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...GroupUser
        }
      }
    }
  }
`);

export const USER_GROUP = gql(`
  query UserGroup($id: ID!) {
    userGroup(id: $id) {
      ...UserGroup
    }
  }
`);

export const CREATE_GROUP = gql(`
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      group {
        ...Group
      }
      errors {
        field
        messages
      }
    }
  }
`);

export const UPDATE_GROUP = gql(`
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      group {
        ...Group
      }
      errors {
        field
        messages
      }
    }
  }
`);

export const SET_GROUP_AVATAR = gql(`
  mutation SetGroupAvatar($input: SetGroupAvatarInput!) {
    setGroupAvatar(input: $input) {
      group {
        ...Group
      }
    }
  }
`);

export const SET_USER_GROUP_AVATAR = gql(`
  mutation SetUserGroupAvatar($input: SetUserGroupAvatarInput!) {
    setUserGroupAvatar(input: $input) {
      userGroup {
        ...UserGroup
      }
    }
  }
`);

export const UPDATE_USER_GROUP = gql(`
  mutation UpdateUserGroup($input: UpdateUserGroupInput!) {
    updateUserGroup(input: $input) {
      userGroup {
        ...UserGroup
      }
    }
  }
`);

export const IMPORT_TO_ERP = gql(`
  mutation ImportToErp($input: ImportToErpInput!) {
    importToErp(input: $input) {
      success
    }
  }
`);

export const LEADERBOARD_USER_FRAGMENT = gql(`
  fragment LeaderboardUser on LeaderboardUserNode {
    user {
      ...User
    }
    points
    position
  }
`);

export const LEADERBOARD_USERS = gql(`
  query LeaderboardUsers(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $user: ID
  ) {
    leaderboardUsers(
      before: $before
      after: $after
      first: $first
      last: $last
      user: $user
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...LeaderboardUser
        }
      }
    }
  }
`);
