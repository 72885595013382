import React from 'react';
import { Icon } from '~/data/models/custom';
import { ArrowIcon, Container, CounterBadge, Text } from './style';

type ToDoListCellProps = {
  testID: string;
  listTitle: string;
  tasksUnfinished: number;
  icon: Icon | null;
  onPress: () => void;
};

export default function ToDoListCell({
  testID,
  listTitle,
  tasksUnfinished,
  // icon,
  onPress,
}: ToDoListCellProps): JSX.Element {
  return (
    <Container testID={testID} onPress={onPress}>
      {/* <IconContent>
        {icon?.icon ? <ToDoItemIcon name={icon.icon as IconName} /> : null}
      </IconContent> */}
      <Text>{listTitle}</Text>
      {tasksUnfinished > 0 && (
        <CounterBadge>{`${tasksUnfinished}`}</CounterBadge>
      )}
      <ArrowIcon />
    </Container>
  );
}
