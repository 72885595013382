import { ApolloCache } from '@apollo/client';
import {
  ADMIN_GET_GRADOO_STAFF,
  ADMIN_GET_GROUP,
  ADMIN_GET_INSTITUTE,
  ADMIN_GET_USER,
} from './';
import {
  AdminGetGradooStaffQuery as AdminGetGradooStaff,
  AdminGetGradooStaffQueryVariables as AdminGetGradooStaffVariables,
  AdminGetGroupQuery as AdminGetGroup,
  AdminGetGroupQueryVariables as AdminGetGroupVariables,
  AdminGetInstituteQuery as AdminGetInstitute,
  AdminGetInstituteQueryVariables as AdminGetInstituteVariables,
  AdminGetUserQuery as AdminGetUser,
  AdminGetUserQueryVariables as AdminGetUserVariables,
} from './../../types/graphql';

export function readAdminGetGroupQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: AdminGetGroupVariables;
}): AdminGetGroup | null {
  return cache.readQuery<AdminGetGroup>({
    query: ADMIN_GET_GROUP,
    variables,
  });
}

export function readAdminGetUserQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: AdminGetUserVariables;
}): AdminGetUser | null {
  return cache.readQuery<AdminGetUser>({
    query: ADMIN_GET_USER,
    variables,
  });
}

export function writeAdminGetUserQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: AdminGetUserVariables;
  data: AdminGetUser;
}): void {
  cache.writeQuery<AdminGetUser>({
    query: ADMIN_GET_USER,
    variables,
    data,
  });
}

export function readAdminGetInstituteQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: AdminGetInstituteVariables;
}): AdminGetInstitute | null {
  return cache.readQuery<AdminGetInstitute>({
    query: ADMIN_GET_INSTITUTE,
    variables,
  });
}

export function readAdminGetStaffQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: AdminGetGradooStaffVariables;
}): AdminGetGradooStaff | null {
  return cache.readQuery<AdminGetGradooStaff>({
    query: ADMIN_GET_GRADOO_STAFF,
    variables,
  });
}

export function writeAdminGetStaffQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: AdminGetGradooStaffVariables;
  data: AdminGetGradooStaff;
}): void {
  cache.writeQuery<AdminGetGradooStaff>({
    query: ADMIN_GET_GRADOO_STAFF,
    variables,
    data,
  });
}
