import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ReactNativeFile } from 'apollo-upload-client';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import { DefaultAvatar } from '~/data/models/custom';
import { Country } from '~/data/models/marketProfile';
import { UPDATE_ME } from '~/data/operations/auth';
import { DEFAULT_AVATARS } from '~/data/operations/custom';
import {
  SET_USER_GROUP_AVATAR,
  UPDATE_USER_GROUP,
} from '~/data/operations/group';
import { setAuth } from '~/data/storage';
import { UserType } from '~/data/types/graphql';
import { ProfileStackParamList, RootStackParamList } from '~/navigation/types';
import { getFileName } from '~/utils/helpers';
import ProfileSetupLayout, { MeInput, ProfileSetupLoading } from './layout';

type ProfileSetupNavProp = CompositeNavigationProp<
  StackNavigationProp<ProfileStackParamList, 'ProfileSetup'>,
  StackNavigationProp<RootStackParamList>
>;

export default function ProfileSetup(): JSX.Element {
  const navigation = useNavigation<ProfileSetupNavProp>();

  const {
    country,
    authGroupId,
    authUserGroupId,
    authUserGroup,
    onLogout,
    onUpdateCountry,
  } = useAuth();

  const authUser = authUserGroup?.user;

  const { data: defaultAvatarsData } = useQuery(DEFAULT_AVATARS, {
    variables: {
      first: NO_PAG_LIST_OFFSET,
    },
  });
  const defaultAvatars: DefaultAvatar[] =
    (defaultAvatarsData?.defaultAvatars?.edges.map(
      (edge) => edge?.node,
    ) as DefaultAvatar[]) || [];

  const [updateMe] = useMutation(UPDATE_ME);
  const [updateUserGroup] = useMutation(UPDATE_USER_GROUP);
  const [setUserGroupAvatar, { loading: setUserGroupAvatarLoading }] =
    useMutation(SET_USER_GROUP_AVATAR);

  const onUpdateMe = async ({
    firstName,
    lastName,
    email,
    phone,
    gender,
    updateToken,
  }: MeInput) => {
    try {
      if (!authUser) {
        return;
      }

      const { data } = await updateMe({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            phone,
            phoneCountryCode: authUser.phoneCountryCode,
            gender,
            communicationLanguage: authUser.communicationLanguage,
            countryOfResidence: country,
          },
        },
      });

      const messages = data?.updateMe?.errors?.map(
        (error) => error?.messages[0],
      );

      const errorMessage = messages?.[0];
      if (errorMessage) {
        Snackbar.show(errorMessage);
        return;
      }

      const authToken = data?.updateMe?.token;
      const refreshToken = data?.updateMe?.refreshToken;

      if (updateToken && authToken && refreshToken) {
        setAuth(authToken, refreshToken);
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onUpdateRole = async (role: UserType) => {
    try {
      if (authGroupId && authUserGroup?.user?.id) {
        await updateUserGroup({
          variables: {
            input: {
              groupId: authGroupId,
              userId: authUserGroup.user.id,
              role: role || authUserGroup.role,
            },
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onUpdateAvatar = async (
    uri: string | null,
    defaultAvatarId: string | null,
  ) => {
    try {
      const imageFile = uri
        ? new ReactNativeFile({
            uri,
            type: 'image/jpeg',
            name: getFileName(uri),
          })
        : null;

      await setUserGroupAvatar({
        variables: {
          input: {
            userGroupId: authUserGroupId,
            avatar: imageFile,
            defaultAvatarId,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  if (!authUser || !authUserGroup) {
    return (
      <ProfileSetupLoading
        onBack={() => navigation.goBack()}
        onLogout={() => onLogout()}
      />
    );
  }

  return (
    <ProfileSetupLayout
      authUser={authUser}
      userGroup={authUserGroup}
      uploadingImage={setUserGroupAvatarLoading}
      defaultAvatars={defaultAvatars}
      onBack={() => navigation.goBack()}
      onLogout={() => onLogout()}
      onUpdateMe={onUpdateMe}
      onUpdateRole={onUpdateRole}
      onUpdateAvatar={onUpdateAvatar}
      onDeleteAccount={() => navigation.navigate('DeleteAccount')}
      country={country as Country}
      onUpdateCountry={onUpdateCountry}
    />
  );
}
