import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import Button from '~/components/Button';
import TeamIcon from '~/components/TeamIcon';
import {
  GroupImage,
  FlexRowCentered,
  Spacer,
} from '~/components/common/style.web';
import { EditUserGroup, EditUserGroupTeam } from '~/data/models/admin';
import { Status } from '~/data/types/graphql';
import { t } from '~/utils/i18n';
import { UserGroupAction } from '../';
import {
  GroupName,
  GroupNameTypeColumn,
  GroupNameTypeWrapper,
  TeamColumn,
  TeamName,
  FlexEnd,
  StyledGroupRow,
  BoldText,
  DetailText,
} from './style';

type GroupRowProps = {
  group: EditUserGroup;
  teams: EditUserGroupTeam[];
  status?: Status | null;
  onPress: () => void;
  onActionPress: (action: UserGroupAction) => void;
};

const GroupRow = ({
  group,
  teams,
  onPress,
  status,
  onActionPress,
}: GroupRowProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });
  const isPending = status === 'PENDING';
  const ctaSuffix = !isDesktop ? 'Short' : '';
  const rowCtaText = isPending
    ? t(`adminPanelSearch.acceptPendingGroup${ctaSuffix}`)
    : t(`adminPanelSearch.removeFromGroup${ctaSuffix}`);

  const onCTAPress = isPending
    ? () => onActionPress('acceptPending')
    : () => onActionPress('removeGroup');

  return (
    <StyledGroupRow onPress={onPress} testID={`GroupRow:${group.id}`}>
      <GroupImage
        isSmall
        source={{
          uri: group?.avatar || group?.icon?.icon || undefined,
        }}
      />
      <GroupNameTypeColumn isDesktop={isDesktop}>
        <GroupNameTypeWrapper>
          <GroupName>{group?.name}</GroupName>
          <DetailText>
            {t(`adminPanelSearch.flags.${group?.institute.country}`)}{' '}
            {group?.institute.internalId}
          </DetailText>
        </GroupNameTypeWrapper>
        <GroupNameTypeWrapper>
          <BoldText>{group?.type.name}</BoldText>
          <DetailText>{group?.year}</DetailText>
        </GroupNameTypeWrapper>
      </GroupNameTypeColumn>
      {isDesktop && (
        <TeamColumn>
          {isPending ? (
            <DetailText>{t('adminPanelSearch.pending')}</DetailText>
          ) : (
            teams?.map((team) => (
              <FlexRowCentered key={team?.id}>
                <TeamIcon
                  iconName={team?.icon?.icon}
                  size={24}
                  iconSize={10}
                  teamColor={team?.color?.hex}
                />
                <Spacer h={8} />
                <TeamName>{team?.name}</TeamName>
              </FlexRowCentered>
            ))
          )}
        </TeamColumn>
      )}
      <FlexEnd>
        <Button
          type={isPending ? 'secondary-base' : 'destructive'}
          text={rowCtaText.toUpperCase()}
          onPress={onCTAPress}
          flex={true}
          minWidth={20}
        />
      </FlexEnd>
    </StyledGroupRow>
  );
};

export default GroupRow;
