import { gql } from './../../types';

export const TEAM_USER_FRAGMENT = gql(`
  fragment TeamUser on UserTeamNode {
    id
    isAdmin
    status
    avatar
    defaultAvatar
    user {
      ...User
    }
    created
    modified
  }
`);

export const TEAM_FRAGMENT = gql(`
  fragment Team on TeamNode {
    id
    name
    type
    approvalSetting
    color {
      ...Color
    }
    icon {
      ...Icon
    }
    isHidden
    created
  }
`);

export const TEAM_SAMPLE_FRAGMENT = gql(`
  fragment TeamSample on TeamNode {
    ...Team
    usersCount
    sampleMembers {
      ...TeamUser
    }
  }
`);

export const TEAM_DETAIL_FRAGMENT = gql(`
  fragment TeamDetail on TeamNode {
    ...Team
    users {
      ...TeamUser
    }
  }
`);

export const TEAMS = gql(`
  query Teams(
    $after: String
    $first: Int!
    $name: String
    $group: ID
    $userGroupId: String
  ) {
    teams(
      after: $after
      first: $first
      name: $name
      group: $group
      userGroupId: $userGroupId
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Team
        }
      }
    }
  }
`);

export const TEAM_SAMPLES = gql(`
  query TeamSamples($after: String, $first: Int!, $name: String, $group: ID) {
    teams(after: $after, first: $first, name: $name, group: $group) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...TeamSample
        }
      }
    }
  }
`);

export const TEAM = gql(`
  query Team($id: ID!) {
    team(id: $id) {
      ...Team
    }
  }
`);

export const TEAM_DETAIL = gql(`
  query TeamDetail($id: ID!) {
    team(id: $id) {
      ...TeamDetail
    }
  }
`);

export const ADD_USER_TO_TEAM = gql(`
  mutation AddUserToTeam($input: AddUserToTeamInput!) {
    addUserToTeam(input: $input) {
      userTeam {
        ...TeamUser
      }
      teams {
        ...Team
        avatar
        group {
          ...Group
        }
      }
    }
  }
`);

export const REMOVE_USER_FROM_TEAM = gql(`
  mutation RemoveUserFromTeam($input: RemoveUserFromTeamInput!) {
    removeUserFromTeam(input: $input) {
      userTeam {
        ...TeamUser
      }
    }
  }
`);

export const CREATE_TEAM = gql(`
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      team {
        ...TeamDetail
      }
      errors {
        field
        messages
      }
    }
  }
`);

export const UPDATE_TEAM = gql(`
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      team {
        ...TeamDetail
      }
      errors {
        field
        messages
      }
    }
  }
`);

export const DESTROY_TEAM = gql(`
  mutation DestroyTeam($input: DestroyTeamInput!) {
    destroyTeam(input: $input) {
      team {
        ...TeamDetail
      }
      success
    }
  }
`);

export const ACTIVATE_TEAM = gql(`
  mutation ActivateTeam($input: ActivateTeamInput!) {
    activateTeam(input: $input) {
      team {
        ...TeamDetail
      }
      success
    }
  }
`);

export const ACCEPT_USER_IN_TEAM = gql(`
  mutation AcceptUserInTeam($input: AcceptUserInTeamInput!) {
    acceptUserInTeam(input: $input) {
      userTeam {
        ...TeamUser
      }
    }
  }
`);

export const USER_TEAM = gql(`
  query UserTeam($id: ID!) {
    userTeam(id: $id) {
      ...TeamUser
    }
  }
`);
