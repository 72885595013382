import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import Dropdown, { OptionsInfo } from '~/components/Dropdown';
import IconStyled from '~/components/IconStyled';
import {
  COUNTRIES,
  MAX_ALLOWED_ZIPCODES,
  createPlaceholderFilter,
} from '~/data/constants';
import { AdsTargetingFilter } from '~/data/models/admin';
import { Regions } from '~/data/models/campaign';
import { waitFor } from '~/utils/helpers';
import useUploadCSV from '~/utils/hooks/useUploadCSV';
import { t } from '~/utils/i18n';
import CheckBox from '../CheckBox';
import CsvInputField from '../CsvInputField';
import InputField from '../InputField';
import Snackbar from '../Snackbar';
import SwitchSelector from '../SwitchSelector';
import {
  Container,
  Title,
  TitleContainer,
  HeaderContainer,
  DeleteButton,
  LabelContainer,
  Label,
  LocationRadiusWrapper,
  CheckBoxWrapper,
  CheckBoxText,
  CheckBoxSize,
} from './style';

type LocationFilteringBlockProps = {
  filterData: AdsTargetingFilter;
  index: number;
  updateFilter: (index: number, filter: AdsTargetingFilter) => void;
  removeFilter: (index: number) => void;
  regions: Regions[];
};

const globalOption: OptionsInfo = {
  key: 'global',
  name: 'Global',
};

const countryOptions: OptionsInfo[] = Object.values(COUNTRIES).map(
  (countryInfo) => ({
    key: countryInfo.country,
    name: countryInfo.name,
  }),
);

export enum LocationFilterBlockType {
  CITY = 'CITY',
  ZIP = 'ZIP',
  REGION = 'REGION',
}

type DistanceOption = '5km' | '10km' | '25km' | '50km' | '100km' | '200km';
const DISTANCE_OPTIONS: DistanceOption[] = [
  '5km',
  '10km',
  '25km',
  '50km',
  '100km',
  '200km',
];

export default function LocationFilteringBlock({
  filterData,
  updateFilter,
  index,
  removeFilter,
  regions,
}: LocationFilteringBlockProps): JSX.Element {
  const [filterType, setFilterType] = useState<LocationFilterBlockType>(
    LocationFilterBlockType.ZIP,
  );
  const [zipInput, setZipInput] = useState<(number | null)[]>(
    filterData?.location?.zip || [],
  );
  const [isRadiusActive, setIsRadiusActive] = useState(
    filterData?.location?.radius !== null || false,
  );
  const [selectedDistance, setSelectedDistance] = useState<DistanceOption>(
    filterData?.location?.radius !== null
      ? (`${filterData?.location?.radius}` as DistanceOption)
      : '5km',
  );
  const [selectedRegions, setSelectedRegions] = useState<string[]>(
    (filterData?.location?.region as string[]) || [],
  );

  const [city, setCity] = useState(filterData?.location?.city || '');

  const onDistanceSelected = (distance: string) => {
    setSelectedDistance(distance as DistanceOption);
  };

  const allOptions: OptionsInfo[] = [globalOption, ...countryOptions];
  const hasSelectedCountry =
    filterData?.location &&
    filterData?.location?.country !== undefined &&
    filterData?.location?.country !== globalOption.key;

  let filteredRegionsData: { id: string; name: string }[] = [];
  if (hasSelectedCountry) {
    const countryCode = countryOptions?.find((country) => {
      return country.key === filterData?.location?.country;
    })?.key;

    regions =
      regions?.filter((region) => region?.country === countryCode) || [];
    filteredRegionsData =
      regions?.map((region) => ({
        id: region?.region as string,
        name: region?.region as string,
      })) || [];
  }

  const [regionsData, setRegionsData] =
    useState<{ id: string; name: string }[]>(filteredRegionsData);
  const {
    uploadingState,
    handleUploadCSV,
    removeUploadedFile,
    uploadedCSVFileName,
    uploadedCSVFileLength,
    uploadedInput,
  } = useUploadCSV();

  useEffect(() => {
    if (hasSelectedCountry) {
      updateZip(index, uploadedInput, zipInput);
    }
  }, [uploadedInput, zipInput]);

  useEffect(() => {
    if (zipInput.length > 0) {
      setFilterType(LocationFilterBlockType.ZIP);
    } else if (city) {
      setFilterType(LocationFilterBlockType.CITY);
    } else if (selectedRegions.length > 0) {
      setFilterType(LocationFilterBlockType.REGION);
    }
  }, [zipInput, city, selectedRegions]);

  useEffect(() => {
    if (hasSelectedCountry) {
      if (isRadiusActive) {
        updateCity(index, city, selectedDistance);
        return;
      }
      updateCity(index, city, null);
    }
  }, [city, selectedDistance]);

  useEffect(() => {
    const updatedFilterData: AdsTargetingFilter = {
      ...filterData,
      location: {
        ...filterData.location,
        region: selectedRegions,
      },
    };
    updateFilter(index, updatedFilterData);
  }, [selectedRegions]);

  const updateCountry = (idx: number, country?: string) => {
    if (!country || country === 'global') {
      updateFilter(idx, createPlaceholderFilter('location'));
      return;
    }

    const filteredRegionsData = regions?.filter(
      (region) => region?.country === country,
    );
    setRegionsData(
      filteredRegionsData?.map((region) => ({
        id: region?.region as string,
        name: region?.region as string,
      })) || [],
    );

    const updatedFilterData: AdsTargetingFilter = {
      ...filterData,
      location: {
        ...filterData.location,
        country: country,
      },
    };

    updateFilter(idx, updatedFilterData);
  };

  const zipInputValue = zipInput
    .map((item) => (item === null ? '' : item))
    .join(',');

  const updateZip = (
    idx: number,
    zipUploaded: (string | number)[],
    zipInput: (number | null)[],
  ) => {
    // Convert null values in zipInput to a placeholder string
    const zipInputProcessed = zipInput.map((item) =>
      item === null ? 'null' : item,
    );

    // Concatenate the two arrays
    const combinedZip = zipUploaded.concat(zipInputProcessed);

    // Convert the array of mixed types to an array of numbers or null
    const zipAsNumberArray = combinedZip.map((item) => {
      if (item === 'null') return null;
      const numValue = Number(item);
      return isNaN(numValue) ? null : numValue;
    });

    const updatedFilterData: AdsTargetingFilter = {
      ...filterData,
      location: {
        ...filterData.location,
        zip: zipAsNumberArray,
      },
    };

    updateFilter(idx, updatedFilterData);
  };

  const updateCity = (idx: number, city: string, radius: string | null) => {
    let radiusToNumber: number | null = null;
    if (radius !== null) {
      radiusToNumber = parseFloat(radius.split('km')[0]);
    }

    const updatedFilterData: AdsTargetingFilter = {
      ...filterData,
      location: {
        ...filterData.location,
        city,
        radius: radiusToNumber,
      },
    };
    updateFilter(idx, updatedFilterData);
  };

  const handleZipInputChange = (zipString: string) => {
    const zips = zipString
      .split(',')
      .map((zip, index, array) => {
        const trimmedZip = zip.trim();
        if (!trimmedZip && index > 0 && array[index - 1].trim()) {
          return null;
        }
        const numValue = Number(trimmedZip);
        return isNaN(numValue) ? null : numValue;
      })
      .filter((zip) => zip !== undefined);

    if (zips.length > MAX_ALLOWED_ZIPCODES) {
      Snackbar.show(
        t('createAssets.createTargeting.locationFilter.cantInputZipCode'),
      );
    } else {
      setZipInput(zips);
    }
  };

  const handleRegionCheckboxChange = (regionKey: string) => {
    if (selectedRegions.includes(regionKey)) {
      setSelectedRegions((prevRegions) =>
        prevRegions.filter((region) => region !== regionKey),
      );
      return;
    }
    setSelectedRegions((prevRegion) => [...prevRegion, regionKey]);
    const updateFilteredData: AdsTargetingFilter = {
      ...filterData,
      location: {
        ...filterData.location,
        region: selectedRegions,
      },
    };
    updateFilter(index, updateFilteredData);
  };

  const resetLocationData = async () => {
    setCity('');
    setZipInput([]);
    setSelectedDistance('5km');
    setIsRadiusActive(false);
    setSelectedRegions([]);
    // waiting to set local state before updating targeting data
    await waitFor(100);
    const updatedFilterData: AdsTargetingFilter = {
      ...filterData,
      location: {
        ...filterData.location,
        city: null,
        zip: null,
        region: null,
        radius: null,
      },
    };
    updateFilter(index, updatedFilterData);
  };

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <IconStyled name="location_dark" size={14} />
          <Title>
            {t('createAssets.createTargeting.locationFilter.title')}
          </Title>
        </TitleContainer>
        <TouchableOpacity>
          {' '}
          <DeleteButton onPress={() => removeFilter(index)}>
            {t('createAssets.createTargeting.locationFilter.deleteBtn')}
          </DeleteButton>
        </TouchableOpacity>
      </HeaderContainer>
      <LabelContainer>
        <Label>
          {t('createAssets.createTargeting.locationFilter.country')}
        </Label>
        <Dropdown
          testID={'countryDropdown'}
          selectedLocale={filterData.location?.country ?? 'global'}
          selectableOptions={allOptions}
          onSelectedLocale={(selectedCountry) =>
            updateCountry(index, selectedCountry)
          }
        />
      </LabelContainer>
      {hasSelectedCountry && (
        <>
          <SwitchSelector
            testID={'pickerLocation'}
            items={Object.keys(LocationFilterBlockType).map((key) => ({
              key,
              title:
                LocationFilterBlockType[
                  key as keyof typeof LocationFilterBlockType
                ],
            }))}
            selectedItemKey={filterType}
            onSelectedItem={(type: string) => {
              setFilterType(type as LocationFilterBlockType);
              resetLocationData();
            }}
          />
          {filterType === LocationFilterBlockType.CITY && (
            <>
              <LabelContainer>
                <Label>
                  {t(
                    'createAssets.createTargeting.locationFilter.cityTargeting',
                  )}
                </Label>
              </LabelContainer>
              <InputField
                testID={'assetsCityInput'}
                value={city ?? ''}
                onChangeText={(city) => setCity(city)}
                placeholder={t(
                  'createAssets.createTargeting.locationFilter.placeholderCity',
                )}
                size={'small'}
              />
              <LocationRadiusWrapper>
                <CheckBoxWrapper>
                  <CheckBoxSize>
                    <CheckBox
                      checked={isRadiusActive}
                      onChange={(checked) => setIsRadiusActive(checked)}
                      size={'small'}
                    />
                  </CheckBoxSize>
                  <CheckBoxText>
                    {' '}
                    {t(
                      'createAssets.createTargeting.locationFilter.activeRadius',
                    )}
                  </CheckBoxText>
                </CheckBoxWrapper>

                <Dropdown
                  testID="distanceDropdown"
                  selectedLocale={selectedDistance}
                  onSelectedLocale={onDistanceSelected}
                  selectableOptions={DISTANCE_OPTIONS.map((distance) => ({
                    key: distance,
                    name: distance,
                  }))}
                />
              </LocationRadiusWrapper>
            </>
          )}

          {filterType === LocationFilterBlockType.ZIP && (
            <>
              <LabelContainer>
                <Label>
                  {t('createAssets.createTargeting.locationFilter.label')}
                </Label>
                <CsvInputField
                  uploadingState={uploadingState}
                  handleUploadCSV={handleUploadCSV}
                  removeUploadedFile={removeUploadedFile}
                  uploadedCSVFileName={uploadedCSVFileName}
                  uploadedCSVFileLength={uploadedCSVFileLength}
                />
              </LabelContainer>
              <InputField
                testID={'assetsZipInput'}
                value={zipInputValue}
                onChangeText={(zipString) => {
                  handleZipInputChange(zipString);
                }}
                placeholder={t(
                  'createAssets.createTargeting.locationFilter.placeholderZip',
                )}
                size={'small'}
              />
            </>
          )}

          {filterType === LocationFilterBlockType.REGION && (
            <>
              <LabelContainer>
                <Label>
                  {t(
                    'createAssets.createTargeting.locationFilter.regionTargeting',
                  )}
                </Label>
              </LabelContainer>
              {regionsData.length > 0 &&
                regionsData.map((region: { id: string; name: string }) => (
                  <CheckBoxWrapper key={region.id}>
                    <CheckBoxSize>
                      <CheckBox
                        checked={selectedRegions.includes(region.id as string)}
                        onChange={() => handleRegionCheckboxChange(region.id)}
                        size={'small'}
                      />
                    </CheckBoxSize>
                    <CheckBoxText>{region.name}</CheckBoxText>
                  </CheckBoxWrapper>
                ))}
            </>
          )}
        </>
      )}
    </Container>
  );
}
