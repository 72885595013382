import React from 'react';
import { FlatList } from 'react-native';
import { ScrollView } from 'react-native';
import { RefreshControl } from 'react-native-gesture-handler';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import CommonIconCell from '~/components/CommonIconCell';
import SectionHeader from '~/components/SectionHeader';
import { Spacer } from '~/components/common/style.web';
import { CustomPage } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import ModuleLayout from '../../layout/ModuleLayout';
import {
  GuideContent,
  GuideText,
  ImageMegaphone,
  Header,
  RestrictedAllCustomPagesContainer,
  LockImage,
  RestrictedAllCustomPagesDescription,
  RestrictedAllCustomPagesTeamName,
} from './style';

type CustomLayoutProps = {
  onBack: () => void;
  yearbookAdmin?: boolean;
  isActive?: boolean;
  isDeadlinePassed?: boolean;
  viewOnly?: boolean;
  addPagesEnabled: boolean;
  viewPagesEnabled: boolean;
  guide?: string | null;
  loading: boolean;
  restrictedToTeam?: string | null;
  customPagesInstances: CustomPage[];
  hasNextPage?: boolean;
  userCustomPages?: CustomPage[];
  allHasNextPage?: boolean;
  onRefresh: () => void;
  onSetup: () => void;
  onLoadMore: () => void;
  onAllLoadMore: () => void;
  onAddPages: () => void;
  onOpenCustomPage: (customPageId: string) => void;
  onHelp: () => void;
  onRequestYearbookTeam: () => void;
};

export default function Custom({
  onBack,
  yearbookAdmin,
  isActive,
  viewOnly,
  isDeadlinePassed,
  addPagesEnabled,
  viewPagesEnabled,
  restrictedToTeam,
  customPagesInstances,
  guide,
  loading,
  hasNextPage,
  userCustomPages,
  allHasNextPage,
  onAllLoadMore,
  onRefresh,
  onSetup,
  onLoadMore,
  onAddPages,
  onOpenCustomPage,
  onHelp,
  onRequestYearbookTeam,
}: CustomLayoutProps): JSX.Element {
  const renderItem = ({ item }: { item: CustomPage }) => {
    return (
      <CommonIconCell
        testID={'buttonCellPoll'}
        info={{ text: item.title }}
        onPress={() => onOpenCustomPage(item.id)}
      />
    );
  };
  return (
    <ModuleLayout
      title={t('screens.customs')}
      yearbookAdmin={yearbookAdmin}
      isActive={isActive}
      viewOnly={viewOnly}
      onBack={onBack}
      onSetup={onSetup}
      isDeadlinePassed={isDeadlinePassed}
      onHelp={onHelp}
      onRequestYearbookTeam={onRequestYearbookTeam}
    >
      <ScrollView>
        <Header>
          {guide && (
            <>
              <GuideContent>
                <ImageMegaphone source={general.megaphone_sticker} />
                <GuideText>{guide}</GuideText>
              </GuideContent>
              <Spacer v={32} />
            </>
          )}
          <SectionHeader
            title={t('customPages.myCustomPages')}
            RightComponent={
              addPagesEnabled && (
                <Button
                  text={t('customPages.addPageBtn')}
                  onPress={onAddPages}
                  size="sm"
                  type="primary-brand-01"
                />
              )
            }
          />
          <FlatList
            data={customPagesInstances}
            renderItem={renderItem}
            keyExtractor={(item) => `My:${item.id}`}
            refreshControl={
              <RefreshControl refreshing={loading} onRefresh={onRefresh} />
            }
            ItemSeparatorComponent={() => <Spacer v={8} />}
            onEndReached={() => hasNextPage && onLoadMore()}
          />
          <Spacer v={32} />
          <SectionHeader title={t('customPages.allCustomPages')} />
          <Spacer v={16} />
          {viewPagesEnabled ? (
            <FlatList
              data={userCustomPages}
              renderItem={renderItem}
              refreshControl={
                <RefreshControl refreshing={loading} onRefresh={onRefresh} />
              }
              keyExtractor={(item) => item.id}
              ItemSeparatorComponent={() => <Spacer v={8} />}
              onEndReached={() => allHasNextPage && onAllLoadMore()}
            />
          ) : (
            <RestrictedAllCustomPagesContainer>
              <LockImage source={general.lock}></LockImage>
              <RestrictedAllCustomPagesDescription>
                {t('customPages.allCustomPagesRestriction')}
                <RestrictedAllCustomPagesTeamName>
                  {` ${restrictedToTeam}.`}
                </RestrictedAllCustomPagesTeamName>
              </RestrictedAllCustomPagesDescription>
            </RestrictedAllCustomPagesContainer>
          )}
        </Header>
      </ScrollView>
    </ModuleLayout>
  );
}
