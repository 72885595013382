import { useQuery } from '@apollo/client';
import { useAuth } from '~/context/auth';
import useThrottledState from '~/utils/hooks/useThrottledState';
import { DEFAULT_LIST_OFFSET } from '../constants';
import { TeamSample } from '../models/team';
import { TEAM_SAMPLES } from '../operations/team';
type TeamSamplesOptions = {
  skip?: boolean;
};

type TeamSamplesHookProps = {
  teamSamples: TeamSample[];
  loading: boolean;
  errorMessage?: string;
  hasNextPage?: boolean;
  onSearchTeam: (searchingText: string) => void;
  onLoadMore: () => void;
};

const useTeamSamples = (options?: TeamSamplesOptions): TeamSamplesHookProps => {
  const skip = options?.skip;

  const [searchingName, setSearchingName] = useThrottledState<string>('', 500);

  const { authGroupId } = useAuth();

  const teamSamplesVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    name: searchingName || null,
    group: authGroupId,
  };

  const {
    loading,
    data: teamsData,
    error: teamSamplesError,
    fetchMore: fetchMoreTeamSamples,
  } = useQuery(TEAM_SAMPLES, {
    skip: !authGroupId && skip,
    variables: teamSamplesVar,
    notifyOnNetworkStatusChange: true,
  });

  const teamSamples: TeamSample[] =
    (teamsData?.teams?.edges.map((edge) => edge?.node) as TeamSample[]) || [];

  const hasNextPage = teamsData?.teams?.pageInfo.hasNextPage;
  const endCursor = teamsData?.teams?.pageInfo.endCursor;

  const onLoadMore = () => {
    hasNextPage &&
      fetchMoreTeamSamples({
        variables: {
          ...teamSamplesVar,
          after: endCursor,
        },
      });
  };

  return {
    teamSamples,
    loading,
    errorMessage: teamSamplesError?.message,
    hasNextPage,
    onSearchTeam: (searchingText) => setSearchingName(searchingText),
    onLoadMore,
  };
};

export default useTeamSamples;
