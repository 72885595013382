import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import useUserGroups from '~/data/hooks/useUserGroups';
import { UserGroup } from '~/data/models/group';
import { ProfilePageComment } from '~/data/models/yearbook';
import { PROFILE_PAGE_COMMENTS } from '~/data/operations/yearbook';
import { YearbookStackParamList } from '~/navigation/types';
import ProfilePageCommentsLayout from './layout';

type ProfilePageCommentsNavProp = StackNavigationProp<
  YearbookStackParamList,
  'ProfilePageComments'
>;
type ProfilePageCommentsRouteProp = RouteProp<
  YearbookStackParamList,
  'ProfilePageComments'
>;

export default function ProfilePageComments(): JSX.Element {
  const navigation = useNavigation<ProfilePageCommentsNavProp>();
  const {
    params: { moduleInstanceId },
  } = useRoute<ProfilePageCommentsRouteProp>();

  const { authUserGroupId } = useAuth();

  const profilePageCommentsVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    moduleInstance: moduleInstanceId,
    comentatorUserGroup: authUserGroupId,
  };
  const {
    data: commentsData,
    fetchMore: fetchMoreProfilePageComments,
    refetch: refetchProfilePageComments,
  } = useQuery(PROFILE_PAGE_COMMENTS, {
    variables: profilePageCommentsVar,
  });

  const writtenProfilePageComments: ProfilePageComment[] =
    (commentsData?.profilePageComments?.edges.map(
      (edge) => edge?.node,
    ) as ProfilePageComment[]) || [];

  const commentsHasNextPage =
    commentsData?.profilePageComments?.pageInfo.hasNextPage;
  const commentsEndCursor =
    commentsData?.profilePageComments?.pageInfo.endCursor;

  const {
    userGroups,
    loading: userGroupsLoading,
    onRefresh: onRefreshUserGroups,
    onLoadMore: onLoadMoreUsers,
  } = useUserGroups();

  const sortedWrittenProfilePageComments = writtenProfilePageComments.sort(
    (pc1, pc2) =>
      pc1.profileUserGroup.user.firstName.localeCompare(
        pc2.profileUserGroup.user.firstName,
      ),
  );

  const writtenGroupUserIds = writtenProfilePageComments.map(
    (comment) => comment.profileUserGroup.id,
  );

  const notCommentUserGroups: UserGroup[] = userGroups.filter(
    (userGroup) =>
      !writtenGroupUserIds.includes(userGroup.id) &&
      userGroup.id !== authUserGroupId,
  );

  const handleRefresh = () => {
    onRefreshUserGroups();
    refetchProfilePageComments();
  };

  useEffect(() => {
    refetchProfilePageComments();
  }, []);

  return (
    <ProfilePageCommentsLayout
      writtenProfilePageComments={sortedWrittenProfilePageComments}
      notCommentUserGroups={notCommentUserGroups}
      loading={userGroupsLoading}
      onBack={() => navigation.goBack()}
      onUpdateComment={(
        profileUserGroupId,
        profilePageCommentId,
        currentText,
      ) =>
        navigation.navigate('ProfileCreateComment', {
          moduleInstanceId,
          profileUserGroupId,
          profilePageCommentId,
          currentText,
        })
      }
      onCreateComment={(profileUserGroupId) =>
        navigation.navigate('ProfileCreateComment', {
          moduleInstanceId,
          profileUserGroupId,
        })
      }
      onRefresh={handleRefresh}
      onLoadMoreUsers={onLoadMoreUsers}
      onLoadMoreComments={() => {
        commentsHasNextPage &&
          fetchMoreProfilePageComments({
            variables: {
              ...profilePageCommentsVar,
              after: commentsEndCursor,
            },
          });
      }}
    />
  );
}
