import React from 'react';
import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Share } from 'react-native';
import { useAuth } from '~/context/auth';
import useUserGroups from '~/data/hooks/useUserGroups';
import { GROUP } from '~/data/operations/group';
import { ProfileStackParamList } from '~/navigation/types';
import GroupSetupLayout, { GroupSetupLoadingLayout } from './layout';

type GroupSetupNavProp = StackNavigationProp<
  ProfileStackParamList,
  'GroupSetup'
>;

export default function GroupSetup(): JSX.Element {
  const navigation = useNavigation<GroupSetupNavProp>();

  const {
    authGroupId,
    authUserId,
    coreAdmin,
    loading: authLoading,
  } = useAuth();

  const {
    data: groupData,
    loading: loadingGroup,
    refetch: refetchGroup,
  } = useQuery(GROUP, {
    skip: !authGroupId,
    variables: {
      id: authGroupId,
    },
  });

  const {
    userGroups,
    loading: userGroupsLoading,
    onRefresh: onRefetchUserGroups,
    onLoadMore: onLoadMoreUsers,
    onRemoveUser,
  } = useUserGroups();

  const group = groupData?.group;

  if (!group || authLoading) {
    return <GroupSetupLoadingLayout onBack={() => navigation.goBack()} />;
  }
  const handleOpenSettings = () => {
    navigation.navigate('GroupSettings');
  };

  const onShareLink = (url: string) => {
    Share.share({
      message: url,
      url,
    });
  };
  return (
    <GroupSetupLayout
      authUserId={authUserId}
      coreAdmin={coreAdmin}
      group={group}
      acceptedUserGroups={userGroups}
      loading={loadingGroup}
      loadingMore={userGroupsLoading}
      usersCount={group?.usersCount || undefined}
      onBack={() => navigation.goBack()}
      onRemoveUser={onRemoveUser}
      onRefresh={() => {
        refetchGroup();
        onRefetchUserGroups();
      }}
      onLoadMoreUsers={onLoadMoreUsers}
      onShareLink={onShareLink}
      onOpenSettings={handleOpenSettings}
    />
  );
}
