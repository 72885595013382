import { useMutation, useQuery } from '@apollo/client';
import Snackbar from '~/components/Snackbar';
import { DEFAULT_LIST_OFFSET } from '../constants';
import { ReportInstance } from '../models/yearbook';
import {
  DESTROY_REPORTS_INSTANCE,
  REPORTS_INSTANCES,
} from '../operations/yearbook';
import {
  readReportsInstancesQuery,
  writeReportsInstancesQuery,
} from '../operations/yearbook/helpers';

type ReportInstancesHookOptions = { moduleInstanceId: string };

type ReportInstancesHookProps = {
  reportsInstances: ReportInstance[];
  loading: boolean;
  hasNextPage?: boolean;
  deleteLoading: boolean;
  onRefresh: () => void;
  onLoadMore: () => void;
  onDeleteReport: (reportInstanceId: string, onDeleted: () => void) => void;
};

const useReportInstances = (
  options: ReportInstancesHookOptions,
): ReportInstancesHookProps => {
  const { moduleInstanceId } = options;

  const reportInstancesVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    moduleInstance: moduleInstanceId,
  };

  const {
    data: reportsInstancesData,
    loading,
    refetch: refetchReportsInstances,
    fetchMore: fetchMoreReportInstances,
  } = useQuery(REPORTS_INSTANCES, {
    variables: reportInstancesVar,
    notifyOnNetworkStatusChange: true,
  });

  const [destroyReportsInstance, { loading: deleteLoading }] = useMutation(
    DESTROY_REPORTS_INSTANCE,
  );

  const reportsInstances: ReportInstance[] =
    (reportsInstancesData?.reportsInstances?.edges.map(
      (edge) => edge?.node,
    ) as ReportInstance[]) || [];
  const hasNextPage =
    reportsInstancesData?.reportsInstances?.pageInfo.hasNextPage;
  const endCursor = reportsInstancesData?.reportsInstances?.pageInfo.endCursor;

  const onDeleteReport = async (
    reportInstanceId: string,
    onDeleted: () => void,
  ) => {
    try {
      await destroyReportsInstance({
        variables: {
          input: {
            id: reportInstanceId,
          },
        },
        update(cache) {
          const currentReportsInstancesQuery = readReportsInstancesQuery({
            cache,
            variables: reportInstancesVar,
          });

          if (currentReportsInstancesQuery?.reportsInstances?.edges) {
            writeReportsInstancesQuery({
              cache,
              variables: reportInstancesVar,
              data: {
                ...currentReportsInstancesQuery,
                reportsInstances: {
                  ...currentReportsInstancesQuery.reportsInstances,
                  edges:
                    currentReportsInstancesQuery.reportsInstances.edges.filter(
                      (edge) => edge?.node?.id !== reportInstanceId,
                    ),
                },
              },
            });
          }
        },
      });
      onDeleted();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onRefresh = () => {
    refetchReportsInstances(reportInstancesVar);
  };

  const onLoadMore = () => {
    hasNextPage &&
      fetchMoreReportInstances({
        variables: {
          ...reportInstancesVar,
          after: endCursor,
        },
      });
  };

  return {
    reportsInstances,
    loading,
    hasNextPage,
    deleteLoading,
    onRefresh,
    onLoadMore,
    onDeleteReport,
  };
};

export default useReportInstances;
