import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';
import IconStyled from '~/components/IconStyled';

export const ScrollView = styled(KeyboardAwareScrollView)`
  padding-horizontal: 16px;
`;

export const Title = styled.Text`
  margin-top: 24px;
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
`;

export const ImageContent = styled.TouchableOpacity``;

export const EmptyImageIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'image_empty',
  color: theme.color.base.c6,
  size: 48,
}))``;

export const ImageBanner = styled.ImageBackground`
  background-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 5px;
  aspect-ratio: 1.8;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

export const AboutEvent = styled.TextInput.attrs(({ theme }) => ({
  placeholderTextColor: theme.color.base.c6,
}))`
  height: 245px;
  border-color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 18px;
  border-width: 2px;
  justify-content: flex-start;
  padding: 22px 16px;
  border-radius: 18px;
  color: ${({ theme }) => theme.color.base.c6};
`;

export const CreateEventContainer = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding: 20px 15px 20px 15px;
`;
