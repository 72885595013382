import React, { useState } from 'react';
import Button from '~/components/Button';
import InputField from '~/components/InputField';
import { ModalScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import { ScroolView, Content } from './style';

type CreateCollageLayoutProps = {
  initialName?: string;
  loading: boolean;
  onBack: () => void;
  onSave: (text: string) => void;
};

export default function CreateCollage({
  initialName = '',
  loading,
  onBack,
  onSave,
}: CreateCollageLayoutProps): JSX.Element {
  const [name, setName] = useState(initialName);

  const createCollageEnabled = name != '';
  return (
    <ModalScreenLayout
      backButtonTestID={'buttonBack'}
      title={
        initialName ? t('screens.editCollage') : t('screens.createCollage')
      }
      onBackPress={onBack}
    >
      <ScroolView showsVerticalScrollIndicator={false}>
        <InputField
          testID={'inputName'}
          value={name}
          label={t('g.albumTitle')}
          onChangeText={(value) => setName(value)}
        />
        <Content>
          <Button
            testID={'buttonCreateCollage'}
            state={createCollageEnabled ? 'default' : 'disabled'}
            text={t('g.save')}
            loading={loading}
            flex
            onPress={() => onSave(name)}
          />
        </Content>
      </ScroolView>
    </ModalScreenLayout>
  );
}
