import { gql } from './../../types';

/** Fragments */

export const MODULE_FRAGMENT = gql(`
  fragment Module on ModuleNode {
    id
    name
    image
    type
    description
    features
  }
`);

export const MODULE_INSTANCE_FRAGMENT = gql(`
  fragment ModuleInstance on ModuleInstanceNode {
    id
    isActive
    dueDate
    module {
      ...Module
    }
  }
`);

export const PREWORD_INSTANCE_FRAGMENT = gql(`
  fragment PrewordInstance on PrewordInstanceNode {
    id
    text
  }
`);

export const PROFILE_PAGE_SETUP_FRAGMENT = gql(`
  fragment ProfilePageSetup on ProfilePageSetupNode {
    id
    photoFormat
    maxChars
    anonymousComments
    visibleComments
  }
`);

export const RANKINGS_SETUP_FRAGMENT = gql(`
  fragment RankingsSetup on RankingsSetupNode {
    id
    resultsTeam {
      ...Team
    }
  }
`);

export const COLLAGES_SETUP_FRAGMENT = gql(`
  fragment CollagesSetup on CollagesSetupNode {
    id
    createAlbumsTeam {
      ...Team
    }
    addPhotosTeam {
      ...Team
    }
    viewPhotosTeam {
      ...Team
    }
    photosCount
  }
`);

export const REPORTS_SETUP_FRAGMENT = gql(`
  fragment ReportsSetup on ReportsSetupNode {
    id
    allowImages
  }
`);

export const QUOTES_SETUP_FRAGMENT = gql(`
  fragment QuotesSetup on QuotesSetupNode {
    id
    anonymous
    public
    maxChars
  }
`);

export const CUSTOM_PAGE_SETUP_FRAGMENT = gql(`
  fragment CustomPageSetup on CustomPagesSetupNode {
    id
    createPagesTeam {
      ...Team
    }
    viewPagesTeam {
      ...Team
    }
    guide
  }
`);

export const MODULE_INSTANCE_DETAIL_FRAGMENT = gql(`
  fragment ModuleInstanceDetail on ModuleInstanceNode {
    ...ModuleInstance
    profilePageSetup {
      ...ProfilePageSetup
    }
    rankingsSetup {
      ...RankingsSetup
    }
    collagesSetup {
      ...CollagesSetup
    }
    reportsSetup {
      ...ReportsSetup
    }
    quotesSetup {
      ...QuotesSetup
    }
    customPagesSetup {
      ...CustomPageSetup
    }
  }
`);

export const CUSTOM_PAGE_FRAGMENT = gql(`
  fragment CustomPage on CustomPagesInstanceNode {
    id
    title
    created
    modified
    page
    userGroup {
      ...UserGroup
    }
    moduleInstance {
      ...ModuleInstanceDetail
    }
    pageFormat
    pageType
    pageQuality
  }
`);

export const PREWORD_RESULT_FRAGMENT = gql(`
  fragment PrewordResult on PrewordInstanceNode {
    wordCount
  }
`);

export const PROFILE_PAGE_USER_SUMMARY_FRAGMENT = gql(`
  fragment ProfilePageUserSummary on ProfilePageUserSummaryNode {
    userGroup {
      ...UserGroup
    }
    questionsAnswered
    imagesUploaded
  }
`);

export const PROFILE_PAGE_RESULT_FRAGMENT = gql(`
  fragment ProfilePageResult on ProfilePageSetupNode {
    id
    usersSummary {
      ...ProfilePageUserSummary
    }
    usersDelivered
  }
`);

export const RANKINGS_RESULT_FRAGMENT = gql(`
  fragment RankingsResult on RankingsSetupNode {
    rankingsCount
  }
`);

export const COLLAGES_RESULT_FRAGMENT = gql(`
  fragment CollagesResult on CollagesSetupNode {
    photosCount
  }
`);

export const REPORTS_RESULT_FRAGMENT = gql(`
  fragment ReportsResult on ReportsSetupNode {
    reportsCount
  }
`);

export const QUOTES_RESULT_FRAGMENT = gql(`
  fragment QuotesResult on QuotesSetupNode {
    quotesCount
  }
`);

export const MODULE_INSTANCE_RESULT_FRAGMENT = gql(`
  fragment ModuleInstanceResult on ModuleInstanceNode {
    ...ModuleInstance
    prewordInstance {
      ...PrewordResult
    }
    profilePageSetup {
      ...ProfilePageResult
    }
    rankingsSetup {
      ...RankingsResult
    }
    collagesSetup {
      ...CollagesResult
    }
    reportsSetup {
      ...ReportsResult
    }
    quotesSetup {
      ...QuotesResult
    }
    customPagesInstances {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...CustomPage
        }
        cursor
      }
    }
  }
`);

export const PROFILE_PAGE_PHOTO_FRAGMENT = gql(`
  fragment ProfilePagePhoto on ProfilePagePhotoNode {
    id
    image
  }
`);

export const BASE_PROFILE_PAGE_PHOTO_CATEGORY_FRAGMENT = gql(`
  fragment BaseProfilePagePhotoCategory on ProfilePagePhotoCategoryNode {
    id
    name
  }
`);

export const PROFILE_PAGE_PHOTO_CATEGORY_FRAGMENT = gql(`
  fragment ProfilePagePhotoCategory on ProfilePagePhotoCategoryNode {
    ...BaseProfilePagePhotoCategory
    profilePagePhotos(userGroup: $ppqUserGroup) {
      edges {
        node {
          ...ProfilePagePhoto
        }
      }
    }
  }
`);

export const PROFILE_PAGE_QUESTION_OPTION_FRAGMENT = gql(`
  fragment ProfilePageQuestionOption on ProfilePageQuestionOptionNode {
    id
    text
  }
`);

export const PROFILE_PAGE_ANSWER_FRAGMENT = gql(`
  fragment ProfilePageAnswer on ProfilePageAnswerNode {
    id
    text
    profilePageQuestionOption {
      ...ProfilePageQuestionOption
    }
    userGroup {
      id
    }
  }
`);

export const BASE_PROFILE_PAGE_QUESTION_FRAGMENT = gql(`
  fragment BaseProfilePageQuestion on ProfilePageQuestionNode {
    id
    text
    order
    profilePageQuestionOptions {
      ...ProfilePageQuestionOption
    }
  }
`);

export const PROFILE_PAGE_QUESTION_FRAGMENT = gql(`
  fragment ProfilePageQuestion on ProfilePageQuestionNode {
    ...BaseProfilePageQuestion
    profilePageAnswers(userGroup: $ppqUserGroup) {
      edges {
        node {
          ...ProfilePageAnswer
        }
      }
    }
  }
`);

export const PROFILE_PAGE_SUGGESTED_QUESTION_OPTION_FRAGMENT = gql(`
  fragment ProfilePageSuggestedQuestionOption on ProfilePageSuggestedQuestionOptionNode {
    id
    text
  }
`);

export const PROFILE_PAGE_SUGGESTED_QUESTION_FRAGMENT = gql(`
  fragment ProfilePageSuggestedQuestion on ProfilePageSuggestedQuestionNode {
    id
    text
    options {
      ...ProfilePageSuggestedQuestionOption
    }
  }
`);

export const PROFILE_PAGE_COMMENT_FRAGMENT = gql(`
  fragment ProfilePageComment on ProfilePageCommentNode {
    id
    text
    comentatorUserGroup {
      ...UserGroup
    }
    profileUserGroup {
      ...UserGroup
    }
    created
  }
`);

export const COLLAGES_PHOTO_FRAGMENT = gql(`
  fragment CollagesPhoto on CollagesPhotoNode {
    id
    photo
    collagesPhotoLikesCount
    created
    userGroup {
      id
    }
    collagesPhotoLikes(userGroup: $authUserGroupId) {
      edges {
        node {
          userGroup {
            id
          }
        }
      }
    }
  }
`);

export const COLLAGES_BASE_PHOTO_FRAGMENT = gql(`
  fragment CollagesBasePhoto on CollagesPhotoNode {
    id
    photo
    collagesPhotoLikesCount
    created
    userGroup {
      id
    }
  }
`);

export const BASE_COLLAGES_ALBUM_FRAGMENT = gql(`
  fragment BaseCollagesAlbum on CollagesAlbumNode {
    id
    name
  }
`);

export const COLLAGES_ALBUM_FRAGMENT = gql(`
  fragment CollagesAlbum on CollagesAlbumNode {
    ...BaseCollagesAlbum
    photosCount
    collagesPhotos(after: $cpAfter, first: $cpFirst) {
      edges {
        node {
          ...CollagesBasePhoto
        }
      }
    }
  }
`);

export const COLLAGES_BASE_ALBUM_FRAGMENT = gql(`
  fragment CollagesBaseAlbum on CollagesAlbumNode {
    ...BaseCollagesAlbum
    photosCount
  }
`);

export const REPORTS_INSTANCE_FRAGMENT = gql(`
  fragment ReportsInstance on ReportsInstanceNode {
    id
    title
    text
    editTeam {
      ...Team
    }
    viewTeam {
      ...Team
    }
    maxChars
  }
`);

export const BASE_QUOTE_FRAGMENT = gql(`
  fragment BaseQuote on QuotesInstanceNode {
    id
    quote
    userGroup {
      ...UserGroup
    }
    quotesInstanceLikesCount
    created
  }
`);

export const QUOTE_FRAGMENT = gql(`
  fragment Quote on QuotesInstanceNode {
    ...BaseQuote
    quotesInstanceLikes(userGroup: $authUserGroupId) {
      edges {
        node {
          id
          userGroup {
            id
          }
        }
      }
    }
  }
`);

export const CUSTOM_LIST_FRAGMENT = gql(`
  fragment CustomList on CustomListNode {
    id
    title
  }
`);

export const CUSTOM_LIST_OPTION_FRAGMENT = gql(`
  fragment CustomListOption on CustomListOptionNode {
    id
    text
  }
`);

export const RANKING_QUESTION_RESULT_FRAGMENT = gql(`
  fragment RankingQuestionResult on RankingsQuestionResultNode {
    votee1UserGroup {
      ...UserGroup
    }
    votee2UserGroup {
      ...UserGroup
    }
    voteeListOption {
      ...CustomListOption
    }
    percentage
  }
`);

export const RANKING_QUESTION_RESULTS_FRAGMENT = gql(`
  fragment RankingQuestionResults on RankingsQuestionResultsNode {
    alreadyVoted
    possibleVoters
    options {
      ...RankingQuestionResult
    }
  }
`);

export const RANKING_ANSWER_FRAGMENT = gql(`
  fragment RankingsAnswer on RankingsAnswerNode {
    id
    votee1UserGroup {
      ...UserGroup
    }
    votee2UserGroup {
      ...UserGroup
    }
    voteeListOption {
      ...CustomListOption
    }
  }
`);

export const RANKING_QUESTION_FRAGMENT = gql(`
  fragment RankingQuestion on RankingsQuestionNode {
    id
    question
    answerType
    allowedVotes
    onlyOppositeGender
    customList {
      id
    }
    results {
      ...RankingQuestionResults
    }
    rankingsAnswers(voterUserGroup: $authUserGroupId) {
      edges {
        node {
          ...RankingsAnswer
        }
      }
    }
  }
`);

export const RANKING_BASE_QUESTION_FRAGMENT = gql(`
  fragment RankingBaseQuestion on RankingsQuestionNode {
    id
    question
    answerType
    allowedVotes
    onlyOppositeGender
    customList {
      id
    }
    rankingsAnswers(voterUserGroup: $authUserGroupId) {
      edges {
        node {
          id
        }
      }
    }
  }
`);

export const DATA_EXPORT_FRAGMENT = gql(`
  fragment DataExport on DataExportNode {
    id
    isodatetime
    userGroup {
      ...UserGroup
    }
    exportFile
    exportCollages
    exportRankings
    exportProfilePages
    exportQuotes
  }
`);

/** Queries */

export const MODULES = gql(`
  query Modules($after: String, $first: Int!) {
    modules(after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Module
        }
      }
    }
  }
`);

export const MODULE = gql(`
  query Module($id: ID!) {
    module(id: $id) {
      ...Module
    }
  }
`);

export const MODULE_INSTANCES = gql(`
  query ModuleInstances($after: String, $first: Int!, $group: ID) {
    moduleInstances(after: $after, first: $first, group: $group) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ModuleInstance
        }
      }
    }
  }
`);

export const MODULE_INSTANCE = gql(`
  query ModuleInstance($id: ID!) {
    moduleInstance(id: $id) {
      ...ModuleInstanceDetail
    }
  }
`);

export const MODULE_INSTANCE_RESULTS = gql(`
  query ModuleInstanceResults($after: String, $first: Int!, $group: ID) {
    moduleInstances(after: $after, first: $first, group: $group) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ModuleInstanceResult
        }
      }
    }
  }
`);

export const MODULE_INSTANCE_RESULT = gql(`
  query ModuleInstanceResult($id: ID!) {
    moduleInstance(id: $id) {
      ...ModuleInstanceResult
    }
  }
`);

export const PROFILE_PAGE_PHOTO_CATEGORIES = gql(`
  query ProfilePagePhotoCategories(
    $after: String
    $first: Int!
    $moduleInstance: ID
    $ppqUserGroup: ID
  ) {
    profilePagePhotoCategories(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ProfilePagePhotoCategory
        }
      }
    }
  }
`);

export const PROFILE_PAGE_QUESTIONS = gql(`
  query ProfilePageQuestions(
    $after: String
    $first: Int!
    $moduleInstance: ID
    $ppqUserGroup: ID
  ) {
    profilePageQuestions(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ProfilePageQuestion
        }
      }
    }
  }
`);

export const PROFILE_PAGE_SUGGESTED_QUESTIONS = gql(`
  query ProfilePageSuggestedQuestions($after: String, $first: Int!) {
    profilePageSuggestedQuestions(after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ProfilePageSuggestedQuestion
        }
      }
    }
  }
`);

export const PROFILE_PAGE_COMMENTS = gql(`
  query ProfilePageComments(
    $after: String
    $first: Int!
    $profileUserGroup: ID
    $comentatorUserGroup: ID
    $moduleInstance: ID
  ) {
    profilePageComments(
      after: $after
      first: $first
      profileUserGroup: $profileUserGroup
      comentatorUserGroup: $comentatorUserGroup
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ProfilePageComment
        }
      }
    }
  }
`);

export const COLLAGES_ALBUMS = gql(`
  query CollagesAlbums(
    $after: String
    $first: Int!
    $moduleInstance: ID
    $cpAfter: String
    $cpFirst: Int
  ) {
    collagesAlbums(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...CollagesAlbum
        }
      }
    }
  }
`);

export const COLLAGES_ALBUM = gql(`
  query CollagesAlbum($id: ID!) {
    collagesAlbum(id: $id) {
      ...CollagesBaseAlbum
    }
  }
`);

export const COLLAGES_PHOTOS = gql(`
  query CollagesPhotos(
    $after: String
    $first: Int
    $collagesAlbum: ID
    $authUserGroupId: ID
  ) {
    collagesPhotos(
      after: $after
      first: $first
      collagesAlbum: $collagesAlbum
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...CollagesPhoto
        }
      }
    }
  }
`);

export const REPORTS_INSTANCES = gql(`
  query ReportsInstances($after: String, $first: Int!, $moduleInstance: ID) {
    reportsInstances(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ReportsInstance
        }
      }
    }
  }
`);

export const REPORTS_INSTANCE = gql(`
  query ReportsInstance($id: ID!) {
    reportsInstance(id: $id) {
      ...ReportsInstance
    }
  }
`);

export const QUOTES_INSTANCES = gql(`
  query QuotesInstances(
    $after: String
    $first: Int!
    $moduleInstance: ID
    $userGroup: ID
    $authUserGroupId: ID
    $orderBy: String
  ) {
    quotesInstances(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
      userGroup: $userGroup
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Quote
        }
      }
    }
  }
`);

export const CUSTOM_PAGES_INSTANCES = gql(`
  query CustomPagesInstances(
    $after: String
    $first: Int!
    $moduleInstance: ID
    $userGroup: String!
  ) {
    customPagesInstances(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
      userGroup: $userGroup
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...CustomPage
        }
        cursor
      }
    }
  }
`);

export const CUSTOM_PAGES_INSTANCES_RESULT = gql(`
  query CustomPagesInstancesResult(
    $after: String
    $first: Int!
    $moduleInstance: String!
  ) {
    customPagesInstancesResult(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...CustomPage
        }
        cursor
      }
    }
  }
`);

export const CUSTOM_LISTS = gql(`
  query CustomLists($after: String, $first: Int!, $moduleInstance: ID) {
    customLists(after: $after, first: $first, moduleInstance: $moduleInstance) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...CustomList
        }
      }
    }
  }
`);

export const CUSTOM_LIST_OPTIONS = gql(`
  query CustomListOptions($after: String, $first: Int!, $customList: ID) {
    customListOptions(after: $after, first: $first, customList: $customList) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...CustomListOption
        }
      }
    }
  }
`);

export const RANKINGS_QUESTIONS = gql(`
  query RankingsQuestions(
    $after: String
    $first: Int!
    $moduleInstance: ID
    $authUserGroupId: ID
  ) {
    rankingsQuestions(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...RankingQuestion
        }
      }
    }
  }
`);

export const RANKINGS_QUESTION = gql(`
  query RankingsQuestion($id: ID!, $authUserGroupId: ID) {
    rankingsQuestion(id: $id) {
      ...RankingBaseQuestion
    }
  }
`);

export const DATA_EXPORTS = gql(`
  query DataExports($after: String, $first: Int!, $group: ID!) {
    dataExports(after: $after, first: $first, group: $group) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...DataExport
        }
      }
    }
  }
`);

export const CUSTOM_PAGES_INSTANCE = gql(`
  query CustomPagesInstance($id: ID!) {
    customPagesInstance(id: $id) {
      ...CustomPage
    }
  }
`);

/** Mutations */

export const CREATE_MODULE_INSTANCE = gql(`
  mutation CreateModuleInstance($input: CreateModuleInstanceInput!) {
    createModuleInstance(input: $input) {
      errors {
        field
        messages
      }
      moduleInstance {
        ...ModuleInstance
      }
    }
  }
`);

export const DESTROY_MODULE_INSTANCE = gql(`
  mutation DestroyModuleInstance($input: DestroyModuleInstanceInput!) {
    destroyModuleInstance(input: $input) {
      moduleInstance {
        ...ModuleInstance
      }
    }
  }
`);

export const UPDATE_PREWORD_INSTANCE = gql(`
  mutation UpdatePrewordInstance($input: UpdatePrewordInstanceInput!) {
    updatePrewordInstance(input: $input) {
      errors {
        field
        messages
      }
      prewordInstance {
        ...PrewordInstance
      }
    }
  }
`);

export const CREATE_PROFILE_PAGE_PHOTO_CATEGORY = gql(`
  mutation CreateProfilePagePhotoCategory(
    $input: CreateProfilePagePhotoCategoryInput!
  ) {
    createProfilePagePhotoCategory(input: $input) {
      errors {
        field
        messages
      }
      profilePagePhotoCategory {
        ...BaseProfilePagePhotoCategory
      }
    }
  }
`);

export const UPDATE_PROFILE_PAGE_PHOTO_CATEGORY = gql(`
  mutation UpdateProfilePagePhotoCategory(
    $input: UpdateProfilePagePhotoCategoryInput!
  ) {
    updateProfilePagePhotoCategory(input: $input) {
      errors {
        field
        messages
      }
      profilePagePhotoCategory {
        ...BaseProfilePagePhotoCategory
      }
    }
  }
`);

export const DESTROY_PROFILE_PAGE_PHOTO_CATEGORY = gql(`
  mutation DestroyProfilePagePhotoCategory(
    $input: DestroyProfilePagePhotoCategoryInput!
  ) {
    destroyProfilePagePhotoCategory(input: $input) {
      profilePagePhotoCategory {
        ...BaseProfilePagePhotoCategory
      }
    }
  }
`);

export const CREATE_PROFILE_PAGE_PHOTO = gql(`
  mutation CreateProfilePagePhoto($input: CreateProfilePagePhotoInput!) {
    createProfilePagePhoto(input: $input) {
      profilePagePhoto {
        ...ProfilePagePhoto
      }
    }
  }
`);

export const UPDATE_PROFILE_PAGE_PHOTO = gql(`
  mutation UpdateProfilePagePhoto($input: UpdateProfilePagePhotoInput!) {
    updateProfilePagePhoto(input: $input) {
      profilePagePhoto {
        ...ProfilePagePhoto
      }
    }
  }
`);

export const DESTROY_PROFILE_PAGE_PHOTO = gql(`
  mutation DestroyProfilePagePhoto($input: DestroyProfilePagePhotoInput!) {
    destroyProfilePagePhoto(input: $input) {
      profilePagePhoto {
        ...ProfilePagePhoto
      }
    }
  }
`);

export const CREATE_PROFILE_PAGE_QUESTION = gql(`
  mutation CreateProfilePageQuestion($input: CreateProfilePageQuestionInput!) {
    createProfilePageQuestion(input: $input) {
      errors {
        field
        messages
      }
      profilePageQuestion {
        ...BaseProfilePageQuestion
      }
    }
  }
`);

export const UPDATE_PROFILE_PAGE_QUESTION = gql(`
  mutation UpdateProfilePageQuestion($input: UpdateProfilePageQuestionInput!) {
    updateProfilePageQuestion(input: $input) {
      errors {
        field
        messages
      }
      profilePageQuestion {
        ...BaseProfilePageQuestion
      }
    }
  }
`);

export const DESTROY_PROFILE_PAGE_QUESTION = gql(`
  mutation DestroyProfilePageQuestion(
    $input: DestroyProfilePageQuestionInput!
  ) {
    destroyProfilePageQuestion(input: $input) {
      profilePageQuestion {
        ...BaseProfilePageQuestion
      }
    }
  }
`);

export const CREATE_PROFILE_PAGE_ANSWER = gql(`
  mutation CreateProfilePageAnswer($input: CreateProfilePageAnswerInput!) {
    createProfilePageAnswer(input: $input) {
      errors {
        field
        messages
      }
      profilePageAnswer {
        ...ProfilePageAnswer
      }
    }
  }
`);

export const UPDATE_PROFILE_PAGE_ANSWER = gql(`
  mutation UpdateProfilePageAnswer($input: UpdateProfilePageAnswerInput!) {
    updateProfilePageAnswer(input: $input) {
      errors {
        field
        messages
      }
      profilePageAnswer {
        ...ProfilePageAnswer
      }
    }
  }
`);

export const DESTROY_PROFILE_PAGE_ANSWER = gql(`
  mutation DestroyProfilePageAnswer($input: DestroyProfilePageAnswerInput!) {
    destroyProfilePageAnswer(input: $input) {
      clientMutationId
    }
  }
`);

export const UPDATE_PROFILE_PAGE_SETUP = gql(`
  mutation UpdateProfilePageSetup($input: UpdateProfilePageSetupInput!) {
    updateProfilePageSetup(input: $input) {
      errors {
        field
        messages
      }
      profilePageSetup {
        ...ProfilePageSetup
      }
    }
  }
`);

export const UPDATE_MODULE_INSTANCE = gql(`
  mutation UpdateModuleInstance($input: UpdateModuleInstanceInput!) {
    updateModuleInstance(input: $input) {
      errors {
        field
        messages
      }
      moduleInstance {
        ...ModuleInstanceDetail
      }
    }
  }
`);

export const CREATE_PROFILE_PAGE_COMMENT = gql(`
  mutation CreateProfilePageComment($input: CreateProfilePageCommentInput!) {
    createProfilePageComment(input: $input) {
      errors {
        field
        messages
      }
      profilePageComment {
        ...ProfilePageComment
      }
    }
  }
`);

export const UPDATE_PROFILE_PAGE_COMMENT = gql(`
  mutation UpdateProfilePageComment($input: UpdateProfilePageCommentInput!) {
    updateProfilePageComment(input: $input) {
      errors {
        field
        messages
      }
      profilePageComment {
        ...ProfilePageComment
      }
    }
  }
`);

export const DESTROY_PROFILE_PAGE_COMMENT = gql(`
  mutation DestroyProfilePageComment($input: DestroyProfilePageCommentInput!) {
    destroyProfilePageComment(input: $input) {
      profilePageComment {
        ...ProfilePageComment
      }
    }
  }
`);

export const MOVE_PROFILE_PAGE_QUESTION_TO_POSITION = gql(`
  mutation MoveProfilePageQuestionToPosition(
    $input: MoveProfilePageQuestionToPositionInput!
  ) {
    moveProfilePageQuestionToPosition(input: $input) {
      profilePageQuestion {
        ...BaseProfilePageQuestion
      }
    }
  }
`);

export const ADD_PHOTO_TO_COLLAGES_ALBUM = gql(`
  mutation AddPhotoToCollagesAlbum(
    $input: AddPhotoToCollagesAlbumInput!
    $authUserGroupId: ID
  ) {
    addPhotoToCollagesAlbum(input: $input) {
      photo {
        ...CollagesPhoto
      }
    }
  }
`);

export const REMOVE_PHOTO_FROM_COLLAGES_ALBUM = gql(`
  mutation RemovePhotoFromCollagesAlbum(
    $input: RemovePhotoFromCollagesAlbumInput!
  ) {
    removePhotoFromCollagesAlbum(input: $input) {
      success
    }
  }
`);

export const CREATE_COLLAGES_ALBUM = gql(`
  mutation CreateCollagesAlbum($input: CreateCollagesAlbumInput!) {
    createCollagesAlbum(input: $input) {
      errors {
        field
        messages
      }
      collagesAlbum {
        ...BaseCollagesAlbum
      }
    }
  }
`);

export const UPDATE_COLLAGES_ALBUM = gql(`
  mutation UpdateCollagesAlbum($input: UpdateCollagesAlbumInput!) {
    updateCollagesAlbum(input: $input) {
      errors {
        field
        messages
      }
      collagesAlbum {
        ...BaseCollagesAlbum
      }
    }
  }
`);

export const DESTROY_COLLAGES_ALBUM = gql(`
  mutation DestroyCollagesAlbum($input: DestroyCollagesAlbumInput!) {
    destroyCollagesAlbum(input: $input) {
      collagesAlbum {
        ...BaseCollagesAlbum
      }
    }
  }
`);

export const LIKE_COLLAGES_PHOTO = gql(`
  mutation LikeCollagesPhoto(
    $input: LikeCollagesPhotoInput!
    $authUserGroupId: ID
  ) {
    likeCollagesPhoto(input: $input) {
      collagesPhoto {
        ...CollagesPhoto
      }
    }
  }
`);

export const REMOVE_LIKE_FROM_COLLAGES_PHOTO = gql(`
  mutation RemoveLikeFromCollagesPhoto(
    $input: RemoveLikeFromCollagesPhotoInput!
    $authUserGroupId: ID
  ) {
    removeLikeFromCollagesPhoto(input: $input) {
      collagesPhoto {
        ...CollagesPhoto
      }
    }
  }
`);

export const UPDATE_COLLAGES_SETUP = gql(`
  mutation UpdateCollagesSetup($input: UpdateCollagesSetupInput!) {
    updateCollagesSetup(input: $input) {
      errors {
        field
        messages
      }
      collagesSetup {
        ...CollagesSetup
      }
    }
  }
`);

export const UPDATE_REPORTS_SETUP = gql(`
  mutation UpdateReportsSetup($input: UpdateReportsSetupInput!) {
    updateReportsSetup(input: $input) {
      errors {
        field
        messages
      }
      reportsSetup {
        ...ReportsSetup
      }
    }
  }
`);

export const CREATE_REPORTS_INSTANCE = gql(`
  mutation CreateReportsInstance($input: CreateReportsInstanceInput!) {
    createReportsInstance(input: $input) {
      errors {
        field
        messages
      }
      reportsInstance {
        ...ReportsInstance
      }
    }
  }
`);

export const UPDATE_REPORTS_INSTANCE = gql(`
  mutation UpdateReportsInstance($input: UpdateReportsInstanceInput!) {
    updateReportsInstance(input: $input) {
      errors {
        field
        messages
      }
      reportsInstance {
        ...ReportsInstance
      }
    }
  }
`);

export const DESTROY_REPORTS_INSTANCE = gql(`
  mutation DestroyReportsInstance($input: DestroyReportsInstanceInput!) {
    destroyReportsInstance(input: $input) {
      success
      reportsInstanceId
    }
  }
`);

export const CREATE_QUOTES_INSTANCE = gql(`
  mutation CreateQuotesInstance($input: CreateQuotesInstanceInput!) {
    createQuotesInstance(input: $input) {
      errors {
        field
        messages
      }
      quotesInstance {
        ...BaseQuote
      }
    }
  }
`);

export const DESTROY_QUOTES_INSTANCE = gql(`
  mutation DestroyQuotesInstance($input: DestroyQuotesInstanceInput!) {
    destroyQuotesInstance(input: $input) {
      quotesInstance {
        ...BaseQuote
      }
    }
  }
`);

export const UPDATE_QUOTES_SETUP = gql(`
  mutation UpdateQuotesSetup($input: UpdateQuotesSetupInput!) {
    updateQuotesSetup(input: $input) {
      errors {
        field
        messages
      }
      quotesSetup {
        ...QuotesSetup
      }
    }
  }
`);

export const LIKE_QUOTES_INSTANCE = gql(`
  mutation LikeQuotesInstance(
    $input: LikeQuotesInstanceInput!
    $authUserGroupId: ID
  ) {
    likeQuotesInstance(input: $input) {
      quotesInstance {
        ...Quote
      }
    }
  }
`);

export const REMOVE_LIKE_FROM_QUOTES_INSTANCE = gql(`
  mutation RemoveLikeFromQuotesInstance(
    $input: RemoveLikeFromQuotesInstanceInput!
    $authUserGroupId: ID
  ) {
    removeLikeFromQuotesInstance(input: $input) {
      quotesInstance {
        ...Quote
      }
    }
  }
`);

export const UPDATE_RANKINGS_SETUP = gql(`
  mutation UpdateRankingsSetup($input: UpdateRankingsSetupInput!) {
    updateRankingsSetup(input: $input) {
      errors {
        field
        messages
      }
      rankingsSetup {
        ...RankingsSetup
      }
    }
  }
`);

export const UPDATE_CUSTOM_PAGES_SETUP = gql(`
  mutation UpdateCustomPagesSetup($input: UpdateCustomPagesSetupInput!) {
    updateCustomPagesSetup(input: $input) {
      errors {
        field
        messages
      }
      customPagesSetup {
        ...CustomPageSetup
      }
    }
  }
`);

export const CREATE_CUSTOM_PAGES_INSTANCE = gql(`
  mutation CreateCustomPagesInstance($input: CreateCustomPagesInstanceInput!) {
    createCustomPagesInstance(input: $input) {
      customPagesInstance {
        ...CustomPage
      }
    }
  }
`);

export const UPDATE_CUSTOM_PAGES_INSTANCE = gql(`
  mutation UpdateCustomPagesInstance($input: UpdateCustomPagesInstanceInput!) {
    updateCustomPagesInstance(input: $input) {
      customPagesInstance {
        ...CustomPage
      }
    }
  }
`);

export const DESTROY_CUSTOM_PAGES_INSTANCE = gql(`
  mutation DestroyCustomPagesInstance(
    $input: DestroyCustomPagesInstanceInput!
  ) {
    destroyCustomPagesInstance(input: $input) {
      success
      customPagesInstanceId
    }
  }
`);

export const CREATE_RANKINGS_QUESTION = gql(`
  mutation CreateRankingsQuestion(
    $input: CreateRankingsQuestionInput!
    $authUserGroupId: ID
  ) {
    createRankingsQuestion(input: $input) {
      errors {
        field
        messages
      }
      rankingsQuestion {
        ...RankingQuestion
      }
    }
  }
`);

export const UPDATE_RANKINGS_QUESTION = gql(`
  mutation UpdateRankingsQuestion(
    $input: UpdateRankingsQuestionInput!
    $authUserGroupId: ID
  ) {
    updateRankingsQuestion(input: $input) {
      errors {
        field
        messages
      }
      rankingsQuestion {
        ...RankingQuestion
      }
    }
  }
`);

export const DESTROY_RANKINGS_QUESTION = gql(`
  mutation DestroyRankingsQuestion(
    $input: DestroyRankingsQuestionInput!
    $authUserGroupId: ID
  ) {
    destroyRankingsQuestion(input: $input) {
      rankingsQuestion {
        ...RankingQuestion
      }
    }
  }
`);

export const CREATE_RANKINGS_ANSWER = gql(`
  mutation CreateRankingsAnswer(
    $input: CreateRankingsAnswerInput!
    $authUserGroupId: ID
  ) {
    createRankingsAnswer(input: $input) {
      errors {
        field
        messages
      }
      rankingsAnswer {
        rankingsQuestion {
          ...RankingQuestion
        }
      }
    }
  }
`);

export const UPDATE_RANKINGS_ANSWER = gql(`
  mutation UpdateRankingsAnswer(
    $input: UpdateRankingsAnswerInput!
    $authUserGroupId: ID
  ) {
    updateRankingsAnswer(input: $input) {
      errors {
        field
        messages
      }
      rankingsAnswer {
        rankingsQuestion {
          ...RankingQuestion
        }
      }
    }
  }
`);

export const CREATE_CUSTOM_LIST = gql(`
  mutation CreateCustomList($input: CreateCustomListInput!) {
    createCustomList(input: $input) {
      customList {
        ...CustomList
      }
    }
  }
`);

export const UPDATE_CUSTOM_LIST = gql(`
  mutation UpdateCustomList($input: UpdateCustomListInput!) {
    updateCustomList(input: $input) {
      customList {
        ...CustomList
      }
    }
  }
`);

export const DELETE_CUSTOM_LIST = gql(`
  mutation DeleteCustomList($input: DeleteCustomListInput!) {
    deleteCustomList(input: $input) {
      customList {
        ...CustomList
      }
    }
  }
`);

export const CREATE_CUSTOM_LIST_OPTION = gql(`
  mutation CreateCustomListOption($input: CreateCustomListOptionInput!) {
    createCustomListOption(input: $input) {
      customListOption {
        ...CustomListOption
      }
    }
  }
`);

export const UPDATE_CUSTOM_LIST_OPTION = gql(`
  mutation UpdateCustomListOption($input: UpdateCustomListOptionInput!) {
    updateCustomListOption(input: $input) {
      customListOption {
        ...CustomListOption
      }
    }
  }
`);

export const DELETE_CUSTOM_LIST_OPTION = gql(`
  mutation DeleteCustomListOption($input: DeleteCustomListOptionInput!) {
    deleteCustomListOption(input: $input) {
      customListOption {
        ...CustomListOption
      }
    }
  }
`);

export const CREATE_DATA_EXPORT = gql(`
  mutation CreateDataExport($input: CreateDataExportInput!) {
    createDataExport(input: $input) {
      success
    }
  }
`);
