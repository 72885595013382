import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';
import IconStyled from '~/components/IconStyled';
import { TAB_SCREEN_WIDTH } from '~/data/constants';

export const NUM_COLUMNS = 4;
const BASE_CONTAINER_PADDING = 16;
const INNER_CARD_PADDING = 8;
const CARD_SIZE =
  (TAB_SCREEN_WIDTH -
    BASE_CONTAINER_PADDING * 2 -
    INNER_CARD_PADDING * (NUM_COLUMNS - 1)) /
  NUM_COLUMNS;

interface IContainer {
  noImages: boolean;
}

export const AllowedView = styled.View<IContainer>`
  position: relative;
  margin-top: ${({ noImages }) => (noImages ? `230px` : `38px`)};
`;

export const HiddenPhotoList = styled.View`
  flex-direction: row;
  position: absolute;
  overflow: hidden;
`;

export const HiddenPhoto = styled(LinearGradient).attrs(({ theme }) => ({
  colors: [theme.color.base.c2, 'transparent'],
  end: {
    x: 0.5,
    y: 1,
  },
}))`
  width: ${CARD_SIZE}px;
  height: ${CARD_SIZE}px;
  flex-direction: row;
  border-radius: 8px;
  margin-right: ${INNER_CARD_PADDING}px;
`;

export const LockIconContainer = styled.View`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${CARD_SIZE}px;
  z-index: 1;
`;

export const LockIcon = styled(IconStyled).attrs(() => ({
  name: 'lock_dark',
  size: 40,
}))`
  position: absolute;
  z-index: 1;
`;

export const Content = styled.View`
  padding-vertical: 24px;
  align-items: center;
`;

export const EmptyView = styled.View`
  padding-vertical: 120px;
  align-items: center;
`;

export const EmptyImageIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'image_empty',
  color: theme.color.base.c6,
  size: 120,
}))`
  align-self: center;
  margin-bottom: 32px;
`;

export const Loading = styled.ActivityIndicator``;

export const FooterText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c7};
  font-size: 18px;
  text-align: center;
  margin-bottom: 16px;
`;

export const FooterBoldText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 18px;
  text-align: center;
`;
