import React from 'react';
import { useMutation } from '@apollo/client';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { Event } from '~/data/models/calendar';
import { LEAVE_EVENT } from '~/data/operations/calendar';
import { RootStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import AttendeesListLayout from './layout';

type AttendeesListNavProp = StackNavigationProp<
  RootStackParamList,
  'AttendeesList'
>;

type AttendeesListRouteProp = RouteProp<RootStackParamList, 'AttendeesList'>;
export default function AttendeesList(): JSX.Element {
  const navigation = useNavigation<AttendeesListNavProp>();
  const {
    params: { event },
  } = useRoute<AttendeesListRouteProp>();

  const [leaveEvent] = useMutation(LEAVE_EVENT);

  const onLeaveEvent = async (event: Event) => {
    try {
      await leaveEvent({
        variables: {
          input: {
            eventId: event.id,
          },
        },
      });
      Snackbar.show(t('attendeesList.successfullyLeftTheEvent'));

      navigation.navigate('EventDetail', { eventId: event.id });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <AttendeesListLayout
      onBack={() => navigation.goBack()}
      event={event as Event}
      onLeaveEvent={onLeaveEvent}
    />
  );
}
