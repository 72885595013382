import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';
import SectionButton from '~/components/SectionButton';
import SectionHeader from '~/components/SectionHeader';

export const ScrollView = styled(KeyboardAwareScrollView)`
  background-color: ${({ theme }) => theme.color.base.c0};
`;
export const Header = styled.View`
  padding-bottom: 16px;
  padding-horizontal: 16px;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const EventList = styled.FlatList``;

export const EmptyText = styled.Text`
  padding: 24px 16px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 16px;
`;

export const EventsHeaderContainer = styled.View`
  top: 20px;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
`;

export const EventsHeader = styled(SectionHeader)`
  flex: 1;
`;

export const UpcomingEventsContainer = styled.View`
  margin-top: 20px;
`;

export const TabContainer = styled.View`
  padding: 0 15px 0 15px;
`;

export const HeaderButton = styled(SectionButton)`
  height: 35px;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const EventCardContainer = styled.View`
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
`;
