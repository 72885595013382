import { KeyboardAwareFlatList } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';

export const PostCellCard = styled.View`
  background-color: ${({ theme }) => theme.color.base.c1};
  padding-top: 16px;
`;

export const CommentsTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: ${({ theme }) => theme.fontSize.title};
  color: ${({ theme }) => theme.color.base.c10};
  background-color: ${({ theme }) => theme.color.base.c1};
  padding-left: 16px;
  padding-bottom: 16px;
  padding-top: 4px;
`;

export const CommentList = styled(KeyboardAwareFlatList)`
  background-color: ${({ theme }) => theme.color.base.c1};
`;

export const CommentButtonContainer = styled.View`
  background-color: ${({ theme }) => theme.color.base.c1};
  padding-horizontal: 16px;
  padding-bottom: 16px;
`;
