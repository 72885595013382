import React from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import { StackScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  ScrollContent,
  Content,
  Title,
  Image,
  Descp,
  ColorBox,
  ImageWrapper,
  SmallImage,
  Footer,
} from './style';

type CreateGroupSuccessLayoutProps = {
  avatar: string;
  onBack: () => void;
  onStartPlaning: () => void;
  onShareInvite: () => void;
  onLogout: () => void;
};

export default function CreateGroupSuccess({
  avatar,
  onBack,
  onStartPlaning,
  onShareInvite,
  onLogout,
}: CreateGroupSuccessLayoutProps): JSX.Element {
  return (
    <StackScreenLayout
      backButtonTestID={'buttonBack'}
      colorStyle={'light'}
      onBackPress={onBack}
      RightComponent={
        <Button
          testID="buttonLogout"
          size="sm"
          text={t('g.logout')}
          type="secondary-base"
          onPress={onLogout}
        />
      }
      BgComponent={<ColorBox />}
    >
      <ScrollContent>
        <Content>
          <ImageWrapper>
            <Image
              source={{
                uri: avatar,
              }}
            />
            <SmallImage source={general.double_hearts} />
          </ImageWrapper>
          <Title>{t('createGroupSuccess.title')}</Title>
          <Descp>{t('createGroupSuccess.descp')}</Descp>
        </Content>
        <Footer>
          <Button
            testID={'buttonStartPlanning'}
            text={t('createGroupSuccess.startPlanning')}
            type={'secondary-contrast'}
            size="2xl"
            bottomMargin={8}
            onPress={onStartPlaning}
            flex
            flexScreenContainer="stack"
          />
          <Button
            testID={'buttonInviteClassmates'}
            text={t('createGroupSuccess.shareInviteLink')}
            type={'secondary-base'}
            onPress={onShareInvite}
            size="2xl"
            flex
            flexScreenContainer="stack"
          />
        </Footer>
      </ScrollContent>
    </StackScreenLayout>
  );
}
