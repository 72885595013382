import React from 'react';
import { useMutation } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import useAppLocale from '~/data/hooks/useAppLocale';
import { FORGET_PASSWORD } from '~/data/operations/auth';
import { AuthStackParamList } from '~/navigation/types';
import ForgotPasswordLayout from './layout';

type ForgotPasswordNavProp = StackNavigationProp<
  AuthStackParamList,
  'ForgotPassword'
>;
type ForgotPasswordRouteProp = RouteProp<AuthStackParamList, 'ForgotPassword'>;

export default function ForgotPassword(): JSX.Element {
  const navigation = useNavigation<ForgotPasswordNavProp>();
  const {
    params: { email },
  } = useRoute<ForgotPasswordRouteProp>();

  const { locale, onChangeLocale } = useAppLocale({
    onReload: () => navigation.replace('ForgotPassword', { email }),
  });

  const [forgetPassword, { loading }] = useMutation(FORGET_PASSWORD);

  const onForgotPassword = async (email: string) => {
    try {
      const { data } = await forgetPassword({
        variables: {
          input: { email },
        },
      });
      const errors = data?.forgetPassword?.errors;
      if (errors && errors.length > 0) {
        const messages = errors.map((error) => error?.messages[0]);
        const errorMessage = messages?.[0] as string;
        Snackbar.show(errorMessage);
        return;
      }
      navigation.navigate('ForgotPasswordInstruction');
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };
  return (
    <ForgotPasswordLayout
      redirectedEmail={email}
      loading={loading}
      selectedLocale={locale}
      onBack={() => navigation.goBack()}
      onForgotPassword={onForgotPassword}
      onSelectedLocale={onChangeLocale}
    />
  );
}
