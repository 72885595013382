import styled from 'styled-components/native';

export const ScrollView = styled.ScrollView`
  padding-horizontal: 16px;
`;

export const Image = styled.Image`
  margin-top: 22px;
  width: 100%;
  height: 219px;
  border-radius: 15px;
`;

export const Title = styled.Text`
  margin-top: 32px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 24px;
  padding-horizontal: 16px;
`;

export const Descp = styled.Text`
  margin-top: 32px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
  line-height: 25px;
  padding-horizontal: 16px;
`;

export const CheckRow = styled.View`
  padding-vertical: 56px;
  flex-direction: row;
`;

export const CheckText = styled.Text`
  flex: 1;
  margin-left: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
  line-height: 20px;
`;

export const Footer = styled.View`
  padding: 16px;
`;

export const SuccessTitle = styled.Text`
  margin-top: 32px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.error};
  font-size: 38px;
  padding-horizontal: 16px;
`;
