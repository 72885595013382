import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import AdminHeader from '~/components/AdminHeader';
import LanguageDropdown from '~/components/LanguageDropdown';
import SettingsDatabase from '~/components/SettingsDatabase';
import { WebContainer } from '~/components/WebGrid';
import {
  Container,
  FormContainer,
  SectionLabel,
  Spacer,
} from '~/components/common/style.web';
import { AppLocale } from '~/data/models/app';
import { AdminUser } from '~/data/models/user';
import { UpgradeUserInput } from '~/screens/Admin/Settings';
import { t } from '~/utils/i18n';
import {
  SpacedFlexRow,
  LanguageContainer,
  LanguageAbsolutePlaceholder,
  SettingsContainer,
} from './style';

type SettingsLayoutProps = {
  onBack: () => void;
  agents: AdminUser[];
  users: AdminUser[];
  agentsTotalCount: number;
  loading: boolean;
  onOpenDrawer: () => void;
  selectedLocale: AppLocale;
  onSelectedLocale: (language: AppLocale) => void;
  onUserUpgrade: (id: string) => void;
  onUserDowngrade: (id: string) => void;
  onAddAgent: (data: UpgradeUserInput) => void;
  onRemoveAgent: (id: string) => void;
  setFilterQuery: (value: string) => void;
  filterQuery: string;
  setCurrentActiveTab: (tab: number) => void;
  setUserSearchValue: (query: string) => void;
  rowsPerPage?: number;
  setRowsPerPage?: (rowsPerPage: number) => void;
};

// const defaultOptions: OptionsInfo[] = [
//   {
//     key: 'global',
//     name: '🌏 Global',
//   },
// ];

export default function Settings({
  onBack,
  agents,
  users,
  agentsTotalCount,
  loading,
  onOpenDrawer,
  selectedLocale,
  onSelectedLocale,
  onUserUpgrade,
  onUserDowngrade,
  onAddAgent,
  onRemoveAgent,
  setFilterQuery,
  filterQuery,
  setCurrentActiveTab,
  setUserSearchValue,
  rowsPerPage,
  setRowsPerPage,
}: SettingsLayoutProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });
  const isMobile = !isDesktop;

  return (
    <Container>
      <AdminHeader
        title={t('screens.settings')}
        mobileTitle={t('screens.settings')}
        onBack={onBack}
        breadcrumbs={['Search']}
        buttonTitle={t('screens.settings')}
        onOpenDrawer={onOpenDrawer}
      />
      <WebContainer isDesktop={isDesktop}>
        <FormContainer isDesktop={isDesktop}>
          <SpacedFlexRow isDesktop={isDesktop}>
            <SettingsContainer>
              <SectionLabel>{t('settingsPanel.language')}</SectionLabel>
              <LanguageContainer isDesktop={isDesktop}>
                <LanguageAbsolutePlaceholder>
                  <LanguageDropdown
                    testID={'languageDropdown'}
                    selectedLocale={selectedLocale}
                    onSelectedLocale={onSelectedLocale}
                    openToSide={isMobile}
                  />
                </LanguageAbsolutePlaceholder>
              </LanguageContainer>
            </SettingsContainer>

            {
              // TODO: Uncomment when functionality for this is clear (future ticket)
              /* <SearchSettingsContainer>
              <SectionLabel>{t('settingsPanel.default')}</SectionLabel>
              <Spacer v={20} />
              <DropDownContainer>
                <Dropdown
                  testID={'Dropdown'}
                  selectedLocale={selectedSettings}
                  selectableOptions={defaultOptions}
                  onSelectedLocale={setSelectedSettings}
                />
              </DropDownContainer>
            </SearchSettingsContainer> */
            }
          </SpacedFlexRow>
          <Spacer v={isDesktop ? 160 : 80} />
          <SettingsDatabase
            agents={agents}
            users={users}
            agentsTotalCount={agentsTotalCount}
            loading={loading}
            selectedLocale={selectedLocale}
            onUserUpgrade={onUserUpgrade}
            onUserDowngrade={onUserDowngrade}
            onAddAgent={onAddAgent}
            onRemoveAgent={onRemoveAgent}
            setFilterQuery={setFilterQuery}
            filterQuery={filterQuery}
            setCurrentActiveTab={setCurrentActiveTab}
            setUserSearchValue={setUserSearchValue}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </FormContainer>
      </WebContainer>
    </Container>
  );
}
