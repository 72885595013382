import styled from 'styled-components/native';

export const ScrollView = styled.ScrollView`
  padding: 16px;
`;

export const VSpacer = styled.View`
  height: 24px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c9,
}))``;
