import styled from 'styled-components/native';
import Avatar from '~/components/Avatar';
import { ScrollView } from '~/components/TextEditor/style';

export const JoinedUserContainer = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  height: 80px;
`;

export const JoinedUser = styled.Text`
  size: 18px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const NumberOfAttendees = styled.Text`
  size: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  padding-bottom: 10px;
`;

export const AttendeeAvatar = styled(Avatar).attrs({
  size: 48,
})`
  border-radius: 60px;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

export const AttendeeName = styled.Text`
  size: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const AttendeeContainer = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  align-items: center;
  padding-bottom: 15px;
`;

export const AttendeeLine = styled.View`
  height: 1px;
  background-color: ${({ theme }) => theme.color.base.c3};
  width: 100%;
`;

export const AttendeesScrollView = styled(ScrollView)`
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 30px 0;
  padding: 0 20px 0 20px;
`;

export const FlatList = styled.FlatList`
  overflow-x: scroll;
  background-color: ${({ theme }) => theme.color.base.c0};

  flex-direction: column;
`;

export const RemoveAttendanceContainer = styled.View`
  padding: 20px 15px 0 15px;
`;
