import { useMutation } from '@apollo/client';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import {
  CREATE_POLL_VOTE,
  DELETE_POLL_VOTE,
  POST,
} from '~/data/operations/post';

type PollVoteHookProps = {
  onPollVote: (postId: string, optionId: string, revoteId?: string) => void;
};

const usePollVote = (): PollVoteHookProps => {
  const [createPollVote] = useMutation(CREATE_POLL_VOTE);
  const [deletePollVote] = useMutation(DELETE_POLL_VOTE);

  const { authUserGroupId } = useAuth();

  const onPollVote = async (
    postId: string,
    optionId: string,
    revoteId?: string,
  ) => {
    try {
      if (revoteId) {
        await deletePollVote({
          variables: {
            input: {
              pollOptionId: revoteId,
            },
          },
        });
      }
      await createPollVote({
        variables: {
          input: {
            pollOption: optionId,
          },
        },
        refetchQueries: [
          {
            query: POST,
            variables: {
              id: postId,
              authUserGroupId,
            },
          },
        ],
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };
  return {
    onPollVote,
  };
};

export default usePollVote;
